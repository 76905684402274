import { FiSearch, FiX } from 'react-icons/fi'
import styled from 'styled-components'
import { FormInput40 } from '../../styled/formElements'

const InputContainer = styled.div`
    height: 4rem;
    position: relative;
`

const SearchIcon = styled(FiSearch)`
    position: absolute;
    top: 1.4rem;
    left: 1rem;
    color: ${({ theme }) => theme.primar};
`

const CloseIcon = styled(FiX)`
    color: ${({ theme }) => theme.black};

    cursor: pointer;
    font-size: 1.6rem;
    position: absolute;
    top: 2.4rem;
    right: 1rem;
    transform: translateY(-50%);
`

interface SearchInputProps {
    onChangeCB?: (text: string) => void
    placeholder: string
    onClearSearch?: () => void
    text: string
}

const SearchInput: React.FC<SearchInputProps> = ({
    onChangeCB = () => {},
    placeholder,
    onClearSearch = () => {},
    text,
}) => {
    const handleTextChange = (value: string) => {
        onChangeCB(value)
    }

    return (
        <InputContainer>
            <SearchIcon size={18} />
            <FormInput40
                value={text}
                onChange={(e) => handleTextChange(e.target.value)}
                placeholder={placeholder}
                type="text"
                style={{
                    padding: '0 4rem',
                }}
            />
            {text.length > 1 && <CloseIcon onClick={onClearSearch} />}
        </InputContainer>
    )
}

export default SearchInput
