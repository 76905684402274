import { useTypedSelector } from './useTypedSelector'

export const useIsPartner = () => {
    const { isLoggedIn, user } = useTypedSelector((state) => state.auth)

    if (isLoggedIn && user?.roles.includes('partner')) {
        return true
    } else {
        return false
    }
}
