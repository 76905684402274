import './index.css'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from './state'
import ThemeProvider from './providers/ThemeProvider'
import Header from './components/layout/header/Header'
import ActiveModal from './components/layout/ActiveModal'
import ReportingChannelHomepage from './components/pages/whistleblower/homepage/ReportingChannelHomepage'
import LoginPage from './components/pages/auth/login/LoginPage'
import VerifyEmailPage from './components/pages/auth/verify-email/VerifyEmailPage'
import NewCasePage from './components/pages/whistleblower/new-case/NewCasePage'
import ProtectedCaseworkerRoute from './components/routing/ProtectedCaseworkerRoute'
import ProtectedUnauthenticatedRoute from './components/routing/ProtectedUnauthenticatedRoute'
import ProtectedWhistleblowerRoute from './components/routing/ProtectedWhistleblowerRoute'
// import RegisterPage from './components/pages/auth/register/RegisterPage'
import { useTheme } from './hooks/useTheme'
import WhistleblowerAccessReportPage from './components/pages/whistleblower/access-report/WhistleblowerAccessReportPage'
import DashboardPage from './components/pages/partners/dashboard/DashboardPage'
import WhistleblowerMagicLogin from './components/pages/redirects/WhistleblowerMagicLogin'
import OrganizationalCode404 from './components/pages/404/OrganizationalCode404'
import ClientsPage from './components/pages/partners/clients/ClientsPage'
import StatisticsPage from './components/pages/partners/statistics/StatisticsPage'
import SingleCase from './components/pages/partners/single-case/SingleCase'
import SettingsPage from './components/pages/shared/settings/SettingsPage'
import { useEffect, useState } from 'react'
import { useActions, useTypedSelector } from './hooks'
import WhistleblowerUnauthenticated from './components/pages/auth/whistleblower-unauthenticated/WhistleblowerUnauthenticated'
import ProtectedUnauthenticatedWhistleblowerRoute from './components/routing/ProtectedUnauthenticatedWhistleblowerRoute'
import WhistleblowerLoading from './components/pages/loading/WhistleblowerLoading'
import CasesPage from './components/pages/partners/cases/CasesPage'
import {
    getFirstSubdomain,
    getSecondSubdomain,
    getUrlAfterAppSubdomain,
} from './helpers/routing'
import ProtectedAdminRoute from './components/routing/ProtectedAdminRoute'
import AdminPage from './components/pages/admin/AdminPage'
import ClientInformationPage from './components/pages/clients/ClientInformationPage'
import MagicLogin from './components/pages/redirects/MagicLogin'
import Logout from './components/pages/redirects/Logout'
import AddNewClient from './components/pages/partners/add-new-client/AddNewClient'
import ProtectedClientRoute from './components/routing/ProtectedClientRoute'
import ProtectedPartnerRoute from './components/routing/ProtectedPartnerRoute'
import { useIdleTimer } from 'react-idle-timer'
import InactiveModal from './components/layout/InactiveModal'
import config from './config'
import Echo from 'laravel-echo'
// import ToSmallScreenModal from './components/layout/ToSmallScreenModal'
import ForwardCasePage from './components/pages/partners/forward-case/ForwardReportPage'
import ResetPasswordPage from './components/pages/auth/reset-password/ResetPasswordPage'
import ProtectedContactRoute from './components/routing/ProtectedContactRoute'
import ContactDashboardPage from './components/pages/contact-users/dashboard/ContactDashboardPage'
import ContactAllPages from './components/pages/contact-users/all-cases/ContactAllCases'
import ContactClosedCasesPage from './components/pages/contact-users/closed-cases/ContactClosedCasesPage'
import NewestRegisterPage from './components/pages/auth/newest-register/NewestRegisterPage'
import ClientHomepage from './components/pages/clients/ClientHomepage'
import HowItWorksPage from './components/pages/whistleblower/how-it-works/HowItWorksPage'
import { useLocation } from 'react-router-dom'
import OnboardingPage from './components/pages/clients/OnboardingPage'
import axios from 'axios'
import ChangedEmailVerify from './components/pages/redirects/ChangedEmailVerify'
import WhistleblowerPolicyResolver from './components/pages/whistleblower/whistleblower-policy/WhistleblowerPolicyResolver'

const Base = () => {
    return (
        <Router>
            <Provider store={store}>
                <ThemeProvider>
                    <div style={{ position: 'relative', overflowX: 'hidden' }}>
                        <ActiveModal />
                        {window.location.pathname !== '/new-register' && (
                            <Header />
                        )}
                        <App />
                    </div>
                </ThemeProvider>
            </Provider>
        </Router>
    )
}

const App = () => {
    const { env, pusherKey } = config
    const theme = useTheme()
    const [platform, setPlatform] = useState<string | null>('')
    const {
        getCategories,
        addCountries,
        getSelf,
        login,
        getNotifications,
        showLatestNotification,
        getPaymentInformation,
        contactGetOrganizations,
        pingAPI,
        changeLanguage,
        getIdentity,
    } = useActions()

    // Configure axios headers
    useEffect(() => {
        axios.defaults.headers.common['X-Frame-Options'] = 'DENY'
        axios.defaults.headers.common['X-Content-Type-Options'] = 'nosniff'
        axios.defaults.headers.common['Referrer-Policy'] =
            'strict-origin-when-cross-origin'
    }, [])

    useEffect(() => {
        // @ts-ignore
        window.Pusher = require('pusher-js')

        // @ts-ignore
        window.Echo = new Echo({
            broadcaster: 'pusher',
            key: pusherKey,
            cluster: 'eu',
            forceTLS: true,
        })
        // eslint-disable-next-line
    }, [])

    const [isInactiveModalActive, setIsInactiveModalActive] = useState(false)

    const { isWhistleblowerLoggedIn } = useTypedSelector(
        (state) => state.whistleblower
    )

    const { selectedLanguage } = useTypedSelector((state) => state.identity)

    useEffect(() => {
        if (selectedLanguage) {
            changeLanguage(selectedLanguage)
        }
        //eslint-disable-next-line
    }, [selectedLanguage])

    const { user } = useTypedSelector((state) => state.auth)

    useEffect(() => {
        if (user) {
            getIdentity()
        }
        //eslint-disable-next-line
    }, [user])

    useEffect(() => {
        if (user) {
            setTimeout(() => {
                alert(
                    'You have been on the platform for two hours which is the max length of a session. To continue using the platform please login again. You will be redirected to the login'
                )

                localStorage.clear()

                window.location.replace(`http://${getUrlAfterAppSubdomain()}`)
            }, 7199940)

            // Event listener to logout user if he closes the tab
            /* window.addEventListener('beforeunload', function (event) {
                return (event.returnValue = 'Are you sure you want to close?')
            }) */
        }
    }, [user])

    // Set the schedule to ping the backend every minute to keep the API token alive
    useEffect(() => {
        if (user) {
            setInterval(() => {
                pingAPI()
            }, 60000)
        }

        // eslint-disable-next-line
    }, [user])

    const { organization } = useTypedSelector((state) => state.organization)

    const { shouldShowTrialInformation, isTrialExpired } = useTypedSelector(
        (state) => state.payment
    )

    useEffect(() => {
        if (user) {
            // @ts-ignore
            window.Echo.channel(`notification-user-${user.userId}`).listen(
                '.Illuminate\\Notifications\\Events\\BroadcastNotificationCreated',
                (notification: any) => {
                    showLatestNotification(notification)
                }
            )
        }
        // eslint-disable-next-line
    }, [user])

    // Get organization for contact
    useEffect(() => {
        if (!user || organization) {
            return
        }

        if (user.roles.includes('contact') && !user.roles.includes('client')) {
            contactGetOrganizations(user.userId)
        }

        // eslint-disable-next-line
    }, [user, organization])

    useEffect(() => {
        if (user) {
            getNotifications()
        }
        // eslint-disable-next-line
    }, [user])

    const handleOnIdle = () => {
        setIsInactiveModalActive(true)
    }

    useIdleTimer({
        onIdle: handleOnIdle,
        timeout: 1000 * 60 * 14,
        crossTab: true,
        debounce: 500,
    })

    useEffect(() => {
        const subDomain = window.location.host.split('.')[1]
        if (
            subDomain === 'partner' ||
            subDomain === 'whistleblower' ||
            subDomain === 'client' ||
            subDomain === 'admin' ||
            subDomain === 'contact'
        ) {
            setPlatform(subDomain)
        } else if (subDomain === 'undefined') {
        } else if (subDomain === 'walor') {
            setPlatform(null)
        }
    }, [])

    useEffect(() => {
        if (env === 'local') {
            const userType = getSecondSubdomain()
            if (
                userType === 'admin' ||
                userType === 'partner' ||
                userType === 'caseworker' ||
                userType === 'client' ||
                userType === 'contact'
            ) {
                const isLoggedIn = localStorage.getItem('isLoggedIn')
                if (isLoggedIn) {
                    const user = localStorage.getItem('user')
                    if (user) {
                        const userJson = JSON.parse(user)

                        login(userJson)
                    }
                }
            }
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (getFirstSubdomain() !== 'app') {
            window.location.replace(`http://app.${getUrlAfterAppSubdomain()}`)
        }
    }, [])

    // useEffect(() => {
    //     if (getFirstSubdomain() === 'app') {
    //         const minusApp = window.location.host.replace('app.', '')
    //         if (minusApp === getUrlAfterAppSubdomain()) {
    //             const isLoggedIn = localStorage.getItem('isLoggedIn')
    //             const userType = localStorage.getItem('userType')
    //             const correctUser =
    //                 userType === 'admin' ||
    //                 userType === 'partner' ||
    //                 userType === 'caseworker'

    //             const subdomain =
    //                 userType === 'caseworker' ? 'partner' : userType
    //             if (isLoggedIn && correctUser) {
    //                 window.location.replace(
    //                     `http://app.${subdomain}.${getUrlAfterAppSubdomain()}`
    //                 )
    //             }
    //         }
    //     }
    // }, [])

    useEffect(() => {
        getCategories()
        addCountries()

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const userType = getSecondSubdomain()
        if (userType === 'partner') {
            if (user) {
                getSelf()
            }
        }
        // eslint-disable-next-line
    }, [user])

    useEffect(() => {
        if (!user || user.temp) {
            return
        }

        if (user.roles.includes('client')) {
            getPaymentInformation(user.userId)
        }
        // eslint-disable-next-line
    }, [user])

    const location = useLocation()

    return (
        <div
            className="app"
            style={{
                background: theme.platformGrey,
                minHeight: 'calc(100vh - 8.5rem)',
                margin: '0 auto',
            }}
        >
            <div
                style={{
                    maxWidth: '160rem',
                    margin: '0 auto',
                    filter:
                        isTrialExpired &&
                        shouldShowTrialInformation &&
                        location.pathname !== '/settings'
                            ? 'blur(4px)'
                            : '',
                }}
            >
                {isInactiveModalActive &&
                    (getSecondSubdomain() === 'partner' ||
                        getSecondSubdomain() === 'whistleblower') &&
                    (isWhistleblowerLoggedIn || user) && (
                        <InactiveModal
                            closeModal={() => setIsInactiveModalActive(false)}
                        />
                    )}
                {/* {platform !== 'whistleblower' && <ToSmallScreenModal />} */}
                {/*<EnterPasswordModal />*/}
                <Switch>
                    <Route
                        path="/forward-report/:id"
                        component={ForwardCasePage}
                    />
                    <Route path="/onboarding" component={OnboardingPage} />
                    {/*<Route path="/new-register" component={NewRegisterPage} /> */}
                    <Route
                        exact
                        path={['/register/', '/register/:partnerId']}
                        component={NewestRegisterPage}
                    />
                    {/* Routes accessable before login in */}
                    <Route
                        path="/magic-login/:magicLoginToken"
                        component={MagicLogin}
                    />
                    {!platform && (
                        <>
                            {/* <ProtectedUnauthenticatedRoute
                            path="/onboarding"
                            component={RegisterPage}
                        /> */}

                            <ProtectedUnauthenticatedRoute
                                exact
                                path="/"
                                component={LoginPage}
                            />
                            <ProtectedUnauthenticatedRoute
                                exact
                                path="/reset-password/:token"
                                component={ResetPasswordPage}
                            />
                            <ProtectedUnauthenticatedRoute
                                path="/verify-email/:requestNumber/:expires/:hash/:signature"
                                component={VerifyEmailPage}
                            />
                            <Route
                                path="/changed-email-verify/:verificationToken"
                                component={ChangedEmailVerify}
                            />
                            <Route exact path="/logout" component={Logout} />
                        </>
                    )}

                    {platform === 'whistleblower' && (
                        <>
                            <ProtectedWhistleblowerRoute
                                exact
                                path="/"
                                component={ReportingChannelHomepage}
                            />

                            <Route
                                path="/organization/:id/:loginType"
                                component={WhistleblowerMagicLogin}
                            />

                            <Route
                                exact
                                path="/not-found/organizational-code"
                                component={OrganizationalCode404}
                            />

                            {/* Routes accessable for whistleblowers */}
                            <ProtectedWhistleblowerRoute
                                path="/create-report"
                                component={NewCasePage}
                            />
                            <ProtectedWhistleblowerRoute
                                path="/whistleblower-policy"
                                component={WhistleblowerPolicyResolver}
                            />
                            <ProtectedWhistleblowerRoute
                                path="/how-it-works"
                                component={HowItWorksPage}
                            />
                            <ProtectedWhistleblowerRoute
                                path="/access-report"
                                component={WhistleblowerAccessReportPage}
                            />
                            <ProtectedUnauthenticatedWhistleblowerRoute
                                path="/not-assigned-to-company"
                                component={WhistleblowerUnauthenticated}
                            />
                            <ProtectedUnauthenticatedWhistleblowerRoute
                                path="/loading"
                                component={WhistleblowerLoading}
                            />
                        </>
                    )}

                    {platform === 'partner' && (
                        <>
                            <ProtectedCaseworkerRoute
                                exact
                                path="/"
                                component={DashboardPage}
                                Pan
                                Idræt
                            />

                            <ProtectedCaseworkerRoute
                                exact
                                path="/cases"
                                component={CasesPage}
                            />

                            <ProtectedPartnerRoute
                                exact
                                path="/clients"
                                component={ClientsPage}
                            />

                            <ProtectedCaseworkerRoute
                                exact
                                path="/statistics"
                                component={StatisticsPage}
                            />

                            <ProtectedCaseworkerRoute
                                exact
                                path="/report/:id"
                                component={SingleCase}
                            />

                            <ProtectedCaseworkerRoute
                                exact
                                path="/settings"
                                component={SettingsPage}
                            />

                            <ProtectedCaseworkerRoute
                                exact
                                path="/add-new-client"
                                component={AddNewClient}
                            />
                        </>
                    )}

                    {platform === 'client' && (
                        <>
                            <ProtectedClientRoute
                                exact
                                path="/"
                                component={ClientHomepage}
                            />
                            <ProtectedClientRoute
                                exact
                                path="/settings"
                                component={SettingsPage}
                            />
                        </>
                    )}
                    {platform === 'contact' && (
                        <>
                            <ProtectedContactRoute
                                exact
                                path="/"
                                component={ContactDashboardPage}
                            />
                            {/*<ProtectedContactRoute
                                exact
                                path="/"
                                component={ClientHomepage}
                            /> */}
                            <ProtectedContactRoute
                                exact
                                path="/report/:id"
                                component={SingleCase}
                            />
                            <ProtectedContactRoute
                                exact
                                path="/cases"
                                component={ContactAllPages}
                            />
                            <ProtectedContactRoute
                                exact
                                path="/closed-cases"
                                component={ContactClosedCasesPage}
                            />
                            <ProtectedContactRoute
                                exact
                                path="/settings"
                                component={SettingsPage}
                            />
                            {user?.roles.includes('client') && (
                                <>
                                    <ProtectedContactRoute
                                        exact
                                        path="/organization"
                                        component={ClientInformationPage}
                                    />
                                </>
                            )}
                        </>
                    )}

                    {platform === 'contact' && (
                        <>
                            <ProtectedContactRoute
                                exact
                                path="/"
                                component={ContactDashboardPage}
                            />
                            <ProtectedContactRoute
                                exact
                                path="/report/:id"
                                component={SingleCase}
                            />
                            <ProtectedContactRoute
                                exact
                                path="/cases"
                                component={ContactAllPages}
                            />
                            <ProtectedContactRoute
                                exact
                                path="/closed-cases"
                                component={ContactClosedCasesPage}
                            />
                            <ProtectedContactRoute
                                exact
                                path="/settings"
                                component={SettingsPage}
                            />
                            {user?.roles.includes('client') && (
                                <>
                                    <ProtectedContactRoute
                                        exact
                                        path="/organization"
                                        component={ClientInformationPage}
                                    />
                                </>
                            )}
                        </>
                    )}

                    {platform === 'admin' && (
                        <>
                            <ProtectedAdminRoute
                                exact
                                path="/"
                                component={AdminPage}
                            />
                        </>
                    )}
                </Switch>
            </div>
        </div>
    )
}

ReactDOM.render(<Base />, document.querySelector('#root'))
