import React from 'react'
import ClosedOrForwardedCasesTemplate from '../../../reusable/templates/ClosedOrForwardedCasesTemplate'

interface ContactClosedCasesPageProps {}

const ContactClosedCasesPage: React.FC<ContactClosedCasesPageProps> = () => {
    return <>
        <ClosedOrForwardedCasesTemplate />
    </>
}

export default ContactClosedCasesPage