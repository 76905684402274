import axios from 'axios'
import config from '../../config'
import { CountriesActionType } from '../action-types'
import { Dispatch } from 'redux'


const { apiUrl } = config

export const addCountries = () => async (dispatch: Dispatch) => {
    const res = await axios.get(`${apiUrl}/v1/countries`)

    return dispatch({
        type: CountriesActionType.ADD_COUNTRIES,
        payload: {
            countries: res.data.payload,
        },
    })
}
