import React, { useEffect } from 'react'
import styled from 'styled-components'
import { FlexJustSpaceBetween } from '../../styled/flex'
import { HeadingSecondary } from '../../styled/typography'
import SearchInput from '../form/SearchInput'
import Cases from '../cases/Cases'
import { useTranslation, useActions, useTypedSelector } from '../../../hooks'

const Container = styled.div`
    width: 100%;
    padding: 4rem;

    position: relative;
`

const CasesContainer = styled.div`
    margin-top: 2rem;
    height: 40rem;
`

const ClosedOrForwardedCasesTemplate: React.FC = () => {
    const translation = useTranslation()

    const { getClosedReports, searchClosedReports } = useActions()

    const { closedReportsFiltered, closedReportsSearchString } = useTypedSelector(state => state.caseworker)

    const handleSearch = (text: string) => {
        searchClosedReports(text)
    }


    useEffect(() => {
        getClosedReports()

        // eslint-disable-next-line
    }, [])

    return  <Container>
        <FlexJustSpaceBetween>
            <HeadingSecondary>
                {translation.casesPartner.headlineClosedCases}
            </HeadingSecondary>
            <SearchInput
                placeholder={translation.casesPartner.searchCases}
                text={closedReportsSearchString}
                onChangeCB={handleSearch}
                onClearSearch={() => searchClosedReports('')}
            />
        </FlexJustSpaceBetween>
        <CasesContainer>
            {closedReportsFiltered && (
                <Cases
                    cases={closedReportsFiltered}
                    title={translation.casesPartner.closedCases}
                    isAllClosed={true}
                    height='27.2rem'
                />
            )}
        </CasesContainer>
    </Container>
}

export default ClosedOrForwardedCasesTemplate