import styled from 'styled-components'
import { NextButton } from '../../../../reusable/buttons'
import { saveAs } from 'file-saver'
import { useEffect, useState } from 'react'
import { useTranslation } from '../../../../../hooks'

const ModalContainer = styled.div`
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);

    position: fixed;
    top: 0;
    left: 0;
    z-index: 500;
`

const Modal = styled.div`
    height: 48rem;
    width: 48rem;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: ${({ theme }) => theme.white};
    z-index: 501;
    text-align: center;
    border-radius: 2rem;
    padding: 0 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 480px) {
        width: 35rem;
    }
`
const ModalHeading = styled.h4`
    margin-inline-start: 40rem;
    padding-block-start: 2rem;
    padding-block-end: 4rem;
    @media screen and (max-width: 480px) {
        margin-inline-start: 25rem;
    }
`

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    letter-spacing: -0.02rem;
`
const CloseModal = styled.button`
    background: none;
    border: none;
    opacity: 0.5;
    font-size: 1.7rem;
    cursor: pointer;

    &:hover {
        color: ${({ theme }) => theme.primary};
    }
`
const Title = styled.h2`
    font-size: 1.8rem;
`

interface ReportingChanelQRmodalProps {
    handleCloseModal: () => void
    url: string
}

const ReportingChanelQRmodal: React.FC<ReportingChanelQRmodalProps> = ({
    handleCloseModal,
    url,
}) => {
    const [qrCode, setQrCode] = useState('')

    useEffect(() => {
        setQrCode(url)
    }, [url])

    const downloadImage = () => {
        saveAs(qrCode, 'QR-code.png')
    }

    const translation = useTranslation()

    return (
        <ModalContainer>
            <Modal>
                <ModalHeading>
                    <CloseModal onClick={handleCloseModal}>
                        {translation.closeButton.close}
                    </CloseModal>
                </ModalHeading>
                <ModalContent>
                    <Title>{translation.clientView.reportingChannel}</Title>
                    <img src={qrCode} alt="QR-code" />
                    <NextButton
                        text={translation.clientView.downloadQRCodeImage}
                        icon={false}
                        isActive={true}
                        width="16rem"
                        height="3.5rem"
                        fontSize="1.4rem"
                        handleOnClick={downloadImage}
                    />
                </ModalContent>
            </Modal>
        </ModalContainer>
    )
}

export default ReportingChanelQRmodal
