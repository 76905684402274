import produce from 'immer'
import { AuthActionType } from '../action-types'
import { AuthAction } from '../actions'
import User from '../state-types/UserStateType'

interface AuthState {
    authLoading: boolean
    authError: string | null
    isLoggedIn: boolean
    user: User | null
    loginStage:
        | 'verify-credentials'
        | 'send-2fa-token'
        | 'verify-2fa-token'
        | 'done'
    validationErrors: any
    newOrganizationCode: null | string
    successResponse: null | string
    dynamicLoginError: any
}

const initialState: AuthState = {
    authLoading: false,
    authError: null,
    isLoggedIn: false,
    user: null,
    loginStage: 'verify-credentials',
    validationErrors: null,
    newOrganizationCode: null,
    successResponse: null,
    dynamicLoginError: null,
}

const reducer = produce(
    (state: AuthState = initialState, action: AuthAction) => {
        switch (action.type) {
            case AuthActionType.REGISTER_USER:
                state.authLoading = false
                state.authError = null
                state.validationErrors = null

                return state

            case AuthActionType.SELF_REGISTER_ORGANIZATION:
                state.authLoading = false
                state.authError = null
                state.validationErrors = null

                return state

            case AuthActionType.ONBOARDING_CREATE_LINE_OF_CONTACT:
                if (state.user) {
                    state.user.roles = action.payload
                }

                state.authLoading = false

                return state

            case AuthActionType.ONBOARDING_GENERATE_WHISTLEBLOWER_POLICY:
                state.authLoading = false
                state.authError = null

                return state

            case AuthActionType.ORGANIZATION_FINISH_ONBOARDING:
                state.authLoading = false
                state.authError = null
                if (state.user) {
                    state.user.apiToken = action.payload.token
                }

                return state

            case AuthActionType.REGISTER_ORGANIZATION:
                state.authLoading = false
                state.authError = null
                state.newOrganizationCode = action.payload.code

                return state
            case AuthActionType.VERIFY_LOGIN_CREDENTIALS:
                state.authLoading = false
                state.authError = null
                state.loginStage = 'send-2fa-token'

                return state

            case AuthActionType.SEND_2FA_TOKEN:
                state.authLoading = false
                state.authError = null
                state.loginStage = 'verify-2fa-token'

                return state

            case AuthActionType.LOGIN:
                state.authLoading = false
                state.dynamicLoginError = null
                state.isLoggedIn = true
                state.user = action.payload.user

                return state

            case AuthActionType.SET_LOADING:
                state.authError = null
                state.authLoading = true

                return state

            case AuthActionType.SET_ERROR:
                state.authError = action.payload.message
                state.authLoading = false

                return state

            case AuthActionType.SET_VALIDATION_ERRORS:
                state.authLoading = false
                state.validationErrors = action.payload

                return state

            case AuthActionType.CLEAR_VALIDATION_ERRORS:
                state.authLoading = false
                state.validationErrors = null
                state.dynamicLoginError = null

                return state

            // TODO remove this action completely and use the one above
            case AuthActionType.VERIFY_COMPANY_INFORMATION:
                state.authLoading = false
                state.validationErrors = null

                return state

            case AuthActionType.VERIFY_EMAIL:
                state.authLoading = false
                state.authError = null
                state.successResponse = action.payload.message

                return state

            case AuthActionType.SEND_CLIENT_INFORMATION_EMAIL:
                state.authLoading = false
                state.authError = null

                return state

            case AuthActionType.SET_DYNAMIC_LOGIN_ERROR:
                state.authLoading = false
                state.dynamicLoginError = action.payload

                return state

            default:
                return state
        }
    }
)

export default reducer
