import { useState } from 'react'
import styled from 'styled-components'
import {
    checkQuestion,
    isValidQuestion,
} from '../../../../../helpers/validation/question'
import ButtonSpinnerWhite from '../../../../layout/spinner/ButtonSpinnerWhite'
import { Card, CardHeader } from '../../../../styled/cards'
import {
    ErrorMessage,
    FormField,
    FormInput40,
    PrimaryLabel,
} from '../../../../styled/formElements'

interface AddQuestionProps {
    closeModal: () => void
    handleAddReportQuestion: (questionItem: any) => void
    loading: boolean
}

interface AddQuestionButtonProps {
    loading: boolean
    questionSuccess: boolean
}


const ModalContainer = styled.div`
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 500;
`
const Modal = styled.div`
    height: 28rem;
    width: 55.6rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 501;
`
const CancelButton = styled.p`
    font-size: 1.4rem;
    color: ${({ theme }) => `${theme.primary}50`};
    cursor: pointer;
`
const Form = styled.form`
    padding-top: 5rem;
`
const FormContent = styled.div`
    width: 35rem;
    margin: 0 auto;
`
const Button = styled.div<AddQuestionButtonProps>`
    height: 5rem;
    width: 63%;
    background: ${({ theme}) => theme.primary}50;
    color: ${({ theme }) => theme.white};
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    cursor: pointer;
    margin: 0 auto;
    background: ${({ questionSuccess}) => questionSuccess ? 'rgba(36, 125, 208, 0.7)' : 'rgba(34, 35, 48, 0.5)'};

    &:hover {
        background: ${({ theme }) => theme.blue};
    }
`

const AddQuestionModal: React.FC<AddQuestionProps> = ({
    closeModal,
    handleAddReportQuestion,
    loading,
}) => {
    const [questionText, setQuestionText] = useState('')
    const [questionError, setQuestionError] = useState('')
    const [questionSuccess, setQuestionSuccess] = useState(false)

    const questionValidation = () => {
        setQuestionError(checkQuestion(questionText))

        if (questionError.length === 0) {
            setQuestionSuccess(false)
        } else {
            setQuestionSuccess(true)
        }
    }

    const isFormValidated = () => {
        if (isValidQuestion(questionText)) {
            return true
        } else {
            return false
        }
    }

    const handleSubmitAddQuestion = async () => {
        if (!isFormValidated()) {
            return
        }

        const questionItem = {
            question: questionText,
        }

        await handleAddReportQuestion(questionItem)
        closeModal()
    }

    return (
        <ModalContainer>
            <Modal>
                <Card>
                    <CardHeader>
                        Adding a new question
                        <CancelButton onClick={closeModal}>Cancel</CancelButton>
                    </CardHeader>
                    <Form>
                        <FormContent>
                            <FormField>
                                <PrimaryLabel>New question</PrimaryLabel>
                                <FormInput40
                                    placeholder="New question"
                                    onChange={(e) =>
                                        setQuestionText(e.target.value)
                                    }
                                    value={questionText}
                                    className={
                                        questionError
                                            ? 'border-error'
                                            : questionSuccess
                                            ? 'border-success'
                                            : ''
                                    }
                                    onBlur={questionValidation}
                                />
                                {questionError.length > 1 && (
                                    <ErrorMessage>{questionError}</ErrorMessage>
                                )}
                            </FormField>
                        </FormContent>
                        <Button
                            loading={loading}
                            onClick={handleSubmitAddQuestion}
                            questionSuccess={questionSuccess}
                        >
                            {loading ? <ButtonSpinnerWhite /> : 'Add question'}
                        </Button>
                    </Form>
                </Card>
            </Modal>
        </ModalContainer>
    )
}

export default AddQuestionModal
