import { useTranslation, useTypedSelector } from '../../../../../hooks'
import styled from 'styled-components'
import {
    NewCasePageFrame,
    NewCasePageFrameHeading,
    NewCasePageFrameTagline,
} from '../styled'
import { NextButton } from '../../../../reusable/buttons'
import noProfileImage from '../../../../../assets/images/no-profile-image.svg'
import { useState } from 'react'
import NewCaseContactTooltip from '../components/NewCaseContactTooltip'
import { BiUserX } from 'react-icons/bi'
import NewCaseContactPopup from '../components/NewCaseContactPopup'
import {
    AvatarImage,
    ContactCard,
    ContactContainer,
    ContactHeading,
    ContactHeadingContainer,
    ContactPosition,
    Flag,
    FlagContainer,
    Flex,
    GreyFont,
    LanguagesContainer,
} from '../styled/ChooseContactCard'
import { CountryInterface } from '../../../../../state/reducers/countriesReducer'

const ExcludedContactContainer = styled(ContactContainer)`
    position: relative;
`

export interface IsActiveProp {
    isActive: boolean
}

const ExcludedContactCard = styled(ContactCard) <IsActiveProp>`
    background: ${({ theme, isActive }) =>
        isActive ? theme.red : theme.white};
`

const ExcludedAvatarImage = styled(AvatarImage)`
    background: ${({ theme }) => theme.white};
`

const ExcludedContactHeadingContainer = styled(
    ContactHeadingContainer
) <IsActiveProp>`
    color: ${({ theme, isActive }) => isActive && theme.white};
`

const ExcludeButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15rem;
    height: 3rem;
    border: 1px solid ${({ theme }) => theme.black};
    border-radius: 2rem;
    gap: 0.5rem;
    margin: 0 auto;
    margin-bottom: 4rem;
    cursor: pointer;

    :hover {
        background-color: ${({ theme }) => theme.black};
    }
`

const ExcludeUserIcon = styled(BiUserX)`
    font-size: 1.7rem;

    ${ExcludeButtonContainer}:hover & {
        color: ${({ theme }) => theme.white};
    }
`

const ExcludeButtonText = styled.p`
    font-size: 1.2rem;

    ${ExcludeButtonContainer}:hover & {
        color: ${({ theme }) => theme.white};
    }
`

interface ChooseContactFrameProps {
    updateReport: (key: string, value: Array<any>) => void
    updatePage: () => void
    contacts: any[]
}

const ExcludeModeChooseContactFrame: React.FC<ChooseContactFrameProps> = ({
    updateReport,
    updatePage,
    contacts,
}) => {
    const { lineOfContacts } = useTypedSelector((state) => state.lineOfContact)

    const getDefaultContacts = () => {
        if (contacts && contacts.length > 0) {
            return contacts
        }
        return lineOfContacts.map((contact) => {
            return { ...contact, is_excluded: false }
        })
    }

    const [contactsWithExclude, setContactsWithExclude] = useState(() =>
        getDefaultContacts()
    )

    const [isOpen, setIsOpen] = useState<boolean>(false)

    const translation = useTranslation()

    return (
        <NewCasePageFrame>
            <NewCasePageFrameHeading>
                {
                    translation.newCaseFlow.chooseContactFrame
                        .pickExcludedCaseworker
                }
            </NewCasePageFrameHeading>
            <NewCasePageFrameTagline>
                {
                    translation.newCaseFlow.chooseContactFrame
                        .theContactWillNot
                }
            </NewCasePageFrameTagline>
            <NewCaseContactPopup
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                contactsWithExclude={contactsWithExclude}
                onSave={(contacts: any) =>
                    setContactsWithExclude(contacts)
                }
            />
            <ExcludedContactContainer>
                {contactsWithExclude.map((contact: any) => (
                    <ExcludedContactCard
                        isActive={contact.is_excluded}
                        key={contact.id}
                    >
                        <NewCaseContactTooltip
                            note={contact.contact_information.note}
                        />
                        <Flex>
                            <ExcludedAvatarImage
                                src={
                                    contact.contact_information
                                        .profile_img === null
                                        ? noProfileImage
                                        : contact.contact_information
                                            .profile_img
                                }
                                alt="Profile Image"
                            />
                            <ExcludedContactHeadingContainer
                                isActive={contact.is_excluded}
                            >
                                <ContactHeading>
                                    {contact.first_name +
                                        ' ' +
                                        contact.last_name}
                                </ContactHeading>
                                <ContactPosition>
                                    {contact.contact_information
                                        .position === null
                                        ? 'Position not given'
                                        : contact.contact_information
                                            .position}
                                </ContactPosition>
                            </ExcludedContactHeadingContainer>
                            <LanguagesContainer>
                                <GreyFont>Languages</GreyFont>
                                <FlagContainer>
                                    {contact.languages.length === 0 ? (
                                        <ContactPosition>
                                            Lanugages not given
                                        </ContactPosition>
                                    ) : (
                                        contact.languages.map(
                                            (language: CountryInterface) => (
                                                <Flag
                                                    src={
                                                        language?.flag
                                                    }
                                                />
                                            )
                                        )
                                    )}
                                </FlagContainer>
                            </LanguagesContainer>
                        </Flex>
                    </ExcludedContactCard>
                ))}
            </ExcludedContactContainer>
            <ExcludeButtonContainer
                onClick={() => {
                    setIsOpen(true)
                }}
            >
                <ExcludeUserIcon />
                <ExcludeButtonText>Exclude Person</ExcludeButtonText>
            </ExcludeButtonContainer>
            <NextButton
                handleOnClick={() => {
                    updateReport('contacts', contactsWithExclude)
                    updatePage()
                }}
                isActive={
                    contactsWithExclude.filter(
                        (c: any) => !c.is_excluded
                    ).length > 0
                }
            />
        </NewCasePageFrame>
    )
}

export default ExcludeModeChooseContactFrame
