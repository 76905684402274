import produce from 'immer'
import { AdminActionType } from '../action-types'
import { AdminAction } from '../actions/adminActions'

interface AdminState {
    adminLoading: boolean
    message: null | string
    adminError: null | string
}

const initialState: AdminState = {
    adminLoading: false,
    message: null,
    adminError: null,
}

const reducer = produce(
    (state: AdminState = initialState, action: AdminAction) => {
        switch (action.type) {
            case AdminActionType.CREATE_PARTNER:
                state.adminLoading = false
                state.message = 'You have created a new partner'
                state.adminError = null

                return state
            default:
                return state
        }
    }
)

export default reducer
