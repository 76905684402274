import styled from 'styled-components'
import { useTranslation } from '../../../../../../hooks'

const Container = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
`

const Heading = styled.h3`
    font-size: 3.2rem;
    font-weight: 500;
    color: ${({ theme }) => theme.primary};
`

const Tagline = styled.p`
    margin-top: 2rem;
    font-size: 1.6rem;
    color: ${({ theme }) => theme.primary};
    width: 28rem;
`

const ThanksCheckYourEmail: React.FC = () => {
    const translation = useTranslation()

    return (
        <Container>
            <Heading>{translation.registerPage.thanksCheckYour}</Heading>
            <Tagline>
                {translation.registerPage.rememberToCheckYourSpam} support@walor.io
            </Tagline>
        </Container>
    )
}

export default ThanksCheckYourEmail
