import styled from 'styled-components'

const Container = styled.div`
    padding-top: 6rem;
    width: 100%;
    text-align: center;
`

const Heading = styled.h1`
    font-size: 4.8rem;
    color: ${({ theme }) => theme.primary};
`

const Tagline = styled.h3`
    font-size: 3.6rem;
    color: ${({ theme }) => `${theme.primary}50`};
    padding-top: 1rem;
`

const WhistleblowerUnauthenticated: React.FC = () => {
    return (
        <Container>
            <Heading>403</Heading>
            <Tagline>
                You need to be assigned to a company to access this resource
            </Tagline>
        </Container>
    )
}

export default WhistleblowerUnauthenticated
