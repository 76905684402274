import { FiTrash2, FiPlusCircle } from 'react-icons/fi'
import styled from 'styled-components'
import { AiOutlineFilePdf } from 'react-icons/ai'
import { FaFileCsv } from 'react-icons/fa'
import { AiOutlineFileImage, AiOutlineFileWord } from 'react-icons/ai'
import { useTranslation, useTypedSelector } from '../../../../../hooks'
import { NextButton } from '../../../../reusable/buttons'
import { ErrorMessage, FormInput50 } from '../../../../styled/formElements'
import {
    FileName,
    FilePreview,
    NewCasePageFrame,
    NewCasePageFrameHeading,
    NewCasePageFrameTagline,
    TaglineLight,
} from '../styled'
import InformationYouCouldInclude from '../components/InformationYouCouldInclude'
import { QuestionInterface } from '../NewCasePage'
import { ReportQuestionInterface } from '../../../../../ts/interfaces/ReportQuestionInterface'
import { useState } from 'react'
import { checkAnswer } from '../../../../../helpers/validation/answer'
import { FileIcon } from '../styled/Files'

const AllFieldsTagline = styled.p`
    margin-top: 2rem;
    margin-bottom: 2rem;
    color: ${({ theme }) => `${theme.black}50`};
    font-size: 1.6rem;
`

const ReportForm = styled.form`
    @media screen and (max-width: 600px) {
        margin-block-end: 3rem;
    }
`

const FormField = styled.div`
    width: 50rem;
    text-align: left;
    margin: 1rem 0;

    @media (max-width: 600px) {
        width: 90%;
        margin: 1rem auto;
    }
`

const FormLabel = styled.label`
    font-size: 1.4rem;
`

const FormTextarea = styled.textarea`
    height: 15rem;
    width: 100%;
    border: 2px solid ${({ theme }) => theme.lineGrey};
    border-radius: 0.5rem;
    margin-top: 1rem;
    padding: 1rem;
    font-family: 'NeueMontreal';
`

const FileInputLabel = styled.div`
    min-height: 9.6rem;
    max-height: 20rem;
    width: 100%;
    text-align: center;
    border-radius: 0.5rem;
    border: 2px dotted ${({ theme }) => theme.lineGrey};
    cursor: pointer;
    margin-top: 0.5rem;
    overflow-y: auto;
`

const FIleInputLabelWhite = styled(FileInputLabel)`
    width: 100%;
    text-align: center;
    border-radius: 0.5rem;
    border: 2px dotted ${({ theme }) => theme.lineGrey};
    background: ${({ theme }) => theme.white};
    cursor: default;
`

const AddFileInputLabel = styled.label`
    width: 92%;
    height: 2.5rem;
    padding: 0 1rem;
    margin-inline: 2rem;
    margin-block-end: 1rem;
    border-radius: 1.5rem;
    border: none;
    background-color: #247dd0;
    opacity: 1;
    color: white;
    font-size: 1.4rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    @media screen and (max-width: 600px) {
        width: 88%;
    }
`

const FileInputLabelHeading = styled.h3`
    font-size: 1.6rem;
    color: ${({ theme }) => theme.black};
    padding-top: 3rem;
`
const FileInput = styled.input`
    display: none;
`

const FileInputLabelTagline = styled.p`
    font-size: 1.2rem;
    color: ${({ theme }) => theme.darkColor};
    margin-top: 1.4rem;
    opacity: 0.3;
`

interface FillInReportProps {
    updateReport: (key: string, value: any) => void
    updatePage: () => void
    removeSingleFile: (fileIndex: number) => void
    title: null | string
    description: null | string
    files: Array<File>
    questions: QuestionInterface[]
    setQuestions: React.Dispatch<React.SetStateAction<QuestionInterface[]>>
}

const FillInReport: React.FC<FillInReportProps> = ({
    updateReport,
    updatePage,
    title,
    description,
    files,
    removeSingleFile,
    questions,
    setQuestions,
}) => {
    const translation = useTranslation()
    const { reportQuestions } = useTypedSelector(
        (state) => state.reportQuestions
    )
    const [answer, setAnswer] = useState('')
    const [currentAnswer, setCurrentAnswer] = useState<number>()
    const [answerError, setAnswerError] = useState('')

    const handleAnswerValidation = () => {
        // eslint-disable-next-line
        questions.map((question: QuestionInterface) => {
            if (question.organization_custom_question_id === currentAnswer) {
                const validationError = checkAnswer(answer)
                setAnswerError(validationError)
            }
        })
    }

    const handleAnswerChange = (
        inputValue: string,
        questionItem: ReportQuestionInterface
    ) => {
        const newQuestions = questions.map((question: QuestionInterface) => {
            if (question.organization_custom_question_id === questionItem.id) {
                return {
                    ...question,
                    answer: inputValue,
                }
            }
            return question
        })
        setQuestions(newQuestions)
        updateReport('questions', newQuestions)
    }

    return (
        <NewCasePageFrame>
            <NewCasePageFrameHeading>
                {translation.newCaseFlow.describeTheEvents}
            </NewCasePageFrameHeading>
            <NewCasePageFrameTagline
                style={{
                    marginBottom: 0,
                }}
            >
                {translation.newCaseFlow.theMoreDetailedTheDescription}
            </NewCasePageFrameTagline>
            <AllFieldsTagline>
                {translation.newCaseFlow.allFieldsWith}
            </AllFieldsTagline>
            <ReportForm>
                <FormField>
                    <FormLabel>{translation.newCaseFlow.headline}</FormLabel>
                    <FormInput50
                        placeholder={translation.newCaseFlow.enterHeadline}
                        type="text"
                        value={title !== null ? title : ''}
                        onChange={(e) => updateReport('title', e.target.value)}
                    />
                </FormField>

                {reportQuestions.length !== 0 &&
                    reportQuestions.map((questionItem) => {
                        return (
                            <FormField key={questionItem.id}>
                                <FormLabel>{questionItem.question}</FormLabel>
                                <FormInput50
                                    required
                                    onChange={(e) => {
                                        setCurrentAnswer(questionItem.id)
                                        setAnswer(e.target.value)
                                        handleAnswerChange(
                                            e.target.value,
                                            questionItem
                                        )
                                    }}
                                    onBlur={handleAnswerValidation}
                                ></FormInput50>
                                {answerError && (
                                    <ErrorMessage>{answerError}</ErrorMessage>
                                )}
                            </FormField>
                        )
                    })}

                <FormField>
                    <FormLabel>{translation.newCaseFlow.description}</FormLabel>
                    <FormTextarea
                        placeholder={translation.newCaseFlow.enterDescription}
                        onChange={(e) =>
                            updateReport('description', e.target.value)
                        }
                    >
                        {description}
                    </FormTextarea>
                </FormField>

                {!files.length ? (
                    <FormField>
                        <FormLabel>{translation.newCaseFlow.files}</FormLabel>
                        <FileInputLabel>
                            <label
                                htmlFor="file-input"
                                style={{ cursor: 'pointer' }}
                            >
                                <FileInputLabelHeading>
                                    {translation.newCaseFlow.uploadFiles}
                                </FileInputLabelHeading>
                                <FileInputLabelTagline>
                                    {translation.newCaseFlow.maximumFileSize}
                                </FileInputLabelTagline>
                            </label>
                        </FileInputLabel>

                        <FileInput
                            onChange={(event: any) => {
                                //@ts-ignore
                                updateReport(
                                    'files',
                                    //@ts-ignore
                                    Array.from(event.target.files)
                                )
                            }}
                            name="file-input"
                            id="file-input"
                            type="file"
                            multiple
                        />
                    </FormField>
                ) : (
                    <FormField>
                        <FormLabel>{translation.newCaseFlow.files}</FormLabel>
                        <FIleInputLabelWhite>
                            <div
                                style={{
                                    padding: '1rem 2rem',
                                    marginTop: '-0.5rem',
                                }}
                            >
                                {Array.from(files).map(
                                    (file: any, index: number) => {
                                        return (
                                            <FilePreview key={index}>
                                                {file.file.type ===
                                                'application/pdf' ? (
                                                    <AiOutlineFilePdf
                                                        size={15}
                                                    />
                                                ) : file.file.type ===
                                                  'text/csv' ? (
                                                    <FaFileCsv size={15} />
                                                ) : file.file.type.startsWith(
                                                      'image/'
                                                  ) ? (
                                                    <AiOutlineFileImage
                                                        size={15}
                                                    />
                                                ) : file.file.type ===
                                                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ? (
                                                    <AiOutlineFileWord
                                                        size={15}
                                                    />
                                                ) : (
                                                    <FileIcon />
                                                )}
                                                <FileName>
                                                    {file.file.name}
                                                </FileName>
                                                <FiTrash2
                                                    size={15}
                                                    onClick={() =>
                                                        removeSingleFile(index)
                                                    }
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                />
                                            </FilePreview>
                                        )
                                    }
                                )}
                            </div>
                            <div>
                                <FileInput
                                    onChange={(event: any) => {
                                        updateReport(
                                            'files',
                                            Array.from(event.target.files)
                                        )
                                    }}
                                    type="file"
                                    name="file-input"
                                    id="file-input"
                                    multiple
                                />
                                <AddFileInputLabel htmlFor="file-input">
                                    {translation.newCaseFlow.addFiles}
                                    <FiPlusCircle />
                                </AddFileInputLabel>
                            </div>
                        </FIleInputLabelWhite>
                    </FormField>
                )}

                <FormField>
                    <TaglineLight>
                        {
                            translation.newCaseFlow
                                .youShouldBeAwareThatFilesCanContainMetadata
                        }
                    </TaglineLight>
                </FormField>
            </ReportForm>
            <NextButton
                handleOnClick={updatePage}
                isActive={title && description ? true : false}
            />
            <InformationYouCouldInclude />
        </NewCasePageFrame>
    )
}

export default FillInReport
