import { useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation, useTypedSelector } from '../../../hooks'
import Message from './Message'

const StyledMessages = styled.div`
    height: 31.4rem;
    overflow-y: auto;
    overflow-x: hidden;

    ::-webkit-scrollbar {
        width: 0.5rem;
        margin: 0.5rem 0;
        padding: 2rem;
        border-radius: 2rem;
    }

    ::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 2rem;
        padding: 2rem;
    }

    ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 2rem;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`

const SpecialForwardedMessage = styled.div`
    width: 90%;
    border-top: 1px solid ${({ theme }) => theme.grey};
    border-bottom: 1px solid ${({ theme }) => theme.grey};
    margin: 3.1rem auto;
`

const SpecialForwardedMessageContent = styled.div`
    padding: 2rem;
    text-align: center;
`

const SpecialForwardedMessageText = styled.p`
    font-size: 1.4rem;
    color: ${({ theme }) => theme.primary};
    font-weight: 600;
`

export interface MessageInterface {
    attributes: any
    content: string
    id: string
    source: string
    type: string
    files: any
    created_at: string
    caseworker_name: string
}

interface MessagesProps {
    messages: MessageInterface[]
    height?: string
    userType: string
}

const Messages: React.FC<MessagesProps> = ({ messages, height, userType }) => {
    const translation = useTranslation()

    const { openReport } = useTypedSelector(
        (state) => state.caseworkerOpenReport
    )

    const { user } = useTypedSelector((state) => state.auth)

    // @ts-ignore
    useEffect(() => {
        const chatBox = document?.getElementById('chat-box')
        if (chatBox) {
            chatBox.scrollTo(0, 9999999)
        }

        //eslint-disable-next-line
    }, [messages])

    return (
        <StyledMessages
            id="chat-box"
            style={{
                height: height,
            }}
        >
            {messages.map((message: MessageInterface) => (
                <>
                    {message.type !== 'forwarded' ? (
                        <>
                            <Message
                                key={message.id}
                                message={message}
                                userType={userType}
                            />
                        </>
                    ) : (
                        <SpecialForwardedMessage>
                            <SpecialForwardedMessageContent>
                                {openReport?.status === 'Forwarded' && (
                                    <SpecialForwardedMessageText>
                                        {
                                            translation.caseworkerOpenReport
                                                .theCaseHasBeenForwardedTo
                                        }{' '}
                                        {openReport?.forward?.data.forwarded_to}
                                    </SpecialForwardedMessageText>
                                )}
                                {user?.roles.includes('contact') && (
                                    <SpecialForwardedMessageText>
                                        {
                                            translation.caseworkerOpenReport
                                                .theCaseHasBeenForwardedBy
                                        }{' '}
                                        {openReport?.forward?.data.forwarded_by}
                                    </SpecialForwardedMessageText>
                                )}
                            </SpecialForwardedMessageContent>
                        </SpecialForwardedMessage>
                    )}
                </>
            ))}
        </StyledMessages>
    )
}

export default Messages
