import styled from 'styled-components'
import { FiArrowLeft, FiHelpCircle } from 'react-icons/fi'

export const HeadingContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const Heading = styled.div`
    display: flex;
`

export const HeadingText = styled.h3`
    font-size: 2rem;
    font-weight: 600;
    color: ${({ theme }) => theme.primary};
`

export const HelpIcon = styled(FiHelpCircle)`
    color: ${({ theme }) => `${theme.primary}50`};
    font-size: 2rem;
    margin-left: 1rem;
`

export const GoBackButton = styled.div`
    display: flex;
    gap: 1rem;
    height: 3.5rem;
    align-items: center;
    cursor: pointer;
    transition: opacity 0.3s;

    :hover {
        opacity: 0.8;
    }

    :hover .hover-contact-left-icon {
        transform: translateX(-0.3rem);
    }
`

export const LeftArrowIcon = styled(FiArrowLeft)`
    color: ${({ theme }) => theme.greyText};
    font-size: 1.9rem;
    transition: transform 0.3s;
`
export const GoBackText = styled.p`
    color: ${({ theme }) => theme.greyText};
    font-size: 1.4rem;
`

export const ButtonContainer = styled.div`
    display: flex;
    gap: 2rem;
`
