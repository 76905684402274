
export const isValidAnswer = (answer: string) => {
    if (answer.length < 2 || answer.length > 200) {
        return false
    }

    return true
}

export const checkAnswer = (answer: string) => {
    if (answer.length < 2) {
        return 'The answer cannot be shorter'
    } else if (answer.length > 200) {
        return 'The answer cannot be longer'
    } else {
        return ''
    }
}
