import Translation from '../../state/state-types/TranslationStateType'

export const isValidAddress = (address: string) => {
    if (address.length < 3) {
        return false
    } else if (address.length > 70) {
        return false
    }

    return true
}

export const checkAddress = (address: string, translation: Translation) => {
    if (address.length < 3) {
        return translation.validation.addressIsToShort
    } else if (address.length > 70) {
        return translation.validation.addressIsToLong
    }

    return ''
}
