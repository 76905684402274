import { isPossiblePhoneNumber } from 'react-phone-number-input'
import Translation from '../../state/state-types/TranslationStateType'

export const isValidPhone = (phone: string) => {
    return (isPossiblePhoneNumber(phone)) 

}

export const checkPhone = (phone: string, translation: Translation) => {
    if (!isValidPhone(phone)) {
        return translation.validation.notAValidPhoneNumber
    } else {
        return ''
    }
}
