import React from 'react'
import styled from 'styled-components'
import { useTranslation, useTypedSelector } from '../../../../../../../hooks'

const Score = styled.h3`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`

const MinMaxText = styled.span`
  font-size: 1rem;
  color: ${({ theme }) => `${theme.primary}50`};
`

const ScoreBox = styled.div`
  height: 4.5rem;
  width: 27rem;
  background-color: #F9F9F9;
  border-radius: 10rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 4rem;
`

interface IsActiveProp {
    isActive: boolean
}

const ScoreNumber = styled.h3<IsActiveProp>`
  font-size: 1.8rem;
  color: ${({ theme, isActive }) =>
    isActive ? theme.primary : `${theme.primary}20`};
`

interface SeverityScoreFrameProps {}

const SeverityScoreFrame: React.FC<SeverityScoreFrameProps> = () => {
    const { openReport } = useTypedSelector(state => state.caseworkerOpenReport)
    const translation = useTranslation()

    return (<Score>
        <MinMaxText>{translation.forwardReportPage.minimumAbbreviated.toLowerCase()}</MinMaxText>
        <ScoreBox>
            {[1, 2, 3, 4, 5, 6].map((i) => (
                <ScoreNumber key={i} isActive={i === openReport.forward.data.severity_score}>
                    {i}
                </ScoreNumber>
            ))}
        </ScoreBox>
        <MinMaxText>{translation.forwardReportPage.maximumAbbreviated.toLowerCase()}</MinMaxText>
    </Score>)
}

export default SeverityScoreFrame