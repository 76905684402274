import styled from 'styled-components'

import { NextButton } from '../../buttons'
import {
    ButtonContainer,
    Heading,
    HeadingContainer,
    HeadingText,
    // HelpIcon,
} from '../styled'
import React, { useEffect, useState } from 'react'
import ContactLineFormRow from '../ContactLineFormRow'
import { useActions, useTranslation, useTypedSelector } from '../../../../hooks'
import { useDefaultCountryDenmark } from '../../../../hooks/useDefaultCountryDenmark'

const Form = styled.form`
    padding: 2rem;
`

const SkipButton = styled.p`
    font-size: 1.4rem;
    color: ${({ theme }) => `${theme.primary}50`};
    cursor: pointer;
    margin-top: 1rem;
`
const SubTitleText = styled.p`
    font-size: 1.4rem;
    margin-block: 0.6rem;
    color: #a0a0a6; ;
`

export interface ContactInterface {
    id?: number
    first_name: string
    last_name: string
    position: string
    email: string
    country: any
    phone: string
}

interface CreateYourLineOfContactsFrameProps {
    clickNextCB: () => void
}

const OnboardingCreateYourLineOfContactsFrame: React.FC<
    CreateYourLineOfContactsFrameProps
> = ({ clickNextCB }) => {
    const { onboardingCreateLineOfContact } = useActions()

    const {
        user,
        authLoading,
        // validationErrors
    } = useTypedSelector((state) => state.auth)
    const { client } = useTypedSelector((state) => state.client)

    const translation = useTranslation()

    const handleClickNext = async () => {
        if (user) {
            const success: any = await onboardingCreateLineOfContact(
                user.userId,
                client.organization.data.id,
                contacts
            )
            if (success) {
                clickNextCB()
            }
        }
    }

    const handleClickSkip = async () => {
        if (user) {
            const success: any = await onboardingCreateLineOfContact(
                user.userId,
                client.organization.data.id,
                []
            )
            if (success) {
                clickNextCB()
            }
        }
    }

    const baseContact: ContactInterface = {
        first_name: '',
        last_name: '',
        position: '',
        email: '',
        country: null,
        phone: '',
    }

    const [contacts, setContacts] = useState<ContactInterface[]>([
        { ...baseContact, id: 1 },
        { ...baseContact, id: 2 },
        { ...baseContact, id: 3 },
    ])

    const handleSetContacts = (key: any, value: any, contactId: number) => {
        setContacts(
            contacts.map((contact) =>
                contact.id === contactId
                    ? {
                          ...contact,
                          [key]: value,
                      }
                    : contact
            )
        )
    }

    const denmark = useDefaultCountryDenmark()

    useEffect(() => {
        if (denmark !== 'still default') {
            setContacts(
                contacts.map((contact) => ({
                    ...contact,
                    country: denmark,
                }))
            )
        }

        // eslint-disable-next-line
    }, [denmark])

    return (
        <>
            <HeadingContainer>
                <Heading>
                    <HeadingText>
                        {translation.onboardingPage.createYourLineOfContact}
                    </HeadingText>
                    {/*<HelpIcon />*/}
                </Heading>
                <ButtonContainer>
                    <SkipButton onClick={handleClickSkip}>
                        {translation.onboardingPage.skipThisStep}
                    </SkipButton>
                    <NextButton
                        text={translation.onboardingPage.nextStep}
                        handleOnClick={handleClickNext}
                        isActive={true}
                        height="3.5rem"
                        width="12.1rem"
                        fontSize="1.4rem"
                        isLoading={authLoading}
                    />
                </ButtonContainer>
            </HeadingContainer>
            <SubTitleText>
                {translation.onboardingPage.subcreateYourLineOfContact}
            </SubTitleText>
            <Form>
                <ContactLineFormRow
                    contact={contacts[0]}
                    handleSetContacts={handleSetContacts}
                />{' '}
                <ContactLineFormRow
                    contact={contacts[1]}
                    handleSetContacts={handleSetContacts}
                />{' '}
                <ContactLineFormRow
                    contact={contacts[2]}
                    handleSetContacts={handleSetContacts}
                />
            </Form>
        </>
    )
}

export default OnboardingCreateYourLineOfContactsFrame
