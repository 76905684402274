import React, { useEffect, useState } from 'react'
import {
    useActions,
    useTheme,
    useTranslation,
    useTypedSelector,
} from '../../../hooks'
import styled from 'styled-components'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import AddUserModal from '../../pages/shared/settings/modals/AddUserModal'
import {
    ButtonSmallOpacity,
    ButtonSmallOpacityText,
    PlusIcon,
    ReorderIcon,
} from '../../styled/buttons'
import MiniPersonForm from '../form/MiniPersonForm'
import DeleteContactModal from '../modals/DeleteContactModal'
import ButtonSpinnerWhite from '../../layout/spinner/ButtonSpinnerWhite'
import FlashMessage from '../feedback/FlashMessage'
import profilePlaceholder from '../../../assets/images/profile.svg'
import { CountryInterface } from '../../../state/reducers/countriesReducer'
import { Contact } from '../../../ts/interfaces/CreateLineOfContactInterface'

const ListOfContacts = styled.div``
const ContactSpacing = styled.div`
    :not(:first-child) {
        margin-top: 2rem;
    }
`

const MissingLineOfContactsText = styled.h3`
    font-size: 1.6rem;
    color: ${({ theme }) => theme.primary};
    margin-top: 2rem;
`

const LocMenu = styled.div`
    width: 100%;
    display: flex;
    justify-content: left;
    gap: 2rem;
    margin-bottom: 2rem;
`

const FlexWrapper = styled.div`
    width: 100%;
    display: flex;
    gap: 20px;
`

const RowWrapper = styled.div`
    display: flex;
    gap: 1rem;
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem 0 2rem 0;
    gap: 1rem;
    max-width: 75%;
    @media screen and (max-width: 1600px) {
        max-width: 100%;
    }
`

const LeftSide = styled.div`
    width: 70%;
    @media screen and (max-width: 1000px) {
        width: 100%;
    }
`

const RightSide = styled.div`
    width: 30%;
    background: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.grey};
    border-radius: 0.5rem;
    @media screen and (max-width: 1000px) {
        display: none;
    }
`

const PreviewWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @media screen and (max-width: 1000px) {
        display: none;
    }
`
const Preview = styled.div`
    font-size: 2rem;
    color: ${({ theme }) => theme.primary};
    padding: 1rem 0 1rem 0;
    border-bottom: 1px solid ${({ theme }) => theme.grey};
    display: flex;
    justify-content: center;
    width: 100%;
`
const PickCaseworker = styled.div`
    font-size: 2.5rem;
    color: ${({ theme }) => theme.primary};
    padding: 1rem 0 1rem 0;
`
const ChosenContactReceive = styled.div`
    font-size: 1.2rem;
    color: ${({ theme }) => theme.greyText};
    padding: 1rem 0 1rem 0;
`
const ContactPreview = styled.div`
    border: 2px solid ${({ theme }) => theme.grey};
    border-radius: 0.5rem;
    padding: 0 2rem 0 2rem;
    margin: 1rem 0 1rem 0;
    max-width: 80%;
`
const MiniContactContent = styled.div`
    max-width: 90%;
    display: flex;
    gap: 2rem;

    @media screen and (max-width: 1600px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0;
        max-width: 100%;
    }
`

const MiniContactPhoto = styled.img`
    height: 8rem;
    width: 8rem;
    border: 1px solid ${({ theme }) => theme.grey};
    border-radius: 100%;
    margin: 2rem 0 2rem 0;
    @media screen and (max-width: 1600px) {
        margin: 1rem 0 0 0;
    }
`
const MiniContactName = styled.div`
    font-size: 1.6rem;
    color: ${({ theme }) => theme.primary};
`
const MiniContactPosition = styled.div`
    font-size: 1.4rem;
    background-color: ${({ theme }) => theme.lightBlue};
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.lightBlue};
    border-radius: 1.5rem;
    width: 15rem;
    padding: 0.2rem;
`
const MiniContactLanguages = styled.div`
    background-color: ${({ theme }) => theme.lightBlue};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.lightBlue};
    border-radius: 1.5rem;
    width: 8rem;
    padding: 0.2rem;
`
const MiniContactNote = styled.div`
    font-size: 1.4rem;
    background-color: ${({ theme }) => theme.lightBlue};
    display: flex;
    justify-content: center;
    border: 1px solid ${({ theme }) => theme.lightBlue};
    border-radius: 1.5rem;
    align-items: center;
    width: 24rem;
    padding: 0.5rem;
    max-width: 100%;
    @media screen and (max-width: 1600px) {
        max-width: 100%;
    }
`
const MiniCountryFlag = styled.img`
    height: 1.4rem;
    width: 1.4rem;
    border-radius: 50%;
    margin-right: 0.5rem;
    border: 1px solid black;
`

interface LineOfContactsFrameProps {}

export interface PriorityInterface {
    contact_id: number
    priority: number
}

interface ContactPreviewInterface {
    id: number
    name: string
    position: string
    note: string
    img: null | string | File
    languages: CountryInterface[]
}

const LineOfContactsFrame: React.FC<LineOfContactsFrameProps> = () => {
    const theme = useTheme()
    const { organization, selectedOrganizationInformation } = useTypedSelector(
        (state) => state.caseworker
    )
    const { client } = useTypedSelector((state) => state.client)
    const { user } = useTypedSelector((state) => state.auth)
    const {
        lineOfContactLoading,
        lineOfContacts,
        addLineOfContactErrors,
        lineOfContactUpdatePriorityLoading,
        flashMessage,
        updateContactErrors,
    } = useTypedSelector((state) => state.lineOfContact)
    const {
        addLineOfContact,
        addPriorityOfContacts,
        addContact,
        addSelf,
        updateContactListInState,
        clearUpdateContactErrors,
    } = useActions()
    const translation = useTranslation()
    const { countries } = useTypedSelector((state) => state.country)

    const [isAddLocModalOpen, setIsAddLocModalOpen] = useState(false)
    const [isAddCttModalOpen, setIsAddCttModalOpen] = useState(false)
    const [isAddSelfModalOpen, setIsAddSelfModalOpen] = useState(false)
    const [isDeleteContactModalOpen, setIsDeleteContactModalOpen] =
        useState(false)
    const [listOfContacts, setListOfContacts] = useState(lineOfContacts)
    const [priorityList, setPriorityList] = useState<PriorityInterface[]>([])

    const [contactToDelete, setContactToDelete] = useState<any>(null)
    const [contactToEdit, setContactToEdit] = useState<any>(null)

    const [profileImgSrc, setProfileImgSrc] = useState('')

    const initialContactsPreviews: ContactPreviewInterface[] =
        lineOfContacts.map((contact) => ({
            id: contact.id,
            name: contact.first_name + ' ' + contact.last_name,
            img: contact.contact_information.profile_img,
            position: contact.contact_information.position,
            note: contact.contact_information.note,
            languages: contact.languages,
        }))

    const [contactsPreviews, setContactsPreviews] = useState<
        ContactPreviewInterface[]
    >(initialContactsPreviews)

    const [isEditModeActive, setIsEditModeActive] = useState<boolean>(false)

    useEffect(() => {
        setListOfContacts(lineOfContacts)
    }, [lineOfContacts])

    useEffect(() => {
        const priority: PriorityInterface[] = []
        listOfContacts.forEach((contact: { id: number }) =>
            priority.push({
                contact_id: contact.id,
                priority: listOfContacts.indexOf(contact) + 1,
            })
        )
        setPriorityList(priority)
        setContactsPreviews(
            listOfContacts.map((contact) => ({
                id: contact.id,
                name: contact.first_name + ' ' + contact.last_name,
                img: contact.contact_information.profile_img,
                position: contact.contact_information.position,
                note: contact.contact_information.note,
                languages: contact.languages,
            }))
        )
    }, [lineOfContacts, listOfContacts])

    useEffect(() => {
        if (updateContactErrors) {
            let errorMessage = ''
            if (
                typeof updateContactErrors.errors['contact.email'] !==
                'undefined'
            ) {
                errorMessage = updateContactErrors.errors['contact.email'][0]
            } else if (
                typeof updateContactErrors.errors['contact.phone'] !==
                'undefined'
            ) {
                errorMessage = updateContactErrors.errors['contact.phone'][0]
            } else {
                errorMessage = 'Something went wrong. Please try again later.'
            }

            alert(errorMessage)
            clearUpdateContactErrors()
        }
    }, [updateContactErrors, clearUpdateContactErrors])

    const handleAddLineOfContact = async (user: any) => {
        const success = await addLineOfContact(
            organization.id,
            selectedOrganizationInformation.id,
            user
        )

        return success
    }

    const handleAddContact = async (user: any) => {
        const success = await addContact(client.id, user)

        return success
    }

    const handleAddSelfAsContact = async (
        contactInformation: Contact['contact_information'] & {
            profile_img?: File | undefined
        }
    ) => {
        const payload: Contact['contact_information'] & {
            profile_img?: File | undefined
        } = {
            position: contactInformation.position,
            priority: (listOfContacts.length + 1).toString(),
            note: contactInformation.note,
        }

        if (contactInformation.profile_img) {
            payload.profile_img = contactInformation.profile_img
        }

        const success = await addSelf(user?.userId, payload)

        return success
    }

    const isPriorityUpdated = () => {
        let isUpdated = false
        listOfContacts.forEach((contact: any, index) => {
            if (
                contact.contact_information.priority !==
                lineOfContacts[index]?.contact_information.priority
            ) {
                isUpdated = true
            }
        })

        return isUpdated
    }

    const onDragEnd = (droppedItem: any) => {
        if (!droppedItem.destination) return

        const updatedContacts = [...listOfContacts]

        const [reorderedItem] = updatedContacts.splice(
            droppedItem.source.index,
            1
        )
        updatedContacts.splice(droppedItem.destination.index, 0, reorderedItem)

        setListOfContacts(updatedContacts)
    }

    const handleAddPriorityOfContacts = async () => {
        const success = await addPriorityOfContacts(client.id, priorityList)

        if (!success) return

        updateContactListInState(listOfContacts)
    }

    const getPreviewImage = (contact: ContactPreviewInterface) => {
        if (!contact.img) {
            return profilePlaceholder
        } else if (contact.img instanceof File) {
            return URL.createObjectURL(contact.img)
        } else {
            return contact.img
        }
    }

    const handleChangeContact = (
        contactId: number,
        field: string,
        value: any
    ): void => {
        setListOfContacts([
            ...listOfContacts.map((contact) => {
                if (contact.id === contactId) {
                    if (field === 'position') {
                        setContactsPreviews(
                            contactsPreviews.map((contactPreview) => {
                                if (contactPreview.id === contactId) {
                                    return {
                                        ...contactPreview,
                                        position: value,
                                    }
                                }
                                return contactPreview
                            })
                        )
                        return {
                            ...contact,
                            contact_information: {
                                ...contact.contact_information,
                                [field]: value,
                            },
                        }
                    } else if (field === 'phone') {
                        return {
                            ...contact,
                            phone: {
                                ...contact.phone,
                                [field]: value,
                            },
                        }
                    } else if (field === 'country_id') {
                        const countryCode = countries?.find(
                            (country) => country.id === value
                        )?.calling_code
                        return {
                            ...contact,
                            [field]: value,
                            phone: {
                                ...contact.phone,
                                country_code: countryCode,
                            },
                        }
                    } else if (field === 'languages') {
                        setContactsPreviews(
                            contactsPreviews.map((contactPreview) => {
                                if (contactPreview.id === contactId) {
                                    return {
                                        ...contactPreview,
                                        languages: [
                                            ...contactPreview.languages,
                                            value,
                                        ],
                                    }
                                }
                                return contactPreview
                            })
                        )
                        return {
                            ...contact,
                            languages: [...contact.languages, value],
                        }
                    } else if (field === 'note') {
                        setContactsPreviews(
                            contactsPreviews.map((contactPreview) => {
                                if (contactPreview.id === contactId) {
                                    return {
                                        ...contactPreview,
                                        note: value,
                                    }
                                }
                                return contactPreview
                            })
                        )
                        return {
                            ...contact,
                            [field]: value,
                            contact_information: {
                                ...contact.contact_information,
                                note: value,
                            },
                        }
                    } else if (field === 'profile_img') {
                        setProfileImgSrc(URL.createObjectURL(value))
                        setContactsPreviews(
                            contactsPreviews.map((contactPreview) => {
                                if (contactPreview.id === contactId) {
                                    return {
                                        ...contactPreview,
                                        img: URL.createObjectURL(value),
                                    }
                                }
                                return contactPreview
                            })
                        )
                        return {
                            ...contact,
                            [field]: value,
                            contact_information: {
                                ...contact.contact_information,
                                profile_img: value,
                            },
                        }
                    } else {
                        return {
                            ...contact,
                            [field]: value,
                        }
                    }
                }
                return contact
            }),
        ])
    }

    return (
        <>
            {flashMessage && <FlashMessage message={flashMessage} />}
            {isAddCttModalOpen && (
                <AddUserModal
                    contactView={true}
                    isLineOfContact={true}
                    isAddSelfModal={false}
                    closeModal={() => setIsAddCttModalOpen(false)}
                    addUserCallback={handleAddContact}
                    isLoading={lineOfContactLoading}
                    errors={addLineOfContactErrors}
                />
            )}
            {isDeleteContactModalOpen && (
                <DeleteContactModal
                    closeModal={() => setIsDeleteContactModalOpen(false)}
                    handleDelete={() => {}}
                    contact={contactToDelete}
                />
            )}
            {isAddLocModalOpen && (
                <AddUserModal
                    contactView={false}
                    isLineOfContact={true}
                    isAddSelfModal={false}
                    closeModal={() => setIsAddLocModalOpen(false)}
                    addUserCallback={handleAddLineOfContact}
                    isLoading={lineOfContactLoading}
                    errors={addLineOfContactErrors}
                />
            )}
            {isAddSelfModalOpen && (
                <AddUserModal
                    contactView={false}
                    isLineOfContact={false}
                    isAddSelfModal={isAddSelfModalOpen}
                    closeModal={() => setIsAddSelfModalOpen(false)}
                    addUserCallback={handleAddSelfAsContact}
                    isLoading={lineOfContactLoading}
                    errors={addLineOfContactErrors}
                />
            )}

            {lineOfContacts.length !== 0 ? (
                <ListOfContacts>
                    <LocMenu>
                        <ButtonSmallOpacity
                            onClick={() => setIsAddCttModalOpen(true)}
                        >
                            <PlusIcon />
                            <ButtonSmallOpacityText>
                                {
                                    translation.singleClientViewPartner
                                        .addLineOfContact
                                }
                            </ButtonSmallOpacityText>
                        </ButtonSmallOpacity>
                        {user?.roles.includes('client') &&
                            !user?.roles.includes('contact') && (
                                <ButtonSmallOpacity
                                    onClick={() => setIsAddSelfModalOpen(true)}
                                >
                                    <PlusIcon />
                                    <ButtonSmallOpacityText>
                                        {
                                            translation.singleClientViewPartner
                                                .addSelfAsContact
                                        }
                                    </ButtonSmallOpacityText>
                                </ButtonSmallOpacity>
                            )}
                        {lineOfContacts.length > 1 && isPriorityUpdated() && (
                            <ButtonSmallOpacity
                                width="18rem"
                                onClick={handleAddPriorityOfContacts}
                            >
                                {!lineOfContactUpdatePriorityLoading ? (
                                    <>
                                        <ReorderIcon />

                                        <ButtonSmallOpacityText>
                                            {
                                                translation
                                                    .singleClientViewPartner
                                                    .saveOrderOfContacts
                                            }
                                        </ButtonSmallOpacityText>
                                    </>
                                ) : (
                                    <ButtonSpinnerWhite
                                        overrideColor={theme.blue}
                                    />
                                )}
                            </ButtonSmallOpacity>
                        )}
                    </LocMenu>
                    <FlexWrapper>
                        <LeftSide>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(provided) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        >
                                            {listOfContacts.map(
                                                (contact, index) => (
                                                    <Draggable
                                                        draggableId={contact.id.toString()}
                                                        index={index}
                                                        key={contact.id}
                                                        isDragDisabled={
                                                            isEditModeActive
                                                        }
                                                    >
                                                        {(provided) => (
                                                            <ContactSpacing
                                                                key={contact.id}
                                                                ref={
                                                                    provided.innerRef
                                                                }
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <MiniPersonForm
                                                                    handleChangeContact={
                                                                        handleChangeContact
                                                                    }
                                                                    openIsDeleteContactModal={() =>
                                                                        setIsDeleteContactModalOpen(
                                                                            true
                                                                        )
                                                                    }
                                                                    setContactToDelete={
                                                                        setContactToDelete
                                                                    }
                                                                    contact={
                                                                        contact
                                                                    }
                                                                    contactNumber={
                                                                        index +
                                                                        1
                                                                    }
                                                                    cancelEditingCallback={() => {
                                                                        setListOfContacts(
                                                                            lineOfContacts
                                                                        )
                                                                        setContactsPreviews(
                                                                            initialContactsPreviews
                                                                        )
                                                                        setProfileImgSrc(
                                                                            profilePlaceholder
                                                                        )
                                                                    }}
                                                                    profileImgSrc={
                                                                        profileImgSrc
                                                                    }
                                                                    isEditing={
                                                                        isEditModeActive &&
                                                                        contactToEdit.id ===
                                                                            contact.id
                                                                    }
                                                                    setIsEditModeActive={
                                                                        setIsEditModeActive
                                                                    }
                                                                    setContactToEdit={
                                                                        setContactToEdit
                                                                    }
                                                                />
                                                            </ContactSpacing>
                                                        )}
                                                    </Draggable>
                                                )
                                            )}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </LeftSide>
                        <RightSide>
                            <PreviewWrapper>
                                <Preview>
                                    {translation.clientView.preview}
                                </Preview>
                                <PickCaseworker>
                                    {translation.clientView.pickCaseworker}
                                </PickCaseworker>
                                <ChosenContactReceive>
                                    {
                                        translation.clientView
                                            .chosenContactWillReceive
                                    }
                                </ChosenContactReceive>
                                {contactsPreviews.map((contactPreview) => (
                                    <ContactPreview key={contactPreview.id}>
                                        <MiniContactContent>
                                            <MiniContactPhoto
                                                src={getPreviewImage(
                                                    contactPreview
                                                )}
                                            />

                                            <Column>
                                                <MiniContactName>
                                                    {contactPreview.name}
                                                </MiniContactName>
                                                <RowWrapper>
                                                    <MiniContactPosition>
                                                        {
                                                            contactPreview.position
                                                        }
                                                    </MiniContactPosition>
                                                    <MiniContactLanguages>
                                                        {contactPreview.languages.map(
                                                            (language: any) => {
                                                                return (
                                                                    <MiniCountryFlag
                                                                        key={
                                                                            language.country_code
                                                                        }
                                                                        src={
                                                                            language.flag
                                                                        }
                                                                    />
                                                                )
                                                            }
                                                        )}
                                                    </MiniContactLanguages>
                                                </RowWrapper>
                                                <MiniContactNote>
                                                    {contactPreview.note ??
                                                        'Note still not written'}
                                                </MiniContactNote>
                                            </Column>
                                        </MiniContactContent>
                                    </ContactPreview>
                                ))}
                            </PreviewWrapper>
                        </RightSide>
                    </FlexWrapper>
                </ListOfContacts>
            ) : (
                <>
                    <MissingLineOfContactsText>
                        {
                            translation.singleClientViewPartner
                                .lineOfContactsHaveNot
                        }
                    </MissingLineOfContactsText>
                </>
            )}
        </>
    )
}

export default LineOfContactsFrame
