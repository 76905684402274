import produce from 'immer'
import { ModalActionType } from '../action-types'
import { ModalAction } from '../actions'

interface ModalState {
    openModal: string | null
}

const initialState: ModalState = {
    openModal: null,
}

const reducer = produce(
    (state: ModalState = initialState, action: ModalAction) => {
        switch (action.type) {
            case ModalActionType.OPEN_MODAL:
                state.openModal = action.payload.modal

                return state

            case ModalActionType.CLOSE_MODAL:
                state.openModal = null

                return state
            default:
                return state
        }
    }
)

export default reducer
