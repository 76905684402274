import React from 'react'
import styled from 'styled-components'
import { FiCheckCircle } from 'react-icons/fi'
import { useTranslation, useTypedSelector } from '../../../../../hooks'

const ClosedCaseBox = styled.div`
  height: 8.2rem;
  width: 100%;
  background: ${({ theme }) => theme.primary};
  border-radius: 0.5rem;
  margin-bottom: 1.4rem;
  margin-top: 2rem;
`

const ClosedCaseBoxFlex = styled.div`
  padding-top: 2rem;
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 7fr;
  color: ${({ theme }) => theme.white};
  margin-left: 2rem;
`

const CheckIcon = styled(FiCheckCircle)`
  color: ${({ theme }) => theme.white};
  font-size: 1.9rem;
`

const ClosedHeading = styled.h4`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.white};
  margin-left: 1rem;
`

const ClosedInformationHeading = styled.p`
  font-size: 1rem;
  color: ${({ theme }) => `${theme.white}50`};
`

const ClosedInformation = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.white};
  margin-top: 0.4rem;
`

interface CLosedOrForwardedBoxProps {}

const ClosedOrForwardedBox: React.FC<CLosedOrForwardedBoxProps> = () => {
    const { openReport } = useTypedSelector(state => state.caseworkerOpenReport)

    const translation = useTranslation()

    return <>
        <ClosedCaseBox>
            <ClosedCaseBoxFlex>
                <div
                    style={{
                        display: 'flex',
                    }}
                >
                    <CheckIcon />
                    <ClosedHeading>
                        {openReport.closed_status}
                    </ClosedHeading>
                </div>
                <div>
                    <ClosedInformationHeading>
                        {
                            openReport?.status !=='Forwarded' ? translation.caseClosedViewPartner
                                .closeDate : translation.caseworkerOpenReport.forwardedAt
                        }
                    </ClosedInformationHeading>
                    <ClosedInformation>
                        { openReport.closed_at}
                    </ClosedInformation>
                </div>
                {openReport?.status !=='Forwarded' &&  <div>
                    <ClosedInformationHeading>
                        {
                            translation.caseClosedViewPartner
                                .reason
                        }
                    </ClosedInformationHeading>
                    <ClosedInformation>
                        {openReport.closed_reason}
                    </ClosedInformation>
                </div>}
                <div>
                    {openReport.closed_comment && (
                        <>
                            <ClosedInformationHeading>
                                {
                                    translation
                                        .caseClosedViewPartner
                                        .comment
                                }
                            </ClosedInformationHeading>
                            <ClosedInformation>
                                {openReport.closed_comment}
                            </ClosedInformation>
                        </>
                    )}
                </div>
            </ClosedCaseBoxFlex>
        </ClosedCaseBox>
    </>
}

export default ClosedOrForwardedBox