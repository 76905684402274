import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Card } from '../styled/cards'
import { NextButton } from '../reusable/buttons'
import { useActions, useTypedSelector } from '../../hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { Modal, ModalContainer } from '../styled/modals'
import { FormField, FormInput40, PrimaryLabel } from '../styled/formElements'
import { isValidPassword } from '../../helpers/validation'

const eye = <FontAwesomeIcon icon={faEye} />
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />

const HeaderText = styled.h1`
    margin-top: 5rem;
    margin-bottom: 2rem;
`

const LoginButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
`

export const ShowPasswordIcon = styled.i`
    position: absolute;
    font-size: 1.6rem;
    top: 46%;
    transform: translateY(-50%);
    right: 1rem;

    &:hover {
        color: ${({ theme }) => theme.blue};
        cursor: pointer;
    }
`

export const PasswordInput = styled.input`
    width: 100%;
    border: 2px solid grey;
    border-radius: 0.5rem;
    margin-top: 0.4rem;
    padding: 0 1rem;
    height: 5rem;
`

const CancelButton = styled.button`
    color: ${({ theme }) => theme.blue};
    width: 5rem;
    height: 5rem;
    font-size: 1.5rem;
    background-color: transparent;
    border-color: transparent;
`

const FormContainer = styled.div`
    width: 24.7rem;
    margin: 0 auto;
`

const ErrorMessage = styled.p`
    color: ${({ theme }) => theme.red};
    position: absolute;
    left: 0;
    bottom: 1.5rem;
`

interface EnterPasswordModalProps {
    closeModal: () => void
    onContinueButtonClicked: () => void
}

const EnterPasswordModal: React.FC<EnterPasswordModalProps> = ({
    closeModal,
    onContinueButtonClicked,
}) => {
    // Misc
    const { user, authLoading, dynamicLoginError } = useTypedSelector(
        (state) => state.auth
    )

    const { verifyLoginCredentials, clearValidationErrors } = useActions()

    // State declarations
    const [enableLoginButton, setEnableLoginButton] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [enteredPassword, setEnteredPassword] = useState('')
    const [passwordError, setPasswordError] = useState('')

    const onConfirmPasswordButtonClicked = async () => {
        if (user) {
            const verifiedSuccess: any = await verifyLoginCredentials(
                user.email,
                enteredPassword
            )

            if (verifiedSuccess) {
                onContinueButtonClicked()
            }
        }
    }

    useEffect(() => {
        if (isValidPassword(enteredPassword) && !enableLoginButton) {
            setEnableLoginButton(true)
        }
        // eslint-disable-next-line
    }, [enteredPassword])

    useEffect(() => {
        dynamicLoginError?.errors?.login &&
            setPasswordError(dynamicLoginError.errors.login)
        // eslint-disable-next-line
    }, [dynamicLoginError])

    useEffect(() => {
        return () => {
            clearValidationErrors()
        }
        // eslint-disable-next-line
    }, [])

    return (
        <ModalContainer>
            <Modal height="28.4rem" width="55.6rem">
                <Card
                    style={{
                        textAlign: 'center',
                    }}
                >
                    <HeaderText>
                        To make this action, please confirm your password
                    </HeaderText>
                    <FormContainer>
                        <FormField>
                            <PrimaryLabel>Confirm password</PrimaryLabel>
                            <FormInput40
                                type={showPassword ? 'text' : 'password'}
                                onChange={(e) =>
                                    setEnteredPassword(e.target.value)
                                }
                                onFocus={() => setPasswordError('')}
                            />
                            <ShowPasswordIcon
                                onClick={() => setShowPassword(true)}
                            >
                                {!showPassword ? eye : eyeSlash}
                            </ShowPasswordIcon>
                            {passwordError.length > 0 && (
                                <ErrorMessage>{passwordError}</ErrorMessage>
                            )}
                        </FormField>
                    </FormContainer>
                    <LoginButtonWrapper>
                        <NextButton
                            isActive={enableLoginButton}
                            width="15rem"
                            icon={false}
                            handleOnClick={onConfirmPasswordButtonClicked}
                            isLoading={authLoading}
                        />
                    </LoginButtonWrapper>
                    <CancelButton onClick={closeModal}>Cancel</CancelButton>
                </Card>
            </Modal>
        </ModalContainer>
    )
}

export default EnterPasswordModal
