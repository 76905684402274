import styled from 'styled-components'
import { DynamicHeightBlueButton, DynamicHeightTransButton } from '../../../../styled/buttons'
import { Card } from '../../../../styled/cards'
import { useTranslation, useTypedSelector } from '../../../../../hooks'
import ButtonSpinnerWhite from '../../../../layout/spinner/ButtonSpinnerWhite'

const ModalContainer = styled.div`
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);

  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
`

const Modal = styled.div`
  height: 32rem;
  width: 55rem;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`

const Heading = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.primary};
  padding-top: 8rem;
`

const Contact = styled.h4`
  font-size: 2rem;
  color: ${({ theme }) => theme.primary};
  font-weight: medium;
  padding-top: 4.4rem;
`

const ButtonContainer = styled.div`
  padding-top: 9.6rem;
  display: flex;
  justify-content: space-around;
`

interface AreYouSureYouWantToForwardModalProps {
    closeModal: () => void
    handleForwardReport: () => void
    contact: any
}

const AreYouSureYouWantToForwardModal: React.FC<AreYouSureYouWantToForwardModalProps> = ({
    closeModal,
    handleForwardReport,
    contact,
}) => {
    const translation = useTranslation()

    const { openReport, forwardReportLoading } = useTypedSelector(state => state.caseworkerOpenReport)

    return (
        <ModalContainer>
            <Modal>
                <Card>
                    <Heading>
                        {
                            translation.forwardReportPage
                                .popUpConfirmForwardPartOne
                        }{' '}
                        {openReport?.readable_id}
                        {' '}{
                            translation.forwardReportPage
                                .popUpConfirmForwardPartTwo
                        }
                    </Heading>
                    <Contact>{contact.first_name + ' ' + contact.last_name}</Contact>
                    <ButtonContainer>
                        <DynamicHeightTransButton
                            height='4.5rem'
                            width='24rem'
                            fontSize='1.6rem'
                            onClick={closeModal}
                        >
                            {translation.forwardReportPage.popUpNo}
                        </DynamicHeightTransButton>
                        <DynamicHeightBlueButton
                            height='4.5rem'
                            width='24rem'
                            fontSize='1.6rem'
                            onClick={handleForwardReport}
                        >
                            {/* eslint-disable-next-line react/jsx-no-undef */}
                            {forwardReportLoading ? <ButtonSpinnerWhite />: translation.forwardReportPage.popUpYes}
                        </DynamicHeightBlueButton>
                    </ButtonContainer>
                </Card>
            </Modal>
        </ModalContainer>
    )
}

export default AreYouSureYouWantToForwardModal
