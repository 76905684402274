import produce from 'immer'
import { AvailableLanguagesActionType } from '../action-types'
import { AvailableLanguagesAction } from '../actions'

export interface AvailableLanguagesInterface {
    id: number
    country_id: number
    name: string
    code: string
    country: {
        flag: string
    }
}

interface AvailableLanguagesState {
    availableLanguagesLoading: boolean
    availableLanguagesError: null | string
    availableLanguages: AvailableLanguagesInterface[] | null
    updateAvailableLanguage: null | number
    saveDefaultLanguageMessage: null | string
    updateReportingChannelLanguage: null | number
}

const initialState: AvailableLanguagesState = {
    availableLanguagesLoading: false,
    availableLanguagesError: null,
    availableLanguages: null,
    updateAvailableLanguage: null,
    saveDefaultLanguageMessage: null,
    updateReportingChannelLanguage: null,
}

const reducer = produce(
    (
        state: AvailableLanguagesState = initialState,
        action: AvailableLanguagesAction
    ) => {
        switch (action.type) {
            case AvailableLanguagesActionType.AVAILABLE_LANGUAGES_LOADING:
                state.availableLanguagesLoading = true

                return state

            case AvailableLanguagesActionType.GET_AVAILABLE_LANGUAGES:
                state.availableLanguagesLoading = false
                state.availableLanguagesError = null
                state.availableLanguages = action.payload.availableLanguages

                return state

            case AvailableLanguagesActionType.AVAILABLE_LANGUAGES_ERROR:
                state.availableLanguagesLoading = false
                state.availableLanguagesError = action.payload.message

                return state

            case AvailableLanguagesActionType.UPDATE_DEFAULT_LANGUAGE:
                state.availableLanguagesLoading = false
                state.availableLanguagesError = null
                state.updateAvailableLanguage = action.payload
                state.saveDefaultLanguageMessage =
                    'Your changes have been saved successfully!'

                return state

            case AvailableLanguagesActionType.UPDATE_REPORTING_CHANNEL_LANGUAGE:
                state.availableLanguagesLoading = false
                state.availableLanguagesError = null
                state.updateReportingChannelLanguage = action.payload

                return state

            case AvailableLanguagesActionType.CLEAR_DEFAULT_LANGUAGE_FLASH_MESSAGE:
                state[action.payload.message] = null

                return state
            default:
                return state
        }
    }
)

export default reducer
