import styled from 'styled-components'
import { useTranslation, useTypedSelector } from '../../../../hooks'
import { TaglineLight } from '../../../pages/whistleblower/new-case/styled'
import { HeadingPrimary } from '../../../styled/typography'
import { FiPaperclip } from 'react-icons/fi'
import { NextButton } from '../../buttons'
import Spinner from '../../../layout/spinner/Spinner'
import config from '../../../../config'

const Container = styled.div`
  padding-top: 6rem;
  width: 60rem;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 6rem;
`

const Tagline = styled(TaglineLight)`
  margin-top: 4.6rem;
`

const SecondTagline = styled.h4`
  font-size: 1.6rem;
  font-weight: 600;
  color: ${({ theme }) => theme.primary};
  margin-top: 2rem;
`

const LinkBox = styled.div`
  margin-top: 1rem;
  height: 8rem;
  width: 100%;
  background: ${({ theme }) => theme.lightBlue};
  border-radius: 0.5rem;
`

const LinkHeadingFlex = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.8rem;
  padding-top: 2rem;
`

const CopyIcon = styled(FiPaperclip)`
  color: ${({ theme }) => theme.primary};
  font-size: 1.8rem;
`
const LinkHeading = styled.p`
  color: ${({ theme }) => theme.primary};
  font-size: 1.8rem;
`

const Link = styled.p`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.primary};
  opacity: 0.5;
  padding-top: 1rem;
`

const Divider = styled.div`
  padding-top: 2rem;
`

const SendLinkInfo = styled.p`
  font-size: 1.6rem;
  padding-top: 6rem;
  color: ${({ theme }) => theme.primary};
`

const NextButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 2rem;
`

const SpinnerContainer = styled.div`
  padding-top: 6rem;
`

const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.red};
  font-size: 1.4rem;
`

interface AddedNewClientFrameProps {
    openSendEmailModal: () => void
}

const AddedNewClientFrame: React.FC<AddedNewClientFrameProps> = ({
    openSendEmailModal,
}) => {
    const translation = useTranslation()

    const { newOrganizationCode, authLoading, authError } = useTypedSelector(
        (state) => state.auth,
    )

    const getWhistleblowerLink = () => {
        return `http://app.whistleblower.${appUrlExtension}/organization/${newOrganizationCode}/whistleblower`
    }

    const getWhistleblowerPolicyLink = () => {
        return `http://app.whistleblower.${appUrlExtension}/organization/${newOrganizationCode}/policy`
    }

    const { appUrlExtension } = config

    return (
        <Container>
            <HeadingPrimary>
                {translation.addNewClientPartner.thanksForYourSubscription}
            </HeadingPrimary>
            {authLoading ? (
                <SpinnerContainer>
                    <Spinner />
                </SpinnerContainer>
            ) : (
                <>
                    <Tagline>
                        {
                            translation.addNewClientPartner
                                .informationOnHowToGetStarted
                        }
                    </Tagline>
                    <SecondTagline>
                        {translation.addNewClientPartner.linkText}
                    </SecondTagline>
                    <LinkBox>
                        <LinkHeadingFlex>
                            <CopyIcon />
                            <LinkHeading>
                                {translation.addNewClientPartner.employeeLink}
                            </LinkHeading>
                        </LinkHeadingFlex>
                        <Link>{getWhistleblowerLink()}</Link>
                    </LinkBox>
                    <Divider />
                    <SecondTagline>
                        {translation.addNewClientPartner.whistleblowerPolicyText}
                    </SecondTagline>
                    <LinkBox>
                        <LinkHeadingFlex>
                            <CopyIcon />
                            <LinkHeading>
                                {
                                    translation.addNewClientPartner
                                        .whistleblowerPolicy
                                }
                            </LinkHeading>
                        </LinkHeadingFlex>
                        <Link>{getWhistleblowerPolicyLink()}</Link>
                    </LinkBox>
                    <SendLinkInfo>
                        {translation.addNewClientPartner.sendLinkText}
                    </SendLinkInfo>
                    {authError && <ErrorMessage>{authError}</ErrorMessage>}
                    <NextButtonContainer>
                        <NextButton
                            isActive={true}
                            handleOnClick={openSendEmailModal}
                            text={
                                translation.addNewClientPartner.buttonSendEmail
                            }
                        />
                    </NextButtonContainer>
                </>
            )}
        </Container>
    )
}

export default AddedNewClientFrame
