import { FiArchive } from 'react-icons/fi'
import styled from 'styled-components'
import { CardCaseRow, CaseCardText, CasePill, CasePillText } from '../../styled/cards'
import { FlexAlignCenter } from '../../styled/flex'
import { CaseGrid4Items } from '../../styled/grids'
// import { CaseInterface } from './Cases'
import { useHistory } from 'react-router'
import { formatDate } from '../../../helpers/date'

const CaseClosedPill = styled(CasePill)`
  background: ${({ theme }) => theme.black};
`

const CaseClosedIcon = styled(FiArchive)`
  color: ${({ theme }) => theme.white};
`

const CaseClosedPillText = styled(CasePillText)`
  color: ${({ theme }) => theme.white + ' !important'};
  margin-top: 0.2rem;
  margin-left: 1rem;
`

interface StatusProp {
    status: string
}

const Status = styled.div<StatusProp>`
  height: 1.8rem;
  padding: 0 1rem;
  border-radius: 2rem;

  display: inline-block;
  background: ${({ status, theme }) => {
    if (status === 'New') {
      return `${theme.red}30`
    } else if (status === 'Awaiting Response') {
      return `${theme.lightBlue}`
    } else if (status === 'Action Required') {
      return `${theme.yellow}30`
    }
  }};
`

const StatusText = styled.p<StatusProp>`
  margin-top: 0.5rem;
  color: ${({ status, theme }) => {
    if (status === 'New') {
      return theme.red
    } else if (status === 'Awaiting Response') {
      return theme.blue
    } else if (status === 'Action Required') {
      return theme.yellow
    }
  }};
`

interface OrganizationCaseProps {
    currentCase: any
    isAllClosed: boolean
}

const OrganizationCase: React.FC<OrganizationCaseProps> = ({
                                                               currentCase,
                                                               isAllClosed,
                                                           }) => {
    const history = useHistory()

    return (
        <CardCaseRow onClick={() => history.push(`report/${currentCase.id}`)}>
            <CaseGrid4Items>
                <CaseCardText>{currentCase.title}</CaseCardText>
                <CaseCardText>
                    {formatDate(currentCase.created_at)}
                </CaseCardText>

                <div>
                    {!isAllClosed ? (
                        <Status status={currentCase.status}>
                            <StatusText status={currentCase.status}>
                                {currentCase.status}
                            </StatusText>
                        </Status>
                    ) : (
                        <CaseClosedPill>
                            <FlexAlignCenter>
                                <CaseClosedIcon size={12} />
                                <CaseClosedPillText>
                                    {formatDate(currentCase.created_at)}
                                </CaseClosedPillText>
                            </FlexAlignCenter>
                        </CaseClosedPill>
                    )}
                </div>
            </CaseGrid4Items>
        </CardCaseRow>
    )
}

export default OrganizationCase
