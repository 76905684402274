import { useHistory } from 'react-router'
import styled from 'styled-components'
import { useTranslation } from '../../../../../hooks'
import { ButtonFullWidth } from '../../../../styled/buttons'
import { Card } from '../../../../styled/cards'
import { Modal, ModalContainer } from '../../../../styled/modals'

const ModalBody = styled.div`
  text-align: center;
  margin: 0 auto;
  padding-top: 10.4rem;
  width: 50rem;
`

const ModalHeading = styled.h3`
  font-size: 2.4rem;
  font-weight: 600;
  color: ${({ theme }) => theme.primary};
`

const ModalTagline = styled.p`
  font-size: 1.6rem;
  color: ${({ theme }) => `${theme.primary}50`};
  padding-top: 1.4rem;
  padding-bottom: 10rem;
`

interface EmailVerifiedModalProps {
    headerText?: null | string
    taglineText?: null | string
}

const EmailVerifiedModal: React.FC<EmailVerifiedModalProps> = ({
                                                                   headerText = null,
                                                                   taglineText = null,
                                                               }) => {
    const history = useHistory()

    const translation = useTranslation()
    return (
        <ModalContainer>
            <Modal height='36rem' width='55rem'>
                <Card>
                    <ModalBody>
                        <ModalHeading>
                            {headerText ? headerText : translation.verifyEmail.emailVerified}!
                        </ModalHeading>

                        <ModalTagline>
                            {taglineText ? taglineText : translation.verifyEmail.yourEmailHasBeen}.
                        </ModalTagline>
                        <ButtonFullWidth
                            isActive={true}
                            onClick={() => history.push('/')}
                        >
                            {translation.verifyEmail.goToLogin}
                        </ButtonFullWidth>
                    </ModalBody>
                </Card>
            </Modal>
        </ModalContainer>
    )
}

export default EmailVerifiedModal
