import React, { useEffect } from 'react'
import AllCasesPageTemplate from '../../../reusable/templates/AllCasesPageTemplate'
import { useActions, useTypedSelector } from '../../../../hooks'
import { useHistory } from 'react-router'

const ContactAllCases: React.FC = () => {
    const { allReportsFiltered } = useTypedSelector(state => state.caseworker)

    const { contactGetSelfReports } = useActions()

    const history = useHistory()

    useEffect(() => {
            contactGetSelfReports()

        // eslint-disable-next-line
    }, [])

    const navigateToClosedCases = () => history.push('/closed-cases')

    return <>
        <AllCasesPageTemplate
            cases={allReportsFiltered}
            onFilterBoxBottomLinkClicked={navigateToClosedCases}
        />
    </>
}

export default ContactAllCases

