import styled from 'styled-components'
import { FiCopy } from 'react-icons/fi'

interface ActiveInterface {
    isActive: boolean
}

export const CodeBox = styled.div<ActiveInterface>`
    height: 6rem;
    margin: 0 auto;
    max-width: 60rem;
    width: 52vw;
    border-radius: 10rem;
    background: ${({ theme, isActive }) =>
        isActive ? theme.black + ' !important' : theme.white};

    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    cursor: pointer;
    transition: all 0.2s;

    @media screen and (max-width: 1000px) {
        max-width: 35rem;
    }

    @media (max-width: 600px) {
        width: 100%;
    }
`

export const Code = styled.h2<ActiveInterface>`
    font-size: 2.2rem;
    color: ${({ theme, isActive }) => (isActive ? theme.white : theme.primary)};
    transition: color 0.2s;

    @media (max-width: 600px) {
        font-size: 1.8rem;
    }
`

export const CopyLink = styled.div<ActiveInterface>`
    display: flex;
    align-items: center;
    opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
    padding-top: 1rem;
    cursor: pointer;
    transition: all 0.2s;
`

export const CopyLinkText = styled.span`
    font-size: 1.2rem;
    margin-right: 0.5rem;
    color: ${({ theme }) => theme.primary};
`

export const CodeBoxScaleLightBlue = styled(CodeBox)`
    width: 100%;
    background: ${({ theme }) => theme.lightBlue};
`

export const CopyIcon = styled(FiCopy)`
    font-size: 1.4rem;
    color: ${({ theme }) => theme.primary};
`
