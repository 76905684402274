import { Route } from 'react-router-dom'
import { getUrlAfterAppSubdomain } from '../../helpers/routing'
import { useTypedSelector } from '../../hooks'

const ProtectedAdminRoute = ({ component: Component, ...rest }) => {
    const { isLoggedIn, user } = useTypedSelector((state) => state.auth)

    return (
        <Route
            {...rest}
            render={(props) => {
                if (!isLoggedIn || !user) {
                    window.location.replace(`http://app.${getUrlAfterAppSubdomain()}/logout`)
                } else if (!user.roles.includes('admin')) {
                    window.location.replace(`http://app.${getUrlAfterAppSubdomain()}/logout`)
                } else {
                    return <Component {...props} />
                }
            }}
        />
    )
}

export default ProtectedAdminRoute
