import styled, { keyframes } from 'styled-components'

interface SkeletonCardProps {
    height?: string
    width?: string
    border?: string
}

const SkeletonLoading = keyframes`
  from {
    left: -20rem;
  }
  to {
    left: 100%
  }
`

const SkeletonWrapper = styled.div<SkeletonCardProps>`
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    border-radius: ${({ border }) => border};
    position: relative;
    overflow: hidden;
    background-color: #e7e7e7;

    ::before {
        content: '';
        display: block;
        position: absolute;
        left: -20rem;
        top: 0;
        height: 100%;
        width: 20rem;
        background: linear-gradient(
            to right,
            #e7e7e7 0%,
            #d3d3d3 50%,
            #e7e7e7 100%
        );
        animation: ${SkeletonLoading} 1400ms ease-in-out infinite;
    }
`

const SkeletonCard: React.FC<SkeletonCardProps> = ({
    height = '100%',
    width = '100%',
    border = '0.3rem',
}) => {
    return <SkeletonWrapper height={height} width={width} border={border} />
}

export default SkeletonCard
