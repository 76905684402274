import styled from 'styled-components'
import { useTranslation, useTypedSelector } from '../../../../../../hooks'

const Container = styled.div`
    margin: 0 6.2rem;
    margin-top: 1.4rem;
`

interface IsActiveProp {
    isActive: boolean
}

const Contact = styled.div<IsActiveProp>`
    height: 6.6rem;
    width: 23.8rem;
    border-radius: 1rem;
    border: 1px solid ${({ theme }) => theme.greyText};
    padding: 1.4rem;
    display: flex;
    margin-bottom: 1.2rem;
    background: ${({ theme, isActive }) =>
        isActive ? theme.primary : theme.white};
    cursor: ${({ isActive }) => (isActive ? 'not-allowed' : 'pointer')};
`

const ContactPriority = styled.div<IsActiveProp>`
    height: 1.8rem;
    width: 1.8rem;
    border-radius: 50%;
    font-size: 1rem;
    color: ${({ theme }) => theme.white};
    background: ${({ theme, isActive }) =>
        isActive ? theme.blue : theme.greyText};

    display: flex;
    align-items: center;
    justify-content: center;
`

const NameContainer = styled.div`
    margin-left: 1.4rem;
`

const Name = styled.h4<IsActiveProp>`
    font-size: 1.4rem;
    color: ${({ theme, isActive }) =>
        isActive ? theme.white : theme.greyText};
`

const Position = styled.p<IsActiveProp>`
    font-size: 1.2rem;
    color: ${({ theme, isActive }) =>
        isActive ? theme.white : theme.greyText};
`

const InformationText = styled.p`
    padding-top: 4rem;
    font-size: 1.6rem;
    color: ${({ theme }) => theme.primary};
`

interface SelectLineOfContactProps {
    selectedContact: any
    setContact: (contact: any) => void
}

const SelectLineOfContact: React.FC<SelectLineOfContactProps> = ({
    selectedContact,
    setContact,
}) => {
    const { openReport } = useTypedSelector(
        (state) => state.caseworkerOpenReport
    )

    const translation = useTranslation()

    return (
        <>
            {openReport && (
                <Container>
                    {openReport.organization.data.contact_lines.data.map(
                        (contact: any) => (
                            <Contact
                                onClick={() => setContact(contact)}
                                isActive={selectedContact?.id === contact.id}
                                key={contact.id}
                            >
                                <ContactPriority
                                    isActive={
                                        selectedContact?.id === contact.id
                                    }
                                >
                                    {contact.id}
                                </ContactPriority>
                                <NameContainer>
                                    <Name
                                        isActive={
                                            selectedContact?.id === contact.id
                                        }
                                    >
                                        {contact.first_name +
                                            ' ' +
                                            contact.last_name}
                                    </Name>
                                    <Position
                                        isActive={
                                            selectedContact?.id === contact.id
                                        }
                                    >
                                        {contact.position}
                                    </Position>
                                </NameContainer>
                            </Contact>
                        )
                    )}
                    <InformationText>
                        {translation.forwardReportPage.lineOfContactReminder}
                    </InformationText>
                </Container>
            )}
        </>
    )
}

export default SelectLineOfContact
