import React from 'react'
import styled from 'styled-components'
import { useActions, useTranslation, useTypedSelector } from '../../../hooks'
import { HeadingSecondary } from '../../styled/typography'
import Cases from '../cases/Cases'
import CasesFilterBox from '../filters/CasesFilterBox'
import LoadingAllCasesTemplate from '../../pages/loading/LoadingAllCasesTemplate'

const Container = styled.div`
    width: 100%;
    padding: 4rem;
`

export const FlexContainer = styled.div`
    display: flex;
    gap: 2.2rem;
    padding-top: 2rem;
`

export const CasesBox = styled.div`
    width: 85%;
    height: 57rem;

    @media (max-width: 1500px) {
        width: 75%;
    }

    @media screen and (max-width: 600px) {
        width: 100%;
        height: 30rem;
    }
`

export const FilterBox = styled.div`
    height: 36.6rem;
    width: 20%;

    @media (max-width: 1500px) {
        width: 25%;
    }

    @media screen and (max-width: 600px) {
        display: none;
    }
`

interface AllCasesPageTemplateProps {
    cases: any
    onFilterBoxBottomLinkClicked: () => void
}

const AllCasesPageTemplate: React.FC<AllCasesPageTemplateProps> = ({
    cases,
    onFilterBoxBottomLinkClicked,
}) => {
    const translation = useTranslation()

    const {
        allReportsFilteredCaseType,
        allReportsFilteredStatus,
        allReportsSearchString,
    } = useTypedSelector((state) => state.caseworker)

    const { contactUserLoading } = useTypedSelector(
        (state) => state.contactUser
    )

    const {
        searchAllReports,
        addStatusFilter,
        addCaseTypeFilter,
        removeAllFilters,
    } = useActions()

    const handleSearch = (search: string) => {
        searchAllReports(search)
    }

    const handleClear = () => {
        searchAllReports('')
    }

    const isActiveFilters = () => {
        return !!(
            allReportsFilteredCaseType !== 'all' ||
            allReportsFilteredStatus ||
            allReportsSearchString?.length > 0
        )
    }

    return (
        <Container>
            <HeadingSecondary
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                {translation.casesPartner.headlineCases}
            </HeadingSecondary>
            {contactUserLoading ? (
                <LoadingAllCasesTemplate />
            ) : (
                <FlexContainer>
                    {cases && (
                        <>
                            <CasesBox>
                                <Cases
                                    title={translation.casesPartner.allCases}
                                    cases={cases}
                                    isAllClosed={false}
                                    height="46.4rem"
                                />
                            </CasesBox>

                            <FilterBox>
                                <CasesFilterBox
                                    onBottomLinkClick={
                                        onFilterBoxBottomLinkClicked
                                    }
                                    handleSearchChange={handleSearch}
                                    handleStatusFilterClick={addStatusFilter}
                                    handleCaseTypeFilterClick={
                                        addCaseTypeFilter
                                    }
                                    handleClearSearch={handleClear}
                                    showRemoveFilterBtn={isActiveFilters()}
                                    handleRemoveFiltersClick={removeAllFilters}
                                    searchText={allReportsSearchString}
                                />
                            </FilterBox>
                        </>
                    )}
                </FlexContainer>
            )}
        </Container>
    )
}

export default AllCasesPageTemplate
