import styled from 'styled-components'
import { Card, CardHeader } from '../../../../../styled/cards'
import { FiFileText, FiMail } from 'react-icons/fi'
import { useEffect, useState } from 'react'
import { useTranslation, useTypedSelector } from '../../../../../../hooks'
import InternalNotesFrame from './frames/InternalNotesFrame'
import MessagesFrame from './frames/MessagesFrame'

const Header = styled(CardHeader)`
  padding: 0;
`

interface IsActiveProp {
    isActive: boolean
}

const HeaderLink = styled.div<IsActiveProp>`
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: ${({ theme, isActive }) =>
          isActive && `2px solid ${theme.blue}`};
  cursor: ${({ isActive }) => (isActive ? 'not-allowed' : 'pointer')};

  :hover {
    .header-link-icon {
      color: ${({ theme }) => theme.primary};
    }

    .header-link-text {
      color: ${({ theme }) => theme.primary};
    }
  }
`

const IconWrapper = styled.div<IsActiveProp>`
  color: ${({ theme, isActive }) =>
          isActive ? theme.primary : theme.greyText};
`

const MailIcon = styled(FiMail)`
  font-size: 1.6rem;
  transition: color 0.3s;
`

const NotesIcon = styled(FiFileText)`
  font-size: 1.6rem;
  transition: color 0.3s;
`

const LinkText = styled.p<IsActiveProp>`
  color: ${({ theme, isActive }) =>
          isActive ? theme.primary : theme.greyText};
  font-weight: 600;
  margin-left: 1rem;
  transition: color 0.3s;
`

interface MessagesInternalNotesBoxProps {
}

const MessagesInternalNotesBox: React.FC<MessagesInternalNotesBoxProps> = () => {
    const translation = useTranslation()

    const { openReportMessages, openReportMessagesLoading } = useTypedSelector((state) => state.caseworkerOpenReport)

    type ActiveLink = 'messages' | 'notes'
    const [activeLink, setActiveLink] = useState<ActiveLink>('messages')

    const [currentFrame, setCurrentFrame] = useState(<MessagesFrame isLoading={openReportMessagesLoading} />)

    useEffect(() => {
        if (activeLink === 'messages') {
            setCurrentFrame(<MessagesFrame isLoading={openReportMessagesLoading} />)
        } else if (activeLink === 'notes') {
            setCurrentFrame(<InternalNotesFrame />)
        }
    }, [activeLink, openReportMessages, openReportMessagesLoading])

    return (
        <Card>
            <Header>
                <HeaderLink
                    isActive={activeLink === 'messages'}
                    onClick={() => setActiveLink('messages')}
                >
                    <IconWrapper isActive={activeLink === 'messages'}>
                        <MailIcon className='header-link-icon' />
                    </IconWrapper>
                    <LinkText
                        className='header-link-text'
                        isActive={activeLink === 'messages'}
                    >
                        {translation.caseworkerOpenReport.messages}
                    </LinkText>
                </HeaderLink>
                <HeaderLink
                    isActive={activeLink === 'notes'}
                    onClick={() => setActiveLink('notes')}
                >
                    <IconWrapper isActive={activeLink === 'notes'}>
                        <NotesIcon className='header-link-icon' />
                    </IconWrapper>

                    <LinkText
                        className='header-link-text'
                        isActive={activeLink === 'notes'}
                    >
                        {translation.caseworkerOpenReport.internalNotes}
                    </LinkText>
                </HeaderLink>
            </Header>

            {currentFrame}
        </Card>
    )
}

export default MessagesInternalNotesBox
