import React, { useRef } from 'react'
import styled from 'styled-components'
import { Card, CardHeader } from '../../../styled/cards'
import { useActions, useTranslation, useTypedSelector } from '../../../../hooks'
import notificationTransformer from '../../../../state/transformers/NotificationTransformer'
import { useHistory } from 'react-router'
import Spinner from '../../spinner/Spinner'

const Dropdown = styled.div`
    height: 37.3rem;
    width: 29rem;

    position: absolute;
    right: 14rem;
    top: 6rem;
    z-index: 5001;
`

const NotificationInformationCircle = styled.div`
    height: 0.6rem;
    width: 0.6rem;
    border-radius: 50%;
    background: ${({ theme }) => theme.grey};
`

const Notifications = styled.div`
    height: calc(100% - 5rem);
    overflow-y: scroll;
`

const Notification = styled.div`
    max-height: 6.6rem;
    width: 100%;

    display: flex;
    padding: 1.4rem;
    border-bottom: 1px solid ${({ theme }) => theme.grey};
    cursor: pointer;

    :hover {
        background: ${({ theme }) => theme.lightBlue};
    }
`

interface IsNewProp {
    isNew: boolean
}

const NotificationCircle = styled(NotificationInformationCircle)<IsNewProp>`
    height: 0.8rem;
    width: 0.8rem;
    margin-right: 1rem;
    margin-top: 0.2rem;
    background: ${({ theme, isNew }) => (isNew ? theme.red : theme.grey)};
`

const NotificationTitle = styled.div`
    font-size: 1.4rem;
    color: ${({ theme }) => theme.primary};
`

/*const NotificationID = styled.div`
    font-size: 1rem;
    color: ${({ theme }) => theme.primary};
`*/

const NotificationDate = styled.div`
    display: flex;
    margin-left: auto;
`

const NotificationDateText = styled.span`
    font-size: 1rem;
    color: ${({ theme }) => theme.primary};
    opacity: 0.5;
    margin-left: 1rem;
`

const CancelButton = styled.div`
    font-size: 1.2rem;
    color: ${({ theme }) => `${theme.primary}50`};
    transition: opacity 0.3s;
    cursor: pointer;

    :hover {
        opacity: 0.7;
    }
`

export const NotificationLoadingContainer = styled.div`
    height: 3rem;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 1.4rem;
`

interface NotificationDropdownProps {
    closeDropdown: () => void
}

const NotificationDropdown: React.FC<NotificationDropdownProps> = ({
    closeDropdown,
}) => {
    const { notifications, notificationsNextPageLoading } = useTypedSelector(
        (state) => state.notification
    )

    const {
        readAllNotifications,
        readSingleNotification,
        getNotifications,
        resetNotificationCount,
    } = useActions()

    const translation = useTranslation()

    const history = useHistory()

    const scrollRef: any = useRef()

    const handleReadNotification = async (notification: any) => {
        if (!notification.read_at) {
            const success: any = await readSingleNotification(notification.id)

            if (success) {
                history.push(`/report/${notification.case_id}`)
                closeDropdown()
            }
        } else {
            history.push(`/report/${notification.case_id}`)
            closeDropdown()
        }
    }

    const handleScroll = (event: React.UIEvent) => {
        if (scrollRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollRef.current

            if (scrollTop + clientHeight === scrollHeight) {
                getNotifications()
            }
        }
    }

    const handleReadAllNotifications = () => {
        readAllNotifications()
        resetNotificationCount()
    }

    return (
        <Dropdown>
            <Card>
                <CardHeader>
                    {translation.header.notifications}
                    <CancelButton onClick={handleReadAllNotifications}>
                        {translation.header.readAll}
                    </CancelButton>
                </CardHeader>
                <Notifications
                    ref={scrollRef}
                    onScroll={(event: React.UIEvent) => handleScroll(event)}
                >
                    {notificationTransformer(notifications, translation).map(
                        (notification: any) => (
                            <Notification
                                key={notification.id}
                                onClick={() =>
                                    handleReadNotification(notification)
                                }
                            >
                                <NotificationCircle
                                    isNew={!notification.read_at}
                                />
                                <div>
                                    <NotificationTitle>
                                        {notification.title}
                                    </NotificationTitle>
                                    {/*            <NotificationID>
                                        {notification.id}
                                    </NotificationID>*/}
                                </div>
                                <NotificationDate>
                                    <NotificationDateText>
                                        {notification.date
                                            ? notification.date
                                            : 'Just now'}
                                    </NotificationDateText>
                                </NotificationDate>
                            </Notification>
                        )
                    )}
                    {notificationsNextPageLoading && (
                        <NotificationLoadingContainer>
                            <Spinner height="20" width="20" />
                        </NotificationLoadingContainer>
                    )}
                </Notifications>
            </Card>
        </Dropdown>
    )
}

export default NotificationDropdown
