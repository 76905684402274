import Translation from '../../state/state-types/TranslationStateType'

export const isValidCompanyName = (name: string) => {
    if (name.length < 3) {
        return false
    } else if (name.length > 50) {
        return false
    }

    return true
}

export const checkCompanyName = (name: string, translation: Translation) => {
    if (name.length < 3) {
        return translation.validation.organizationIsToShort
    } else if (name.length > 50) {
        return translation.validation.organizationIsToLong
    }

    return ''
}
