import styled from 'styled-components'
import { FiArrowLeft } from 'react-icons/fi'

const Container = styled.div`
    display: flex;
    cursor: pointer;
    align-items: center;
    white-space: nowrap;
`

const ArrowIcon = styled(FiArrowLeft)`
    font-size: 1.4rem;
    color: ${({ theme }) => `${theme.primary}50`};
`

const LinkText = styled.p`
    font-size: 1.4rem;
    color: ${({ theme }) => `${theme.primary}50`};
    margin-left: 0.5rem;
    margin-top: 0.1rem;
    font-weight: 600;
`

interface NavigationArrowLinkProps {
    onClickCallback: () => void
    linkText?: string
}

const NavigationArrowLink: React.FC<NavigationArrowLinkProps> = ({
    onClickCallback,
    linkText,
}) => {
    return (
        <Container onClick={onClickCallback}>
            <ArrowIcon />
            {linkText && <LinkText>{linkText}</LinkText>}
        </Container>
    )
}

export default NavigationArrowLink
