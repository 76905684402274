import produce from 'immer'
import { ContactUserActionType } from '../action-types'
import { ContactUserAction } from '../actions'

interface ContactUserState {
    selfReports: any
    contactUserLoading: boolean
}

const initialState: ContactUserState = {
    selfReports: null,
    contactUserLoading: false,
}

const reducer = produce(
    (state: ContactUserState = initialState, action: ContactUserAction) => {
        switch (action.type) {
            case ContactUserActionType.CONTACT_USER_LOADING:
                state.contactUserLoading = true

                return state

            case ContactUserActionType.CONTACT_GET_SELF_REPORTS:
                state.contactUserLoading = false
                state.selfReports = action.payload

                return state

            default:
                return state
        }
    }
)

export default reducer
