import styled from 'styled-components'
import { useTranslation, useTypedSelector } from '../../../../../hooks'
import { Card, CardHeader } from '../../../../styled/cards'
import ButtonSpinnerWhite from '../../../../layout/spinner/ButtonSpinnerWhite'

const ModalContainer = styled.div`
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);

    position: fixed;
    top: 0;
    left: 0;
    z-index: 500;
`

const Modal = styled.div`
  height: 30rem;
  width: 54rem;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background ${({ theme }) => theme.white};
  z-index: 501;
  text-align: center;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const CancelButton = styled.div`
    font-size: 1.4rem;
    color: ${({ theme }) => theme.primary};
    opacity: 0.5;
    cursor: pointer;
`

const HeadingOne = styled.div`
    font-size: 1.6rem;
    color: ${({ theme }) => theme.primary};
    padding-top: 4.8rem;
`

const HeadingTwo = styled.div`
    font-size: 2rem;
    color: ${({ theme }) => theme.primary};
    padding-top: 2rem;
    font-weight: 600;
`

const ButtonContainer = styled.div`
    padding: 0 1.4rem;
`

const ModalButton = styled.div`
    height: 5rem;
    width: 100%;
    background: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.white};

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    margin-top: 7.2rem;
    border-radius: 0.5rem;
    cursor: pointer;
`

interface ChangeStatusModalProps {
    statusChange: any
    modalAction: (status: any) => any
    closeModal: () => void
    statusChangePayload: 'action required' | 'awaiting response'
}

const ChangeStatusModal: React.FC<ChangeStatusModalProps> = ({
    statusChange,
    modalAction,
    closeModal,
    statusChangePayload,
}) => {
    const translation = useTranslation()

    const { updateStatusLoading } = useTypedSelector(
        (state) => state.caseworkerOpenReport
    )

    const handleOnClick = async () => {
        const success = await modalAction(statusChangePayload.toLowerCase())

        success && closeModal()
    }

    return (
        <ModalContainer>
            <Modal>
                <Card>
                    <CardHeader>
                        {translation.caseworkerOpenReport.changeStatus}
                        <CancelButton onClick={closeModal}>
                            {translation.caseworkerOpenReport.cancel}
                        </CancelButton>
                    </CardHeader>
                    <HeadingOne>
                        {translation.caseworkerOpenReport.youAreInTheProcess}
                    </HeadingOne>
                    <HeadingTwo>{statusChange}</HeadingTwo>
                    <ButtonContainer onClick={handleOnClick}>
                        <ModalButton>
                            {!updateStatusLoading ? (
                                translation.caseworkerOpenReport.confirm
                            ) : (
                                <ButtonSpinnerWhite />
                            )}
                        </ModalButton>
                    </ButtonContainer>
                </Card>
            </Modal>
        </ModalContainer>
    )
}

export default ChangeStatusModal
