import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

const CodeInput = styled.div`
    height: 10rem;
    width: 100%;
    border: 0;
    border-radius: 10rem;

    display: flex;
    border-radius: 10rem;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: ${({ theme }) => theme.white};
    gap: 3.2rem;
    :focus {
        border: none;
        outline: none;
        border: 1px solid ${({ theme }) => theme.lineGrey};
    }
`

interface InputProps {
    isActive: boolean
}

const Input = styled.input<InputProps>`
    outline: none;
    width: 4.8rem;
    margin-top: 2rem;
    border: 0;
    border-bottom: 1.4px solid
        ${({ theme, isActive }) =>
            isActive ? theme.primary : `${theme.primary}50`};
    padding-left: 1.5rem;
    font-size: 2rem;
    color: ${({ theme, isActive }) =>
        isActive ? theme.primary : `${theme.primary}50`};
    padding-bottom: 1rem;

    :focus {
        border-bottom: 1.4px solid ${({ theme }) => theme.primary};
        color: ${({ theme }) => theme.primary};
    }
`

interface SecurityCodeInputProps {
    handleOnChange: (otp: string) => void
}

const SecurityCodeInput: React.FC<SecurityCodeInputProps> = ({
    handleOnChange,
}) => {
    const [activeInput, setActiveInput] = useState(1)

    const input1: any = useRef(null)
    const input2: any = useRef(null)
    const input3: any = useRef(null)
    const input4: any = useRef(null)
    const input5: any = useRef(null)
    const input6: any = useRef(null)

    const [input1Val, setInput1Val] = useState<string>('')
    const [input2Val, setInput2Val] = useState<string>('')
    const [input3Val, setInput3Val] = useState<string>('')
    const [input4Val, setInput4Val] = useState<string>('')
    const [input5Val, setInput5Val] = useState<string>('')
    const [input6Val, setInput6Val] = useState<string>('')

    useEffect(() => {
        const otp =
            input1Val +
            input2Val +
            input3Val +
            input4Val +
            input5Val +
            input6Val

        handleOnChange(otp)
        // eslint-disable-next-line
    }, [input1Val, input2Val, input3Val, input4Val, input5Val, input6Val])

    const handleChange = (val: string) => {
        if (val.length === 0) {
            if (activeInput === 1) {
                setInput1Val('')
            } else if (activeInput === 2) {
                setInput2Val('')
                input1?.current.focus()
            } else if (activeInput === 3) {
                setInput3Val('')
                input2?.current.focus()
            } else if (activeInput === 4) {
                setInput4Val('')
                input3?.current.focus()
            } else if (activeInput === 5) {
                setInput5Val('')
                input4?.current.focus()
            } else if (activeInput === 6) {
                setActiveInput(7)
                setInput6Val('')
                input5?.current.focus()
            }
        } else {
            const lastChar = val.charAt(val.length - 1)
            if (activeInput === 1) {
                setInput1Val(lastChar)
                input2?.current.focus()
            } else if (activeInput === 2) {
                setInput2Val(lastChar)
                input3?.current.focus()
            } else if (activeInput === 3) {
                setInput3Val(lastChar)
                input4?.current.focus()
            } else if (activeInput === 4) {
                setInput4Val(lastChar)
                input5?.current.focus()
            } else if (activeInput === 5) {
                setInput5Val(lastChar)
                input6?.current.focus()
            } else if (activeInput === 6) {
                setInput6Val(lastChar)
                input6?.current.blur()
            }
            activeInput <= 6 && setActiveInput(activeInput + 1)
        }
    }

    const handlePaste = (val: string, input: number) => {
        const pasteCode = () => {
            for (let i = input; i <= 6; i++) {
                if (i === 1) {
                    setInput1Val(val.charAt(i - 1))
                    input2?.current.focus()
                } else if (i === 2) {
                    setInput2Val(val.charAt(i - 1))
                    input3?.current.focus()
                } else if (i === 3) {
                    setInput3Val(val.charAt(i - 1))
                    input4?.current.focus()
                } else if (i === 4) {
                    setInput4Val(val.charAt(i - 1))
                    input5?.current.focus()
                } else if (i === 5) {
                    setInput5Val(val.charAt(i - 1))
                    input6?.current.focus()
                } else if (i === 6) {
                    setInput6Val(val.charAt(i - 1))
                    input6?.current.blur()
                }
            }
        }

        setTimeout(pasteCode, 0.1)
    }
    return (
        <CodeInput>
            <div
                style={{
                    display: 'flex',
                    gap: '1rem',
                }}
            >
                <Input
                    value={input1Val}
                    ref={input1}
                    maxLength={1}
                    onChange={(e) => handleChange(e.target.value)}
                    type="number"
                    autoFocus
                    onFocus={() => setActiveInput(1)}
                    onPaste={(e) =>
                        handlePaste(e.clipboardData.getData('text'), 1)
                    }
                    isActive={input1Val.length > 0}
                />
                <Input
                    value={input2Val}
                    ref={input2}
                    onChange={(e) => handleChange(e.target.value)}
                    type="number"
                    onFocus={() => setActiveInput(2)}
                    isActive={input2Val.length > 0}
                />
                <Input
                    value={input3Val}
                    ref={input3}
                    onChange={(e) => handleChange(e.target.value)}
                    type="number"
                    onFocus={() => setActiveInput(3)}
                    isActive={input3Val.length > 0}
                />
            </div>
            <div
                style={{
                    display: 'flex',
                    gap: '1rem',
                }}
            >
                <Input
                    value={input4Val}
                    ref={input4}
                    onChange={(e) => handleChange(e.target.value)}
                    type="number"
                    onFocus={() => setActiveInput(4)}
                    isActive={input4Val.length > 0}
                />
                <Input
                    value={input5Val}
                    ref={input5}
                    onChange={(e) => handleChange(e.target.value)}
                    type="number"
                    onFocus={() => setActiveInput(5)}
                    isActive={input5Val.length > 0}
                />
                <Input
                    value={input6Val}
                    ref={input6}
                    onChange={(e) => handleChange(e.target.value)}
                    type="number"
                    onFocus={() => setActiveInput(6)}
                    isActive={input6Val.length > 0}
                />
            </div>
        </CodeInput>
    )
}

export default SecurityCodeInput
