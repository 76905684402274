import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useActions, useTypedSelector } from '../../../hooks'
import { useDefaultCountryDenmark } from '../../../hooks/useDefaultCountryDenmark'
import { NextButton } from '../../reusable/buttons'
import CountryCodeDropdown from '../../reusable/form/CountryCodeDropdown'
import { FormField, FormInput40, PrimaryLabel } from '../../styled/formElements'
import { HeadingPrimary, HeadingSecondary } from '../../styled/typography'

const Container = styled.div`
    width: 100%;
    margin: 0 auto;
    padding-top: 12rem;
    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 3rem;
`

const Form = styled.form`
    padding-top: 4rem;
    max-width: 45rem;
    width: 30%;
    padding-bottom: 3rem;
    text-align: left;
`

const FlexInputContainer = styled.div`
    display: flex;
    gap: 16px;
`

const PhoneInputContainer = styled.div`
    width: 75%;
`

const AdminPage: React.FC = () => {
    const defaultCountry = useDefaultCountryDenmark()

    useEffect(() => {
        updateOrganizationCountry(defaultCountry)
        updatePersonalInformationCountry(defaultCountry)
        // eslint-disable-next-line
    }, [defaultCountry])

    const { user } = useTypedSelector((state) => state.auth)
    const { createPartner } = useActions()
    const [organization, setOrganization] = useState({
        vat: '',
        organizationName: '',
        email: '',
        country: defaultCountry,
        phone: '',
        address: '',
        city: '',
        zipCode: '',
    })

    const [personalInformation, setPersonalInformation] = useState({
        registererFirstName: '',
        registererLastName: '',
        registererCountry: defaultCountry,
        registererPhone: '',
        registererEmail: '',
    })

    const updateOrganization = (key: string, value: null | string | number) => {
        setOrganization({ ...organization, [key]: value })
    }

    const updatePersonalInformation = (key: string, value: string) => {
        setPersonalInformation({ ...personalInformation, [key]: value })
    }

    const handleAddPartner = () => {
        createPartner({
            ...organization,
            ...personalInformation,
        })
    }

    const updateOrganizationCountry = (country: any) => {
        setOrganization({ ...organization, country })
    }

    const updatePersonalInformationCountry = (country: any) => {
        setPersonalInformation({
            ...personalInformation,
            registererCountry: country,
        })
    }

    return (
        <Container>
            <HeadingPrimary>Hello, {user && user.firstName}</HeadingPrimary>
            <HeadingSecondary>Company information</HeadingSecondary>
            <Form>
                <FormField>
                    <PrimaryLabel>VAT</PrimaryLabel>
                    <FormInput40
                        type="text"
                        value={organization.vat}
                        onChange={(e) =>
                            updateOrganization('vat', e.target.value)
                        }
                    />
                </FormField>
                <FormField>
                    <PrimaryLabel>Company name</PrimaryLabel>
                    <FormInput40
                        type="text"
                        value={organization.organizationName}
                        onChange={(e) =>
                            updateOrganization(
                                'organizationName',
                                e.target.value
                            )
                        }
                    />
                </FormField>
                <FormField>
                    <PrimaryLabel>Email</PrimaryLabel>
                    <FormInput40
                        type="email"
                        value={organization.email}
                        onChange={(e) =>
                            updateOrganization('email', e.target.value)
                        }
                    />
                </FormField>

                <FormField>
                    <PrimaryLabel>Phone</PrimaryLabel>
                    <FlexInputContainer>
                        <CountryCodeDropdown
                            error={false}
                            success={false}
                            selectDropdownCB={updateOrganizationCountry}
                        />
                        <PhoneInputContainer>
                            <FormInput40
                                type="text"
                                value={organization.phone}
                                onChange={(e) =>
                                    updateOrganization('phone', e.target.value)
                                }
                            />
                        </PhoneInputContainer>
                    </FlexInputContainer>
                </FormField>

                <FormField>
                    <PrimaryLabel>Address</PrimaryLabel>
                    <FormInput40
                        type="text"
                        value={organization.address}
                        onChange={(e) =>
                            updateOrganization('address', e.target.value)
                        }
                    />
                </FormField>

                <FormField>
                    <PrimaryLabel>City</PrimaryLabel>
                    <FormInput40
                        type="text"
                        value={organization.city}
                        onChange={(e) =>
                            updateOrganization('city', e.target.value)
                        }
                    />
                </FormField>

                <FormField>
                    <PrimaryLabel>Zip Code</PrimaryLabel>
                    <FormInput40
                        type="text"
                        value={organization.zipCode}
                        onChange={(e) =>
                            updateOrganization('zipCode', e.target.value)
                        }
                    />
                </FormField>
            </Form>
            <HeadingSecondary>Registerer information</HeadingSecondary>
            <Form>
                <FormField>
                    <PrimaryLabel>First name</PrimaryLabel>
                    <FormInput40
                        type="text"
                        value={personalInformation.registererFirstName}
                        onChange={(e) =>
                            updatePersonalInformation(
                                'registererFirstName',
                                e.target.value
                            )
                        }
                    />
                </FormField>
                <FormField>
                    <PrimaryLabel>Last name</PrimaryLabel>
                    <FormInput40
                        type="text"
                        value={personalInformation.registererLastName}
                        onChange={(e) =>
                            updatePersonalInformation(
                                'registererLastName',
                                e.target.value
                            )
                        }
                    />
                </FormField>
                <FormField>
                    <PrimaryLabel>Phone</PrimaryLabel>
                    <FlexInputContainer>
                        <CountryCodeDropdown
                            error={false}
                            success={false}
                            selectDropdownCB={updatePersonalInformationCountry}
                        />
                        <PhoneInputContainer>
                            <FormInput40
                                type="text"
                                value={personalInformation.registererPhone}
                                onChange={(e) =>
                                    updatePersonalInformation(
                                        'registererPhone',
                                        e.target.value
                                    )
                                }
                            />
                        </PhoneInputContainer>
                    </FlexInputContainer>
                </FormField>

                <FormField>
                    <PrimaryLabel>Email</PrimaryLabel>
                    <FormInput40
                        type="text"
                        value={personalInformation.registererEmail}
                        onChange={(e) =>
                            updatePersonalInformation(
                                'registererEmail',
                                e.target.value
                            )
                        }
                    />
                </FormField>
            </Form>
            <NextButton isActive={true} handleOnClick={handleAddPartner} />
        </Container>
    )
}

export default AdminPage
