import axios from 'axios'
import config from '../../config'
import { CaseStatisticActionType } from '../action-types'

const { apiUrl } = config

const setGetDashboardStatisticsLoading = () => ({
    type: CaseStatisticActionType.SET_GET_DASHBOARD_CASE_STATISTICS_LOADING,
})

export const getDashboardStatistics = () => async(dispatch: any) => {
    dispatch(setGetDashboardStatisticsLoading())

    const res = await axios.get(`${apiUrl}/v1/me/statistics`)

    dispatch({
        type: CaseStatisticActionType.GET_DASHBOARD_CASE_STATISTICS,
        payload: res.data,
    })
}
