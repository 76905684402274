import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useActions, useTypedSelector, useTranslation } from '../../../../hooks'
import { ReportQuestionInterface } from '../../../../ts/interfaces/ReportQuestionInterface'
import AddQuestionModal from './modal/AddQuestionModal'
import QuestionComponent from './modal/QuestionComponent'

export interface LoadingProps {
    loading: boolean
}

const AddQuestionButton = styled.button`
    color: ${({ theme }) => theme.blue};
    background-color: rgba(36, 125, 208, 0.3);
    border: 1px solid rgba(36, 125, 208, 0.3);
    border-radius: 0.5rem;
    padding: 1rem 2rem;
    font-size: 1.8rem;
    margin-bottom: 2rem;

    &:hover {
        background-color: rgba(36, 125, 208, 0.7);
    }
`

const ReportQuestionsFrame = () => {
    const [isAddingQuestionModalOpen, setIsAddingQuestionModalOpen] =
        useState(false)
    const { client } = useTypedSelector((state) => state.client)
    const translation = useTranslation()
    const {
        addReportQuestion,
        getReportQuestions,
        putReportQuestion,
        deleteReportQuestion,
    } = useActions()
    const { reportQuestions, reportQuestionsLoading } = useTypedSelector(
        (state) => state.reportQuestions
    )

    const handleAddReportQuestion = async (
        questionItem: ReportQuestionInterface
    ) => {
        const success = await addReportQuestion(
            client.organization.data.id,
            questionItem
        )

        return success
    }

    
    useEffect(() => {
        getReportQuestions(client.organization.data.id)
        // eslint-disable-next-line
    }, [])

    const handleDelete = async (questionItem: ReportQuestionInterface) => {
        const success = await deleteReportQuestion(
            client.organization.data.id,
            questionItem
        )

        return success
    }

    const handleEdit = async (
        questionItem: ReportQuestionInterface,
        editedText: string
    ) => {
        const newQuestionItem = {
            ...questionItem,
            question: editedText,
        }
        const success = await putReportQuestion(
            client.organization.data.id,
            newQuestionItem,
            newQuestionItem
        )

        return success
    }

    return (
        <>
            {isAddingQuestionModalOpen && (
                <AddQuestionModal
                    closeModal={() => setIsAddingQuestionModalOpen(false)}
                    handleAddReportQuestion={handleAddReportQuestion}
                    loading={reportQuestionsLoading}
                />
            )}
            <AddQuestionButton
                onClick={() => setIsAddingQuestionModalOpen(true)}
            >
                + {translation.clientView.addQuestion}
            </AddQuestionButton>

            {reportQuestions.map((questionItem: ReportQuestionInterface) => {
                return (
                    <QuestionComponent
                        key={questionItem.id}
                        index={questionItem.id}
                        reportQuestions={reportQuestions}
                        questionItem={questionItem}
                        handleDelete={handleDelete}
                        handleEdit={handleEdit}
                        loading={reportQuestionsLoading}
                    />
                )
            })}
        </>
    )
}

export default ReportQuestionsFrame
