import { useTypedSelector } from './useTypedSelector'

export const useDefaultCountryDenmark = () => {
    const { countries } = useTypedSelector((state) => state.country)

    const defualtCountry = countries
        ? countries.find((country: any) => country.id === 208)
        : 'still default'

    return defualtCountry
}
