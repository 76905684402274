import { useEffect, useState } from 'react'
import { useTypedSelector } from '../../hooks'
import ApperanceModal from '../pages/whistleblower/new-case/modals/ApperanceModal'

const ActiveModal = () => {
    const openModal: string | null = useTypedSelector(
        (state) => state.modal.openModal
    )

    const [activeModal, setActiveModal] = useState<null | JSX.Element>(null)

    useEffect(() => {
        if (openModal) {
            if (openModal === 'apperance') {
                setActiveModal(<ApperanceModal />)
            }
        } else {
            setActiveModal(null)
        }
    }, [openModal])
    return <>{activeModal}</>
}

export default ActiveModal
