import styled from 'styled-components'
import {
    Card,
    CardCaseHeadingRow,
    CardCaseHeadingText,
    CardCaseRow,
    CardHeader,
    CaseCardText,
} from '../../../../styled/cards'

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr 1fr;
    height: 100%;
    align-items: center;
`

const InvoiceLink = styled.p`
    font-size: 1.4rem;
    cursor: pointer;
    color: ${({ theme }) => theme.blue};
`

const PaymentDeitails: React.FC = () => {
    return (
        <Card>
            <CardHeader>Invoices</CardHeader>
            <CardCaseHeadingRow>
                <Grid>
                    <CardCaseHeadingText>Date</CardCaseHeadingText>
                    <CardCaseHeadingText>Description</CardCaseHeadingText>
                    <CardCaseHeadingText>Plan</CardCaseHeadingText>
                    <CardCaseHeadingText>Total</CardCaseHeadingText>
                    <div />
                </Grid>
            </CardCaseHeadingRow>
            <CardCaseRow>
                <Grid>
                    <CaseCardText>02.02.2020</CaseCardText>
                    <CaseCardText>Walor Whistleblower Solution</CaseCardText>
                    <CaseCardText>Yearly</CaseCardText>
                    <CaseCardText>5.999 DKK</CaseCardText>
                    <InvoiceLink>View invoice</InvoiceLink>
                </Grid>
            </CardCaseRow>
            <CardCaseRow>
                <Grid>
                    <CaseCardText>02.02.2020</CaseCardText>
                    <CaseCardText>Walor Whistleblower Solution</CaseCardText>
                    <CaseCardText>Yearly</CaseCardText>
                    <CaseCardText>5.999 DKK</CaseCardText>
                    <InvoiceLink>View invoice</InvoiceLink>
                </Grid>
            </CardCaseRow>
            <CardCaseRow>
                <Grid>
                    <CaseCardText>02.02.2020</CaseCardText>
                    <CaseCardText>Walor Whistleblower Solution</CaseCardText>
                    <CaseCardText>Yearly</CaseCardText>
                    <CaseCardText>5.999 DKK</CaseCardText>
                    <InvoiceLink>View invoice</InvoiceLink>
                </Grid>
            </CardCaseRow>
            <CardCaseRow>
                <Grid>
                    <CaseCardText>02.02.2020</CaseCardText>
                    <CaseCardText>Walor Whistleblower Solution</CaseCardText>
                    <CaseCardText>Yearly</CaseCardText>
                    <CaseCardText>5.999 DKK</CaseCardText>
                    <InvoiceLink>View invoice</InvoiceLink>
                </Grid>
            </CardCaseRow>
        </Card>
    )
}

export default PaymentDeitails
