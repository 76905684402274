import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
    useActions,
    useIsPartner,
    useTranslation,
    useTypedSelector,
} from '../../../../hooks'
import EnterPasswordModal from '../../../layout/EnterPasswordModal'
import { Card } from '../../../styled/cards'
import { HeadingSecondary } from '../../../styled/typography'
import MyAccount from './frames/MyAccount'
import PaymentDeitails from './frames/PaymentDeitails'
import Users from './frames/Users'
import AddUserModal from './modals/AddUserModal'
import Payment from './frames/Payment'
import Organization from './frames/Organization'

type CurrentFrameNameType =
    | 'my-account'
    | 'organization'
    | 'users'
    | 'payment-details'
    | 'payment-methods'

const Container = styled.div`
    width: 100%;
    padding: 4rem;
    position: relative;
`

const FlexContainer = styled.div`
    display: flex;
    gap: 2.2rem;
    padding-top: 2rem;
`

const LeftContainer = styled.div`
    width: 25%;
`

const RightContainer = styled.div`
    width: 75%;
`

const NavigationContainer = styled.div`
    height: 46rem;
    position: relative;
`

interface IsActive {
    isActive: boolean
}

const NavigationLink = styled.div<IsActive>`
    height: 6.2rem;
    width: 100%;
    border-bottom: ${({ theme, isActive }) =>
        isActive ? `2px solid ${theme.blue}` : `1px solid ${theme.primary}10`};

    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s;

    :hover {
        background: ${({ theme }) => `${theme.primary}05`};
    }
`

const NavigationLinkText = styled.span<IsActive>`
    font-size: 1.8rem;
    color: ${({ theme }) => theme.primary};
    opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
    margin-left: 1.4rem;
`

const LogOutLink = styled.span`
    cursor: pointer;

    font-size: 1.8rem;
    color: ${({ theme }) => theme.primary};
    opacity: 0.5;
    position: absolute;
    bottom: 2rem;
    left: 1.4rem;
    transition: transform 0.2s;

    :hover {
        transform: scale(1.02);
    }
`

const SettingsPage: React.FC = () => {
    const {
        getSelf,
        getAllCaseworkers,
        logout,
        addCaseworker,
        addCaseworkerValidation,
    } = useActions()
    const {
        user,
        organization,
        caseworkers,
        caseworkerLoading,
        newCaseworkerValidationErrors,
    } = useTypedSelector((state) => state.caseworker)

    const authUser = useTypedSelector((state) => state.auth.user)
    const { isTrialExpired } = useTypedSelector((state) => state.payment)

    const isPartner = useIsPartner()

    const translation = useTranslation()

    const [currentFrame, setCurrentFrame] = useState(
        isTrialExpired ? <Payment /> : <MyAccount />
    )
    const [currentFrameName, setCurrentFrameName] =
        useState<CurrentFrameNameType>(
            isTrialExpired ? 'payment-methods' : 'my-account'
        )

    const [isAddUserModalActive, setIsAddUserModalActive] = useState(false)

    const [newUser, setNewUser] = useState<any>(null)
    const [showPasswordModal, setShowPasswordModal] = useState(false)

    useEffect(() => {
        !user && getSelf()
        if (isPartner && organization) {
            caseworkers.length === 0 && getAllCaseworkers(organization.id)
        }
        // eslint-disable-next-line
    }, [organization])

    useEffect(() => {
        if (currentFrameName === 'my-account') {
            setCurrentFrame(<MyAccount />)
        } else if (currentFrameName === 'organization') {
            setCurrentFrame(<Organization />)
        } else if (currentFrameName === 'users') {
            setCurrentFrame(
                <Users openModal={() => setIsAddUserModalActive(true)} />
            )
        } else if (currentFrameName === 'payment-methods') {
            setCurrentFrame(<Payment />)
        } else if (currentFrameName === 'payment-details') {
            setCurrentFrame(<PaymentDeitails />)
        }

        // eslint-disable-next-line
    }, [currentFrameName])

    useEffect(() => {
        if (newCaseworkerValidationErrors.length > 0) {
            setIsAddUserModalActive(true)
        }
    }, [newCaseworkerValidationErrors])

    const handleAddCaseworker = async () => {
        await addCaseworker(organization.id, newUser)
        setShowPasswordModal(false)
    }

    const handleStartPasswordRequest = async (user: any) => {
        const isValid: any = await addCaseworkerValidation(user)
        if (isValid) {
            setNewUser(user)
            setShowPasswordModal(true)
            setIsAddUserModalActive(false)
        }
    }

    return (
        <Container>
            {isAddUserModalActive && (
                <AddUserModal
                    contactView={false}
                    closeModal={() => setIsAddUserModalActive(false)}
                    isAddSelfModal={false}
                    addUserCallback={handleStartPasswordRequest}
                    isLoading={caseworkerLoading}
                    errors={newCaseworkerValidationErrors}
                    isLineOfContact={false}
                />
            )}
            {showPasswordModal && (
                <EnterPasswordModal
                    closeModal={() => setShowPasswordModal(false)}
                    onContinueButtonClicked={handleAddCaseworker}
                />
            )}
            <HeadingSecondary>
                {translation.settingsPartner.headlineSettings}
            </HeadingSecondary>
            <FlexContainer>
                <LeftContainer>
                    <NavigationContainer>
                        <Card>
                            <NavigationLink
                                isActive={currentFrameName === 'my-account'}
                                onClick={() =>
                                    setCurrentFrameName('my-account')
                                }
                            >
                                <NavigationLinkText
                                    isActive={currentFrameName === 'my-account'}
                                >
                                    {translation.settingsPartner.myAccount}
                                </NavigationLinkText>
                            </NavigationLink>
                            {isPartner && (
                                <NavigationLink
                                    isActive={
                                        currentFrameName === 'organization'
                                    }
                                    onClick={() =>
                                        setCurrentFrameName('organization')
                                    }
                                >
                                    <NavigationLinkText
                                        isActive={
                                            currentFrameName === 'organization'
                                        }
                                    >
                                        {
                                            translation.settingsPartner
                                                .organization
                                        }
                                    </NavigationLinkText>
                                </NavigationLink>
                            )}
                            {isPartner && (
                                <NavigationLink
                                    isActive={currentFrameName === 'users'}
                                    onClick={() => setCurrentFrameName('users')}
                                >
                                    <NavigationLinkText
                                        isActive={currentFrameName === 'users'}
                                    >
                                        {translation.settingsPartner.users}
                                    </NavigationLinkText>
                                </NavigationLink>
                            )}
                            {authUser?.roles.includes('client') && (
                                <NavigationLink
                                    isActive={
                                        currentFrameName === 'payment-methods'
                                    }
                                    onClick={() =>
                                        setCurrentFrameName('payment-methods')
                                    }
                                >
                                    <NavigationLinkText
                                        isActive={
                                            currentFrameName ===
                                            'payment-methods'
                                        }
                                    >
                                        {translation.settingsPartner.billing}
                                    </NavigationLinkText>
                                </NavigationLink>
                            )}
                            {/* <NavigationLink
                                isActive={currentFrameNumber === 4}
                                onClick={() => setCurrentFrameNumber(4)}
                            >
                                <NavigationLinkText
                                    isActive={currentFrameNumber === 4}
                                >
                                    {translation.settingsPartner.invoices}
                                </NavigationLinkText>
                            </NavigationLink> */}
                            <LogOutLink onClick={logout}>
                                {translation.settingsPartner.logOut}
                            </LogOutLink>
                        </Card>
                    </NavigationContainer>
                </LeftContainer>
                <RightContainer>{currentFrame}</RightContainer>
            </FlexContainer>
        </Container>
    )
}

export default SettingsPage
