import Translation from '../../state/state-types/TranslationStateType'

export const isValidCompanyIdentificationNumber = (number: string) => {
    if (number.length !== 8) {
        return false
    }

    return true
}

export const checkCompanyIdentificationNumber = (
    number: string,
    translation: Translation
) => {
    if (number.length !== 8) {
        return translation.validation.companyIdentificationNumberMust
    }

    return ''
}
