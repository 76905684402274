import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation, useTypedSelector } from '../../../../../../hooks'
import BackButton from '../../../../../reusable/buttons/BackButton'
import CompanyInformationFrame from '../../../../../reusable/frames/CompanyInformationFrame'
import CompanyInvoiceFrame from '../../../../../reusable/frames/CompanyInvoiceFrame'
import LineOfContactsFrame from '../../../../../reusable/frames/LineOfContactsFrame'
import { HeadingSecondary } from '../../../../../styled/typography'
import CompanyCases from './frames/CompanyCases'

const Container = styled.div`
  width: 100%;
  padding: 4rem;
  position: relative;
`

const Navigation = styled.div`
  border-bottom: 1px solid ${({ theme }) => `${theme.primary}20`};
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 2rem;
  margin-bottom: 2rem;
`

interface IsActive {
    isActive: boolean
}

const NavigationLink = styled.div<IsActive>`
  padding-bottom: 1rem;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
  color: ${({ theme }) => theme.primary};
  font-size: 1.8rem;
  font-weight: 600;
  margin-right: 3.5rem;
  border-bottom: ${({ theme, isActive }) =>
          isActive ? `2px solid ${theme.blue} ` : 'none'};
  cursor: pointer;
`

interface SingleCompanyProps {
    setCurrentFrameNumber: (frameNumber: number) => void
}

const SingleCompany: React.FC<SingleCompanyProps> = ({
    setCurrentFrameNumber,
}) => {
    const { selectedOrganizationInformation } = useTypedSelector(
        (state) => state.caseworker,
    )

    const translation = useTranslation()

    const [currentInnerFrameNumber, setCurrentInnerFrameNumber] = useState(1)
    const [currentInnerFrame, setCurrentInnerFrame] = useState(
        <CompanyInformationFrame
            client={selectedOrganizationInformation.contact_person.data}
            clientOrganization={selectedOrganizationInformation}
        />,
    )

    useEffect(() => {
        if (currentInnerFrameNumber === 1) {
            setCurrentInnerFrame(
                <CompanyInformationFrame
                    client={selectedOrganizationInformation.contact_person.data}
                    clientOrganization={selectedOrganizationInformation}
                />,
            )
        } else if (currentInnerFrameNumber === 2) {
            setCurrentInnerFrame(<LineOfContactsFrame />)
        } else if (currentInnerFrameNumber === 3) {
            setCurrentInnerFrame(<CompanyCases />)
        } else if (currentInnerFrameNumber === 4) {
            setCurrentInnerFrame(<CompanyInvoiceFrame />)
        }
        // eslint-disable-next-line
    }, [currentInnerFrameNumber])
    return (
        <Container>
            <BackButton onClick={() => setCurrentFrameNumber(1)} />
            <HeadingSecondary style={{ marginTop: '1.4rem' }}>
                {selectedOrganizationInformation.name}
            </HeadingSecondary>
            <Navigation>
                <NavigationLink
                    isActive={currentInnerFrameNumber === 1}
                    onClick={() => setCurrentInnerFrameNumber(1)}
                >
                    {translation.singleClientViewPartner.information}
                </NavigationLink>
                <NavigationLink
                    isActive={currentInnerFrameNumber === 2}
                    onClick={() => setCurrentInnerFrameNumber(2)}
                >
                    {translation.singleClientViewPartner.lineOfContact}
                </NavigationLink>
                <NavigationLink
                    isActive={currentInnerFrameNumber === 3}
                    onClick={() => setCurrentInnerFrameNumber(3)}
                >
                    {translation.singleClientViewPartner.cases}
                </NavigationLink>
                {/* <NavigationLink
                    isActive={currentInnerFrameNumber === 4}
                    onClick={() => setCurrentInnerFrameNumber(4)}
                >
                    Invoices
                </NavigationLink> */}
            </Navigation>
            {currentInnerFrame}
        </Container>
    )
}

export default SingleCompany
