import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { DynamicHeightSaveButton } from '../../../../../styled/buttons'
import { useTranslation } from '../../../../../../hooks'

interface IsActiveProp {
    isActive: boolean
}
const Contianer = styled.div`
    margin: 0 6.2rem;
`

const Heading = styled.div`
    font-size: 1.6rem;
    margin-top: 2.4rem;
    color: ${({ theme }) => theme.primary};
`

const ButtonContainer = styled.div`
    display: flex;
    gap: 3.6rem;
    padding-top: 1rem;
`

const Button = styled.div<IsActiveProp>`
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.greyText};
    color: ${({ theme, isActive }) =>
        isActive ? theme.white : theme.greyText};
    background: ${({ theme, isActive }) =>
        isActive ? theme.primary : theme.white};
    font-size: 1.6rem;
    cursor: ${({ isActive }) => (!isActive ? 'pointer' : 'not-allowed')};

    display: flex;
    align-items: center;
    justify-content: center;
`

const ScoreContainer = styled.div`
    padding-top: 4rem;
`

const Score = styled.h3<IsActiveProp>`
    font-size: 2.8rem;
    font-weight: medium;
    color: ${({ theme, isActive }) =>
        isActive ? theme.primary : `${theme.primary}20`};
    padding-top: 1rem;
    padding-bottom: 2rem;
`

interface AccusationAndEvidenceScoreProps {
    setScore: (score: number | null) => void
}

const AccusationAndEvidenceScore: React.FC<AccusationAndEvidenceScoreProps> = ({
    setScore,
}) => {
    const translation = useTranslation()
    const [accusationScore, setAccusationScore] = useState(0)
    const [evidenceScore, setEvidenceScore] = useState(0)

    const [bothScoresSelected, setBothScoresSelected] = useState(false)

    useEffect(() => {
        if (accusationScore && evidenceScore) {
            setBothScoresSelected(true)
        }
    }, [accusationScore, evidenceScore])

    const handleSave = () => {
        if (bothScoresSelected) {
            setScore(accusationScore + evidenceScore)
        }
    }

    return (
        <Contianer>
            <Heading>{translation.forwardReportPage.accusationScore}</Heading>
            <ButtonContainer>
                {[1, 2, 3].map((i) => (
                    <Button
                        isActive={accusationScore === i}
                        onClick={() => setAccusationScore(i)}
                        key={i}
                    >
                        {i}
                    </Button>
                ))}
            </ButtonContainer>
            <Heading>{translation.forwardReportPage.evidenceScore}</Heading>
            <ButtonContainer>
                {[1, 2, 3].map((i) => (
                    <Button
                        isActive={evidenceScore === i}
                        onClick={() => setEvidenceScore(i)}
                        key={i}
                    >
                        {i}
                    </Button>
                ))}
            </ButtonContainer>
            <ScoreContainer>
                <Heading>{translation.forwardReportPage.accusationAndEvidenceScore}</Heading>
            </ScoreContainer>
            <Score isActive={bothScoresSelected}>
                {accusationScore + evidenceScore}
            </Score>
            <DynamicHeightSaveButton
                height="3rem"
                width="7.6rem"
                fontSize="1.4rem"
                onClick={handleSave}
                isActive={bothScoresSelected}
            >
                 {translation.forwardReportPage.saveThis}
            </DynamicHeightSaveButton>
        </Contianer>
    )
}

export default AccusationAndEvidenceScore
