import produce from 'immer'
import { NotificationActionType } from '../action-types'
import { NotificationAction } from '../actions'

interface NotificationState {
    notificationCount: number
    notifications: any[]
    notificationPage: number
    notificationsNextPageLoading: boolean
    filteredNotifications: any[]
}

const initialState: NotificationState = {
    notificationCount: 0,
    notifications: [],
    notificationPage: 1,
    notificationsNextPageLoading: false,
    filteredNotifications: [],
}

const reducer = produce(
    (state: NotificationState = initialState, action: NotificationAction) => {
        switch (action.type) {
            case NotificationActionType.GET_NOTIFICATIONS:
                // @ts-ignore
                state.notifications.push(...action.payload.notifications)
                // @ts-ignore
                state.filteredNotifications.push(
                    ...action.payload.notifications
                )

                if (!state.notificationCount) {
                    state.notificationCount = action.payload.notificationCount
                }

                state.notificationPage++

                state.notificationsNextPageLoading = false

                return state

            case NotificationActionType.SET_GET_NOTIFICATIONS_NEXT_PAGE_LOADING:
                state.notificationsNextPageLoading = true

                return state

            case NotificationActionType.GET_NOTIFICATIONS_NEXT_PAGE:
                return state

            case NotificationActionType.SHOW_LATEST_NOTIFICATION:
                state.notifications.unshift(action.payload)
                state.filteredNotifications.unshift(action.payload)
                state.notificationCount++

                return state

            case NotificationActionType.READ_ALL_NOTIFICATIONS:
                state.notifications = state.notifications.map(
                    (notification: any) => ({
                        ...notification,
                        read_at: '55',
                    })
                )

                return state

            case NotificationActionType.READ_SINGLE_NOTIFICATION:
                state.notificationCount--

                state.notifications = state.notifications.map(
                    (notification) => {
                        if (notification.id === action.payload.id) {
                            return {
                                ...notification,
                                read_at: '55',
                            }
                        } else {
                            return notification
                        }
                    }
                )

                state.filteredNotifications = state.filteredNotifications.map(
                    (notification) => {
                        if (notification.id === action.payload.id) {
                            return {
                                ...notification,
                                read_at: '55',
                            }
                        } else {
                            return notification
                        }
                    }
                )

                return state

            case NotificationActionType.FILTER_NOTIFICATIONS:
                if (action.payload === 'all') {
                    state.filteredNotifications = state.notifications
                } else if (action.payload === 'unread') {
                    state.filteredNotifications = state.notifications.filter(
                        (notification) => notification.read_at === null
                    )
                }

                return state

            case NotificationActionType.RESET_NOTIFICATION_COUNT:
                state.notificationCount = 0

                return state
            default:
                return state
        }
    }
)

export default reducer
