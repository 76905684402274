import styled from 'styled-components'
import { useTypedSelector } from '../../hooks'

const Select = styled.select`
    display: block;
    width: 100%;
    height: 4rem;
    border: ${({ theme }) => `2px solid ${theme.lineGrey}`};
    border-radius: 0.5rem;
`

const Option = styled.option``

interface CountryDropdownProps {
    onCountrySelect: (val: string) => void
}

const CountryDropdown: React.FC<CountryDropdownProps> = ({
    onCountrySelect,
}) => {
    const countries = useTypedSelector((state) => state.country.countries)

    return (
        <Select
            onChange={(event) =>
                onCountrySelect(JSON.parse(event.target.value))
            }
        >
            {countries &&
                countries.map((country: any) => (
                    <Option
                        value={JSON.stringify(country)}
                        selected={country.id === 208}
                    >
                        {country.name}
                    </Option>
                ))}
        </Select>
    )
}

export default CountryDropdown
