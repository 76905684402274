import { Modal, ModalContainer } from '../../styled/modals'
import { Card, CardBody, CardHeader } from '../../styled/cards'
import React from 'react'
import styled from 'styled-components'
import { useActions, useTypedSelector } from '../../../hooks'
import ButtonSpinnerWhite from '../../layout/spinner/ButtonSpinnerWhite'

const ModalHeading = styled.h2`
    font-size: 2.4rem;
    font-weight: 600;
    text-align: center;
`

const ModalText = styled.p`
    font-size: 1.6rem;
    text-align: center;
    color: ${({ theme }) => `${theme.primary}80`};
    margin-top: 1.4rem;
`

const ButtonContainer = styled.div`
    display: flex;
    gap: 1.4rem;
    justify-content: center;
    margin-top: 4rem;
`

const DeleteButton = styled.div`
    height: 5rem;
    width: 50%;
    background: ${({ theme }) => theme.red};
    color: ${({ theme }) => theme.white};
    font-size: 1.6rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    :hover {
        opacity: 0.8;
    }
`

const CancelButton = styled.div`
    height: 5rem;
    width: 50%;
    background: ${({ theme }) => theme.blue};
    color: ${({ theme }) => theme.white};
    font-size: 1.6rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    :hover {
        opacity: 0.8;
    }
`

interface DeleteContactModalProps {
    contact: any

    closeModal: () => void
    handleDelete: () => void
}

const DeleteContactModal: React.FC<DeleteContactModalProps> = ({
    contact,
    closeModal,
    handleDelete,
}) => {
    const { deleteContact } = useActions()

    const { client } = useTypedSelector((state) => state.client)

    const { lineOfContactDeleteLoading } = useTypedSelector(
        (state) => state.lineOfContact
    )

    const handleDeleteContact = async () => {
        await deleteContact(client?.id, contact?.id)

        closeModal()
    }

    return (
        <ModalContainer>
            <Modal height="32rem" width="50rem">
                <Card>
                    <CardHeader>Delete Contact</CardHeader>
                    <CardBody
                        style={{
                            padding: '1.4rem',
                        }}
                    >
                        <ModalHeading>Are you sure?</ModalHeading>
                        <ModalText>
                            If you delete {contact?.first_name}{' '}
                            {contact?.last_name} he/she will be unable to
                            receive a report.
                        </ModalText>
                        <ModalText>
                            This action cannot be undone. If the contact already
                            has a report assigned to him/her you will not be
                            able to delete the contact.
                        </ModalText>
                        <ButtonContainer>
                            <DeleteButton onClick={handleDeleteContact}>
                                {lineOfContactDeleteLoading ? (
                                    <ButtonSpinnerWhite />
                                ) : (
                                    'Delete'
                                )}
                            </DeleteButton>
                            <CancelButton onClick={closeModal}>
                                Cancel
                            </CancelButton>
                        </ButtonContainer>
                    </CardBody>
                </Card>
            </Modal>
        </ModalContainer>
    )
}

export default DeleteContactModal
