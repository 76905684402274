import { FiArrowLeft } from 'react-icons/fi'
import styled from 'styled-components'
import { FlexAlignCenter } from '../../styled/flex'

const Button = styled.div`
  opacity: 0.5;
  cursor: pointer;

  position: absolute;
  top: 2rem;
`

const BackIcon = styled(FiArrowLeft)`
  color: ${({ theme }) => theme.primary};
`

const BackButtonText = styled.span`
  color: ${({ theme }) => theme.primary};
  font-size: 1rem;
  margin-left: 0.5rem;
  margin-top: 0.3rem;
`

interface BackButtonProps {
    onClick: (pageNumber?: number) => void
}

const BackButton: React.FC<BackButtonProps> = ({ onClick }) => {
    return (
        <Button onClick={() => onClick()}>
            <FlexAlignCenter>
                <BackIcon size={18} />
                <BackButtonText>Back</BackButtonText>
            </FlexAlignCenter>
        </Button>
    )
}

export default BackButton
