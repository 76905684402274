// import { AdminActionType } from '../action-types'
import config from '../../config'
import axios from 'axios'
import { Dispatch } from 'redux'


const { apiUrl } = config

export const createPartner = (newPartner: any) => async (dispatch: Dispatch) => {
    const partner = {
        first_name: newPartner.registererFirstName,
        last_name: newPartner.registererLastName,
        phone: newPartner.registererPhone,
        email: newPartner.registererEmail,
        country_id: newPartner.registererCountry.id,
    }

    const organization = {
        name: newPartner.organizationName,
        email: newPartner.email,
        address: newPartner.address,
        phone_number: newPartner.phone,
        city: newPartner.city,
        vat: newPartner.vat,
        zip_code: newPartner.zipCode,
        country: newPartner.country.id,
    }

    const payload = {
        partner,
        organization,
    }

    try {
        const res = await axios.post(`${apiUrl}/v1/admin/partners`, payload)

        if (res.status !== 201 && res.status !== 200 && res.status !== 204) {
            return alert('failed to create the partner')
        }

        return alert('You have created a new partner')
    } catch (error) {
        alert('failed to create the partner')
    }

    // return dispatch({
    //     type: AdminActionType.CREATE_PARTNER,
    //     payload: partner,
    // })
}
