import styled from 'styled-components'
import {
    CheckIcon,
    EditButton,
    EditButtonText,
    EditIcon,
    SaveButton,
    SaveButtonText,
} from '../../../../styled/buttons'
import { Card, CardHeader } from '../../../../styled/cards'
import {
    ErrorMessage,
    FormField,
    FormInput40,
    InputFieldIcon,
    PrimaryLabel,
} from '../../../../styled/formElements'
import { useState, useEffect } from 'react'
import {
    useTranslation,
    useActions,
    useTypedSelector,
} from '../../../../../hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import ButtonSpinnerWhite from '../../../../layout/spinner/ButtonSpinnerWhite'
import { ChangePasswordInterface } from '../../../../../ts/interfaces/ChangePasswordInterface'

import PasswordValidationChecklist from '../../../../PasswordValidationChecklist'
import UpdatedSettingsModal from './components/UpdatedSettingsModal'
import { ReactComponent as CloseIcon } from '../../../../../assets/images/x-icon.svg'

const eye = <FontAwesomeIcon icon={faEye} />
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />

interface IsActiveProp {
    isActive: boolean
}

const FormContainer = styled.form`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-block-start: 2rem;
    padding-inline: 4rem;
`

const FormSection = styled.div`
    width: 40%;
`

const UpdatePassword = styled(SaveButton)<IsActiveProp>`
    background: ${({ theme, isActive }) =>
        isActive ? theme.blue : `${theme.primary}10`};
    && .save-button-text,
    .check-icon {
        color: ${({ theme, isActive }) =>
            isActive ? theme.white : `${theme.primary}40`};
    }

    &:hover {
        opacity: 0.9;
    }
`

const UpdateStettings = styled.div`
    display: flex;
    align-items: center;
    gap: 2rem;
`

const Edit = styled(EditButton)`
    &:hover {
        background-color: ${({ theme }) => `${theme.yellow}20`};
    }
`
const CancelButton = styled.button`
    color: ${({ theme }) => theme.red};
    background-color: ${({ theme }) => `${theme.red}20`};
    cursor: pointer;
    border: none;
    height: 2.9rem;
    width: 12rem;
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    &:hover {
        background-color: ${({ theme }) => `${theme.red}30`};
    }
`

const CancelIcon = styled(CloseIcon)`
    width: 0.8rem;
`

const ChangePassword: React.FC = () => {
    const { user } = useTypedSelector((state) => state.caseworker)
    const { updatePassword, PasswordUpdateSuccess } = useActions()
    const { identityLoading, passwordErrors, passwordSuccess } =
        useTypedSelector((state) => state.identity)

    const translation = useTranslation()

    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [showPassword, setShowPassword] = useState(true)
    const [showConfirmPassword, setShowConfirmPassword] = useState(true)
    const [isFormValidated, setIsFormValidated] = useState(false)
    const [isEditing, setIsEditing] = useState(false)

    const handleFormValidation = (isValid: boolean) => {
        setIsFormValidated(isValid)
    }

    useEffect(() => {
        if (passwordSuccess) {
            const clearSuccessMessage = setTimeout(() => {
                PasswordUpdateSuccess()
            }, 3000)
            return () => clearTimeout(clearSuccessMessage)
        }
    }, [passwordSuccess, PasswordUpdateSuccess])

    const handleShowPasswordCheckboxChanged = () => {
        setShowPassword(!showPassword)
    }
    const handleShowConfirmPasswordCheckboxChanged = () => {
        setShowConfirmPassword(!showConfirmPassword)
    }

    const handleSavePassword = async () => {
        if (!isFormValidated) {
            return
        }

        const updateUserPassword: ChangePasswordInterface = {
            old_password: oldPassword,
            password: newPassword,
            password_confirmation: passwordConfirm,
        }

        const success = await updatePassword(user.userId, updateUserPassword)
        if (!success) {
            return
        }

        setIsEditing(false)
        setOldPassword('')
        setNewPassword('')
        setPasswordConfirm('')
        setIsFormValidated(false)
    }

    const handleCancelButton = () => {
        setIsEditing(false)
        setOldPassword('')
        setNewPassword('')
        setPasswordConfirm('')
        setIsFormValidated(false)
    }

    return (
        <>
            {passwordSuccess && (
                <UpdatedSettingsModal
                    title={translation.settingsPartner.passwordUpdate}
                    tagline={
                        translation.settingsPartner.passwordUpdadetSuccessfully
                    }
                />
            )}
            <Card>
                <CardHeader>
                    {translation.settingsPartner.changePassword}
                    {isEditing ? (
                        <UpdateStettings>
                            <>
                                <CancelButton onClick={handleCancelButton}>
                                    <CancelIcon />
                                    {translation.settingsPartner.cancel}
                                </CancelButton>
                            </>
                            <UpdatePassword
                                isActive={isFormValidated}
                                onClick={handleSavePassword}
                            >
                                {identityLoading ? (
                                    <ButtonSpinnerWhite />
                                ) : (
                                    <>
                                        <CheckIcon className="check-icon" />
                                        <SaveButtonText className="save-button-text">
                                            {translation.settingsPartner.save}
                                        </SaveButtonText>
                                    </>
                                )}
                            </UpdatePassword>
                        </UpdateStettings>
                    ) : (
                        <Edit onClick={() => setIsEditing(true)}>
                            <EditIcon />
                            <EditButtonText>
                                {translation.settingsPartner.edit}
                            </EditButtonText>
                        </Edit>
                    )}
                </CardHeader>
                <FormContainer>
                    <FormSection>
                        <FormField>
                            <PrimaryLabel htmlFor="old-password">
                                {translation.settingsPartner.oldPassword}
                            </PrimaryLabel>
                            <FormInput40
                                disabled={!isEditing}
                                id="old-password"
                                name="old-password"
                                value={oldPassword || ''}
                                onChange={(e) => setOldPassword(e.target.value)}
                                className={
                                    passwordErrors.length > 0
                                        ? 'border-error'
                                        : ''
                                }
                            />
                            {passwordErrors && (
                                <ErrorMessage>{passwordErrors}</ErrorMessage>
                            )}
                        </FormField>
                        <FormField>
                            <PrimaryLabel htmlFor="new-password">
                                {translation.settingsPartner.newPassword}
                            </PrimaryLabel>
                            <FormInput40
                                id="new-password"
                                name="newpassword"
                                type={showPassword ? 'text' : 'password'}
                                value={newPassword || ''}
                                onChange={(e) => setNewPassword(e.target.value)}
                                disabled={!isEditing}
                            />
                            <InputFieldIcon
                                onClick={handleShowPasswordCheckboxChanged}
                            >
                                {!showPassword ? eye : eyeSlash}
                            </InputFieldIcon>
                        </FormField>
                        <FormField>
                            <PrimaryLabel htmlFor="password-confirm">
                                {translation.settingsPartner.confirmPassword}
                            </PrimaryLabel>
                            <FormInput40
                                id="password-confirm"
                                name="password-confirm"
                                type={showConfirmPassword ? 'text' : 'password'}
                                value={passwordConfirm || ''}
                                onChange={(e) =>
                                    setPasswordConfirm(e.target.value)
                                }
                                disabled={!isEditing}
                            />
                            <InputFieldIcon
                                onClick={
                                    handleShowConfirmPasswordCheckboxChanged
                                }
                            >
                                {!showConfirmPassword ? eye : eyeSlash}
                            </InputFieldIcon>
                        </FormField>
                    </FormSection>
                    <PasswordValidationChecklist
                        password={newPassword}
                        passwordConfirm={passwordConfirm}
                        isFormValidated={handleFormValidation}
                    />
                </FormContainer>
            </Card>
        </>
    )
}

export default ChangePassword
