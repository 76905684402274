import styled from 'styled-components'
import {
    useActions,
    useTranslation,
    useTypedSelector,
} from '../../../../../hooks'
import React, { useState, useEffect } from 'react'
import AddPaymentMethodModal from '../modals/AddPaymentMethodModal'
import {
    ButtonSmallOpacity,
    ButtonSmallOpacityText,
    /* DynamicHeightSaveButton, */
    PlusIcon,
} from '../../../../styled/buttons'
import { Card, CardHeader } from '../../../../styled/cards'
import CardItem from './components/CardItem'
import InvoiceItem from './components/InvoiceItem'
import { GrDocumentMissing } from 'react-icons/gr'
import PaymentFlashMessages from './components/PaymentFlashMessages'
import AccountType from '../../../../reusable/AccountType'
import SkeletonCard from '../../../../reusable/skeletons/SkeletonCard'
/* import ButtonSpinnerWhite from '../../../../layout/spinner/ButtonSpinnerWhite' */

const Container = styled.div`
    padding: 1.4rem;
`

const PaymentMethodsHeading = styled.h3`
    font-size: 2.4rem;
    color: ${({ theme }) => theme.primary};
    font-weight: 600;
    margin-top: 2rem;
`

const AdditionalPaymentMethodsContainer = styled.div`
    height: 20rem;
    overflow-x: auto;
    width: 65rem;
    border: 1px solid ${({ theme }) => `${theme.primary}20`};
    padding: 1.4rem;
    border-radius: 0.5rem;

    ::-webkit-scrollbar {
        width: 0.8rem;
    }

    ::-webkit-scrollbar-track {
        margin-block: 1.4rem;
    }
`

const DocumentMissingIcon = styled(GrDocumentMissing)`
    font-size: 1.6rem;

    path {
        stroke: ${({ theme }) => `${theme.primary}50`};
    }
`

const NoPaymentMethodText = styled.p`
    font-size: 1.6rem;
    margin-left: 0.5rem;
    color: ${({ theme }) => `${theme.primary}50`};
    max-width: 60rem;
`

const GetPaymentErrorMsg = styled.div`
    color: ${({ theme }) => theme.red};
    font-size: 1.4rem;
`

const Payment: React.FC = () => {
    const {
        paymentMethods,
        defaultPaymentMethod,
        /* manualChargeLoading,
        subscription, */
        getPaymentInformationLoading,
        getPaymentMethodError,
    } = useTypedSelector((state) => state.payment)
    const { user } = useTypedSelector((state) => state.auth)
    const { organization, plan } = useTypedSelector((state) => state.caseworker)

    const translation = useTranslation()

    const {
        getPaymentInformation,
        /* manualCharge */
    } = useActions()

    const [isAddPaymentMethodModalOpen, setIsAddPaymentMethodModalOpen] =
        useState(false)

    useEffect(() => {
        if (!user) {
            return
        }
        user && getPaymentInformation(user.userId)
        // eslint-disable-next-line
    }, [user])

    /* const handleManualCharge = () => {
        if (!user) {
            return
        }

        manualCharge(user.userId, paymentMethods[0].id)
    } */

    return (
        <>
            {!user || getPaymentInformationLoading ? (
                <SkeletonCard height="70rem" width="100%" border="0.3rem" />
            ) : (
                <Card>
                    <Container>
                        <CardHeader>
                            {
                                translation.settingsPartner
                                    .addPaymentMethodFrame.paymentMethods
                            }
                        </CardHeader>
                        {isAddPaymentMethodModalOpen && (
                            <AddPaymentMethodModal
                                closeModal={() =>
                                    setIsAddPaymentMethodModalOpen(false)
                                }
                            />
                        )}

                        <PaymentFlashMessages />
                        <div
                            style={{
                                display: 'flex',
                                marginTop: '1.4rem',
                            }}
                        >
                            <ButtonSmallOpacity
                                onClick={() =>
                                    setIsAddPaymentMethodModalOpen(true)
                                }
                            >
                                <PlusIcon />
                                <ButtonSmallOpacityText>
                                    {
                                        translation.settingsPartner
                                            .addPaymentMethodFrame
                                            .addPaymentMethod
                                    }
                                </ButtonSmallOpacityText>
                            </ButtonSmallOpacity>
                        </div>
                        <PaymentMethodsHeading>
                            {
                                translation.settingsPartner
                                    .addPaymentMethodFrame.defaultPaymentMethod
                            }
                        </PaymentMethodsHeading>
                        <p
                            style={{
                                marginBottom: '2rem',
                            }}
                        >
                            {
                                translation.settingsPartner
                                    .addPaymentMethodFrame
                                    .yourDefaultPaymentMethod
                            }
                        </p>
                        {defaultPaymentMethod ? (
                            <>
                                {defaultPaymentMethod.type === 'card' ? (
                                    <CardItem
                                        width="65rem"
                                        paymentMethod={defaultPaymentMethod}
                                    />
                                ) : (
                                    <InvoiceItem
                                        paymentMethod={defaultPaymentMethod}
                                    />
                                )}
                            </>
                        ) : (
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '2rem',
                                }}
                            >
                                {getPaymentMethodError ? (
                                    <GetPaymentErrorMsg>
                                        {getPaymentMethodError}
                                    </GetPaymentErrorMsg>
                                ) : (
                                    <>
                                        <DocumentMissingIcon />
                                        <NoPaymentMethodText>
                                            {
                                                translation.settingsPartner
                                                    .addPaymentMethodFrame
                                                    .defaultPaymentMethodMissing
                                            }
                                        </NoPaymentMethodText>
                                    </>
                                )}
                            </div>
                        )}

                        <PaymentMethodsHeading>
                            {
                                translation.settingsPartner
                                    .addPaymentMethodFrame.otherPaymentMethods
                            }
                        </PaymentMethodsHeading>
                        <p
                            style={{
                                marginBottom: '2rem',
                            }}
                        >
                            {
                                translation.settingsPartner
                                    .addPaymentMethodFrame
                                    .otherPaymentMethodsYouHave
                            }
                        </p>
                        {paymentMethods && paymentMethods.length > 0 && (
                            <AdditionalPaymentMethodsContainer>
                                {paymentMethods.map(
                                    (method: any) =>
                                        method.id !==
                                            defaultPaymentMethod?.id &&
                                        (method.type === 'card' ? (
                                            <CardItem paymentMethod={method} />
                                        ) : (
                                            <InvoiceItem
                                                paymentMethod={method}
                                            />
                                        ))
                                )}
                            </AdditionalPaymentMethodsContainer>
                        )}
                        <PaymentMethodsHeading>
                            {' '}
                            {
                                translation.settingsPartner
                                    .addPaymentMethodFrame.currentPlan
                            }
                        </PaymentMethodsHeading>
                        <NoPaymentMethodText>
                            {
                                translation.settingsPartner
                                    .addPaymentMethodFrame.belowYouCanSee
                            }
                        </NoPaymentMethodText>
                        <div
                            style={{
                                margin: '1.4rem 0',
                            }}
                        />
                        {plan && (
                            <AccountType
                                isActive={false}
                                accountType={organization?.account_type}
                                employeeCount={plan.employee_count}
                                small={true}
                                price={`${plan.price_yearly}€`}
                            />
                        )}
                        {/* defaultPaymentMethod && (
                            <DynamicHeightSaveButton
                                height="5rem"
                                width="65rem"
                                fontSize="1.6rem"
                                isActive={!subscription}
                                style={{
                                    marginTop: '4rem',
                                }}
                                onClick={!subscription && handleManualCharge}
                            >
                                {!manualChargeLoading ? (
                                    `Pay ${organization?.plan.price}€`
                                ) : (
                                    <ButtonSpinnerWhite />
                                )}
                            </DynamicHeightSaveButton>
                        ) */}
                    </Container>
                </Card>
            )}
        </>
    )
}

export default Payment
