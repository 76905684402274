import styled from 'styled-components'

interface MaxHeightProp {
    maxHeight?: string
    overflow?: string
}

export const Card = styled.div<MaxHeightProp>`
    width: 100%;
    border: 1px solid ${({ theme }) => theme.grey};
    border-radius: 0.5rem;
    background: ${({ theme }) => theme.white};
    height: 100%;
    overflow: ${({ overflow }) => (overflow ? overflow : 'hidden')};
    max-height: ${({ maxHeight = '100%' }) => maxHeight};
`

export const CardHeader = styled.div`
    height: 5rem;
    padding: 0 1.4rem;
    font-size: 1.4rem;
    font-weight: 600;
    color: ${({ theme }) => theme.primary};

    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${({ theme }) => theme.grey};
`

export const CardBody = styled.div<MaxHeightProp>`
    max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : '80vh')};
    overflow: hidden;
    overflow-y: auto;
`

export const CardCaseHeadingRow = styled.div`
    height: 4.5rem;
    border-bottom: 1px solid ${({ theme }) => theme.primary};
    padding: 0 1.4rem;
`

export const CardCaseHeadingText = styled.span`
    font-size: 1.2rem;
    color: ${({ theme }) => theme.greyText};
    font-weight: 600;
    text-transform: uppercase;
`

export const CardCaseRow = styled.div`
    height: 6.8rem;
    width: 100%;
    display: grid;
    padding: 1.4rem;
    cursor: pointer;
    border-bottom: 1px solid ${({ theme }) => theme.grey};
    transition: all 0.2s;

    :hover {
        background: ${({ theme }) => `${theme.blue}05`};
    }
`

export const CaseCardText = styled.span`
    font-size: 1.4rem;
    color: ${({ theme }) => theme.primary};
`

export const InitialsCircle = styled.div`
    height: 2.7rem;
    width: 2.7rem;
    border-radius: 50%;
    background: ${({ theme }) => theme.lightBlue};

    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
`

export const InitialsText = styled(CaseCardText)`
    opacity: 0.5;
    font-size: 1.4rem;
`

interface IsNewProp {
    isNew?: boolean
}

export const CasePill = styled.div<IsNewProp>`
    height: 1.8rem;
    padding: 0 1rem;
    border-radius: 2rem;

    display: inline-block;
    background: ${({ isNew, theme }) => (isNew ? theme.red : theme.lightBlue)};
`

export const CasePillText = styled.p<IsNewProp>`
    margin-top: 0.5rem;
    color: ${({ isNew, theme }) => (isNew ? theme.white : theme.primary)};
`

export const CaseInformationRow = styled.div`
    height: 4.4rem;
    border-bottom: 1px solid ${({ theme }) => theme.grey};
    padding: 0 1.8rem;

    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const CaseInformationRowTitle = styled.span`
    font-size: 1.2rem;
    font-weight: 600;
    color: ${({ theme }) => theme.primary};
    opacity: 0.7;
`

export const CaseInformationRowInfo = styled.span`
    font-size: 1.4rem;
    color: ${({ theme }) => theme.secondaryDark};
    opacity: 0.8;
`

export const CaseInformationFileRow = styled(CaseInformationRow)`
    min-height: 5.6rem;
`

export const CaseFilePreviewFlexContainer = styled.div`
    display: flex;
    gap: 0.6rem;
    overflow-y: hidden;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    ::webkit-scrollbar {
        display: none;
    }
`

export const CaseFilePreview = styled.div`
  height: 2.5rem;
  padding: 0 1.4rem;
  background: ${({ theme }) => theme.platformGrey};
  color: color: ${({ theme }) => theme.primary};
  font-size: 1.2rem;
  border-radius: 2rem;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 1rem 0;
  white-space: nowrap;
`

export const CaseCategory = styled.div`
    height: 1.8rem;
    background: #b9b9b9;
    padding: 0 1.4rem;

    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.white};
    font-size: 1rem;
    border-radius: 2rem;
`

export const CaseDescriptionParagraph = styled.p`
    padding-top: 1rem;
    padding-left: 1.4rem;
    padding-right: 2rem;
    font-size: 1.4rem;
    color: ${({ theme }) => theme.black};
    opacity: 0.8;
    white-space: pre-wrap;

    :last-child {
        padding-bottom: 4rem;
    }

    overflow-y: auto;
    max-height: 31.4rem;
`
