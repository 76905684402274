import axios from 'axios'
import config from '../../config'
import { Dispatch } from 'redux'

import { ReportInternalNoteActionType } from '../action-types'

const { apiUrl } = config

export const getInternalNotes = (incidentId: number) => async (dispatch: Dispatch) => {
    dispatch(setNotesLoading())

    const res = await axios.get(`${apiUrl}/v1/incidents/${incidentId}/internal-notes`)

    dispatch({
        type: ReportInternalNoteActionType.GET_INTERNAL_NOTES,
        payload: res.data.data,
    })
}

export const createInternalNote = (incidentId: number, note: string) => async (dispatch: Dispatch) => {
    dispatch(setCreateNoteLoading())

    try {
        const res = await axios.post(
            `${apiUrl}/v1/incidents/${incidentId}/internal-notes`, { note },
        )

        if (res.status === 403) {
            return dispatch(
                setCreateNoteError('Only report owners and partners can perform this action'),
            )
        }

        dispatch({
            type: ReportInternalNoteActionType.CREATE_INTERNAL_NOTE,
            payload: res.data.data,
        })

    } catch (error: any) {
        if (error.response.status === 403) {
            return dispatch(
                setCreateNoteError('Only report owners and partners can perform this action'),
            )
        }
    }
}

export const setNotesLoading = () => ({
    type: ReportInternalNoteActionType.SET_NOTES_LOADING,
})

export const setCreateNoteLoading = () => ({
    type: ReportInternalNoteActionType.SET_CREATE_NOTE_LOADING,
})

export const setCreateNoteError = (error: null | string) => ({
    type: ReportInternalNoteActionType.SET_CREATE_NOTE_ERROR,
    payload: error,
})

export const clearInternalNotes = () => ({
    type: ReportInternalNoteActionType.CLEAR_INTERNAL_NOTES,
})