import { useState } from 'react'
import styled from 'styled-components'
import noProfileImage from '../../../../../assets/images/no-profile-image.svg'
import { NextButton } from '../../../../reusable/buttons'
import { Modal, ModalContainer } from '../../../../styled/modals'

const ExcludeModalContainer = styled(ModalContainer) <{ isOpen: boolean }>`
display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`

// const ExcludeModal = styled(Modal) <{ isOpen: boolean }>`
// display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
// `

const ExcludePopupContainer = styled.div`
    padding: 2rem;
    z-index: 2;
    border-radius: 1rem;
    background: ${({ theme }) => theme.white};
    overflow: auto;
    border: 1px solid ${({ theme }) => theme.grey};
`

const ExcludePopupContainerTitle = styled.div`
    display: flex;
    justify-content: space-between;
    height: 2rem;
    margin-bottom: 1rem;
`

const ExcludePopupTitleText = styled.p`
    font-size: 1.575rem;
`

const ExcludePopupTitleClose = styled.p`
    font-size: 1.575rem;
    color: ${({ theme }) => theme.lineGrey};
    cursor: pointer;
`

const GreyLine = styled.div`
    width: 100%;
    height: 1px;
    margin-bottom: 5rem;
    background-color: ${({ theme }) => theme.grey};
`

const ExcludePopupContainerBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`
interface IsActiveProp {
    isActive: boolean
}

const BriefContactContainer = styled.div<IsActiveProp>`
    display: flex;
    width: 25rem;
    margin: auto;
    align-items: center;
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.grey};
    border-radius: 7rem;
    padding: 0.3rem;
    gap: 0.7rem;
    background: ${({ theme, isActive }) => isActive && theme.red};
`

const AvatarImage = styled.img`
    display: block;
    height: 3rem;
    width: '3rem';
    border-radius: 50%;
    vertical-align: middle;
    background: ${({ theme }) => theme.white};
`

const ContactHeading = styled.h3<IsActiveProp>`
    font-size: 1.8rem;
    font-weight: 700;
    color: ${({ theme, isActive }) => isActive && theme.white};
`

const ExcludePopupButtonContainer = styled.div`
    display: flex;
    width: 100%;
    margin-top: 5rem;
    justify-content: space-between;
    gap: 2rem;
`
const PopupButton = styled.div`
    width: 19rem;
    line-height: 3rem;
    padding: 1rem;
    display: inline;
    cursor: pointer;
    font-size: 1.6rem;
    color: ${({ theme }) => theme.black};
    background: ${({ theme }) => theme.white};
    border-radius: 3rem;
    border: 1px solid ${({ theme }) => theme.grey};
    :hover {
        opacity: 0.8;
    }
`

interface PopupProps {
    onClose: () => void
    contactsWithExclude: any[]
    onSave: (contacts: any[]) => void
    isOpen: boolean
}

const NewCaseContactPopup: React.FC<PopupProps> = ({
    onClose,
    contactsWithExclude,
    onSave,
    isOpen,
}) => {
    const [selectExcludedContacts, setSelectExcludedContacts] =
        useState<any[]>(contactsWithExclude)

    const toggleContactInPopup = (contact: any) => {
        setSelectExcludedContacts(
            selectExcludedContacts.map((selectExcludedContact: any) => {
                if (selectExcludedContact.id === contact.id) {
                    return {
                        ...selectExcludedContact,
                        is_excluded: !contact.is_excluded,
                    }
                } else {
                    return { ...selectExcludedContact }
                }
            })
        )
    }

    const saveChange = () => {
        onSave(selectExcludedContacts)
        onClose()
    }

    const discardChange = () => {
        const discardSelectExcludedContacts = selectExcludedContacts.map(
            (selectExcludedContact: any) => {
                return {
                    ...selectExcludedContact,
                    is_excluded: false,
                }
            }
        )
        setSelectExcludedContacts(discardSelectExcludedContacts)
        onSave(discardSelectExcludedContacts)
        onClose()
    }

    return (
        <ExcludeModalContainer isOpen={isOpen}>
            <Modal height="28.4rem" width="55.6rem">
                <ExcludePopupContainer>
                    <ExcludePopupContainerTitle>
                        <ExcludePopupTitleText>Exclude person</ExcludePopupTitleText>
                        <ExcludePopupTitleClose onClick={onClose}>
                            Close
                        </ExcludePopupTitleClose>
                    </ExcludePopupContainerTitle>
                    <GreyLine />
                    <ExcludePopupContainerBody>
                        {selectExcludedContacts.map((contact) => (
                            <BriefContactContainer
                                isActive={contact.is_excluded}
                                onClick={() => toggleContactInPopup(contact)}
                                key={contact.id}
                            >
                                <AvatarImage
                                    src={
                                        contact.contact_information.profile_img === null
                                            ? noProfileImage
                                            : contact.contact_information.profile_img
                                    }
                                    alt="Profile Image"
                                />
                                <ContactHeading isActive={contact.is_excluded}>
                                    {contact.first_name + ' ' + contact.last_name}
                                </ContactHeading>
                            </BriefContactContainer>
                        ))}
                    </ExcludePopupContainerBody>
                    <ExcludePopupButtonContainer>
                        <PopupButton onClick={() => discardChange()}>
                            Discard changes
                        </PopupButton>
                        <NextButton
                            text={'Save changes'}
                            icon={false}
                            handleOnClick={() => saveChange()}
                            isActive={
                                selectExcludedContacts.filter(
                                    (c: any) => !c.is_excluded
                                ).length > 0
                            }
                        />
                    </ExcludePopupButtonContainer>
                </ExcludePopupContainer>
            </Modal>
        </ExcludeModalContainer>

    )
}

export default NewCaseContactPopup
