import styled from 'styled-components'
import { formatDate } from '../../../../../../../helpers/date'
import { formatFileSize } from '../../../../../../../helpers/files'
import {
    FileBox,
    FileDownload,
    FileDownloadIcon,
    FileIcon,
    FileInformationText,
    FileName,
} from '../../../../../whistleblower/new-case/styled/Files'
import { AiOutlineFilePdf } from 'react-icons/ai'
import { FaFileCsv } from 'react-icons/fa'
import { AiOutlineFileImage, AiOutlineFileWord } from 'react-icons/ai'
import config from '../../../../../../../config'
import axios from 'axios'
import { useTranslation, useTypedSelector } from '../../../../../../../hooks'
import { FileInterface } from '../../../../../../../ts/interfaces/FileInterface'

const FileContainer = styled.div`
    margin-top: 3.8rem;
    padding: 1.6rem 2.8rem;
`

const Flex = styled.div`
    display: flex;
    align-items: center;
    gap: 2rem;
    margin-inline-start: 0.5rem;
`

const NoFilesMessage = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    color: ${({ theme }) => theme.primay};
`

const SourceLabel = styled.label`
    opacity: 0.5;
    font-size: 1.5rem;
    padding: 0.5rem;
    display: flex;
    padding-block-end: 1rem;
`

interface FilesFrameProps {
    files: FileInterface[]
}

const FilesFrame: React.FC<FilesFrameProps> = ({ files }) => {
    const translation = useTranslation()

    const { openReport } = useTypedSelector(
        (state) => state.caseworkerOpenReport
    )

    const handleDownloadFile = async (file: any) => {
        const { apiUrl } = config

        const res: any = await axios.get(
            `${apiUrl}/v1/incidents/${openReport.id}/download/${file.id}`,
            {
                method: 'GET',
                responseType: 'blob',
                // @ts-ignore
                Accept: 'application/pdf',
            }
        )

        // @ts-ignore
        const blob = new Blob([res.data], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = file.name
        link.click()
    }

    const filesBySource: Record<string, FileInterface[]> = {}
    files.forEach((file) => {
        if (!filesBySource[file.source]) {
            filesBySource[file.source] = []
        }
        filesBySource[file.source].push(file)
    })

    return (
        <FileContainer>
            {Object.entries(filesBySource).map(([source, files]) => (
                <div key={source}>
                    <SourceLabel>From {source}</SourceLabel>

                    {files?.length > 0 ? (
                        <>
                            {files.map((file) => (
                                <FileBox
                                    key={file.file_path}
                                    onClick={() => handleDownloadFile(file)}
                                >
                                    {file.extension === 'pdf' ? (
                                        <AiOutlineFilePdf size={30} />
                                    ) : file.extension === 'csv' ? (
                                        <FaFileCsv size={30} />
                                    ) : file.extension === 'jpg' ||
                                      file.extension === 'jpeg' ||
                                      file.extension === 'png' ? (
                                        <AiOutlineFileImage size={30} />
                                    ) : file.extension === 'docx' ? (
                                        <AiOutlineFileWord size={30} />
                                    ) : (
                                        <FileIcon />
                                    )}
                                    <div>
                                        <FileName>{file.name}</FileName>
                                        <Flex>
                                            <FileInformationText>
                                                {formatDate(file.created_at)}
                                            </FileInformationText>
                                            <FileInformationText>
                                                {formatFileSize(file.file_size)}
                                            </FileInformationText>
                                        </Flex>
                                    </div>

                                    <FileDownload className="file-download-icon">
                                        <FileDownloadIcon />
                                    </FileDownload>
                                </FileBox>
                            ))}
                        </>
                    ) : (
                        <NoFilesMessage>
                            {translation.caseworkerOpenReport.noFilesUploaded}
                        </NoFilesMessage>
                    )}
                </div>
            ))}
        </FileContainer>
    )
}

export default FilesFrame
