import { getTime, hoursToMinutes, millisecondsToMinutes } from 'date-fns'
import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import styled from 'styled-components'
import { useActions } from '../../../hooks'
import Spinner from '../../layout/spinner/Spinner'
import config from '../../../config'

const Container = styled.div`
    height: calc(100vh - 8.5rem);
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
`

const MagicLogin: React.FC = () => {
    const history = useHistory()
    const { login } = useActions()
    const { magicLoginToken } = useParams<{ magicLoginToken: string }>()

    const { loginWithMagicLoginToken } = useActions()

    const { env } = config

    const handleLoginAttempt = async () => {
        const user = await loginWithMagicLoginToken(magicLoginToken)

        // Login didn't work
        // Return out of the function
        // User will stay on page and see error message
        if (!user) {
            return false
        }

        handleLogin(user)
    }

    const handleLogin = (userResponse: any) => {
        const roles = userResponse.groups.map((currentGroup: any) => {
            let group = currentGroup.name

            if (currentGroup.name === 'Super Admin') {
                group = 'admin'
            } else if (currentGroup.name === 'Contact line') {
                group = 'contact'
            }

            return group.toLowerCase()
        })

        const user = {
            userId: userResponse.id,
            firstName: userResponse.first_name,
            lastName: userResponse.last_name,
            email: userResponse.email,
            phoneNumber: userResponse.phone.phone,
            countryCode: userResponse.phone.country_code,
            roles,
            apiToken: userResponse.verification_token,
            countryId: userResponse.country_id,
            temp: userResponse.temp,
        }

        login(user)

        if (env === 'local') {
            const timeout =
                millisecondsToMinutes(getTime(new Date())) + hoursToMinutes(48)
            localStorage.setItem('isLoggedIn', 'true')
            localStorage.setItem('user', JSON.stringify(user))
            localStorage.setItem('timeout', timeout.toString())
        }

        history.replace('/')
    }

    useEffect(() => {
        if (!magicLoginToken) {
            return
        }

        handleLoginAttempt()

        // eslint-disable-next-line
    }, [])

    return (
        <Container>
            <Spinner />
        </Container>
    )
}

export default MagicLogin
