import styled from 'styled-components'
import { FiCheck } from 'react-icons/fi'

interface HeaderStatusProp {
    isActive: boolean
    isCompleted: boolean
}

const HeaderStatus = styled.div<HeaderStatusProp>`
    height: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    border-right: ${({ theme }) => `1px solid ${theme.grey}`};
    border-bottom: ${({ theme, isActive }) =>
        isActive && `2px solid ${theme.blue}`};
`

const NumberCircle = styled.div<HeaderStatusProp>`
    margin-left: 3.7rem;
    height: 3.2rem;
    width: 3.2rem;
    border-radius: 50%;
    color: ${({ theme }) => theme.greyText};

    border: ${({ theme }) => `1px solid ${theme.greyText}`};
    font-size: 1.5rem;
    font-weight: 600;

    display: flex;
    align-items: center;
    justify-content: center;

    ${({ isActive, theme }) =>
        isActive &&
        `
        background: ${theme.blue};
    color: ${theme.white};
        border: none;

    `};

    ${({ isCompleted, theme }) =>
        isCompleted &&
        `
        background: ${theme.black};
        border: none;
    `};
`

const NumberText = styled.p<HeaderStatusProp>`
    ${({ isCompleted }) =>
        isCompleted &&
        `
        display: none;
    `}
`

const CheckIcon = styled(FiCheck)<HeaderStatusProp>`
    color: ${({ theme }) => theme.white};
    font-size: 1.6rem;
    display: none;

    ${({ isCompleted }) =>
        isCompleted &&
        `
        display: block;
    `}
`

const HeaderText = styled.h4<HeaderStatusProp>`
    font-size: 1.8rem;
    font-weight: 600;
    color: ${({ theme }) => theme.greyText};
    margin-left: 1.6rem;

    ${({ isActive, theme }) =>
        isActive &&
        `
        color: ${theme.blue};
    `};

    ${({ isCompleted, theme }) =>
        isCompleted &&
        `
        color: ${theme.black};
        text-decoration: line-through;
    `};
`

interface OnboardingHeaderProps {
    stepNumber: number
    text: string
    currentStep: number
}

const OnboardingHeader: React.FC<OnboardingHeaderProps> = ({
    stepNumber,
    text,
    currentStep,
}) => {
    return (
        <HeaderStatus
            isActive={currentStep === stepNumber}
            isCompleted={currentStep > stepNumber}
        >
            <NumberCircle
                isActive={currentStep === stepNumber}
                isCompleted={currentStep > stepNumber}
            >
                <NumberText
                    isActive={currentStep === stepNumber}
                    isCompleted={currentStep > stepNumber}
                >
                    {stepNumber}
                </NumberText>
                <CheckIcon
                    isActive={currentStep === stepNumber}
                    isCompleted={currentStep > stepNumber}
                />
            </NumberCircle>
            <HeaderText
                isActive={currentStep === stepNumber}
                isCompleted={currentStep > stepNumber}
            >
                {text}
            </HeaderText>
        </HeaderStatus>
    )
}

export default OnboardingHeader
