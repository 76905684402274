import { Redirect, Route } from 'react-router-dom'
import { useTypedSelector } from '../../hooks'

const ProtectedUnauthenticatedWhistleblowerRoute = ({component: Component, ...rest}) => {
    const { isWhistleblowerLoggedIn } = useTypedSelector(state => state.whistleblower)

    return (
        <Route 
            {...rest}
            render={props => {
                return isWhistleblowerLoggedIn ? <Redirect to={
                    {
                        pathname: '/',
                        state: {
                            from: props.location
                        }
                    }

                }/> : <Component {...props}/>
            }}
        />
    )
}

export default ProtectedUnauthenticatedWhistleblowerRoute 