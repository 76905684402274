import { Dispatch } from 'redux'
import axios from 'axios'
import config from '../../config'
import { PaymentActionType } from '../action-types'

const { apiUrl } = config

export const getPaymentInformation =
    (userId: number) => async (dispatch: Dispatch) => {
        dispatch({
            type: PaymentActionType.SET_GET_PAYMENT_INFORMATION_LOADING,
        })

        try {
            const res = await axios.get(`${apiUrl}/v1/customers/${userId}`)

            const payload = res.data.data

            if (res.status !== 200) {
                dispatch(
                    setGetPaymentMethodError(
                        'Unexpected error. Please, try again later.'
                    )
                )
                return false
            }

            dispatch({
                type: PaymentActionType.GET_PAYMENT_INFORMATION,
                payload,
            })
            return true
        } catch (error: any) {
            dispatch(
                setGetPaymentMethodError(
                    'Something went wrong. Please, try again later.'
                )
            )
        }
    }

export const createSetupIntent =
    (userId: number) => async (dispatch: Dispatch) => {
        try {
            const res = await axios.post(
                `${apiUrl}/v1/customers/${userId}/setup-intent`
            )

            if (res.status !== 200) {
                dispatch(
                    setAddPaymentMethodError(
                        'Unexpected error. Please, try again later.'
                    )
                )
                return
            }

            if (
                res.data === undefined ||
                res.data.client_secret === undefined
            ) {
                return
            }

            return res
        } catch (error: any) {
            dispatch(
                setAddPaymentMethodError(
                    'An error occurred. Please, try again later.'
                )
            )
            return
        }
    }

export const addPaymentMethod =
    (
        userId: number,
        paymentMethodInformation: any,
        paymentType: 'card' | 'invoice'
    ) =>
    async (dispatch: Dispatch) => {
        const payload = {
            payment_method: paymentMethodInformation.paymentMethod,
            payment_type: paymentType,
            cardholder_name: paymentMethodInformation.name,
            cardholder_email: paymentMethodInformation.email,
            address: paymentMethodInformation.address,
            city: paymentMethodInformation.city,
            zip_code: paymentMethodInformation.zipCode,
            default: paymentMethodInformation.default,
        }

        try {
            const res = await axios.post(
                `${apiUrl}/v1/customers/${userId}/payment-method`,
                payload
            )

            if (res.status !== 200) {
                dispatch(
                    setAddPaymentMethodError(
                        'Something went wrong. Please, close this tab and try again.'
                    )
                )
                return false
            }

            dispatch({
                type: PaymentActionType.ADD_PAYMENT_METHOD,
                payload: {
                    paymentMethod: res.data.data,
                    default: payload.default,
                },
            })

            // @ts-ignore
            dispatch(clearFlashMessage('paymentMethodAddedMessage'))

            return true
        } catch (error: any) {
            dispatch(
                setAddPaymentMethodError(
                    'Something went wrong. Please, close this tab and try again.'
                )
            )
        }
    }

export const updateDefaultPaymentMethod =
    (userId: number, paymentMethodId: string) => async (dispatch: any) => {
        dispatch(setPaymentMethodBeingMadeDefaultLoading(paymentMethodId))

        const payload = {
            payment_method: paymentMethodId,
        }

        try {
            const res = await axios.put(
                `${apiUrl}/v1/customers/${userId}/payment-method`,
                payload
            )

            if (res.status !== 200) {
                dispatch(
                    setUpdatePaymentMethodError('updatePaymentMethodError')
                )
                dispatch(clearFlashMessage('updatePaymentMethodError'))
                return
            }

            dispatch({
                type: PaymentActionType.UPDATE_DEFAULT_PAYMENT_METHOD,
                payload: {
                    paymentMethod: res.data,
                },
            })
        } catch (error: any) {
            dispatch(setUpdatePaymentMethodError('updatePaymentMethodError'))
            dispatch(clearFlashMessage('updatePaymentMethodError'))
        }
    }

export const manualCharge =
    (userId: number, paymentMethod: string) => async (dispatch: Dispatch) => {
        dispatch(setManualChargeLoading())

        const payload = {
            payment_method: paymentMethod,
        }

        try {
            const res = await axios.post(
                `${apiUrl}/v1/customers/${userId}/charge`,
                payload
            )

            dispatch({
                type: PaymentActionType.MANUAL_CHARGE,
                payload: {
                    subscription: res.data.data.subscription,
                },
            })

            // @ts-ignore
            dispatch(clearFlashMessage('paymentSuccessfulMessage'))

            return res
        } catch (error: any) {
            dispatch(
                manualChargeError(
                    'Something went wrong. Please, try again later.'
                )
            )
        }
    }

export const deletePaymentMethod =
    (userId: number, paymentMethodId: string) => async (dispatch: any) => {
        dispatch(setPaymentMethodDeletedLoading(paymentMethodId))

        const payload = {
            payment_method: paymentMethodId,
        }

        try {
            const res = await axios.delete(
                `${apiUrl}/v1/customers/${userId}/payment-method`,
                { data: payload }
            )

            if (res.status !== 204) {
                dispatch(
                    setDeletePaymentMethodError('deletePaymentMethodError')
                )
                dispatch(clearFlashMessage('deletePaymentMethodError'))
                return
            }

            dispatch({
                type: PaymentActionType.DELETE_PAYMENT_METHOD,
                payload: {
                    paymentMethodId,
                },
            })

            dispatch(clearFlashMessage('paymentMethodDeletedMessage'))
        } catch (error: any) {
            dispatch(setDeletePaymentMethodError('deletePaymentMethodError'))
            dispatch(clearFlashMessage('deletePaymentMethodError'))
        }
    }

export const setAddPaymentMethodLoading = () => ({
    type: PaymentActionType.SET_ADD_PAYMENT_METHOD_LOADING,
})

export const setUpdatePaymentMethodLoading = () => ({
    type: PaymentActionType.SET_UPDATE_PAYMENT_METHOD_LOADING,
})

export const setPaymentMethodBeingMadeDefaultLoading = (
    paymentMethodId: string
) => ({
    type: PaymentActionType.SET_PAYMENT_METHOD_BEING_MADE_DEFAULT_LOADING,
    payload: { paymentMethodId },
})

export const setManualChargeLoading = () => ({
    type: PaymentActionType.SET_MANUAL_CHARGE_LOADING,
})

export const setPaymentMethodDeletedLoading = (paymentMethodId: string) => ({
    type: PaymentActionType.SET_PAYMENT_METHOD_BEING_DELETED_LOADING,
    payload: { paymentMethodId },
})

export const clearFlashMessage = (message: string) => (dispatch: any) => {
    return setTimeout(() => {
        dispatch({
            type: PaymentActionType.CLEAR_FLASH_MESSAGE,
            payload: {
                message,
            },
        })
    }, 5000)
}

export const setGetPaymentMethodError = (message: string) => ({
    type: PaymentActionType.SET_GET_PAYMENT_METHOD_ERROR,
    payload: {
        message,
    },
})

export const setAddPaymentMethodError = (message: string) => ({
    type: PaymentActionType.SET_ADD_PAYMENT_METHOD_ERROR,
    payload: {
        message,
    },
})

export const setUpdatePaymentMethodError = (message: string) => ({
    type: PaymentActionType.SET_UPDATE_PAYMENT_METHOD_ERROR,
    payload: {
        message,
    },
})

export const manualChargeError = (message: string) => ({
    type: PaymentActionType.SET_MANUAL_CHARGE_ERROR,
    payload: {
        message,
    },
})

export const setDeletePaymentMethodError = (message: string) => ({
    type: PaymentActionType.SET_DELETE_PAYMENT_METHOD_ERROR,
    payload: {
        message,
    },
})

export const clearAddPaymentMethodError = () => ({
    type: PaymentActionType.CLEAR_ADD_PAYMENT_METHOD_ERROR,
})
