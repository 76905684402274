import produce from 'immer'
import { ThemeAction } from '../actions/themeActions'
import { dark, light } from '../themes'
import { ThemeActionType } from '../action-types'

interface ThemeState {
    selected: 'light' | 'dark'
    theme: {
        primary: string
        blue: string
        blackest: string
        lightBlue: string
        white: string
        lineGrey: string
        black: string
        platformGrey: string
        darkColor: string
        red: string
        pink: string
        orange: string
        yellow: string
        greyText: string
        memberColor: string
        lightGrey: string
    }
}

const initialState: ThemeState = {
    selected: 'light',
    theme: light,
}

const reducer = produce(
    (state: ThemeState = initialState, action: ThemeAction) => {
        switch (action.type) {
            case ThemeActionType.SWITCH_THEME:
                if (state.selected === 'light') {
                    state.selected = 'dark'
                    state.theme = dark
                } else {
                    state.selected = 'light'
                    state.theme = light
                }
                return state
            default:
                return state
        }
    }
)

export default reducer
