import styled from 'styled-components'

export const PaymentTypeHeading = styled.h4`
    font-size: 2rem;
    margin-top: 1.4rem;
    text-align: center;
    font-weight: 600;
    export color: ${({ theme }) => theme.primary};
`

export const CityZipFlex = styled.div`
    display: flex;
    gap: 1rem;
`

export const CityContainer = styled.div`
    width: 60%;
`

export const ZipContainer = styled.div`
    width: 40%;
`

export const CardContainer = styled.div`
    border: 2px solid ${({ theme }) => theme.lineGrey};
    height: 4rem;
    padding: 0 1.4rem;
    margin-top: 0.5rem;
    border-radius: 0.5rem;

    display: grid;
    align-items: center;
`

export const Form = styled.form`
    padding: 0 6rem;
`
