import styled from 'styled-components'
import { useState } from 'react'
import { ReportQuestionInterface } from '../../../../../ts/interfaces/ReportQuestionInterface'
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai'
import ButtonSpinnerWhite from '../../../../layout/spinner/ButtonSpinnerWhite'
import {
    checkQuestion,
    isValidQuestion,
} from '../../../../../helpers/validation/question'

interface ReportQuestionsProps {
    reportQuestions: ReportQuestionInterface[]
    questionItem: ReportQuestionInterface
    handleDelete(questionItem: ReportQuestionInterface): void
    handleEdit(questionItem: ReportQuestionInterface, editedText: string): void
    index: number
    loading: boolean
}

interface EditingProps {
    isEditing: boolean
}

interface ValidationProps {
    questionSuccess: boolean
}

const QuestionContainer = styled.div`
    width: 52rem;
    height: 12rem;
    background-color: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.grey};
    border-radius: 0.5rem;
    margin: 1.5rem 0;
`
const QuestionHeader = styled.div`
    border-bottom: 1px solid ${({ theme }) => theme.grey};
    height: 5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    font-size: 1.6rem;
    font-weight: 400;
`
const QuestionId = styled.p`
    font-size: 1.6rem;
    font-weight: 400;
`
const QuestionEditors = styled.div`
    display: flex;
    align-items: center;
`
const RedCircle = styled.button`
    width: 2.5rem;
    height: 2.5rem;
    border: 1px solid #fff;
    border-radius: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(236, 67, 56, 0.3);
    &:hover {
        background-color: rgba(236, 67, 56, 0.7);
    }
`

const BlueCircle = styled.button<EditingProps>`
    width: 2.5rem;
    height: 2.5rem;
    border: 1px solid #fff;
    border-radius: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ isEditing }) =>
        isEditing ? 'rgba(36, 125, 208, 0.7)' : 'rgba(36, 125, 208, 0.3)'};
    &:hover {
        background-color: rgba(36, 125, 208, 0.7);
    }
`

const RedAiOutlineDelete = styled(AiOutlineDelete)`
    color: #ec4338;
    font-size: 1.5rem;
`
const BlueAiOutlineEdit = styled(AiOutlineEdit)`
    color: ${({ theme }) => theme.blue};
    font-size: 1.5rem;
`

const QuestionMain = styled.div`
    height: 7.2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    margin-left: 1rem;
`
const QuestionNumber = styled.div`
    width: 3rem;
    height: 3rem;
    background-color: #e7eff0;
    border: 1px solid #fff;
    border-radius: 10rem;
    font-size: 1.8rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
`
const Question = styled.p`
    font-size: 1.5rem;
    font-weight: 500;
    background-color: #d7d7d7;
    border: 0.1rem solid #d7d7d7;
    border-radius: 0.5rem;
    padding: 0.4rem 0.8rem;
    margin-left: 1rem;
`

const QuestionInput = styled.input`
    border: 1px solid ${({ theme }) => theme.grey};
    border-radius: 0.5rem;
    font-size: 1.5rem;
    height: 2.5rem;
    width: 30rem;
    margin-left: 1rem;
    padding-left: 1rem;
`
const SaveButton = styled.button<ValidationProps>`
    border-radius: 0.5rem;
    font-size: 1.5rem;
    font-weight: 400;
    padding: 0.5rem 1rem;
    margin-left: 1.2rem;
    color: ${({ questionSuccess, theme }) =>
        questionSuccess ? theme.blue : 'white'};
    border: 0.1rem solid
        ${({ questionSuccess, theme }) =>
            questionSuccess ? theme.blue : theme.grey};
    background-color: ${({ questionSuccess }) =>
        questionSuccess ? 'rgba(36, 125, 208, 0.3)' : 'rgba(34, 35, 48, 0.5)'};
`

const QuestionComponent: React.FC<ReportQuestionsProps> = ({
    reportQuestions,
    questionItem,
    handleDelete,
    handleEdit,
    loading,
}) => {
    const [isEditing, setIsEditing] = useState(false)
    const [editedText, setEditedText] = useState(questionItem.question)
    const [questionError, setQuestionError] = useState('')
    const [questionSuccess, setQuestionSuccess] = useState(false)

    const questionValidation = () => {
        setQuestionError(checkQuestion(editedText))

        if (questionError.length === 0) {
            setQuestionSuccess(false)
        } else {
            setQuestionSuccess(true)
        }
    }

    const isFormValidated = () => {
        if (isValidQuestion(editedText)) {
            return true
        } else {
            return false
        }
    }

    const handleEditSubmit = async (editedText: string) => {
        if (!isFormValidated()) {
            return
        }
        await handleEdit(questionItem, editedText)

        setIsEditing(false)
    }

    return (
        <QuestionContainer>
            <QuestionHeader>
                <QuestionId>
                    Question # {reportQuestions.indexOf(questionItem) + 1}
                </QuestionId>
                <QuestionEditors>
                    <RedCircle
                        onClick={(e) => {
                            handleDelete(questionItem)
                        }}
                    >
                        {loading ? (
                            <ButtonSpinnerWhite />
                        ) : (
                            <RedAiOutlineDelete />
                        )}
                    </RedCircle>
                    <BlueCircle
                        isEditing={isEditing}
                        onClick={(e) => {
                            setIsEditing(true)
                        }}
                    >
                        {loading ? (
                            <ButtonSpinnerWhite />
                        ) : (
                            <BlueAiOutlineEdit />
                        )}
                    </BlueCircle>
                </QuestionEditors>
            </QuestionHeader>
            <QuestionMain>
                <QuestionNumber>
                    {reportQuestions.indexOf(questionItem) + 1}
                </QuestionNumber>
                {isEditing && (
                    <QuestionInput
                        value={editedText}
                        onChange={(e: any) => setEditedText(e.target.value)}
                        className={
                            questionError
                                ? 'border-error'
                                : questionSuccess
                                ? 'border-success'
                                : ''
                        }
                        onBlur={questionValidation}
                    />
                )}
                {!isEditing && <Question>{questionItem.question}</Question>}
                {isEditing && (
                    <SaveButton
                        onClick={() => {
                            handleEditSubmit(editedText)
                        }}
                        questionSuccess={questionSuccess}
                    >
                        {loading ? <ButtonSpinnerWhite /> : 'Save'}
                    </SaveButton>
                )}
            </QuestionMain>
        </QuestionContainer>
    )
}

export default QuestionComponent
