import axios from 'axios'
import config from '../../config'
import { Dispatch } from 'redux'
import { CustomDesignActionType } from '../action-types'

const { apiUrl } = config

export const postCustomDesign =
    (organizationId: number, companyCustomDesign: any) =>
    async (dispatch: Dispatch) => {
        dispatch(setCustomDesignLoading())

        const payload = new FormData()
        payload.append('color', companyCustomDesign.color_hex_code)
        payload.append('logo', companyCustomDesign.logo)

        const res = await axios.post(
            `${apiUrl}/v1/organizations/${organizationId}/customize-design`,
            payload
        )

        if (res.status !== 200) {
            return false
        }

        dispatch({
            type: CustomDesignActionType.POST_CUSTOM_DESIGN,
            payload: res.data.customDesignData,
        })

        return true
    }

export const restoreCustomDesign = () => async (dispatch: Dispatch) => {
    dispatch(setCustomDesignLoading())
    dispatch({
        type: CustomDesignActionType.RESTORE_CUSTOM_DESIGN,
    })
    return true
}

export const setCustomDesignLoading = () => {
    return {
        type: CustomDesignActionType.SET_CUSTOM_DESIGN_LOADING,
    }
}
