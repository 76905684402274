import axios from 'axios'
import config from '../../config'
import {
    CaseworkerOpenReportActionType,
    ReportHistoryLogActionType,
} from '../action-types'
import Translation from '../state-types/TranslationStateType'
import { setCaseworkerError } from './caseworkers'
import { UpdatedStatus } from '../actions'
import { Dispatch } from 'redux'

const { apiUrl } = config

const setGetSingleReportLoading = () => ({
    type: CaseworkerOpenReportActionType.SET_GET_SINGLE_REPORT_LOADING,
})

const setGetSingleReportError = (error: string) => ({
    type: CaseworkerOpenReportActionType.SET_GET_SINGLE_REPORT_ERROR,
    payload: error,
})

export const getSingleReport =
    (id: number, translation: Translation, partnerId?: number) =>
    async (dispatch: any, getState: () => any) => {
        dispatch(setGetSingleReportLoading())
        const state = getState()

        const user = state.auth.user

        let endpoint

        if (user.roles.includes('contact')) {
            endpoint = `${apiUrl}/v1/incidents/${id}?with=files,assigned,organization.contact_lines,talk,reporter,logs`
        } else {
            endpoint = `${apiUrl}/v1/partners/${partnerId}/incidents/${id}?with=files,assigned,organization.contact_lines,talk,reporter,logs`
        }

        try {
            const res = await axios.get(endpoint)

            if (res.status !== 200) {
                return dispatch(
                    setGetSingleReportError(
                        'Something went wrong please reload'
                    )
                )
            }

            dispatch(getReportMessages(res.data.data.id))

            dispatch({
                type: ReportHistoryLogActionType.GET_HISTORY_LOG,
                payload: res.data.data.logs.data,
            })

            dispatch({
                type: CaseworkerOpenReportActionType.GET_SINGLE_REPORT,
                payload: res.data.data,
            })
        } catch (error) {
            return dispatch(
                setGetSingleReportError('Something went wrong please reload')
            )
        }
    }

const setGetReportMessagesLoading = () => ({
    type: CaseworkerOpenReportActionType.SET_GET_REPORT_MESSAGES_LOADING,
})

const setGetReportMessagesError = (error: string) => ({
    type: CaseworkerOpenReportActionType.SET_GET_REPORT_MESSAGES_ERROR,
    payload: error,
})

export const getReportMessages = (id: number) => async (dispatch: Dispatch) => {
    dispatch(setGetReportMessagesLoading())

    try {
        const res = await axios.get(`${apiUrl}/v1/incidents/${id}/auth/talk`)

        if (res.status !== 200) {
            return dispatch(
                setGetReportMessagesError('Something went wrong please reload')
            )
        }

        return dispatch({
            type: CaseworkerOpenReportActionType.GET_REPORT_MESSAGES,
            payload: res.data.data,
        })
    } catch (error) {
        // TODO handle chat error
        return dispatch(
            setGetReportMessagesError('Something went wrong please reload')
        )
    }
}

export const caseworkerAddMessage =
    (chatId: number, content: string, files: any) =>
    async (dispatch: Dispatch) => {
        dispatch({
            type: CaseworkerOpenReportActionType.SET_ADD_MESSAGE_LOADING,
        })

        const payload = new FormData()
        payload.append('source', 'caseworker')

        if (!files) {
            payload.append('type', 'text')
        } else {
            files.forEach((file: any) => {
                payload.append('case_files[]', file)
            })
            payload.append('type', 'data')
        }
        content.length && payload.append('content', content)
        try {
            const res: any = await axios.post(
                `${apiUrl}/v1/incidents/${chatId}/auth/talk`,
                payload
            )

            dispatch({
                type: CaseworkerOpenReportActionType.ADD_MESSAGE,
            })

            return res.data.data
        } catch (error) {
            // TODO dispatch chat error
            dispatch(
                setCaseworkerError(
                    'Something went wrong, please try again and make sure the whistleblower code is correct.'
                )
            )
        }
    }

export const caseworkerShowLatestMessage =
    (message: any) => (dispatch: Dispatch) => {
        const payload = {
            id: message.line.id,
            content: message.content.content,
            created_at: message.line.created_at,
            source: message.content.source,
            type: message.line.type,
            attributes: message.line.attributes,
            files: {
                data: message.files.map((file: any) => ({
                    name: file.original_name,
                    download_url: 'coming soon',
                    ...file,
                })),
            },
        }

        if (payload.files.data) {
            dispatch(caseworkerShowLatestFiles(payload.files.data))
        }

        return dispatch({
            type: CaseworkerOpenReportActionType.SHOW_LATEST_MESSAGE,
            payload,
        })
    }

const caseworkerShowLatestFiles = (files: any) => ({
    type: CaseworkerOpenReportActionType.SHOW_LATEST_FILES,
    payload: files,
})

const setChangeResponsibleLoading = () => ({
    type: CaseworkerOpenReportActionType.SET_CHANGE_RESPONSIBLE_LOADING,
})

export const changeResponsible =
    (partnerOrganizationId: number, reportId: number, caseworkerId: number) =>
    async (dispatch: Dispatch) => {
        dispatch(setChangeResponsibleLoading())

        const payload = {
            user: caseworkerId,
        }
        try {
            const res = await axios.post(
                `${apiUrl}/v1/partners/${partnerOrganizationId}/incidents/${reportId}/caseworker`,
                payload
            )

            dispatch({
                type: CaseworkerOpenReportActionType.CHANGE_RESPONSIBLE,
                payload: res.data.data.assigned,
            })

            return true
        } catch (error) {
            return false
        }
    }

const setUpdateCategoryLoading = () => ({
    type: CaseworkerOpenReportActionType.SET_UPDATE_CATEGORY_LOADING,
})

export const updateCategory =
    (reportId: number, payload: any) => async (dispatch: Dispatch) => {
        dispatch(setUpdateCategoryLoading())

        try {
            const res = await axios.put(
                `${apiUrl}/v1/incidents/${reportId}`,
                payload
            )

            if (res.status !== 202) {
                // TODO dispatch an error
                return false
            }

            dispatch({
                type: CaseworkerOpenReportActionType.UPDATE_CATEGORY,
                payload: {
                    id: res.data.data.category_id,
                    name: res.data.data.category,
                },
            })

            return true
        } catch (error) {
            return false
        }
    }

const setForwardReportLoading = () => ({
    type: CaseworkerOpenReportActionType.SET_FORWARD_REPORT_LOADING,
})

interface ForwardReportPayload {
    category_id: number
    contact_line_id: number
    severity_score: number
    comment: string
}

export const forwardReport =
    (reportId: number, payload: ForwardReportPayload) =>
    async (dispatch: Dispatch) => {
        dispatch(setForwardReportLoading())

        try {
            const res: any = await axios.post(
                `${apiUrl}/v1/incidents/${reportId}/contacts/${payload.contact_line_id}/forward`,
                payload
            )

            if (res.status !== 204) {
                return false
            }

            dispatch({
                type: CaseworkerOpenReportActionType.FORWARD_REPORT,
            })

            return true
        } catch (error) {
            dispatch({
                type: CaseworkerOpenReportActionType.SET_FORWARD_REPORT_ERROR,
            })

            return false
        }
    }

const setUpdateStatusLoading = () => ({
    type: CaseworkerOpenReportActionType.SET_UPDATE_STATUS_LOADING,
})

export const updateStatus =
    (id: number, status: { status: UpdatedStatus }, partnerId?: number) =>
    async (dispatch: any, getState: () => any) => {
        dispatch(setUpdateStatusLoading())

        try {
            const state = getState()

            const user = state.auth.user

            let endpoint

            if (user.roles.includes('contact')) {
                endpoint = `${apiUrl}/v1/incidents/${id}`
            } else {
                endpoint = `${apiUrl}/v1/partners/${partnerId}/incidents/${id}`
            }

            const res = await axios.put(endpoint, status)

            if (res.status !== 202) {
                dispatch(
                    setCaseworkerError('Something went wrong please reload')
                )

                return false
            }

            dispatch({
                type: CaseworkerOpenReportActionType.UPDATE_STATUS,
                payload: res.data.data.status,
            })

            return true
        } catch (error) {
            dispatch(setCaseworkerError('Something went wrong please reload'))

            return false
        }
    }

const setCloseReportLoading = () => ({
    type: CaseworkerOpenReportActionType.SET_CLOSE_REPORT_LOADING,
})

export interface CloseReportPayload {
    status: string
    closed_reason: string
    closed_comment?: string
}

export const closeReport =
    (id: number, payload: CloseReportPayload) => async (dispatch: Dispatch) => {
        dispatch(setCloseReportLoading())

        try {
            const res = await axios.put(`${apiUrl}/v1/incidents/${id}`, payload)

            // if (res.status !== 202) {
            //     // error
            //     return
            // }

            dispatch({
                type: CaseworkerOpenReportActionType.CLOSE_REPORT,
                payload: res.data.data,
            })
        } catch (error) {
            console.log('error', error)
        }
    }

export const caseworkerLeaveReport = () => ({
    type: CaseworkerOpenReportActionType.LEAVE_REPORT,
})

export const showSpecialForwardedMessage = (message: any) => ({
    type: CaseworkerOpenReportActionType.SHOW_SPECIAL_FORWARDED_MESSAGE,
    payload: message,
})
