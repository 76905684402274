import { QuestionInterface, Report } from '../NewCasePage'
import {
    BoldText,
    FileName,
    FilePreview,
    NewCasePageFrame,
    NewCasePageFrameHeading,
} from '../styled'
import styled from 'styled-components'
import { AiOutlineFilePdf } from 'react-icons/ai'
import { FaFileCsv } from 'react-icons/fa'
import { AiOutlineFileImage, AiOutlineFileWord } from 'react-icons/ai'
import { FiTrash2 } from 'react-icons/fi'
import { NextButton } from '../../../../reusable/buttons'
import {
    useActions,
    useTranslation,
    useTypedSelector,
} from '../../../../../hooks'
import Spinner from '../../../../layout/spinner/Spinner'

interface SumaryOfReportingProps {
    report: Report
    removeSingleFile: (fileIndex: number) => void
    updatePage: () => void
    questions: QuestionInterface[]
    contacts: Array<any>
}

const InformationField = styled.div`
    margin: 2rem 0;
`

const RegularText = styled.p`
    font-size: 1.6rem;
    margin-top: 0.5rem;
    white-space: pre-wrap;
`

const SpinnerContainer = styled.div`
    padding-top: 6rem;
`

const SummaryContainer = styled.div`
    width: 50rem;

    @media (max-width: 600px) {
        width: 90%;
    }
`

const ErrorMessage = styled.p`
    font-size: 1.4rem;
    color: ${({ theme }) => theme.red};
`

const ErrorMessageSmall = styled.p`
    font-size: 1.1rem;
    color: ${({ theme }) => theme.red};
    float: left;
    margin-bottom: 1rem;
    margin-left: 0.2rem;
    margin-top: 0.5rem;
`

const SumaryOfReporting: React.FC<SumaryOfReportingProps> = ({
    report,
    removeSingleFile,
    updatePage,
    questions,
    contacts,
}) => {
    const {
        organizationalCode,
        whistleblowerError,
        whistleblowerLoading,
        reportErrors,
    } = useTypedSelector((state) => state.whistleblower)

    const { reportQuestions } = useTypedSelector(
        (state) => state.reportQuestions
    )
    const { createReport } = useActions()

    const translation = useTranslation()
    const { whistleblowerApperance, title, description, files } = report

    const handleSubmitReport = async () => {
        if (organizationalCode) {
            const success: any = await createReport(organizationalCode, report)

            success && updatePage()
        }
    }

    const getReportFileError = (index: any) => {
        if (
            reportErrors &&
            typeof reportErrors[`case_files.${index}`] !== 'undefined'
        ) {
            return reportErrors[`case_files.${index}`]
        }
    }

    const isReportFileError = (index: any) => {
        if (
            reportErrors &&
            typeof reportErrors[`case_files.${index}`] !== 'undefined'
        ) {
            return true
        }

        return false
    }

    return (
        <NewCasePageFrame>
            <NewCasePageFrameHeading>
                {translation.newCaseFlow.reportSummary}
            </NewCasePageFrameHeading>
            {whistleblowerLoading ? (
                <SpinnerContainer>
                    <Spinner />
                </SpinnerContainer>
            ) : (
                <>
                    <SummaryContainer>
                        <InformationField>
                            <BoldText>
                                {translation.newCaseFlow.summaryCaseWorker}
                            </BoldText>
                            {contacts
                                .filter((contact) => !contact.is_excluded)
                                .map((contact) => (
                                    <RegularText>
                                        {contact.first_name +
                                            ' ' +
                                            contact.last_name}
                                    </RegularText>
                                ))}
                        </InformationField>
                        <InformationField>
                            <BoldText>
                                {
                                    translation.newCaseFlow
                                        .summaryHowWouldYouLikeToAppear
                                }
                            </BoldText>
                            <RegularText>
                                {whistleblowerApperance?.text}
                            </RegularText>
                        </InformationField>

                        <InformationField>
                            <BoldText>
                                {translation.newCaseFlow.summaryHeadline}
                            </BoldText>
                            <RegularText>{title}</RegularText>
                        </InformationField>

                        {reportQuestions.length !== 0 &&
                            reportQuestions.map((questionItem) => {
                                return (
                                    <InformationField key={questionItem.id}>
                                        <BoldText>
                                            {questionItem.question}
                                        </BoldText>
                                        {questions.length !== 0 &&
                                            questions.map(
                                                (
                                                    question: QuestionInterface
                                                    // eslint-disable-next-line
                                                ) => {
                                                    if (
                                                        question.organization_custom_question_id ===
                                                        questionItem.id
                                                    ) {
                                                        return (
                                                            <RegularText
                                                                key={
                                                                    question.organization_custom_question_id
                                                                }
                                                            >
                                                                {
                                                                    question.answer
                                                                }
                                                            </RegularText>
                                                        )
                                                    }
                                                }
                                            )}
                                    </InformationField>
                                )
                            })}

                        <InformationField>
                            <BoldText>
                                {translation.newCaseFlow.summaryDescription}
                            </BoldText>
                            <RegularText>{description}</RegularText>
                        </InformationField>

                        {files.length ? (
                            <InformationField>
                                <BoldText>
                                    {translation.newCaseFlow.summaryFiles}
                                </BoldText>
                                {Array.from(files).map(
                                    (file: any, index: number) => {
                                        return (
                                            <>
                                                <FilePreview
                                                    key={file.key}
                                                    isError={isReportFileError(
                                                        file.key - 1
                                                    )}
                                                >
                                                    {file.file.type ===
                                                        'application/pdf' && (
                                                            <AiOutlineFilePdf
                                                                size={15}
                                                            />
                                                        )}
                                                    {file.file.type ===
                                                        'text/csv' && (
                                                            <FaFileCsv size={15} />
                                                        )}
                                                    {file.file.type.startsWith(
                                                        'image/'
                                                    ) && (
                                                            <AiOutlineFileImage
                                                                size={15}
                                                            />
                                                        )}
                                                    {file.file.type ===
                                                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document' && (
                                                            <AiOutlineFileWord
                                                                size={15}
                                                            />
                                                        )}
                                                    <FileName>
                                                        {file.file.name}
                                                    </FileName>
                                                    <FiTrash2
                                                        size={15}
                                                        onClick={() =>
                                                            removeSingleFile(
                                                                index
                                                            )
                                                        }
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                    />
                                                </FilePreview>
                                                <ErrorMessageSmall>
                                                    {getReportFileError(
                                                        file.key - 1
                                                    )}
                                                </ErrorMessageSmall>
                                            </>
                                        )
                                    }
                                )}
                            </InformationField>
                        ) : null}
                        {/* <div
                    style={{
                        margin: '1.5rem 0',
                        width: '100%',
                    }}
                >
                    <label
                        htmlFor="terms"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <input
                            style={{ display: 'none' }}
                            type="checkbox"
                            id="terms"
                            name="terms"
                        />
                        <div
                            style={{
                                height: 14,
                                width: 14,
                                borderRadius: '50%',
                                border: '1px solid #0E263D',
                                marginRight: 8,
                            }}
                        ></div>
                        <p style={{ fontSize: 14 }}>
                            I hereby accept the terms
                        </p>
                    </label>
                </div> */}
                    </SummaryContainer>
                    {whistleblowerError && (
                        <ErrorMessage>{whistleblowerError}</ErrorMessage>
                    )}
                    <InformationField>
                        <NextButton
                            text={translation.newCaseFlow.submitReport}
                            handleOnClick={handleSubmitReport}
                            isActive={true}
                            width="220px"
                        />
                    </InformationField>
                </>
            )}
        </NewCasePageFrame>
    )
}

export default SumaryOfReporting
