import styled from 'styled-components'

export const Container = styled.div`
    padding: 5rem 15rem;

    @media (max-width: 600px) {
        padding: 2rem;
    }
`

export const BackToHomeScreen = styled.div`
    padding-block-start: 5rem;
    padding-inline-start: 15rem;

    @media screen and (max-width: 600px) {
        padding-block-start: 3rem;
        padding-inline-start: 1.5rem;
    }
`

export const NavigationLinkContainer = styled.div`
    display: flex;
    align-items: center;

    @media screen and (max-width: 600px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 1rem;
    }
`

interface NavigationLinkProps {
    active: boolean
}

export const NavigationLink = styled.h3<NavigationLinkProps>`
    font-size: 2.4rem;
    font-weight: ${({ active }) => (active ? 600 : 300)};
    color: ${({ theme }) => theme.secondaryDark};
`

export const NavigationLinkDivider = styled.div`
    height: 2.4rem;
    width: 0.2rem;
    margin: 0 2rem;
    background: ${({ theme }) => theme.primary};
    opacity: 0.2;

    @media screen and (max-width: 600px) {
        display: none;
    }
`

export const PolicyContainer = styled.div`
    margin-top: 2rem;
`

export const Heading = styled.h4`
    font-size: 1.6rem;
    color: ${({ theme }) => theme.blackest};
    font-weight: 600;
`

export const Paragraph = styled.p`
    font-size: 1.6rem;
    color: ${({ theme }) => theme.blackest};
    margin-bottom: 2rem;
`

export const Li = styled.li`
    margin-left: 3rem;
`
