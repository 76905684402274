import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation } from '../../../../../hooks'

const ModalContainer = styled.div`
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);

    position: fixed;
    top: 0;
    left: 0;
    z-index: 500;
`

const Modal = styled.div`
    height: 24rem;
    width: 43rem;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background ${({ theme }) => theme.white};
    z-index: 501;
    text-align: center;
    border-radius: 0.5rem;
    padding: 0 6.5rem;
`

const ModalHeading = styled.h3`
    font-size: 2rem;
    font-weight: 600;
    color: ${({ theme }) => theme.primary};
    padding-top: 4.5rem;
`

const ModalTagline = styled.p`
    font-size: 1.6rem;
    color: ${({ theme }) => theme.primary};
    padding-top: 2.5rem;
`

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding-top: 5rem;
`

const RedButton = styled.div`
    height: 2.7rem;
    width: 14.4rem;
    background: ${({ theme }) => theme.red};
    color: ${({ theme }) => theme.white};
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    cursor: pointer;
`

const RedButtonOpacity = styled(RedButton)`
    background: ${({ theme }) => `${theme.red}10`};
    color: ${({ theme }) => theme.red};
`

interface CancelModalProps {
    closeModal: () => void
}

const CancelModal: React.FC<CancelModalProps> = ({ closeModal }) => {
    const translation = useTranslation()

    const history = useHistory()

    const handleOnCancel = () => {
        closeModal()
        history.replace('/clients')
        // eslint-disable-next-line
    }
    return (
        <ModalContainer>
            <Modal>
                <ModalHeading>
                    {
                        translation.addNewClientPartner
                            .popUpAreYouSureYouWantToCancelThisSession
                    }
                </ModalHeading>
                <ModalTagline>
                    {' '}
                    {
                        translation.addNewClientPartner
                            .popUpYouWillLoseAllYourProgress
                    }
                    .
                </ModalTagline>
                <ButtonContainer>
                    <RedButton onClick={closeModal}>
                        {translation.addNewClientPartner.popUpButtonNo}
                    </RedButton>
                    <RedButtonOpacity onClick={handleOnCancel}>
                        {translation.addNewClientPartner.popUpButtonYes}
                    </RedButtonOpacity>
                </ButtonContainer>
            </Modal>
        </ModalContainer>
    )
}

export default CancelModal
