import styled from 'styled-components'
import { FiDownload, FiFile } from 'react-icons/fi'

interface IsError {
    isError?: boolean
}

export const FileBox = styled.div`
    height: 5.7rem;
    width: 100%;
    background: ${({ theme }) => theme.lightGrey};
    border-radius: 1rem;
    margin-bottom: 1.2rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0 2rem;
    transition: background 0.3s;

    :hover {
        background: ${({ theme }) => theme.lightBlue};
    }

    :hover .file-icon {
        color: ${({ theme }) => theme.primary};
    }

    :hover .file-download-icon {
        opacity: 1;
    }
`

export const FileIcon = styled(FiFile)`
    color: ${({ theme }) => theme.greyText};
    font-size: 2rem;
    margin-right: 1rem;
    transition: color 0.3s;
`

export const FilePreview = styled.div<IsError>`
    height: 2.5rem;
    width: 100%;
    background: ${({ theme }) => theme.lineGrey};
    margin-top: 1rem;
    border-radius: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    border: ${({ isError, theme }) => isError && `1px solid ${theme.red}`};
    color: ${({ isError, theme }) => isError && `${theme.red} !important`};
`

export const FileName = styled.p`
    font-size: 1.4rem;
    color: ${({ theme }) => theme.primary};
    flex-grow: 3;
    text-align: left;
    padding-left: 0.5rem;

    @media screen and (max-width: 600px) {
        font-size: 1.2rem;
    }
`

export const FileInformationText = styled.p`
    margin-top: 0.5rem;
    font-size: 1rem;
    color: ${({ theme }) => theme.greyText};
`

export const FileDownload = styled.div`
    height: 3.6rem;
    width: 3.6rem;
    border-radius: 50%;
    background: ${({ theme }) => theme.blue};

    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    opacity: 0;
    transition: opacity 0.3s;
`

export const FileDownloadIcon = styled(FiDownload)`
    color: ${({ theme }) => theme.white};
    font-size: 1.8rem;
    margin-top: -0.3rem;
`
