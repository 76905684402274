import { useState } from 'react';
import styled from 'styled-components';
import {
    useActions,
    useTranslation,
    useTypedSelector,
} from '../../../../../../../hooks';
import MessageForm from '../../../../../../reusable/message-form/MessageForm';
import Messages from '../../../../../../reusable/messages/Messages';
import { FiMail } from 'react-icons/fi';
import Spinner from '../../../../../../layout/spinner/Spinner';

const MessagesLoading = styled.div`
    height: 32.4rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const NoMessagesContainer = styled.div`
    height: 31.4rem;
    padding-top: 13.4rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    text-align: center;
    flex-direction: column;
`;

const NoMessagesText = styled.p`
    color: ${({ theme }) => theme.greyText};
    font-size: 1.4rem;
`;

const MailIcon = styled(FiMail)`
    color: ${({ theme }) => theme.greyText};
    font-size: 3rem;
`;

interface MessagesFrameProps {
    isLoading?: boolean;
}

const MessagesFrame: React.FC<MessagesFrameProps> = ({ isLoading = false }) => {
    const translation = useTranslation();

    const { caseworkerAddMessage } = useActions();

    const { openReport, openReportMessages, addMessageLoading } =
        useTypedSelector((state) => state.caseworkerOpenReport);

    const [message, setMessage] = useState('');
    const [files, setFiles] = useState([]);

    const handleAddMessage = async () => {
        const fileData: any = files.length > 0 ? files : null;
        await caseworkerAddMessage(openReport.id, message, fileData);
    };

    return (
        <>
            <>
                {isLoading ? (
                    <MessagesLoading>
                        <Spinner />
                    </MessagesLoading>
                ) : (
                    <>
                        {openReportMessages.length > 0 ||
                        openReport?.forwarded_at ? (
                            <Messages
                                messages={openReportMessages}
                                userType="caseworker"
                            />
                        ) : (
                            <NoMessagesContainer>
                                {/*<NoMessagesImage src={NoMessages} />*/}
                                <MailIcon />
                                <NoMessagesText>
                                    {
                                        translation.caseworkerOpenReport
                                            .noMessages
                                    }
                                </NoMessagesText>
                            </NoMessagesContainer>
                        )}
                    </>
                )}
            </>
            <MessageForm
                message={message}
                setMessage={setMessage}
                files={files}
                setFiles={setFiles}
                handleAddMessage={handleAddMessage}
                isLoading={addMessageLoading}
                isReportClosed={openReport?.closed_at}
            />
        </>
    );
};

export default MessagesFrame;
