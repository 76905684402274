const partiallyHidePhone = (phone: string) => {
    let hiddenPhone = ''
    for (let i = 0; i < phone.length; i++) {
        if (i >= 2 && i < phone.length - 2) {
            hiddenPhone += '*'
        } else {
            hiddenPhone += phone[i]
        }
    }

    return hiddenPhone
}
export default partiallyHidePhone
