import styled from 'styled-components'
import { useEffect, useState } from 'react'
import {
    CardHeader,
    CaseDescriptionParagraph,
    CaseFilePreview,
    CaseFilePreviewFlexContainer,
    CaseInformationFileRow,
    CaseInformationRow,
    CaseInformationRowInfo,
    CaseInformationRowTitle,
} from '../../../../styled/cards'
import Messages from '../../../../reusable/messages/Messages'
import MessageForm from '../../../../reusable/message-form/MessageForm'
import { format } from 'date-fns'
import {
    useActions,
    useTranslation,
    useTypedSelector,
} from '../../../../../hooks'
import NavigationArrowLink from '../../../../reusable/buttons/NavigationArrowLink'
import { useHistory } from 'react-router'
import config from '../../../../../config'
import axios from 'axios'

const Container = styled.div`
    padding: 4rem;
    width: 100%;

    @media (max-width: 600px) {
        padding: 2rem;
    }
`
const LogoutButton = styled.div`
    padding-top: 4rem;
    padding-left: 4rem;

    @media screen and (max-width: 600px) {
        padding-left: 1.5rem;
    }
`

const Heading = styled.h3`
    font-size: 2rem;
    font-weight: 600;
    color: ${({ theme }) => theme.primary};
`

const FlexContainer = styled.div`
    display: flex;
    gap: 2.2rem;
    padding-top: 2.4rem;

    @media (max-width: 1000px) {
        flex-direction: column;
    }
`

const LeftContainer = styled.div`
    width: 40%;

    @media (max-width: 1000px) {
        width: 100%;
        display: flex;
        align-items: flex-start;
        column-gap: 1rem;
    }

    @media (max-width: 600px) {
        flex-direction: column;
        row-gap: 2rem;
    }
`
// TODO reuse already existing card components
const CardContainer = styled.div`
    width: 100%;
    background: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.grey};
    border-radius: 0.5rem;
`

const DescriptionCardContainer = styled(CardContainer)`
    margin-top: 1.2rem;
    height: 44.2rem;

    @media (max-width: 1000px) {
        margin-top: 0;
    }
`

const RightContainer = styled.div`
    width: 60%;

    @media (max-width: 1000px) {
        width: 100%;
    }
`

const Report: React.FC = () => {
    const { openReport, messages, whistleblowerError } = useTypedSelector(
        (state) => state.whistleblower
    )

    const {
        leaveReport,
        addMessage,
        whistleblowerShowLatestMessage,
        refreshCaseAfterForward,
    } = useActions()

    const translation = useTranslation()

    const history = useHistory()

    const [message, setMessage] = useState('')
    const [files, setFiles] = useState([])

    const handleDownloadFile = async (file: any) => {
        const { apiUrl } = config

        const res: any = await axios.get(
            `${apiUrl}/v1/whistleblower/incidents/${openReport.whistleblower_code}/download/${file.id}`,
            {
                method: 'GET',
                responseType: 'arraybuffer',
                // @ts-ignore
                Accept: 'application/pdf',
            }
        )

        // @ts-ignore
        const blob = new Blob([res.data], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = file.name
        link.click()
    }

    useEffect(() => {
        if (openReport) {
            // @ts-ignore
            window.Echo.channel(`case-talk-${openReport.talk_id}`).listen(
                '.message',
                (message: any) => whistleblowerShowLatestMessage(message)
            )

            // @ts-ignore
            window.Echo.channel(
                `incident-${openReport.whistleblower_code}`
            ).listen('.incident-forward', (report: any) => {
                refreshCaseAfterForward(report)
            })
        }

        // @ts-ignore
        return () => {
            if (openReport) {
                // @ts-ignore
                window.Echo.leave(`case-talk-${openReport.talk_id}`)
            }
        }

        // eslint-disable-next-line
    }, [openReport])

    const formatDate = () => {
        const dateInstance = new Date(openReport?.created_at)
        return format(dateInstance, "dd.MM.yyyy' ")
    }

    useEffect(() => {
        return () => {
            leaveReport()
        }
        // eslint-disable-next-line
    }, [])

    const handleAddMessage = async () => {
        await addMessage(
            openReport.id,
            openReport.whistleblower_code,
            message,
            files
        )
    }

    return (
        <>
            {whistleblowerError ? (
                <>Something went wrong please try again later</>
            ) : (
                <>
                    <LogoutButton
                    // style={{
                    //     paddingTop: '4rem',
                    //     paddingLeft: '4rem',
                    // }}
                    >
                        <NavigationArrowLink
                            onClickCallback={() => history.replace('/')}
                            linkText={translation.accessCaseFlow.logoutOfReport}
                        />
                    </LogoutButton>
                    <Container>
                        <Heading> {openReport.title}</Heading>
                        <FlexContainer>
                            <LeftContainer>
                                <CardContainer>
                                    <CardHeader>
                                        {translation.accessCaseFlow.info}
                                    </CardHeader>
                                    <CaseInformationRow>
                                        <CaseInformationRowTitle>
                                            {translation.accessCaseFlow.id}
                                        </CaseInformationRowTitle>
                                        <CaseInformationRowInfo>
                                            {openReport.uuid}
                                        </CaseInformationRowInfo>
                                    </CaseInformationRow>
                                    <CaseInformationRow>
                                        <CaseInformationRowTitle>
                                            {translation.accessCaseFlow.date}
                                        </CaseInformationRowTitle>
                                        <CaseInformationRowInfo>
                                            {formatDate()}
                                        </CaseInformationRowInfo>
                                    </CaseInformationRow>
                                    <CaseInformationRow>
                                        <CaseInformationRowTitle>
                                            {
                                                translation.accessCaseFlow
                                                    .appearance
                                            }
                                        </CaseInformationRowTitle>
                                        <CaseInformationRowInfo>
                                            {openReport?.case_type}
                                        </CaseInformationRowInfo>
                                    </CaseInformationRow>
                                    <CaseInformationFileRow>
                                        <CaseInformationRowTitle>
                                            {translation.accessCaseFlow.files}
                                        </CaseInformationRowTitle>
                                        <CaseFilePreviewFlexContainer className="hide-scrollbar">
                                            {openReport?.files.map(
                                                (file: any) => (
                                                    <CaseFilePreview
                                                        key={file.path}
                                                        onClick={() =>
                                                            handleDownloadFile(
                                                                file
                                                            )
                                                        }
                                                    >
                                                        {file.name}
                                                    </CaseFilePreview>
                                                )
                                            )}
                                        </CaseFilePreviewFlexContainer>
                                    </CaseInformationFileRow>
                                </CardContainer>

                                <DescriptionCardContainer>
                                    <CardHeader>
                                        {translation.accessCaseFlow.description}
                                    </CardHeader>
                                    <CaseDescriptionParagraph>
                                        {openReport.description}
                                    </CaseDescriptionParagraph>
                                </DescriptionCardContainer>
                            </LeftContainer>
                            <RightContainer>
                                <CardContainer>
                                    <CardHeader>
                                        {translation.accessCaseFlow.messages}
                                    </CardHeader>
                                    <Messages
                                        messages={messages}
                                        userType="reporter"
                                        height={
                                            !openReport?.closed_at
                                                ? '45rem'
                                                : '55rem'
                                        }
                                    />

                                    <MessageForm
                                        message={message}
                                        setMessage={setMessage}
                                        files={files}
                                        setFiles={setFiles}
                                        handleAddMessage={handleAddMessage}
                                    />
                                </CardContainer>
                            </RightContainer>
                        </FlexContainer>
                    </Container>
                </>
            )}
        </>
    )
}

export default Report
