import produce from 'immer'
import { IdentityActionType } from '../action-types'
import { IdentityActions } from '../actions'

interface IdentityState {
    identityLoading: boolean
    identity: any
    identityErrors: string | null
    passwordErrors: string[]
    passwordSuccess: boolean
    verifyChangedEmailError: string | null
    saveChangesMessage: null | string
    getIdentity: any
    selectedLanguage: any
}

const InitialState: IdentityState = {
    identityLoading: false,
    identity: null,
    identityErrors: null,
    passwordErrors: [],
    passwordSuccess: false,
    verifyChangedEmailError: null,
    saveChangesMessage: null,
    getIdentity: null,
    selectedLanguage: null,
}

const reducer = produce(
    (state: IdentityState = InitialState, action: IdentityActions) => {
        switch (action.type) {
            case IdentityActionType.GET_IDENTITY:
                state.getIdentity = action.payload

                return state

            case IdentityActionType.UPDATE_IDENTITY:
                state.identityLoading = false
                state.identity = action.payload
                state.identityErrors = null
                state.saveChangesMessage =
                    'Your changes have been saved successfully!'

                return state

            case IdentityActionType.IDENTITY_LOADING:
                state.identityLoading = true
                state.identityErrors = null

                return state

            case IdentityActionType.UPDATE_IDENTITY_ERROR:
                state.identityLoading = false
                state.identityErrors = action.payload.message

                return state

            case IdentityActionType.CLEAR_UPDATE_IDENTITY_ERROR:
                state.identityLoading = false
                state.identityErrors = null

                return state

            case IdentityActionType.UPDATE_PASSWORD:
                state.identityLoading = false
                state.passwordErrors = []

                return state

            case IdentityActionType.UPDATE_PASSWORD_SUCCESS:
                state.identityLoading = false
                state.passwordSuccess = action.payload

                return state

            case IdentityActionType.UPDATE_PASSWORD_ERROR:
                state.identityLoading = false
                state.passwordErrors = action.payload

                return state

            case IdentityActionType.SET_VERIFY_EMAIL_ERROR:
                state.identityLoading = false
                state.verifyChangedEmailError = action.payload.message

                return state

            case IdentityActionType.SET_VERIFY_EMAIL_SUCCESS:
                state.identityLoading = false

                return state

            case IdentityActionType.CLEAR_IDENTITY_FLASH_MESSAGE:
                state[action.payload.message] = null

                return state

            case IdentityActionType.UPDATE_SELECTED_LANGUAGE:
                state.selectedLanguage = action.payload

                return state

            default:
                return state
        }
    }
)

export default reducer
