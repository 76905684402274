import styled from 'styled-components'

interface SpinningSvgProps {
    overrideColor: string | null
}

const SpinningSvg = styled.svg<SpinningSvgProps>`
    animation: spin 3.5s linear infinite;
    fill: ${({ overrideColor }) => (overrideColor ? overrideColor : '#FFF')};
`

interface ButtonSpinnerWhiteProps {
    overrideColor?: string
}

const ButtonSpinnerWhite: React.FC<ButtonSpinnerWhiteProps> = ({
    overrideColor = null,
}) => {
    return (
        <SpinningSvg
            width="15"
            height="15"
            viewBox="0 0 29 29"
            xmlns="http://www.w3.org/2000/svg"
            overrideColor={overrideColor}
        >
            <path
                d="M28.295 7.82812V5.21876L20.1158 8.23613L23.0667 0.246745L23.1427 0.0474854H20.5238L17.9714 7.81863L15.4189 0.15186V0.0474854H12.9329L10.3236 7.81863L7.81859 0.15186V0.0474854H5.18076L8.19813 8.24562L0.208746 5.29467L0 5.21876V7.82812L7.77115 10.3711L0.104375 12.933H0V15.3905L7.77115 18.0283L0.104375 20.5333H0V23.1427L8.19813 20.1253L5.25668 28.0957L5.18076 28.295H7.79012L10.3236 20.5333L12.895 28.1906V28.295H15.381L17.9714 20.5333L20.4953 28.1906V28.295H23.1142L20.1253 20.1633L28.0862 23.1047L28.295 23.1806V20.5713L20.5238 18.0283L28.1906 15.4664H28.295V12.9709L20.5238 10.3711L28.1906 7.86608L28.295 7.82812ZM25.0973 14.2329L17.0035 17.0036L14.5175 24.101L14.1475 25.1448L11.3673 17.0795L4.04213 14.5081L3.19765 14.2329L11.2724 11.3863L14.119 3.20718L16.8897 11.3009L23.4463 13.6067L25.0973 14.2329Z"
                fill={overrideColor ? overrideColor : '#FFF'}
            />
        </SpinningSvg>
    )
}

export default ButtonSpinnerWhite
