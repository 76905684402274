import { useEffect, useState } from 'react'
import styled from 'styled-components'
import MessageForm from '../../../../../../reusable/message-form/MessageForm'
import { useActions, useTranslation, useTypedSelector } from '../../../../../../../hooks'
import { ReportInternalNoteInterface } from '../../../../../../../ts/interfaces/ReportInternalNoteInterface'
import { formatDateWithTime } from '../../../../../../../helpers/date'
import Spinner from '../../../../../../layout/spinner/Spinner'
import { BiNotepad } from 'react-icons/bi'


const NotesContainer = styled.div`
  height: 31.4rem;
  padding: 1.4rem;
 
  overflow-y: auto;
`

const InfoBox = styled.div`
  height: 2.5rem;
  width: 100%;
  background: ${({ theme }) => theme.grey};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  color: ${({ theme }) => `${theme.primary}50`};
  border-radius: 10rem;
  margin-bottom: 2rem;
`

const NoteInfo = styled.div`
  display: flex;
  gap: 0.5rem;
`

const NoteAuthor = styled.span`
  font-size: 1rem;
  font-weight: 600;
  color: ${({ theme }) => theme.primary};
  display: block;
`

const NoteDate = styled.span`
  font-size: 1rem;
  opacity: 0.5;
  color: ${({ theme }) => theme.primary};
`

const Note = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.secondaryDark};
  margin-top: 2rem;
  margin-bottom: 4rem;
  white-space: pre-wrap;
`

const LoadingContainer = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`

const NoNotesContainer = styled.div`
  padding-top: 7.4rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  text-align: center;
  flex-direction: column;
`

const NoteIcon = styled(BiNotepad)`
  color: ${({ theme }) => theme.greyText};
  font-size: 3rem;
`

const NoNotesText = styled.p`
  color: ${({ theme }) => theme.greyText};
  font-size: 1.4rem;
`

interface InternalNotesFrameProps {
}

const InternalNotesFrame: React.FC<InternalNotesFrameProps> = () => {
    const translation = useTranslation()

    const [note, setNote] = useState('')

    const {
        notes,
        notesLoading,
        newNoteLoading,
        newNoteError,

    } = useTypedSelector(state => state.reportInternalNote)

    const { openReport } = useTypedSelector((state) => state.caseworkerOpenReport)

    const { getInternalNotes, createInternalNote, setCreateNoteError } = useActions()


    useEffect(() => {
        if(openReport?.status !== 'Forwarded') {
            notes.length === 0 && getInternalNotes(openReport.id)

        }

        // eslint-disable-next-line
    }, [])


    const handleCreateInternalNote = async () => {
        await createInternalNote(openReport.id, note)
    }

    useEffect(() => {
        if (newNoteError) {
            alert(newNoteError) // TODO use a modal or smth for this
            setCreateNoteError(null) // Clear the error
        }
        // eslint-disable-next-line
    }, [newNoteError])


    return (
        <>
            <NotesContainer>
                <InfoBox>
                    {translation.caseworkerOpenReport.onlyTheCaseworkers}
                </InfoBox>
                {!notesLoading ? <>
                    {notes.length > 0 ? <>{notes.map((note: ReportInternalNoteInterface) =>
                        <div key={note.id}>
                            <NoteInfo>
                                <NoteAuthor>{note.user.data.name}</NoteAuthor>
                                <NoteDate>{formatDateWithTime(note.date)}</NoteDate>
                            </NoteInfo>
                            <Note>
                                {note.note}
                            </Note>
                        </div>,
                    )}</> : <NoNotesContainer>
                        <NoteIcon />
                        <NoNotesText>{translation.caseworkerOpenReport.takeNotesHere}</NoNotesText>
                    </NoNotesContainer>}
                </> : <LoadingContainer>
                    <Spinner />
                </LoadingContainer>}

            </NotesContainer>
                <MessageForm
                    message={note}
                    setMessage={setNote}
                    handleAddMessage={handleCreateInternalNote}
                    placeholder={translation.caseworkerOpenReport.enterNote}
                    isLoading={newNoteLoading}
                    buttonText={translation.caseworkerOpenReport.saveNote}
                    isReportClosed={openReport?.closed_at}
                />
        </>)
}

export default InternalNotesFrame

