import { useState } from 'react'
import styled from 'styled-components'
import { Code, CodeBoxScaleLightBlue, CopyIcon, CopyLink, CopyLinkText } from '../../../../styled/misc'
import CopyToClipboard from 'react-copy-to-clipboard'
import { NextButton } from '../../../../reusable/buttons'
import { useTranslation, useTypedSelector } from '../../../../../hooks'
import { FiCheck } from 'react-icons/fi'

const ModalContainer = styled.div`
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);

  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
`

const Modal = styled.div`
  height: 38rem;
  width: 65rem;
  padding: 0 6rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background ${({ theme }) => theme.white};
  z-index: 501;
  text-align: center;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media(max-width: 1000px) {
      width: 80%;
      height: 60%;
      padding: 0 2rem;
  }
`

const ModalHeading = styled.h4`
  font-size: 1.8rem;
  font-weight: 700;
  padding-top: 5rem;
  color: ${({ theme }) => theme.black};
  
  @media(max-width: 600px) {
    font-size: 1.4rem;
  }
`

const ModalTagline = styled.p`
  font-size: 1.6rem;
  color: ${({ theme }) => `${theme.primary}80}`};
  padding: 2rem 0;

  @media(max-width: 600px) {
    font-size: 1.2rem;
  }
`

const CheckIcon = styled(FiCheck)`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.green};
`

interface WhistleblowerCodeModalProps {
    setIsModalActive: (isActive: boolean) => void
    handleFinish: () => void
    isCopied: boolean
    setIsCopied: (isCopied: boolean) => void
}

const WhistleblowerCodeModal: React.FC<WhistleblowerCodeModalProps> = ({
    setIsModalActive,
    handleFinish,
    isCopied,
    setIsCopied,
}) => {
    const { newReport } = useTypedSelector((state) => state.whistleblower)

    const translation = useTranslation()

    const [isHoveringCode, setIsHoveringCode] = useState(false)

    return (
        <ModalContainer>
            <Modal>
                <ModalHeading>
                    {translation.newCaseFlow.popUpRememberYourWhistleblowerCode}
                </ModalHeading>
                <ModalTagline>
                    {translation.newCaseFlow.itIsImportantToMention}
                </ModalTagline>
                <CopyToClipboard
                    text={newReport.code || ''}
                    onCopy={() => setIsCopied(true)}
                >
                    <CodeBoxScaleLightBlue
                        isActive={isHoveringCode || isCopied}
                        onMouseEnter={() => setIsHoveringCode(true)}
                        onMouseLeave={() => setIsHoveringCode(false)}
                    >
                        <Code isActive={isHoveringCode || isCopied}>
                            {newReport.code}
                        </Code>
                    </CodeBoxScaleLightBlue>
                </CopyToClipboard>
                {!isCopied ? (
                    <CopyToClipboard
                        text={newReport.code || ''}
                        onCopy={() => setIsCopied(true)}
                    >
                        <CopyLink
                            isActive={isHoveringCode || isCopied}
                            className='copy-code-link'
                            onMouseEnter={() => setIsHoveringCode(true)}
                            onMouseLeave={() => setIsHoveringCode(false)}
                        >
                            <CopyLinkText>
                                {translation.newCaseFlow.copyToClipboard}
                            </CopyLinkText>
                            <CopyIcon />
                        </CopyLink>
                    </CopyToClipboard>
                ) : (
                    <CopyToClipboard
                        text={newReport.code || ''}
                        onCopy={() => setIsCopied(true)}
                    >
                        <CopyLink isActive={true}>
                            <CopyLinkText>{translation.copiedText.copied}</CopyLinkText>
                            <CheckIcon />
                        </CopyLink>
                    </CopyToClipboard>
                )}
                <div
                    style={{
                        marginTop: '4rem',
                    }}
                >
                    <NextButton
                        text={translation.finishButton.finish}
                        handleOnClick={handleFinish}
                        isActive={true}
                        width='220px'
                        icon={false}
                    />
                </div>
            </Modal>
        </ModalContainer>
    )
}

export default WhistleblowerCodeModal
