import { ModalActionType } from '../action-types'

export const openModal = (modal: string) => ({
    type: ModalActionType.OPEN_MODAL,
    payload: {
        modal,
    },
})

export const closeModal = () => ({
    type: ModalActionType.CLOSE_MODAL,
})
