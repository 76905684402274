import styled from 'styled-components'

interface HeadingPrimaryProps {
    paddingTop?: string
    paddingBottom?: string
}

export const HeadingPrimary = styled.h2<HeadingPrimaryProps>`
    text-align: center;
    color: ${({ theme }) => theme.primary};
    font-size: 3.2rem;
    padding-top: ${({ paddingTop }) => (paddingTop ? paddingTop : 0)};
    padding-bottom: ${({ paddingBottom }) =>
        paddingBottom ? paddingBottom : 0};
    font-weight: 600;
`

export const HeadingSecondary = styled.h3`
    font-size: 2.8rem;
    color: ${({ theme }) => theme.primary};
`
