import React from 'react'

interface PreferredContactFrameProps {
    name: string
}

const PreferredContactFrame: React.FC<PreferredContactFrameProps> = ({
    name,
}) => {
    return (
        <div
            style={{
                padding: '1.4rem',
            }}
        >
            {name}
        </div>
    )
}

export default PreferredContactFrame
