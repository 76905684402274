import produce from 'immer'
import { CategoryActionType } from '../action-types'
import { CategoryAction } from '../actions'

export interface CategoryInterface {
    id: number
    name: string
    slug: string
}

interface CategoryState {
    categories: any
}

const initialState: CategoryState = {
    categories: null,
}

const reducer = produce(
    (state: CategoryState = initialState, action: CategoryAction) => {
        switch (action.type) {
            case CategoryActionType.GET_CATEGORIES:
                state.categories = action.payload

                return state
            default:
                return state
        }
    }
)

export default reducer
