import { FiCreditCard } from 'react-icons/fi'
import { FormInput40, PrimaryLabel } from '../../../../../styled/formElements'
import { Card } from '../../../../../styled/cards'
import React from 'react'
import styled from 'styled-components'
import PaymentMethodCrudButtons from './PaymentMethodCrudButtons'

const DebitIcon = styled(FiCreditCard)`
    font-size: 2rem;
    width: 5.2rem;
`

interface InvoiceInputInterface {
    country: string
}

const InvoiceInput = styled(FormInput40)<InvoiceInputInterface>`
    background-image: ${({ country }) =>
        `url('https://prod-walor-api.s3.amazonaws.com/flags/${country}.png')`};
    background-position: 7px 7px;
    background-repeat: no-repeat;
    padding-left: 5rem;
`

interface InvoiceItemProps {
    paymentMethod: any
}

const InvoiceItem: React.FC<InvoiceItemProps> = ({ paymentMethod }) => {
    return (
        <Card
            style={{ width: '60rem', height: 'auto', marginBottom: '1.4rem' }}
        >
            <div
                style={{
                    display: 'flex',
                    position: 'relative',
                    gap: '2rem',
                    padding: '1.4rem',
                    alignItems: 'center',
                }}
            >
                <DebitIcon />
                <div style={{ width: '72rem' }}>
                    <PrimaryLabel>Iban</PrimaryLabel>
                    <InvoiceInput
                        value={`${paymentMethod.sepa_debit.country} **** **** **** **** **** ${paymentMethod.sepa_debit.last4}`}
                        disabled
                        country={paymentMethod.sepa_debit.country}
                    />
                </div>
                <PaymentMethodCrudButtons paymentMethod={paymentMethod} />
            </div>
        </Card>
    )
}

export default InvoiceItem
