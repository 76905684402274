import React from 'react'
import styled from 'styled-components'
import { useTypedSelector, useTranslation } from '../../hooks'
import { formatDate } from '../../helpers/date'
import { Link } from 'react-router-dom'

interface BoxProps {
    isTrialExpired: boolean
}

const Box = styled.div<BoxProps>`
    min-height: 2.9rem;
    width: auto;
    padding-inline: 1rem;
    background-color: #d52323;
    color: ${({ theme }) => theme.white};
    font-size: 1.4rem;
    font-weight: bold;
    margin-inline-end: 2.4rem;
    border-radius: 100px;
    line-height: 21px;

    display: flex;
    align-items: center;

    & :hover {
        opacity: 0.9;
    }
`

const Tagline = styled(Link)`
    color: ${({ theme }) => theme.white};
    font-size: 1.4rem;
`

const SettingsLink = styled(Link)`
    text-decoration: underline;
    color: ${({ theme }) => theme.white};
`

const TrialWarning: React.FC = () => {
    const translation = useTranslation()
    const { trialEndsAt, isTrialExpired } = useTypedSelector(
        (state) => state.payment
    )

    const daysRemaining = Math.ceil(
        (new Date(trialEndsAt).getTime() - new Date().getTime()) /
            (1000 * 60 * 60 * 24)
    )

    return (
        <Box isTrialExpired={isTrialExpired}>
            <div
                style={{
                    margin: '0 auto',
                    textAlign: 'center',
                }}
            >
                {isTrialExpired ? (
                    <>
                        {translation.trialWarning.yourTrialHasEnded}{' '}
                        <SettingsLink to="settings">
                            {translation.trialWarning.goToSettings}
                        </SettingsLink>
                    </>
                ) : (
                    <>
                        <Tagline to="settings">
                            {isTrialExpired
                                ? `${
                                      translation.trialWarning
                                          .yourFreeTrialEndedOn
                                  } 
                                  ${formatDate(trialEndsAt)}`
                                : `${
                                      translation.trialWarning
                                          .yourFreeTrialEndsOn
                                  } ${daysRemaining} ${
                                      daysRemaining === 1
                                          ? translation.trialWarning.day
                                          : translation.trialWarning.days
                                  }`}
                        </Tagline>
                    </>
                )}
            </div>
        </Box>
    )
}

export default TrialWarning
