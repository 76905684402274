import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useActions, useTranslation } from '../../../../../hooks'
import { NextButton } from '../../../../reusable/buttons'
import {
    NewCasePageFrame,
    NewCasePageFrameHeading,
    NewCasePageFrameTagline,
    SelectButton,
    TaglineLight,
} from '../styled'
import { Apperance } from '../NewCasePage'

interface WhistleblowerApperanceProps {
    updateReport: (key: string, value: { text: any; key: string }) => void
    updatePage: (pageIncrement: number) => void
    apperance: Apperance | null
}

// Not reusable

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
  @media(max-width: 600px) {
    flex-direction: column;
  }
`

const ButtonSeperator = styled.p`
  margin: 0 2.4rem;
  font-size: 1.6rem;
  color: ${({ theme }) => theme.black};
  opacity: 0.8;
`

const BottomLink = styled(TaglineLight)`
  transition: all 0.2s;

  :hover {
    opacity: 1;
    text-decoration: underline;
  }
`

const WhistleblowerApperance: React.FC<WhistleblowerApperanceProps> = ({
    apperance,
    updateReport,
    updatePage,
}) => {
    const translation = useTranslation()

    const [nextPageIncrement, setNextPageIncrement] = useState(1)
    const { openModal } = useActions()

    useEffect(() => {
        apperance?.key === 'anonymous'
            ? setNextPageIncrement(1)
            : setNextPageIncrement(0.5)
    }, [apperance])

    return (
        <NewCasePageFrame>
            <NewCasePageFrameHeading>
                {translation.newCaseFlow.initiateReport}
            </NewCasePageFrameHeading>
            <NewCasePageFrameTagline>
                {translation.newCaseFlow.howWouldYouLikeToAppear}
            </NewCasePageFrameTagline>
            <ButtonContainer>
                <SelectButton
                    onClick={() =>
                        updateReport('whistleblowerApperance', {
                            key: 'anonymous',
                            text: translation.newCaseFlow.anonymous,
                        })
                    }
                    active={apperance?.key === 'anonymous'}
                >
                    {translation.newCaseFlow.anonymous}
                </SelectButton>
                <ButtonSeperator>{translation.newCaseFlow.or}</ButtonSeperator>
                <SelectButton
                    onClick={() =>
                        updateReport('whistleblowerApperance', {
                            key: 'confidential',
                            text: translation.newCaseFlow.confidential,
                        })
                    }
                    active={apperance?.key === 'confidential'}
                >
                    {translation.newCaseFlow.confidential}
                </SelectButton>
            </ButtonContainer>
            <BottomLink
                onClick={() => openModal('apperance')}
                style={{ cursor: 'pointer', margin: '4rem 0' }}
            >
                {translation.newCaseFlow.whatIstheDifferenceBetweenAnAnonymous}
            </BottomLink>
            <NextButton
                handleOnClick={() => updatePage(nextPageIncrement)}
                isActive={apperance !== null}
            />
        </NewCasePageFrame>
    )
}

export default WhistleblowerApperance
