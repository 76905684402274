import { useState } from 'react'
import styled from 'styled-components'
import {
    ButtonSmallOpacity,
    ButtonSmallOpacityText,
    PlusIconCircle,
} from '../../../../styled/buttons'
import {
    Card,
    CardBody,
    CardCaseHeadingRow,
    CardCaseHeadingText,
    CardCaseRow,
    CardHeader,
    CaseCardText,
} from '../../../../styled/cards'
import { FiArrowLeft } from 'react-icons/fi'
import {
    FormField,
    FormInput40,
    PrimaryLabel,
} from '../../../../styled/formElements'
// import SelectCountryCode from '../../../../reusable/form/SelectCountryCode'
import {
    useActions,
    useTranslation,
    useTypedSelector,
} from '../../../../../hooks'
import { formatDate } from '../../../../../helpers/date'
import CountryCodeDropdown from '../../../../reusable/form/CountryCodeDropdown'

const ButtonContainer = styled.div`
    position: absolute;
    right: 4rem;
    top: 4rem;
`

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    width: 100%;
    height: 100%;
`

const BackIcon = styled(FiArrowLeft)`
    color: ${({ theme }) => `${theme.primary}50`};

    font-size: 2.4rem;
    cursor: pointer;
    margin: 2rem 0;
`

const FormContainer = styled.div`
    display: flex;
    gap: 2rem;
    width: 69rem;
    padding: 2rem 0;
    margin-left: 1.4rem;
`

const LeftContainer = styled.div`
    width: 50%;
`

const RightContainer = styled.div`
    width: 50%;
`

const FlexInputContainer = styled.div`
    display: flex;
    gap: 16px;
`

const PhoneInputContainer = styled.div`
    width: 85%;
`

interface UsersProps {
    openModal: () => void
}

const Users: React.FC<UsersProps> = ({ openModal }) => {
    const { getSingleCaseworker } = useActions()
    const { caseworkers, selectedCaseworker } = useTypedSelector(
        (state) => state.caseworker
    )

    const translation = useTranslation()

    const [view, setView] = useState('all-users')

    const handleOnUserClick = async (id: number) => {
        await getSingleCaseworker(id)
        setView('single-user')
    }

    return (
        <>
            {view === 'all-users' ? (
                <>
                    <ButtonContainer>
                        <ButtonSmallOpacity onClick={openModal}>
                            <PlusIconCircle />
                            <ButtonSmallOpacityText>
                                {translation.settingsPartner.buttonAddNewUser}
                            </ButtonSmallOpacityText>
                        </ButtonSmallOpacity>
                    </ButtonContainer>
                    <Card>
                        <CardHeader>
                            {translation.settingsPartner.users}
                        </CardHeader>
                        <CardCaseHeadingRow>
                            <Grid>
                                <CardCaseHeadingText>
                                    {translation.settingsPartner.name}
                                </CardCaseHeadingText>
                                <CardCaseHeadingText>
                                    {translation.settingsPartner.added}
                                </CardCaseHeadingText>
                                <CardCaseHeadingText>
                                    {translation.settingsPartner.openCases}
                                </CardCaseHeadingText>
                                <CardCaseHeadingText>
                                    {translation.settingsPartner.closedCases}
                                </CardCaseHeadingText>
                            </Grid>
                        </CardCaseHeadingRow>
                        <CardBody maxHeight="65vh">
                            {caseworkers &&
                                caseworkers.map((caseworker: any) => (
                                    <CardCaseRow
                                        key={caseworker.id}
                                        onClick={() =>
                                            handleOnUserClick(caseworker.id)
                                        }
                                    >
                                        <Grid>
                                            <CaseCardText>
                                                {caseworker.first_name}{' '}
                                                {caseworker.last_name}
                                            </CaseCardText>
                                            <CaseCardText>
                                                {formatDate(
                                                    caseworker.created_at
                                                )}
                                            </CaseCardText>
                                            <CaseCardText>
                                                {caseworker.active_cases}
                                            </CaseCardText>
                                            <CaseCardText>
                                                {caseworker.closed_cases}
                                                {/* {caseworker.closed_cases} */}
                                            </CaseCardText>
                                        </Grid>
                                    </CardCaseRow>
                                ))}
                        </CardBody>
                    </Card>
                </>
            ) : (
                <>
                    <BackIcon onClick={() => setView('all-users')} />
                    <Card style={{ height: '24.5rem' }}>
                        <CardHeader>
                            {translation.settingsPartner.userInformation}
                        </CardHeader>
                        <FormContainer>
                            <LeftContainer>
                                <FormField>
                                    <PrimaryLabel>
                                        {translation.settingsPartner.firstName}
                                    </PrimaryLabel>
                                    <FormInput40
                                        value={selectedCaseworker.first_name}
                                        disabled
                                    />
                                </FormField>
                                <FormField>
                                    <PrimaryLabel>
                                        {translation.settingsPartner.workEmail}
                                    </PrimaryLabel>
                                    <FormInput40
                                        value={selectedCaseworker.email}
                                        disabled
                                    />
                                </FormField>
                            </LeftContainer>
                            <RightContainer>
                                <FormField>
                                    <FormField>
                                        <PrimaryLabel>
                                            {
                                                translation.settingsPartner
                                                    .lastName
                                            }
                                        </PrimaryLabel>
                                        <FormInput40
                                            value={selectedCaseworker.last_name}
                                            disabled
                                        />
                                    </FormField>
                                    <PrimaryLabel>
                                        {translation.settingsPartner.phone}
                                    </PrimaryLabel>
                                    <FlexInputContainer>
                                        <CountryCodeDropdown
                                            error={false}
                                            success={false}
                                            selectDropdownCB={() => {}}
                                            disabled
                                            previouslySelectedCountryCode={
                                                selectedCaseworker.phone
                                                    .country_code
                                            }
                                        />
                                        <PhoneInputContainer>
                                            <FormInput40
                                                value={
                                                    selectedCaseworker.phone
                                                        .phone
                                                }
                                                disabled
                                            />
                                        </PhoneInputContainer>
                                    </FlexInputContainer>
                                </FormField>
                            </RightContainer>
                        </FormContainer>
                    </Card>
                </>
            )}
        </>
    )
}

export default Users
