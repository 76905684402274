import { useEffect, useState, useRef, HTMLAttributes } from 'react'
import styled from 'styled-components'
import { useActions, useTranslation, useTypedSelector } from '../../../hooks'
import { FiChevronDown } from 'react-icons/fi'
import { CountryInterface } from '../../../state/reducers/countriesReducer'
import { FormInput40 } from '../../styled/formElements'
import { AiOutlineSearch } from 'react-icons/ai'

interface IsDisabled {
    disabled: boolean
    isLanguagesDropdown?: boolean
}

const Dropdown = styled.div<IsDisabled>`
    height: 4rem;
    width: ${({ isLanguagesDropdown }) =>
        isLanguagesDropdown ? '14rem' : '9.8rem'};
    border-radius: 0.5rem;
    border: 2px solid ${({ theme }) => theme.lineGrey};

    padding: 0 1rem;
    margin-top: 0.4rem;
    background: ${({ theme, disabled }) =>
        disabled ? '#fafafa' : theme.white};
    cursor: ${({ disabled }) => !disabled && 'pointer'};
`

const DropdownContent = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;
`

const Flag = styled.img<IsDisabled>`
    height: 1.7rem;
    width: 1.7rem;
    border-radius: 50%;
    margin-right: 0.5rem;
    border: 0.063rem solid black;
    opacity: ${({ disabled }) => disabled && '0.7'};
`

const CallingCode = styled.p<IsDisabled>`
    font-size: ${({ isLanguagesDropdown }) =>
        isLanguagesDropdown ? '1.2rem' : '1.3rem'};
    font-weight: 600;
    color: ${({ theme, disabled, isLanguagesDropdown }) =>
        disabled && !isLanguagesDropdown ? '#6d6d6d' : theme.secondaryDark};
`

const DownIcon = styled(FiChevronDown)<IsDisabled>`
    font-size: 1.3rem;
    position: absolute;
    top: 50%;
    right: -0.5rem;
    transform: translateY(-50%);
    color: ${({ theme, disabled }) =>
        disabled ? '#6d6d6d' : theme.secondaryDark};
`

const Select = styled.div<LanguageDropdown>`
    width: 20rem;
    background: ${({ theme }) => theme.white};
    position: absolute;
    z-index: 500000;
    top: ${({ isLanguagesDropdown, isInAddUser }) =>
        isLanguagesDropdown && !isInAddUser ? '' : '6rem'};
    bottom: ${({ isLanguagesDropdown, isInAddUser }) =>
        isLanguagesDropdown && !isInAddUser ? '16.5rem' : ''};
    left: ${({ isLanguagesDropdown, isInAddUser }) =>
        isLanguagesDropdown && !isInAddUser
            ? '32.5rem'
            : isLanguagesDropdown && isInAddUser
            ? '11.5rem'
            : 'auto'};
    border: 2px solid ${({ theme }) => theme.grey};
`

const OptionScroll = styled.div`
    max-height: 20rem;
    overflow-y: auto;
    overflow-x: hidden;
`

const Option = styled.div`
    min-height: 3rem;
    display: flex;
    align-items: center;
    padding: 0 1.4rem;
    cursor: pointer;
    :not(last-child) {
        border-bottom: 2px solid ${({ theme }) => theme.grey};
    }
`

const SmallFlag = styled.img`
    height: 1.7rem;
    width: 1.7rem;
    border-radius: 50%;
    border: 1px solid black;
`

const CountryText = styled.p`
    font-size: 1.2rem;
    color: ${({ theme }) => theme.primary};
    margin-left: 1.4rem;
`

const SearchIcon = styled(AiOutlineSearch)`
    font-size: 1.7rem;
    position: absolute;
    right: 2.5rem;
    bottom: 2.5rem;
    color: ${({ theme }) => theme.blue400};
`

const SearchInputContainer = styled.div`
    padding: 1.4rem;
    position: relative;
    width: 100%;
`

const SearchInput = styled(FormInput40)`
    padding-right: 2.8rem !important;
`
interface LanguageDropdown extends HTMLAttributes<HTMLDivElement> {
    isLanguagesDropdown?: boolean
    isInAddUser?: boolean
}

interface CountryCodeDropdownProps {
    selectDropdownCB?: (country: CountryInterface) => void
    error: boolean
    success: boolean
    disabled?: boolean
    previouslySelectedCountryCode?: any
    isLanguagesDropdown?: boolean
    isInAddUser?: boolean
    handleAddLanguage?: (countryId: number) => void
}

const CountryCodeDropdown: React.FC<CountryCodeDropdownProps> = ({
    selectDropdownCB,
    error,
    success,
    disabled = false,
    previouslySelectedCountryCode = null,
    isLanguagesDropdown,
    isInAddUser,
    handleAddLanguage,
}) => {
    const { addCountries } = useActions()
    const { countries } = useTypedSelector((state) => state.country)

    const [isDropdownActive, setIsDropdownActive] = useState(false)

    const [selectedCountry, setSelectedCountry] = useState<any>(null)

    const [searchedCountries, setSearchedCountries] = useState<
        CountryInterface[] | null | undefined
    >(countries)

    const translation = useTranslation()

    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        !countries && addCountries()
    }, [countries, addCountries])

    useEffect(() => {
        if (!countries) return

        if (previouslySelectedCountryCode) {
            countries.forEach(
                (country: any) =>
                    country.calling_code === previouslySelectedCountryCode &&
                    setSelectedCountry(country)
            )
        } else {
            countries.forEach(
                (country: any) =>
                    country.iso_3166_3 === 'DNK' && setSelectedCountry(country)
            )
        }
        setSearchedCountries(countries)
    }, [countries, previouslySelectedCountryCode])

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    const handleSelectCountry = (country: any) => {
        setSelectedCountry(country)
        if (selectDropdownCB) {
            selectDropdownCB(country)
        }
        if (handleAddLanguage) {
            handleAddLanguage(country.id)
        }
        setIsDropdownActive(false)
    }

    const handleClickOutside = (event: MouseEvent) => {
        const target = event.target as HTMLInputElement
        if (target && target.classList.contains('keep-open')) {
            return
        }

        if (event.button === 0) {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                setTimeout(() => setIsDropdownActive(false), 200)
            }
        }
    }

    const handleDropdown = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation()
        !disabled && setIsDropdownActive(!isDropdownActive)
    }

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.toLowerCase()

        if (!value) {
            setSearchedCountries(countries)
        }

        const searchResult = countries?.filter(
            (country: { calling_code: string; name: string }) =>
                country.calling_code.includes(value) ||
                country.name.toLowerCase().includes(value)
        )
        setSearchedCountries(searchResult)
    }

    return (
        <>
            <Dropdown
                ref={ref}
                onClick={handleDropdown}
                className={
                    error ? 'border-error' : success ? 'border-success' : ''
                }
                disabled={disabled}
                isLanguagesDropdown={isLanguagesDropdown}
                data-testid="country-code-dropdown"
            >
                {selectedCountry && (
                    <DropdownContent>
                        {isLanguagesDropdown ? (
                            <CallingCode
                                disabled={disabled}
                                isLanguagesDropdown={isLanguagesDropdown}
                            >
                                Add more languages
                            </CallingCode>
                        ) : (
                            <>
                                <Flag
                                    src={selectedCountry.flag}
                                    disabled={disabled}
                                />

                                <CallingCode disabled={disabled}>
                                    {selectedCountry.calling_code}
                                </CallingCode>
                            </>
                        )}
                        <DownIcon disabled={disabled} />
                    </DropdownContent>
                )}
            </Dropdown>

            {isDropdownActive && (
                <Select
                    isInAddUser={isInAddUser}
                    isLanguagesDropdown={isLanguagesDropdown}
                    data-testid="open-dropdown"
                >
                    <SearchInputContainer>
                        <SearchInput
                            type="text"
                            placeholder={translation.dropdown.search}
                            onChange={handleSearch}
                            className="keep-open"
                        />
                        <SearchIcon className="keep-open" />
                    </SearchInputContainer>
                    <OptionScroll className="keep-open">
                        {searchedCountries && searchedCountries.length > 0 ? (
                            searchedCountries.map(
                                (country: CountryInterface) => (
                                    <Option
                                        key={country.id}
                                        onClick={() =>
                                            handleSelectCountry(country)
                                        }
                                        data-testid="country-option"
                                    >
                                        <SmallFlag src={country.flag} />
                                        {isLanguagesDropdown ? (
                                            ''
                                        ) : (
                                            <CountryText>
                                                {country.calling_code}
                                            </CountryText>
                                        )}
                                        <CountryText>
                                            {country.name}
                                        </CountryText>
                                    </Option>
                                )
                            )
                        ) : (
                            <CountryText>
                                {translation.dropdown.countryNotFound}
                            </CountryText>
                        )}
                    </OptionScroll>
                </Select>
            )}
        </>
    )
}

export default CountryCodeDropdown
