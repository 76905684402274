import Translation from '../../state/state-types/TranslationStateType'

export const isValidPosition = (position: string) => {
    if (position.length < 2 || position.length > 26) {
        return false
    } else {
        return true
    }
}

export const checkPosition = (position: string, translation: Translation) => {
    if (position.length < 2) {
        return translation.validation.titleOfPositionMustBe
    } else if (position.length > 26) {
        return translation.validation.titleOfPositionCannotBe
    } else {
        return ''
    }
}
