import React from 'react'
import styled from 'styled-components'
import { FiArrowRight } from 'react-icons/fi'
import { useTheme, useTranslation } from '../../../hooks'
import ButtonSpinnerWhite from '../../layout/spinner/ButtonSpinnerWhite'

interface StyledNextButtonProps {
    height?: string
    width?: string
    fontSize?: string
    active: boolean
    color: string
    opacity: number
}

const StyledNextButton = styled.button<StyledNextButtonProps>`
    height: ${({ height }) => height};
    opacity: ${({ opacity }) => opacity};
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${({ width }) => width};
    background: ${({ theme }) => theme.blue};
    border-radius: 10rem;
    border: none;
    transition: all 0.2s;

    :hover {
        opacity: ${({ active }) => active && '0.8 !important'};
    }

    :hover .next-btn-arrow-icon {
        transform: ${({ active }) => active && 'translateX(30%)'};
    }
`

interface NextButtonTextProps {
    fontSize?: string
}

const NextButtonText = styled.p<NextButtonTextProps>`
    font-size: ${({ fontSize }) => fontSize};
    color: ${({ theme }) => theme.white};
    font-weight: medium;
`

const ArrowIcon = styled(FiArrowRight)`
    color: ${({ theme }) => theme.white};
    size: 1.9rem;
    margin-left: 0.8rem;
    transition: all 0.2s;
`

interface NextButtonProps {
    text?: string | Element
    handleOnClick?: (argument?: any) => void
    isActive: boolean
    height?: string
    width?: string
    fontSize?: string
    icon?: boolean
    isLoading?: boolean
}

const NextButton: React.FC<NextButtonProps> = ({
    text,
    handleOnClick,
    isActive,
    width = '190px',
    height = '5rem',
    fontSize = '1.6rem',
    icon = true,
    isLoading = false,
}) => {
    const theme = useTheme()
    const translation = useTranslation()

    const btnText = text ? text : translation.nextButton.nextStep

    const StyledNextButtonProps = {
        opacity: !isActive ? 0.2 : 1,
        color: !isActive ? theme.primary : theme.blue,
    }

    return (
        <StyledNextButton
            {...StyledNextButtonProps}
            onClick={() => isActive && handleOnClick && handleOnClick()}
            width={width}
            height={height}
            active={isActive}
            disabled={!isActive}
        >
            {!isLoading ? (
                <>
                    <NextButtonText
                        data-testid="next-button"
                        fontSize={fontSize}
                    >
                        {isLoading ? <ButtonSpinnerWhite /> : btnText}
                    </NextButtonText>
                    {icon && <ArrowIcon className="next-btn-arrow-icon" />}
                </>
            ) : (
                <ButtonSpinnerWhite />
            )}
        </StyledNextButton>
    )
}

export default NextButton
