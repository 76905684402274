import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useActions, useTranslation, useTypedSelector } from '../../../hooks'
import Spinner from '../../layout/spinner/Spinner'
import { NextButton } from '../../reusable/buttons'

const Container = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
`

const Heading = styled.h3`
    font-size: 3.2rem;
    font-weight: 500;
    color: ${({ theme }) => theme.primary};
`

const Tagline = styled.p`
    margin-top: 2rem;
    font-size: 1.6rem;
    color: ${({ theme }) => theme.primary};
    width: 28rem;
`
const NextButtonContainer = styled.div`
    padding-top: 2rem;
`

const ErrorMessage = styled.div`
    display: flex;
    justify-content: center;
    padding-block: 30rem;
    color: red;
    font-size: 3rem;
`
const SpinnerContainer = styled.div`
    height: calc(100vh - 8.5rem);
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
`

const ChangedEmailVerify: React.FC = () => {
    const { verificationToken } = useParams<{ verificationToken: string }>()

    const { newEmailVerification } = useActions()

    const history = useHistory()

    const translation = useTranslation()

    const { identityLoading, verifyChangedEmailError } = useTypedSelector(
        (state) => state.identity
    )

    useEffect(() => {
        if (verificationToken) {
            newEmailVerification(verificationToken)
        }
        // eslint-disable-next-line
    }, [])

    return (
        <>
            {identityLoading ? (
                <SpinnerContainer>
                    <Spinner />
                </SpinnerContainer>
            ) : (
                <>
                    {verifyChangedEmailError ? (
                        <ErrorMessage>{verifyChangedEmailError}</ErrorMessage>
                    ) : (
                        <Container>
                            <Heading>
                                {translation.verifyEmail.verifyNewEmail}
                            </Heading>
                            <Tagline>
                                {translation.verifyEmail.loginWithNewEmail}
                            </Tagline>
                            <NextButtonContainer>
                                <NextButton
                                    isActive={true}
                                    text={translation.verifyEmail.goToLogin}
                                    handleOnClick={() => history.push('/')}
                                />
                            </NextButtonContainer>
                        </Container>
                    )}
                </>
            )}
        </>
    )
}

export default ChangedEmailVerify
