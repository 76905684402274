import Translation from '../../state/state-types/TranslationStateType'

export const isValidEmail = (email: string) => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
}

export const checkEmail = (email: string, translation: Translation) => {
    if (!isValidEmail(email)) {
        return translation.validation.mustBeAValidEmail
    } else {
        return ''
    }
}
