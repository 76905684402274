import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Card, CardHeader } from '../../../../styled/cards'
import { FiCheckCircle, FiXCircle } from 'react-icons/fi'
import {
    RadioButtonCircle,
    RadioButtonCircleInner,
    RadioButtonFlex,
    RadioButtonText,
} from '../../../../styled/buttons'
import { useTranslation } from '../../../../../hooks'

// TODO reuse modal styling
const ModalContainer = styled.div`
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);

    position: fixed;
    top: 0;
    left: 0;
    z-index: 500;
`

const Modal = styled.div`
    min-height: 40rem;
    width: 54rem;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 501;

    @media screen and (max-width: 480px) {
        width: 35rem;
    }
`

const CancelButton = styled.p`
    font-size: 1.4rem;
    color: ${({ theme }) => `${theme.primary}50`};
    padding: 1rem;
    cursor: pointer;
`

const ButtonContainer = styled.div`
    padding-top: 2rem;
    padding-bottom: 1rem;
    margin: 0 1.4rem;
    display: flex;
    gap: 1rem;
    border-bottom: 1px solid ${({ theme }) => theme.grey};
`

interface IsActiveProp {
    isActive: boolean
}

const Button = styled.div<IsActiveProp>`
    height: 4.7rem;
    width: 24.5rem;
    background: ${({ theme, isActive }) =>
        isActive ? theme.blue : `${theme.blue}10`};
    color: ${({ theme, isActive }) => (isActive ? theme.white : theme.blue)};
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    cursor: pointer;
`

const ButtonRed = styled(Button)<IsActiveProp>`
    background: ${({ theme, isActive }) =>
        isActive ? theme.red : `${theme.red}10`};
    color: ${({ theme, isActive }) => (isActive ? theme.white : theme.red)};
`

const CheckIcon = styled(FiCheckCircle)<IsActiveProp>`
    font-size: 1.9rem;
    color: ${({ theme, isActive }) => (isActive ? theme.white : theme.blue)};
    margin-right: 0.5rem;
`
const CloseIcon = styled(FiXCircle)<IsActiveProp>`
    font-size: 1.9rem;
    color: ${({ theme, isActive }) => (isActive ? theme.white : theme.red)};
    margin-right: 0.5rem;
`

const AddCommentForm = styled.form`
    width: 100%;
    padding: 0 1.4rem;
    padding-top: 4rem;
    padding-bottom: 3rem;
`

const AddCommentHeading = styled.div`
    font-size: 1.2rem;
    font-weight: 600;
    color: ${({ theme }) => theme.black};
    padding-bottom: 1rem;
`

const AddCommentTextarea = styled.textarea`
    height: 12rem;
    width: 100%;
    border: 1px solid ${({ theme }) => theme.grey};
    resize: none;
    margin-bottom: 2rem;
    padding: 1rem;
`

const ButtonFullWidth = styled.div<IsActiveProp>`
    height: 5rem;
    width: 100%;
    background: ${({ theme, isActive }) =>
        isActive ? theme.blue : `${theme.primary}05`};
    color: ${({ theme, isActive }) =>
        isActive ? theme.white : `${theme.primary}50`};
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    cursor: pointer;
`

const RadioButtonContainer = styled.div`
    margin-left: 1.4rem;
    display: flex;
    padding-top: 2rem;
    gap: 1.4rem;
`

interface CloseCaseModalProps {
    handleCloseCase: (status: string, reason: string, comment?: string) => void
    closeModal: () => void
}

const CloseCaseModal: React.FC<CloseCaseModalProps> = ({
    handleCloseCase,
    closeModal,
}) => {
    const translation = useTranslation()

    const [activeSolveState, setActiveSolveState] = useState<null | string>(
        null
    )

    const [activeRadio, setActiveRadio] = useState<null | string>(null)

    const [comment, setComment] = useState('')

    useEffect(() => {
        setActiveRadio(null)
    }, [activeSolveState])

    const handleOnSubmit = () => {
        if (activeSolveState !== null && activeRadio !== null) {
            comment.length < 1
                ? handleCloseCase(activeSolveState, activeRadio)
                : handleCloseCase(activeSolveState, activeRadio, comment)
        }
    }

    return (
        <ModalContainer>
            <Modal>
                <Card>
                    <CardHeader>
                        {translation.caseworkerOpenReport.closeCase}
                        <CancelButton onClick={closeModal}>
                            {translation.caseworkerOpenReport.cancel}
                        </CancelButton>
                    </CardHeader>
                    <ButtonContainer>
                        <Button
                            isActive={activeSolveState === 'solved'}
                            onClick={() => setActiveSolveState('solved')}
                        >
                            <CheckIcon
                                isActive={activeSolveState === 'solved'}
                            />
                            {translation.caseworkerOpenReport.solved}
                        </Button>
                        <ButtonRed
                            isActive={activeSolveState === 'denied'}
                            onClick={() => setActiveSolveState('denied')}
                        >
                            <CloseIcon
                                isActive={activeSolveState === 'denied'}
                            />
                            {translation.caseworkerOpenReport.denied}
                        </ButtonRed>
                    </ButtonContainer>
                    {activeSolveState === 'solved' && (
                        <RadioButtonContainer>
                            <RadioButtonFlex
                                onClick={() => setActiveRadio('forwarded')}
                            >
                                <RadioButtonCircle
                                    isActive={activeRadio === 'forwarded'}
                                >
                                    <RadioButtonCircleInner
                                        isActive={activeRadio === 'forwarded'}
                                    />
                                </RadioButtonCircle>
                                <RadioButtonText
                                    isActive={activeRadio === 'forwarded'}
                                    onClick={() => {}}
                                >
                                    {translation.caseworkerOpenReport.forwarded}
                                </RadioButtonText>
                            </RadioButtonFlex>

                            <RadioButtonFlex
                                onClick={() =>
                                    setActiveRadio('sent to authorities')
                                }
                            >
                                <RadioButtonCircle
                                    isActive={
                                        activeRadio === 'sent to authorities'
                                    }
                                >
                                    <RadioButtonCircleInner
                                        isActive={
                                            activeRadio ===
                                            'sent to authorities'
                                        }
                                    />
                                </RadioButtonCircle>
                                <RadioButtonText
                                    isActive={
                                        activeRadio === 'sent to authorities'
                                    }
                                    onClick={() => {}}
                                >
                                    {
                                        translation.caseworkerOpenReport
                                            .sentToAuthorities
                                    }
                                </RadioButtonText>
                            </RadioButtonFlex>
                            <RadioButtonFlex
                                onClick={() => setActiveRadio('others')}
                            >
                                <RadioButtonCircle
                                    isActive={activeRadio === 'others'}
                                >
                                    <RadioButtonCircleInner
                                        isActive={activeRadio === 'others'}
                                    />
                                </RadioButtonCircle>
                                <RadioButtonText
                                    isActive={activeRadio === 'others'}
                                    onClick={() => {}}
                                >
                                    {translation.caseworkerOpenReport.others}
                                </RadioButtonText>
                            </RadioButtonFlex>
                        </RadioButtonContainer>
                    )}
                    {activeSolveState === 'denied' && (
                        <RadioButtonContainer>
                            <RadioButtonFlex
                                onClick={() => setActiveRadio('spam')}
                            >
                                <RadioButtonCircle
                                    isActive={activeRadio === 'spam'}
                                >
                                    <RadioButtonCircleInner
                                        isActive={activeRadio === 'spam'}
                                    />
                                </RadioButtonCircle>
                                <RadioButtonText
                                    isActive={activeRadio === 'spam'}
                                >
                                    {translation.caseworkerOpenReport.spam}
                                </RadioButtonText>
                            </RadioButtonFlex>

                            <RadioButtonFlex
                                onClick={() =>
                                    setActiveRadio('lack of evidence')
                                }
                            >
                                <RadioButtonCircle
                                    isActive={
                                        activeRadio === 'lack of evidence'
                                    }
                                >
                                    <RadioButtonCircleInner
                                        isActive={
                                            activeRadio === 'lack of evidence'
                                        }
                                    />
                                </RadioButtonCircle>
                                <RadioButtonText
                                    isActive={
                                        activeRadio === 'lack of evidence'
                                    }
                                >
                                    {
                                        translation.caseworkerOpenReport
                                            .lackOfEvidence
                                    }
                                </RadioButtonText>
                            </RadioButtonFlex>
                            <RadioButtonFlex
                                onClick={() =>
                                    setActiveRadio('case withdrawal')
                                }
                            >
                                <RadioButtonCircle
                                    isActive={activeRadio === 'case withdrawal'}
                                >
                                    <RadioButtonCircleInner
                                        isActive={
                                            activeRadio === 'case withdrawal'
                                        }
                                    />
                                </RadioButtonCircle>
                                <RadioButtonText
                                    isActive={activeRadio === 'case withdrawal'}
                                >
                                    {
                                        translation.caseworkerOpenReport
                                            .caseWithdrawal
                                    }
                                </RadioButtonText>
                            </RadioButtonFlex>
                            <RadioButtonFlex
                                onClick={() => setActiveRadio('others')}
                            >
                                <RadioButtonCircle
                                    isActive={activeRadio === 'others'}
                                >
                                    <RadioButtonCircleInner
                                        isActive={activeRadio === 'others'}
                                    />
                                </RadioButtonCircle>
                                <RadioButtonText
                                    isActive={activeRadio === 'others'}
                                    onClick={() => {}}
                                >
                                    {translation.caseworkerOpenReport.others}
                                </RadioButtonText>
                            </RadioButtonFlex>
                        </RadioButtonContainer>
                    )}
                    <AddCommentForm>
                        <AddCommentHeading>
                            {translation.caseworkerOpenReport.addComment}
                        </AddCommentHeading>
                        <AddCommentTextarea
                            onChange={(e) => setComment(e.target.value)}
                        ></AddCommentTextarea>
                        <ButtonFullWidth
                            isActive={
                                activeSolveState !== null &&
                                activeRadio !== null
                            }
                            onClick={handleOnSubmit}
                        >
                            {translation.caseworkerOpenReport.saveThis}
                        </ButtonFullWidth>
                    </AddCommentForm>
                </Card>
            </Modal>
        </ModalContainer>
    )
}

export default CloseCaseModal
