import Translation from '../state-types/TranslationStateType'

export interface Category {
    id: number
    name: string
    slug: string
}

const categoryTransformer = (
    categories: Category[],
    translation: Translation
): Category[] =>
    categories.map((category: Category) => ({
        ...category,
        name: (translation.categories as { [key: string]: string })[
            category.slug
        ],
    }))

export const singleCategoryTransformer = (
    category: Category,
    translation: Translation
): Category => ({
    ...category,
    name: (translation.categories as { [key: string]: string })[category.slug],
})

export default categoryTransformer
