import styled from 'styled-components'
import { FaRegBuilding } from 'react-icons/fa'
import { useEffect, useState } from 'react'
import { FiX } from 'react-icons/fi'
import axios from 'axios'
import { OrganizationInterface } from '../../../../ts/interfaces'
import { PrimaryLabel } from '../../../styled/formElements'
import { HeadingPrimary } from '../../../styled/typography'
import { NextButton } from '../../buttons'
import SearchInput from '../../form/SearchInput'
import { useTranslation } from '../../../../hooks'
import Spinner from '../../../layout/spinner/Spinner'

const Container = styled.div`
    width: 70rem;
    margin: 0 auto;
    padding-top: 12rem;
    text-align: left;
`

const Form = styled.form`
    padding-top: 6.6rem;
    margin: 0 auto;
`

const ButtonContainer = styled.div`
    padding-top: 2rem;
    width: 100%;

    display: flex;
    justify-content: center;
`

const CompanyInformationDropdown = styled.div`
    width: 100%;
    background: ${({ theme }) => theme.white};
    border-radius: 0.5rem;
    position: relative;
    margin-top: 1rem;
    cursor: pointer;

    :hover {
        background: ${({ theme }) => theme.lightBlue};
    }
`

const BuildingIcon = styled(FaRegBuilding)`
    color: ${({ theme }) => theme.primary};
    font-size: 1.7rem;
    position: absolute;
    top: 2rem;
    left: 2rem;
`

const CompanyName = styled.p`
    padding-top: 2rem;
    font-size: 1.2rem;
    color: ${({ theme }) => theme.primary};
    font-weight: 600;
    margin-left: 6rem;
`

const InformationText = styled.p`
    font-size: 1.2rem;
    color: ${({ theme }) => `${theme.primary}80`};
    margin-left: 6rem;
    padding: 1rem 0;
`

const SelectedOrganization = styled(CompanyInformationDropdown)`
    background: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.white};
    cursor: alias;

    :hover {
        background: ${({ theme }) => theme.primary};
    }
`

const ExitIcon = styled(FiX)`
    color: ${({ theme }) => theme.white};
    font-size: 1.4rem;
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    cursor: pointer;
`

const WhiteBuildingIcon = styled(BuildingIcon)`
    color: ${({ theme }) => theme.white};
`

const WhiteCompanyName = styled(CompanyName)`
    color: ${({ theme }) => theme.white};
`

const WhiteInformationText = styled(InformationText)`
    color: ${({ theme }) => theme.white};
`

const EnterManuallyLink = styled.p`
    font-size: 1.2rem;
    color: ${({ theme }) => theme.primary};
    opacity: 0.5;
    margin-top: 0.5rem;
    cursor: pointer;
    margin-left: 0.2rem;
`

interface IsActive {
    isActive: boolean
}

const FindCompanyButton = styled.div<IsActive>`
    height: 4rem;
    width: 100%;
    border-radius: 0.5rem;
    background: ${({ theme }) => theme.blue};
    color: ${({ theme }) => theme.white};
    font-size: 1.4rem;
    font-weight: 600;

    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
    cursor: pointer;

    transition: opacity 0.2s;

    :hover {
        opacity: 0.8;
    }
`

const SpinnerContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 2.4rem 0;
`

interface FindCompanyFrameProps {
    organization: OrganizationInterface
    setCurrentPageNumber: (pageNumber: number) => void
    setOrganization: (organization: any) => void
}

const FindCompanyFrame: React.FC<FindCompanyFrameProps> = ({
    organization,
    setCurrentPageNumber,
    setOrganization,
}) => {
    const translation = useTranslation()

    const handleSearch = async () => {
        setIsLoading(true)
        const res = await axios.get(
            ` https://cvrapi.dk/api?search=${text}&country=dk`
        )

        if (res.status === 200) {
            setCompanyInDropdown(res.data)
        }
        setIsLoading(false)
    }

    const unselectCompany = () => {
        const company = {
            cprNumber: '',
            organizationName: '',
            email: '',
            phone: '',
            address: '',
            city: '',
            zipCode: '',
            employeeCount: '',
        }

        setOrganization({ ...organization, ...company })
    }

    const selectCompany = () => {
        const selectedCompany = {
            cprNumber: companyInDropdown.vat
                ? companyInDropdown.vat.toString()
                : '',
            organizationName: companyInDropdown.name
                ? companyInDropdown.name
                : '',
            email: companyInDropdown.email ? companyInDropdown.email : '',
            phone: companyInDropdown.phone ? companyInDropdown.phone : '',
            address: companyInDropdown.address ? companyInDropdown.address : '',
            city: companyInDropdown.city ? companyInDropdown.city : '',
            zipCode: companyInDropdown.zipCode ? companyInDropdown.zipCode : '',
            employeeCount: companyInDropdown.employees
                ? companyInDropdown.employees
                : '',
        }

        setOrganization({ ...organization, ...selectedCompany })
    }

    const [companyInDropdown, setCompanyInDropdown] = useState<any>(null)
    const [text, setText] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        !companyInDropdown ? unselectCompany() : selectCompany()

        // eslint-disable-next-line
    }, [companyInDropdown])

    return (
        <Container>
            <HeadingPrimary>
                {translation.addNewClientPartner.companyInformation}
            </HeadingPrimary>
            <Form onSubmit={(e) => e.preventDefault()}>
                <PrimaryLabel>
                    {
                        translation.addNewClientPartner
                            .searchForCompanyNameOrCompanyNumber
                    }
                </PrimaryLabel>
                <SearchInput
                    placeholder="Search"
                    text={text}
                    onChangeCB={setText}
                    onClearSearch={() => setText('')}
                />
                <FindCompanyButton
                    onClick={handleSearch}
                    isActive={text.length > 1}
                >
                    {translation.addNewClientPartner.findCompany}
                </FindCompanyButton>
                <EnterManuallyLink onClick={() => setCurrentPageNumber(2)}>
                    {translation.addNewClientPartner.enterInformationManually}
                </EnterManuallyLink>
                {/* {companyInDropdown && !organization.cprNumber.length && (
                    <CompanyInformationDropdown onClick={selectCompany}>
                        <BuildingIcon />
                        <CompanyName>{companyInDropdown.name}</CompanyName>
                        <InformationText>
                            {companyInDropdown.address}
                        </InformationText>
                        <InformationText>
                            CVR: {companyInDropdown.vat}
                        </InformationText>
                    </CompanyInformationDropdown>
                )} */}
                {isLoading && (
                    <SpinnerContainer>
                        <Spinner />
                    </SpinnerContainer>
                )}
                {organization.cprNumber.length > 1 && (
                    <SelectedOrganization>
                        <WhiteBuildingIcon />
                        <WhiteCompanyName>
                            {organization.organizationName}
                        </WhiteCompanyName>
                        <WhiteInformationText>
                            {organization.address}
                        </WhiteInformationText>

                        <WhiteInformationText>
                            CVR: {organization.cprNumber}
                        </WhiteInformationText>

                        <ExitIcon onClick={unselectCompany} />
                    </SelectedOrganization>
                )}
                <ButtonContainer>
                    <NextButton
                        isActive={organization.cprNumber.length > 1}
                        handleOnClick={() => {
                            organization.cprNumber.length > 1 &&
                                setCurrentPageNumber(2)
                        }}
                    />
                </ButtonContainer>
            </Form>
        </Container>
    )
}

export default FindCompanyFrame
