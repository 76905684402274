import _ from 'lodash'
import { useState } from 'react'
import styled from 'styled-components'
import {
    checkEmail,
    checkFirstName,
    checkLastName,
    checkPhone,
    checkPosition,
    isValidEmail,
    isValidName,
    isValidPhone,
    isValidPosition,
} from '../../../../../helpers/validation'
import { useTranslation } from '../../../../../hooks'
import { WhistleblowerInterface } from '../../../../../ts/interfaces'
import { NextButton } from '../../../../reusable/buttons'
import CountryCodeDropdown from '../../../../reusable/form/CountryCodeDropdown'
import { ErrorMessage, FormField, FormInput40, PrimaryLabel } from '../../../../styled/formElements'

import { NewCasePageFrame, NewCasePageFrameHeading, NewCasePageFrameTagline } from '../styled'

const Form = styled.form`
    width: 50rem;
    margin: 0 auto;
    text-align: left;
  
    @media(max-width: 600px) {
      width: 90%;
    }
`
const FlexInputContainer = styled.div`
    display: flex;
    gap: 16px;
`

const NextButtonContainer = styled.div`
    margin-top: 4rem;
    width: 100%;
    display: flex;
    justify-content: center;
`

interface PersonalInformationProps {
    updateReport: (key: string, value: WhistleblowerInterface) => void
    updatePage: (pageIncrement: number) => void
}

const PersonalInformation: React.FC<PersonalInformationProps> = ({
    updateReport,
    updatePage,
}) => {
    const translation = useTranslation()
    const [formFields, setFormFields] = useState<any>({
        firstName: {
            label: translation.newCaseFlow.firstName,
            placeholder: translation.newCaseFlow.enterFirstName,
            type: 'text',
            key: 'firstName',
            errorMessage: '',
            success: false,
            value: '',
        },
        lastName: {
            label: translation.newCaseFlow.lastName,
            placeholder: translation.newCaseFlow.enterLastName,
            type: 'text',
            key: 'lastName',
            errorMessage: '',
            success: false,
            value: '',
        },
        position: {
            label: translation.newCaseFlow.position,
            placeholder: translation.newCaseFlow.enterPosition,
            type: 'text',
            key: 'position',
            errorMessage: '',
            success: false,
            value: '',
        },
        email: {
            label: translation.newCaseFlow.workEmail,
            placeholder: translation.newCaseFlow.enterWorkEmail,
            type: 'text',
            key: 'email',
            errorMessage: '',
            success: false,
            value: '',
        },
    })

    const updateFormField = (key: string, value: string) => {
        setFormFields({
            ...formFields,
            [key]: {
                ...formFields[key],
                value,
            },
        })
    }

    const [countryCode, setCountryCode] = useState('+45')
    const [countryId, setCountryId] = useState(208)
    const [phone, setPhone] = useState('')
    const [phoneError, setPhoneError] = useState('')
    const [phoneSuccess, setPhoneSuccess] = useState(false)

    const handlePhoneValidation = () => {
        const validationError = checkPhone(
            `${countryCode + phone}`,
            translation
        )

        !validationError && setPhoneSuccess(true)
        setPhoneError(validationError)
    }

    const handlePhoneOnFocus = () => {
        setPhoneError('')
        setPhoneSuccess(false)
    }

    const validationBlurCallback = (key: string) => {
        if (key === 'firstName') {
            setError(
                'firstName',
                checkFirstName(formFields.firstName.value, translation)
            )
        } else if (key === 'lastName') {
            setError(
                'lastName',
                checkLastName(formFields.lastName.value, translation)
            )
        } else if (key === 'position') {
            setError(
                'position',
                checkPosition(formFields.position.value, translation)
            )
        } else if (key === 'phone') {
            // setError('phone', checkPhone(`+${countryCode + phone}`))
        } else if (key === 'email') {
            setError('email', checkEmail(formFields.email.value, translation))
        }
    }

    const validationOnFocusCallback = (key: string) => {
        setError(key, '', true) // Clear the error
    }

    const setError = (
        key: string,
        errorMsg: string,
        onFocus: boolean = false
    ) => {
        const success = errorMsg.length || onFocus ? false : true

        _.forOwn(formFields, (field) => {
            if (field.key === key) {
                setFormFields({
                    ...formFields,
                    [key]: {
                        // @ts-ignore
                        ...formFields[`${key}`],
                        errorMessage: errorMsg,
                        success: success,
                    },
                })
            }
        })
    }

    const isFormValidated = () => {
        if (
            !isValidName(formFields.firstName.value) ||
            !isValidName(formFields.lastName.value) ||
            !isValidPosition(formFields.position.value) ||
            !isValidEmail(formFields.email.value) ||
            !isValidPhone(`${countryCode + phone}`)
        ) {
            return false
        }

        return true
    }

    const addInformationToReport = () => {
        const information = {
            firstName: formFields.firstName.value,
            lastName: formFields.lastName.value,
            position: formFields.position.value,
            email: formFields.email.value,
            countryCode,
            countryId,
            phone,
        }

        updateReport('whistleblower', information)
        updatePage(0.5)
    }

    return (
        <NewCasePageFrame>
            <NewCasePageFrameHeading>
                {translation.newCaseFlow.confidentialInformation}
            </NewCasePageFrameHeading>
            <NewCasePageFrameTagline>
                {translation.newCaseFlow.provideYourPersonalInformation}
            </NewCasePageFrameTagline>
            <Form>
                {Object.values(formFields).map((formField: any) => (
                    <FormField key={formField.key}>
                        <PrimaryLabel
                            isError={
                                formField.errorMessage.length ? true : false
                            }
                            isSuccess={formField.success}
                        >
                            {formField.label}
                        </PrimaryLabel>
                        <FormInput40
                            value={formField.value}
                            placeholder={formField.placeholder}
                            onBlur={() => validationBlurCallback(formField.key)}
                            onFocus={() =>
                                validationOnFocusCallback(formField.key)
                            }
                            className={
                                formField.errorMessage
                                    ? 'border-error'
                                    : formField.success
                                    ? 'border-success'
                                    : ''
                            }
                            onChange={(e: any) =>
                                updateFormField(formField.key, e.target.value)
                            }
                        />
                        {formField.errorMessage.length > 1 && (
                            <ErrorMessage>
                                {formField.errorMessage}
                            </ErrorMessage>
                        )}
                    </FormField>
                ))}
                <FormField>
                    <PrimaryLabel>
                        {' '}
                        {translation.newCaseFlow.phone}
                    </PrimaryLabel>
                    <FlexInputContainer>
                        <CountryCodeDropdown
                            error={phoneError.length > 1}
                            success={phoneSuccess}
                            selectDropdownCB={(country) => {
                                setCountryId(country.id)
                                setCountryCode(country.calling_code)
                            }}
                        />
                        <FormInput40
                            value={phone}
                            onChange={(e: any) => setPhone(e.target.value)}
                            onFocus={handlePhoneOnFocus}
                            placeholder={translation.newCaseFlow.enterPhone}
                            onBlur={handlePhoneValidation}
                            className={
                                phoneError
                                    ? 'border-error'
                                    : phoneSuccess
                                    ? 'border-success'
                                    : ''
                            }
                        />
                        {phoneError.length > 1 && (
                            <ErrorMessage>{phoneError}</ErrorMessage>
                        )}
                    </FlexInputContainer>
                </FormField>

                <NextButtonContainer>
                    <NextButton
                        handleOnClick={addInformationToReport}
                        isActive={isFormValidated()}
                    />
                </NextButtonContainer>
            </Form>
        </NewCasePageFrame>
    )
}

export default PersonalInformation
