import { useEffect, useState } from 'react'
import AllClients from './frames/AllClients'
import SingleCompany from './frames/SingleCompany/SingleCompany'

const ClientsPage: React.FC = () => {
    const [currentFrameNumber, setCurrentFrameNumber] = useState(1)
    const [currentFrame, setCurrentFrame] = useState(
        <AllClients setCurrentFrameNumber={setCurrentFrameNumber} />
    )

    useEffect(() => {
        if (currentFrameNumber === 1) {
            setCurrentFrame(
                <AllClients setCurrentFrameNumber={setCurrentFrameNumber} />
            )
        } else if (currentFrameNumber === 2) {
            setCurrentFrame(
                <SingleCompany setCurrentFrameNumber={setCurrentFrameNumber} />
            )
        }
    }, [currentFrameNumber])

    return <div>{currentFrame}</div>
}

export default ClientsPage
