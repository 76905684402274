import produce from 'immer'
import { PaymentAction } from '../actions/paymentActions'
import { PaymentActionType } from '../action-types'
import { getTime } from 'date-fns'

interface PaymentState {
    paymentMethods: any
    trialEndsAt: any
    defaultPaymentMethod: any
    subscription: any
    isTrialExpired: boolean
    shouldShowTrialInformation: boolean

    // Loading states
    getPaymentInformationLoading: boolean
    paymentMethodBeingMadeDefaultLoading: any
    paymentMethodBeingDeletedLoading: any
    addPaymentMethodLoading: boolean

    updatePaymentMethodLoading: boolean
    manualChargeLoading: boolean

    // Flash message state
    paymentMethodAddedMessage: null | string
    paymentSuccessfulMessage: null | string
    paymentMethodDeletedMessage: null | string
    defaultPaymentMethodUpdatedMessage: null | string

    //Error states
    getPaymentMethodError: null | string
    addPaymentMethodError: null | string
    updatePaymentMethodError: null | string
    deletePaymentMethodError: null | string
    manualChargeError: null | string
}

const initialState: PaymentState = {
    paymentMethods: null,
    trialEndsAt: null,
    defaultPaymentMethod: null,
    subscription: null,
    isTrialExpired: false,
    shouldShowTrialInformation: false,

    getPaymentInformationLoading: false,
    paymentMethodBeingMadeDefaultLoading: null,
    paymentMethodBeingDeletedLoading: null,
    addPaymentMethodLoading: false,
    updatePaymentMethodLoading: false,
    manualChargeLoading: false,

    paymentMethodAddedMessage: null,
    paymentSuccessfulMessage: null,
    paymentMethodDeletedMessage: null,
    defaultPaymentMethodUpdatedMessage: null,

    getPaymentMethodError: null,
    addPaymentMethodError: null,
    updatePaymentMethodError: null,
    deletePaymentMethodError: null,
    manualChargeError: null,
}

const reducer = produce(
    (state: PaymentState = initialState, action: PaymentAction) => {
        switch (action.type) {
            case PaymentActionType.GET_PAYMENT_INFORMATION:
                state.paymentMethods = action.payload.payment_methods
                state.trialEndsAt = action.payload.trial_ends_at
                state.defaultPaymentMethod =
                    action.payload.default_payment_method

                state.subscription = action.payload.subscription

                state.getPaymentInformationLoading = false

                if (!action.payload.trial_ends_at) {
                    return state
                }

                state.isTrialExpired =
                    getTime(new Date()) >
                    getTime(new Date(action.payload.trial_ends_at))

                if (state.trialEndsAt && !state.defaultPaymentMethod) {
                    state.shouldShowTrialInformation = true
                }

                return state
            case PaymentActionType.CREATE_SETUP_INTENT:
                return state

            case PaymentActionType.ADD_PAYMENT_METHOD:
                if (action.payload.default) {
                    state.defaultPaymentMethod = action.payload.paymentMethod
                    state.shouldShowTrialInformation = false
                } else {
                    state.shouldShowTrialInformation = true
                }

                state.paymentMethods = [
                    ...state.paymentMethods,
                    action.payload.paymentMethod,
                ]

                state.paymentMethodAddedMessage = 'Payment method added'

                state.addPaymentMethodLoading = false

                return state

            case PaymentActionType.SET_GET_PAYMENT_INFORMATION_LOADING:
                state.getPaymentInformationLoading = true

                return state

            case PaymentActionType.SET_ADD_PAYMENT_METHOD_LOADING:
                state.addPaymentMethodLoading = true

                return state

            case PaymentActionType.SET_PAYMENT_METHOD_BEING_MADE_DEFAULT_LOADING:
                state.paymentMethodBeingMadeDefaultLoading =
                    action.payload.paymentMethodId

                return state

            case PaymentActionType.SET_PAYMENT_METHOD_BEING_DELETED_LOADING:
                state.paymentMethodBeingDeletedLoading =
                    action.payload.paymentMethodId

                return state

            case PaymentActionType.UPDATE_DEFAULT_PAYMENT_METHOD:
                state.paymentMethodBeingMadeDefaultLoading = null

                state.defaultPaymentMethod =
                    action.payload.paymentMethod.payment_method
                state.paymentMethods = state.paymentMethods.filter(
                    (paymentMethod: any) =>
                        paymentMethod.id !== action.payload.paymentMethod.id
                )

                state.shouldShowTrialInformation = false
                state.defaultPaymentMethodUpdatedMessage =
                    'Default payment method updated'

                return state

            case PaymentActionType.DELETE_PAYMENT_METHOD:
                const { paymentMethodId } = action.payload

                if (paymentMethodId === state.defaultPaymentMethod?.id) {
                    state.defaultPaymentMethod = null

                    if (state.trialEndsAt) {
                        state.shouldShowTrialInformation = true
                    }
                }
                state.paymentMethods = state.paymentMethods.filter(
                    (paymentMethod: any) => paymentMethod.id !== paymentMethodId
                )

                state.paymentMethodDeletedMessage =
                    'Payment method has been deleted'

                return state

            case PaymentActionType.SET_MANUAL_CHARGE_LOADING:
                state.manualChargeLoading = true

                return state

            case PaymentActionType.MANUAL_CHARGE:
                state.manualChargeLoading = false
                state.trialEndsAt = null
                state.shouldShowTrialInformation = false
                state.isTrialExpired = false
                state.subscription = action.payload.subscription

                state.paymentSuccessfulMessage =
                    'Payment has been successfully completed'

                return state

            case PaymentActionType.CLEAR_FLASH_MESSAGE:
                state[action.payload.message] = null

                return state

            case PaymentActionType.SET_GET_PAYMENT_METHOD_ERROR:
                state.getPaymentInformationLoading = false
                state.getPaymentMethodError = action.payload.message

                return state

            case PaymentActionType.SET_ADD_PAYMENT_METHOD_ERROR:
                state.addPaymentMethodLoading = false
                state.addPaymentMethodError = action.payload.message

                return state

            case PaymentActionType.SET_UPDATE_PAYMENT_METHOD_ERROR:
                state.paymentMethodBeingMadeDefaultLoading = false
                state.updatePaymentMethodError =
                    'Something went wrong. Please, try again.'

                return state

            case PaymentActionType.SET_DELETE_PAYMENT_METHOD_ERROR:
                state.paymentMethodBeingDeletedLoading = false
                state.deletePaymentMethodError =
                    'Something went wrong. Please, try again.'

                return state

            case PaymentActionType.SET_MANUAL_CHARGE_ERROR:
                state.manualChargeLoading = false
                state.manualChargeError = action.payload.message

                return state

            case PaymentActionType.CLEAR_ADD_PAYMENT_METHOD_ERROR:
                state.addPaymentMethodError = null

                return state

            default:
                return state
        }
    }
)

export default reducer
