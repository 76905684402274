import styled from 'styled-components'
import { Card } from '../../../../styled/cards'
import { Modal, ModalContainer } from '../../../../styled/modals'

const ModalBody = styled.div`
  text-align: center;
  margin: 0 auto;
  padding-top: 10.4rem;
  width: 50rem;
`

const ModalHeading = styled.h3`
  font-size: 2.4rem;
  font-weight: 600;
  color: ${({ theme }) => theme.primary};
`

const ModalTagline = styled.p`
  font-size: 1.6rem;
  color: ${({ theme }) => `${theme.primary}50`};
  padding-top: 1.4rem;
  padding-bottom: 10rem;
`


const ResetPasswordLinkSentModal: React.FC = () => {
    return (
        <ModalContainer>
            <Modal height='26rem' width='55rem'>
                <Card>
                    <ModalBody>
                        <ModalHeading>
                            Email has been sent
                        </ModalHeading>

                        <ModalTagline>
                            You will be redirected to the login screen.
                        </ModalTagline>
                    </ModalBody>
                </Card>
            </Modal>
        </ModalContainer>
    )
}

export default ResetPasswordLinkSentModal
