import React from 'react'
import { Card } from '../../../../../styled/cards'
import { FormInput40, PrimaryLabel } from '../../../../../styled/formElements'
import VisaLogo from '../../../../../../assets/images/card-logos/visa.png'
import PaymentMethodCrudButtons from './PaymentMethodCrudButtons'
import { useTranslation, useTypedSelector } from '../../../../../../hooks'
import styled from 'styled-components'

const ErrorMessage = styled.div`
    font-size: 1.2rem;
    text-align: center;
    padding-block-end: 1rem;
    color: ${({ theme }) => theme.red};
`

interface CardItemProps {
    paymentMethod: any
    width?: string
}
const CardItem: React.FC<CardItemProps> = ({ paymentMethod, width = null }) => {
    const { deletePaymentMethodError, updatePaymentMethodError } =
        useTypedSelector((state) => state.payment)

    const translation = useTranslation()

    return (
        <Card
            style={{
                width: width ? width : '60rem',
                height: 'auto',
                marginBottom: '1.4rem',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    position: 'relative',
                    gap: '2rem',
                    padding: '1.4rem',
                    alignItems: 'center',
                }}
            >
                <img src={VisaLogo} alt="" style={{ height: '2rem' }} />

                <div style={{ width: '40rem' }}>
                    <PrimaryLabel>
                        {
                            translation.settingsPartner.addPaymentMethodFrame
                                .cardNumber
                        }
                    </PrimaryLabel>
                    <FormInput40
                        value={`**** **** **** ${paymentMethod.card.last4}`}
                        disabled
                    />
                </div>
                <div style={{ width: '30rem' }}>
                    <PrimaryLabel>
                        {
                            translation.settingsPartner.addPaymentMethodFrame
                                .expireDate
                        }
                    </PrimaryLabel>
                    <FormInput40
                        value={` ${('0' + 12).slice(-2)} / ${
                            paymentMethod.card.exp_year
                        }`}
                        disabled
                    />
                </div>

                <PaymentMethodCrudButtons paymentMethod={paymentMethod} />
            </div>
            {deletePaymentMethodError && (
                <ErrorMessage>{deletePaymentMethodError}</ErrorMessage>
            )}
            {updatePaymentMethodError && (
                <ErrorMessage>{updatePaymentMethodError}</ErrorMessage>
            )}
        </Card>
    )
}

export default CardItem
