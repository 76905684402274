import styled from 'styled-components'
import SkeletonCard from '../../reusable/skeletons/SkeletonCard'
// import SkeletonLine from '../../reusable/skeletons/SkeletonLine'
import SkeletonTitle from '../../reusable/skeletons/SkeletonTitle'
import { MobileAlert, Navigation } from '../clients/ClientInformationPage'
import {
    FlexContainer,
    LeftContainer,
    RightContainer,
    Title,
} from '../../reusable/frames/CompanyInformationFrame'
import { useActions, useTranslation, useTypedSelector } from '../../../hooks'
import { useEffect } from 'react'

interface LoadingClientInformationScreenProp {
    padding?: string
}

const Container = styled.div<LoadingClientInformationScreenProp>`
    width: 100%;
    padding: ${({ padding }) => padding};
    position: relative;
`

const NavigationLink = styled.div`
    padding-bottom: 1rem;
    margin-right: 3.5rem;
    font-size: 1.8rem;
    opacity: 0.5;

    @media screen and (max-width: 600px) {
        margin-right: 1.5rem;
        font-size: 1.5rem;
    }
`

const LinkContainer = styled.div`
    margin-block: 1.8rem;
`

const EssentialCompanyInfoFrame = styled(LeftContainer)`
    @media screen and (max-width: 1000px) {
        width: 100%;
    }
`

const NotEssentialCompanyInfoFrame = styled(RightContainer)`
    @media screen and (max-width: 1000px) {
        display: none;
    }
`

const LoadingClientInformationPage: React.FC<
    LoadingClientInformationScreenProp
> = ({ padding }) => {
    const translation = useTranslation()

    const { selectedLanguage } = useTypedSelector((state) => state.identity)

    const { changeLanguage } = useActions()

    useEffect(() => {
        if (selectedLanguage) {
            changeLanguage(selectedLanguage)
        }
        //eslint-disable-next-line
    }, [selectedLanguage])

    return (
        <Container padding={padding}>
            <MobileAlert>
                Please visit the desktop version to see all content and edit.
            </MobileAlert>
            <SkeletonTitle />

            <Navigation>
                <NavigationLink>
                    {translation.clientView.information}
                </NavigationLink>
                <NavigationLink>
                    {translation.clientView.lineOfContact}
                </NavigationLink>
                <NavigationLink>
                    {translation.clientView.customization}
                </NavigationLink>
            </Navigation>
            <Title>{translation.clientView.reportingChannel}</Title>
            <FlexContainer>
                <EssentialCompanyInfoFrame>
                    <LinkContainer>
                        <SkeletonCard height="15rem" border="2rem" />
                    </LinkContainer>
                    <LinkContainer>
                        <SkeletonCard height="15rem" border="2rem" />
                    </LinkContainer>

                    <Title>{translation.clientView.whistleblowerPolicy}</Title>
                    <LinkContainer>
                        <SkeletonCard height="15rem" border="2rem" />
                    </LinkContainer>
                </EssentialCompanyInfoFrame>
                <NotEssentialCompanyInfoFrame>
                    <SkeletonCard height="46rem" />
                    <div style={{ marginBlockStart: '2rem' }}>
                        <SkeletonCard height="25rem" />
                    </div>
                </NotEssentialCompanyInfoFrame>
            </FlexContainer>
        </Container>
    )
}

export default LoadingClientInformationPage
