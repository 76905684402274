import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import styled from 'styled-components'
import { ModalContainer, Modal } from '../../../../styled/modals'
import { Card, CardHeader } from '../../../../styled/cards'
import React, { useState } from 'react'
import { FiCreditCard, FiFileText } from 'react-icons/fi'
import AddPaymentMethodForm from './frames/AddPaymentMethodForm'
import config from '../../../../../config'
import {
    useActions,
    useTranslation,
    useTypedSelector,
} from '../../../../../hooks'

const stripeKey = config.stripeKey

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 2rem 0;
    gap: 1.4rem;
    justify-content: center;
`

interface MethodTypeButtonProps {
    isActive: boolean
}

const MethodTypeButton = styled.button<MethodTypeButtonProps>`
    height: 3.5rem;
    width: 10rem;
    background-color: ${({ theme, isActive }) =>
        isActive ? theme.blue : `${theme.blue}50`};
    color: white;
    cursor: pointer;
    border-radius: 0.5rem;
    font-size: 1.8rem;
    border: none;
    align-items: center;
    display: flex;
    justify-content: center;
`

const CreditCardIcon = styled(FiCreditCard)`
    color: #fff;
    margin-left: 0.4rem;
    font-size: 1.8rem;
`

const InvoiceIcon = styled(FiFileText)`
    color: #fff;
    margin-left: 0.4rem;
    font-size: 1.8rem;
`

const CancelButton = styled.div`
    font-size: 1.4rem;
    color: ${({ theme }) => `${theme.primary}50`};
    cursor: pointer;
    padding: 1rem;
`

interface AddPaymentMethodModalProps {
    closeModal: () => void
}

const StripeProvider: React.FC<AddPaymentMethodModalProps> = ({
    closeModal,
}) => {
    const stripePromise =
        typeof stripeKey !== 'undefined' ? loadStripe(stripeKey) : null

    const translation = useTranslation()

    return (
        <>
            {stripePromise ? (
                <Elements stripe={stripePromise}>
                    <AddPaymentMethodModal closeModal={closeModal} />
                </Elements>
            ) : (
                <>
                    {
                        translation.settingsPartner.addPaymentMethodFrame
                            .addPaymentMethodModal.errorLoadingStripe
                    }
                </>
            )}
        </>
    )
}

const AddPaymentMethodModal: React.FC<AddPaymentMethodModalProps> = ({
    closeModal,
}) => {
    const [activeFrame, setActiveFrame] = useState<'card' | 'invoice'>(
        'invoice'
    )

    const translation = useTranslation()

    const { addPaymentMethodLoading } = useTypedSelector(
        (state) => state.payment
    )

    const { clearAddPaymentMethodError } = useActions()

    const handleCloseModal = () => {
        closeModal()
        clearAddPaymentMethodError()
    }

    return (
        <ModalContainer>
            <Modal height="65rem" width="50rem">
                <Card>
                    <CardHeader>
                        {
                            translation.settingsPartner.addPaymentMethodFrame
                                .addPaymentMethodModal.addPaymentMethod
                        }
                        <CancelButton onClick={handleCloseModal}>
                            {
                                translation.settingsPartner
                                    .addPaymentMethodFrame.addPaymentMethodModal
                                    .cancel
                            }
                        </CancelButton>
                    </CardHeader>
                    <ButtonContainer>
                        <MethodTypeButton
                            isActive={activeFrame === 'card'}
                            onClick={() => {
                                !addPaymentMethodLoading &&
                                    setActiveFrame('card')
                            }}
                        >
                            {
                                translation.settingsPartner
                                    .addPaymentMethodFrame.addPaymentMethodModal
                                    .card
                            }
                            <CreditCardIcon />
                        </MethodTypeButton>
                        <MethodTypeButton
                            isActive={activeFrame === 'invoice'}
                            onClick={() => {
                                !addPaymentMethodLoading &&
                                    setActiveFrame('invoice')
                            }}
                        >
                            {
                                translation.settingsPartner
                                    .addPaymentMethodFrame.addPaymentMethodModal
                                    .invoice
                            }
                            <InvoiceIcon />
                        </MethodTypeButton>
                    </ButtonContainer>
                    {activeFrame === 'card' ? (
                        <AddPaymentMethodForm
                            paymentType="card"
                            closeModal={handleCloseModal}
                        />
                    ) : (
                        <AddPaymentMethodForm
                            paymentType="invoice"
                            closeModal={handleCloseModal}
                        />
                    )}
                </Card>
            </Modal>
        </ModalContainer>
    )
}

export default StripeProvider
