import styled from 'styled-components'
import { ModalContainer, Modal } from '../../../../../styled/modals'
import { Card } from '../../../../../styled/cards'
import { UpdateSettingsModal } from '../../../../../../ts/interfaces/UpdatedSettingsModalInterface'

const ModalTitleAndText = styled.div`
    text-align: center;
    margin: 0 auto;
    padding-top: 7rem;
    width: 30rem;
`

const ModalTitle = styled.h3`
    font-size: 2.4rem;
    font-weight: 600;
    color: ${({ theme }) => theme.primary};
`

const ModalTagLine = styled.p`
    font-size: 1.6rem;
    color: ${({ theme }) => `${theme.primary}50`};
    padding-top: 1.4rem;
    padding-bottom: 10rem;
`

const UpdatedSettingsModal: React.FC<UpdateSettingsModal> = ({
    title,
    tagline,
}) => {
    return (
        <ModalContainer>
            <Modal height="20rem" width="40rem">
                <Card>
                    <ModalTitleAndText>
                        <ModalTitle>{title}</ModalTitle>
                        <ModalTagLine>{tagline}</ModalTagLine>
                    </ModalTitleAndText>
                </Card>
            </Modal>
        </ModalContainer>
    )
}

export default UpdatedSettingsModal
