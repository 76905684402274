import produce from 'immer'
import { CaseStatisticActionType } from '../action-types'
import { CaseStatisticAction } from '../actions'

interface CaseStatisticState {
    dashboardStatisticsLoading: boolean
    dashboardStatistics: any
}

const initialState: CaseStatisticState = {
    dashboardStatisticsLoading: false,
    dashboardStatistics: null,
}

const reducer = produce(
    (state: CaseStatisticState = initialState, action: CaseStatisticAction) => {
        switch (action.type) {


            case CaseStatisticActionType.GET_DASHBOARD_CASE_STATISTICS:
                state.dashboardStatisticsLoading = false
                state.dashboardStatistics = action.payload

                return state


            default:
                return state
        }
    })

export default reducer