import styled from 'styled-components'

// Interfaces
interface StatusBarStatusProps {
    statusWidth: string
}

interface GlowHighlightProp {
    shouldGlow: boolean
}

// Styled components
export const StatusBar = styled.div`
    height: 7.5rem;
    width: 100vw;
    background: ${({ theme }) => theme.grey};
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;

    @media (max-width: 600px) {
        padding: 0 6vw;
    }
`

export const StatusBarItem = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
`

export const StatusBarNumber = styled.div<GlowHighlightProp>`
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: ${({ shouldGlow: isActive, theme }) =>
        isActive ? theme.blue : theme.primary};
    opacity: ${({ shouldGlow: isActive }) => (isActive ? 1 : 0.3)};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    color: ${({ theme }) => theme.white};
    margin-right: 1.4rem;
`

export const StatusBarText = styled.div<GlowHighlightProp>`
    font-size: 1.6rem;
    color: ${({ theme }) => theme.primary};
    opacity: ${({ shouldGlow: isActive }) => (isActive ? 1 : 0.3)};

    @media (max-width: 600px) {
        font-size: 1.4rem;
    }
`

export const StatusBarStatus = styled.div<StatusBarStatusProps>`
    height: 4px;
    background: ${({ theme }) => theme.blue};
    width: ${({ statusWidth }) => statusWidth};
    transition: width 0.2s;
    position: absolute;
    bottom: 0;
    left: 0;
`
