import { formatDate } from '../../helpers/date'
import capitilize from '../../helpers/string/capitilize'

const reportsTransformer = (reports: any) => {
    const newReports = reports.map((report: any) => {
        const assignedToName =
            report.assigned.data.first_name +
            ' ' +
            report.assigned.data.last_name

        let initials = ''

        assignedToName.split(' ').forEach((n: string) => {
            initials += n[0]
        })

        return {
            id: report.id,
            uuid: report.uuid,
            title: report.title,
            description: report.description,
            status: report.status,
            category: report.category ? capitilize(report.category) : null,
            caseType: capitilize(report.case_type),
            whistleblowerCode: report.whistleblower_code,
            assignedToId: report.assigned.data.id,
            assignedToName,
            assignedToInitials: initials,
            organizationName: report.organization.data.name,
            closedAt: report.closed_at,
            closedComment: report.closed_comment,
            closedReason: report.closed_reason,
            createdAt: formatDate(report.created_at),
        }
    })

    return newReports
}

export default reportsTransformer
