import { useHistory, useParams } from 'react-router'
import styled from 'styled-components'
import { FiPlus } from 'react-icons/fi'
import ChangeStatusModal from '../../whistleblower/new-case/modals/ChangeStatusModal'
import { useEffect, useState } from 'react'
import CloseCaseModal from './components/CloseCaseModal'
import { useActions, useTranslation, useTypedSelector } from '../../../../hooks'
import Spinner from '../../../layout/spinner/Spinner'
import capitilize from '../../../../helpers/string/capitilize'
import NavigationArrowLink from '../../../reusable/buttons/NavigationArrowLink'
// import ChangeResponsibleModal from './components/ChangeResponsibleModal'
import InformationBar from './components/InformationBar'
import DescriptionFilesHistoryBox from './components/description-files-history-box/DescriptionFilesHistoryBox'
import MessagesInternalNotesBox from './components/messages-notes-box/MessagesInternalNotesBox'
import { truncate } from '../../../../helpers/string'
import ReporterInformationModal from './components/ReporterInformationModal/ReporterInformationModal'
import ChangeCategoryModal from './components/ChangeCategoryModal'
import { UpdatedStatus } from '../../../../state/actions'
import { useLocation } from 'react-router-dom'
import { CloseReportPayload } from '../../../../state/action-creators'
import ClosedOrForwardedBox from './components/ClosedOrForwadredBox'
import ReporterInfoAnonymModal from './components/ReporterInformationModal/ReporterInfoAnonymModal'
import {
    Category,
    singleCategoryTransformer,
} from '../../../../state/transformers/CategoryTransformer'
// import Report from '../../whistleblower/access-report/frames/Report'

const Container = styled.div`
    width: 100%;
    padding: 4rem;
`

const HeadingContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`

const FlexContainer = styled.div`
    display: flex;
    gap: 2.2rem;
    padding-top: 2rem;
    @media screen and (max-width: 1000px) {
        flex-direction: column;
    }
`

const LeftContainer = styled.div`
    width: 60%;
    min-height: 55rem;
    height: 55vh;
    @media screen and (max-width: 1000px) {
        width: 100%;
    }
`

const RightContainer = styled.div`
    width: 40%;
    min-height: 55rem;
    height: 55vh;
    @media screen and (max-width: 1000px) {
        width: 100%;
    }
`

const SpinnerContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 38vh;
`

const MidSection = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    @media screen and (max-width: 480px) {
        flex-direction: column;
    }
`

const FlexCategoryStatus = styled.div`
    display: flex;
    align-items: center;

    @media screen and (max-width: 480px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 2rem;
    }
`

const LightText = styled.p`
    font-size: 1.2rem;
    color: ${({ theme }) => theme.greyText};
    margin-bottom: 1rem;
`

const Title = styled.h3`
    font-size: 2.4rem;
    color: ${({ theme }) => theme.primary};
    font-weight: 600;
    margin-bottom: 2rem;
`

const GreyButton = styled.div`
    height: 3.2rem;

    border: ${({ theme }) => `1px solid ${theme.greyText}`};
    display: flex;
    align-items: center;
    border-radius: 10rem;
    cursor: pointer;
    font-size: 1.4rem;
    color: ${({ theme }) => theme.greyText};
    transition: all 0.3s;
`
interface isactiveProp {
    isactive: boolean
}
interface CategoryButtonProps extends isactiveProp {
    isForwardedOrClosed?: boolean
}

const CategoryButton = styled(GreyButton)<CategoryButtonProps>`
    display: flex;
    justify-content: center;
    margin-right: 12rem;
    color: ${({ theme, isactive }) =>
        isactive ? theme.primary : theme.greyText};
    border: ${({ theme, isactive }) =>
        isactive
            ? `1px solid ${theme.primary}`
            : `1px solid ${theme.greyText}`};
    min-width: 10.9rem;
    max-width: 18rem;
    padding: 0 1rem;

    cursor: ${({ isForwardedOrClosed = false }) =>
        isForwardedOrClosed && 'default'};

    :hover {
        color: ${({ theme, isForwardedOrClosed }) =>
            !isForwardedOrClosed && theme.white};
        background: ${({ theme, isForwardedOrClosed }) =>
            !isForwardedOrClosed && theme.primary};
    }
`

const UncategorizedIconText = styled.div`
    display: flex;
    gap: 0.3rem;
`

const CategoryButtonContact = styled(CategoryButton)`
    cursor: default;

    :hover {
        color: ${({ theme }) => theme.primary};
        background: transparent;
    }
`

const ActionRequiredButton = styled(GreyButton)<isactiveProp>`
    width: 15.2rem;
    background: ${({ theme, isactive }) => isactive && `${theme.yellow}50`};
    border: ${({ theme, isactive }) =>
        isactive ? 'none' : `1px solid ${theme.greyText}`};
    color: ${({ theme, isactive }) =>
        isactive ? theme.yellow : theme.greyText};

    :hover {
        opacity: 0.6;
    }
`

const AwaitingResponseButton = styled(GreyButton)<isactiveProp>`
    margin-left: 1rem;
    width: 15.2rem;
    background: ${({ theme, isactive }) => isactive && `${theme.blue}50`};
    border: ${({ theme, isactive }) =>
        isactive ? 'none' : `1px solid ${theme.greyText}`};
    color: ${({ theme, isactive }) => (isactive ? theme.blue : theme.greyText)};

    :hover {
        opacity: 0.6;
    }
`

export const PlusIcon = styled(FiPlus)`
    font-size: 1.4rem;
`

const Circle = styled.div<isactiveProp>`
    height: 1.2rem;
    width: 1.2rem;
    border-radius: 50%;
    margin: 0 0.8rem;
`

const ActionRequiredCircle = styled(Circle)`
    background: ${({ theme, isactive }) =>
        isactive ? theme.yellow : theme.greyText};
`

const AwaitingResponseCircle = styled(Circle)`
    background: ${({ theme, isactive }) =>
        isactive ? theme.blue : theme.greyText};
`

const Flex = styled.div`
    display: flex;
    align-items: center;
`

const SingleCase: React.FC = () => {
    const { user } = useTypedSelector((state) => state.auth)

    const { organization } = useTypedSelector((state) => state.caseworker)

    const { openReport, openReportMessages, openReportLoading } =
        useTypedSelector((state) => state.caseworkerOpenReport)

    const { categories } = useTypedSelector((state) => state.category)

    const {
        getSingleReport,
        // updateReport,
        caseworkerShowLatestMessage,
        caseworkerLeaveReport,
        clearInternalNotes,
        updateStatus,
        closeReport,
        showSpecialForwardedMessage,
    } = useActions()

    const history = useHistory()

    const location = useLocation()

    const translation = useTranslation()
    const { id } = useParams<{ id: string }>()
    const [activeStatusModal, setActiveStatusModal] = useState<null | string>(
        null
    )
    const [activeStatus, setActiveStatus] = useState<string>(openReport?.status)

    const [isCloseCaseModalOpen, setIsCloseCaseModalOpen] = useState(false)
    const [, setIsChangeResponsibleModalOpen] = useState(false)

    const [isReporterInformationModalOpen, setIsReporterInformationModalOpen] =
        useState(false)

    const [isChangeCategoryModalOpen, setIsChangeCategoryModalOpen] =
        useState(false)

    // useEffect(() => {
    //     organization && getSingleReport(parseInt(id), translation, organization.id)
    //     // eslint-disable-next-line
    // }, [organization, id])

    useEffect(() => {
        if (!user || !id) {
            return
        }

        if (user.roles.includes('contact')) {
            getSingleReport(parseInt(id), translation)
        } else {
            if (!organization) {
                return
            }

            getSingleReport(parseInt(id), translation, organization.id)
        }

        // eslint-disable-next-line
    }, [user, id, organization])

    useEffect(() => {
        return () => {
            caseworkerLeaveReport()
            clearInternalNotes()
        }
        // eslint-disable-next-line
    }, [location])

    useEffect(() => {
        if (activeStatus === 'New') {
            const success: any = handleUpdateReportStatus('action required')

            return success
        }
        // eslint-disable-next-line
    }, [activeStatus])

    useEffect(() => {
        setActiveStatus(openReport?.status)
    }, [openReport])

    useEffect(() => {
        if (openReport && openReport.talk) {
            // @ts-ignore
            window.Echo.channel(`case-talk-${openReport.talk.data.id}`).listen(
                '.message',
                (message: any) => caseworkerShowLatestMessage(message)
            )
        }

        // @ts-ignore
        return () => {
            if (openReport && openReport.talk) {
                return (
                    // @ts-ignore
                    window.Echo.leave(`case-talk-${openReport.talk.data.id}`)
                )
            }
        }
        // eslint-disable-next-line
    }, [openReportMessages])

    const [specialMessageCreated, setSpecialMessageCreated] = useState(false)

    useEffect(() => {
        if (specialMessageCreated) {
            return
        }

        if (openReport?.forwarded_at) {
            const specialMessage = {
                created_at: openReport.forwarded_at,
                to: 'Nikulás Óskarsson',
                type: 'forwarded',
            }
            showSpecialForwardedMessage(specialMessage)
            setSpecialMessageCreated(true)
        }

        // eslint-disable-next-line
    }, [openReport, openReportMessages])

    const handleUpdateReportStatus = async (status: UpdatedStatus) => {
        if (!user) {
            return
        }

        const payload = {
            status,
        }

        let success

        if (user.roles.includes('contact')) {
            success = await updateStatus(openReport?.id, payload)
        } else {
            success = await updateStatus(
                openReport?.id,
                payload,
                organization.id
            )
        }

        return success
    }

    const handleCloseCase = async (
        status: string,
        reason: string,
        comment?: string
    ) => {
        const payload: CloseReportPayload = comment
            ? {
                  status: status,
                  closed_reason: reason,
                  closed_comment: comment,
              }
            : {
                  status: status,
                  closed_reason: reason,
              }
        await closeReport(openReport?.id, payload)

        setIsCloseCaseModalOpen(false)
    }

    const handleOpenChangeCategoryModal = () => {
        if (openReport?.closed_at) {
            return
        }

        setIsChangeCategoryModalOpen(true)
    }

    const [translateSelectedCategory, setTranslateSelectedCategory] =
        useState<Category | null>(null)

    useEffect(() => {
        if (openReport?.category) {
            const findCategorySlug = categories.find(
                (category: { name: string }) =>
                    category.name === openReport?.category
            )

            if (findCategorySlug) {
                const transformedSingleCategory = singleCategoryTransformer(
                    findCategorySlug,
                    translation
                )
                setTranslateSelectedCategory(transformedSingleCategory)
            }
        }
    }, [openReport, categories, translation])

    return (
        <>
            {!openReportLoading ? (
                <Container>
                    <div
                        style={{
                            paddingBottom: '2rem',
                        }}
                    >
                        <div
                            style={{
                                width: '8rem',
                            }}
                        >
                            <NavigationArrowLink
                                onClickCallback={() => history.goBack()}
                                linkText={
                                    translation.caseworkerOpenReport.goBack
                                }
                            />
                        </div>

                        {openReport?.closed_at && <ClosedOrForwardedBox />}

                        <InformationBar
                            report={openReport}
                            messages={openReportMessages}
                            openReporterModal={() =>
                                setIsReporterInformationModalOpen(true)
                            }
                            openChangeResponsibleModal={() =>
                                setIsChangeResponsibleModalOpen(true)
                            }
                            openCloseCaseModal={() =>
                                setIsCloseCaseModalOpen(true)
                            }
                        />
                    </div>

                    {isReporterInformationModalOpen && openReport && (
                        <>
                            {openReport.case_type === 'anonymous' ? (
                                <ReporterInfoAnonymModal
                                    closeModal={() =>
                                        setIsReporterInformationModalOpen(false)
                                    }
                                />
                            ) : (
                                <ReporterInformationModal
                                    closeModal={() =>
                                        setIsReporterInformationModalOpen(false)
                                    }
                                    reporter={openReport.reporter.data}
                                />
                            )}
                        </>
                    )}

                    {/* {isReporterInformationModalOpen && (
                        <ReporterInformationModal
                            closeModal={() =>
                                setIsReporterInformationModalOpen(false)
                            }
                            reporter={openReport.reporter.data}
                        />
                    )} */}

                    {activeStatusModal && (
                        <ChangeStatusModal
                            statusChange={
                                activeStatusModal === 'Action Required'
                                    ? translation.caseworkerOpenReport
                                          .actionRequired
                                    : translation.caseworkerOpenReport
                                          .awaitingResponse
                            }
                            statusChangePayload={
                                activeStatusModal === 'Action Required'
                                    ? 'action required'
                                    : 'awaiting response'
                            }
                            modalAction={(status: UpdatedStatus) =>
                                handleUpdateReportStatus(status)
                            }
                            closeModal={() => setActiveStatusModal(null)}
                        />
                    )}
                    {isCloseCaseModalOpen && (
                        <CloseCaseModal
                            handleCloseCase={handleCloseCase}
                            closeModal={() => setIsCloseCaseModalOpen(false)}
                        />
                    )}
                    {isChangeCategoryModalOpen && (
                        <ChangeCategoryModal
                            closeModal={() =>
                                setIsChangeCategoryModalOpen(false)
                            }
                            selectedCategory={{
                                id: openReport?.category_id,
                                name: openReport?.category,
                                slug: '', //'slug' is an empty string because it's absent in openReport state, but used in ChangeCategoryModal.tsx for translating chosen category.
                            }}
                        />
                    )}
                    <HeadingContainer>
                        <MidSection>
                            <div>
                                <LightText>
                                    {translation.caseworkerOpenReport.title}
                                </LightText>
                                <Title>{openReport?.title}</Title>
                            </div>
                            <FlexCategoryStatus>
                                <div>
                                    <LightText>
                                        {
                                            translation.caseworkerOpenReport
                                                .category
                                        }
                                    </LightText>
                                    {user?.roles.some(
                                        (role) =>
                                            role === 'contact' ||
                                            role === 'caseworker' ||
                                            role === 'partner'
                                    ) ? (
                                        <CategoryButton
                                            onClick={
                                                handleOpenChangeCategoryModal
                                            }
                                            isactive={
                                                openReport?.category !== null
                                            }
                                            isForwardedOrClosed={
                                                openReport?.forwared_at ||
                                                openReport?.closed_at
                                            }
                                        >
                                            {translateSelectedCategory ? (
                                                truncate(
                                                    capitilize(
                                                        translateSelectedCategory.name
                                                    ),
                                                    25
                                                )
                                            ) : (
                                                <UncategorizedIconText>
                                                    <PlusIcon />
                                                    {
                                                        translation
                                                            .forwardReportPage
                                                            .uncategorized
                                                    }
                                                </UncategorizedIconText>
                                            )}
                                        </CategoryButton>
                                    ) : (
                                        <CategoryButtonContact isactive={true}>
                                            {openReport?.category &&
                                                truncate(
                                                    capitilize(
                                                        openReport?.category
                                                    ),
                                                    25
                                                )}
                                        </CategoryButtonContact>
                                    )}
                                </div>
                                <div>
                                    <LightText>
                                        {
                                            translation.caseworkerOpenReport
                                                .status
                                        }
                                    </LightText>
                                    <Flex>
                                        <ActionRequiredButton
                                            onClick={() =>
                                                setActiveStatusModal(
                                                    'Action Required'
                                                )
                                            }
                                            isactive={
                                                openReport?.status ===
                                                'Action Required'
                                            }
                                        >
                                            <ActionRequiredCircle
                                                isactive={
                                                    openReport?.status ===
                                                    'Action Required'
                                                }
                                            />
                                            {
                                                translation.caseworkerOpenReport
                                                    .actionRequired
                                            }
                                        </ActionRequiredButton>
                                        <AwaitingResponseButton
                                            onClick={() =>
                                                setActiveStatusModal(
                                                    'Awaiting Response'
                                                )
                                            }
                                            isactive={
                                                openReport?.status ===
                                                'Awaiting Response'
                                            }
                                        >
                                            <AwaitingResponseCircle
                                                isactive={
                                                    openReport?.status ===
                                                    'Awaiting Response'
                                                }
                                            />
                                            {
                                                translation.caseworkerOpenReport
                                                    .awaitingResponse
                                            }
                                        </AwaitingResponseButton>
                                    </Flex>
                                </div>
                            </FlexCategoryStatus>
                        </MidSection>
                    </HeadingContainer>

                    <FlexContainer>
                        <LeftContainer>
                            <DescriptionFilesHistoryBox
                                description={openReport?.description}
                                files={openReport?.files.data}
                            />
                        </LeftContainer>
                        <RightContainer>
                            <MessagesInternalNotesBox />
                        </RightContainer>
                    </FlexContainer>
                </Container>
            ) : (
                <SpinnerContainer>
                    <Spinner />
                </SpinnerContainer>
            )}
        </>
    )
}

export default SingleCase
