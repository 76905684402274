import { getTime } from 'date-fns'
import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import styled from 'styled-components'
import { useActions } from '../../../hooks'
import Spinner from '../../layout/spinner/Spinner'

const Container = styled.div`
    height: calc(100vh - 8.5rem);
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
`

const WhistleblowerMagicLogin: React.FC = () => {
    const history = useHistory()

    const { id, loginType } = useParams<{ id: string; loginType: string }>()

    const { whistleblowerLogin } = useActions()
    useEffect(() => {
        const login = async () => {
            try {
                if (
                    loginType !== 'whistleblower' &&
                    loginType !== 'policy' &&
                    loginType !== 'how-it-works'
                ) {
                    return
                }
                const success: any = await whistleblowerLogin(id)

                if (success) {
                    const whistleblowerTimeout = getTime(new Date()) + 1200000

                    localStorage.setItem('isWhistleblowerLoggedIn', 'true')
                    localStorage.setItem('whistleblowerOrganizationalCode', id)
                    localStorage.setItem(
                        'whistleblowerTimeout',
                        whistleblowerTimeout.toString()
                    )

                    if (loginType === 'whistleblower') {
                        history.push('/')
                    } else if (loginType === 'how-it-works') {
                        history.push('/how-it-works')
                    } else {
                        history.push('/whistleblower-policy')
                    }
                } else {
                    history.push('/not-found/organizational-code')
                }
            } catch (error) {
                history.push('/not-found/organizational-code')
            }
        }

        login()
        // eslint-disable-next-line
    }, [])
    return (
        <Container>
            <Spinner />
        </Container>
    )
}

export default WhistleblowerMagicLogin
