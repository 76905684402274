import styled from 'styled-components'
import { useTranslation, useTypedSelector } from '../../../../../../../hooks'
import OrganizationCases from '../../../../../../reusable/cases/OrganizationCases'
// import CasesFilterBox from '../../../../../../reusable/filters/CasesFilterBox'

const FlexContainer = styled.div`
  display: flex;
  gap: 2.2rem;
`

const CasesBox = styled.div`
  width: 100%;
`

// const FilterBox = styled.div`
//     height: 39rem;
//     width: 15%;
// `

const CompanyCases: React.FC = () => {
    const { selectedOrganizationInformation } = useTypedSelector(
        (state) => state.caseworker,
    )
    const translation = useTranslation()

    return (
        <FlexContainer>
            {selectedOrganizationInformation.incidents && (
                <CasesBox>
                    <OrganizationCases
                        height='60vh'
                        cases={selectedOrganizationInformation.incidents.data}
                        title={translation.singleClientViewPartner.allCases}
                        isAllClosed={false}
                    />
                </CasesBox>
            )}
            {/* <FilterBox>
                <CasesFilterBox onBottomLinkClick={() => {}} />
            </FilterBox> */}
        </FlexContainer>
    )
}

export default CompanyCases
