export const light = {
    primary: '#222330',
    black: '#303030',
    blackest: '#000',
    blue900: '#052C51',
    blue400: '#3592E9',
    blue: '#247DD0',
    lightBlue: '#E7EFF0',
    white: '#FFF',
    grey: '#E9E9E9',
    grey200: '#EAECF0',
    lineGrey: '#D7D7D7',
    darkColor: '#0E263D',
    secondaryDark: '#08292C',
    platformGrey: '#F8F7F7',
    green: '#27AE60',
    red: '#EC4338',
    pink: '#FBE2E1',
    orange: '#FFC338',
    yellow: '#E39600',
    greyText: '#A0A0A6',
    memberColor: '#5E7388',
    lightGrey: '#F5F5F5',
}

export const dark = {
    primary: '#222330',
    black: '#303030',
    blackest: '#000',
    blue900: '#052C51',
    blue400: '#3592E9',
    blue: '#247DD0',
    lightBlue: '#E7EFF0',
    white: '#FFF',
    grey: '#E9E9E9',
    grey200: '#EAECF0',
    lineGrey: '#D7D7D7',
    darkColor: '#0E263D',
    secondaryDark: '#08292C',
    platformGrey: '#F8F7F7',
    green: '#27AE60',
    red: '#EC4338',
    pink: '#FBE2E1',
    orange: '#FFC338',
    yellow: '#E39600',
    greyText: '#A0A0A6',
    memberColor: '#5E7388',
    lightGrey: '#F5F5F5',
}
