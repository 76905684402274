import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
    checkEmail,
    checkFirstName,
    checkLastName,
    checkPhone,
    checkPosition,
    isValidEmail,
    isValidName,
    isValidPhone,
    isValidPosition,
} from '../../../../../helpers/validation'
import {
    useActions,
    useTranslation,
    useTypedSelector,
} from '../../../../../hooks'
import ButtonSpinnerWhite from '../../../../layout/spinner/ButtonSpinnerWhite'
import CountryCodeDropdown from '../../../../reusable/form/CountryCodeDropdown'
import { Card, CardHeader } from '../../../../styled/cards'
import {
    FormField,
    FormInput40,
    PrimaryLabel,
} from '../../../../styled/formElements'
import profilePlaceholder from '../../../../../assets/images/profile.svg'
import { Contact } from '../../../../../ts/interfaces'
import { CountryInterface } from '../../../../../state/reducers/countriesReducer'

const ModalContainer = styled.div`
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);

    position: fixed;
    top: 0;
    left: 0;
    z-index: 500;
`

const Modal = styled.div<ModalProps>`
    height: ${({ isLineOfContact, isAddSelfModal }) =>
        isLineOfContact && !isAddSelfModal
            ? '72rem'
            : isAddSelfModal
            ? '30rem'
            : '59.2rem'};
    width: 55.6rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 501;
`

const CancelButton = styled.p`
    font-size: 1.4rem;
    color: ${({ theme }) => `${theme.primary}50`};
    cursor: pointer;
`

const Form = styled.form<ModalProps>`
    height: ${({ isLineOfContact, isAddSelfModal }) =>
        isLineOfContact && !isAddSelfModal
            ? 'calc(72rem - 7rem)'
            : isAddSelfModal
            ? 'calc(30rem - 7rem)'
            : 'calc(59.2rem - 7rem)'};
    padding-top: 2rem;
    overflow-y: scroll;
`

const FormContent = styled.div`
    width: 35rem;
    margin: 0 auto;
`

const VerificationInfo = styled.p`
    font-size: 1.4rem;
    color: ${({ theme }) => `${theme.primary}50`};
    text-align: center;
    padding-top: 1rem;
`

const Button = styled.div<IsActiveProp>`
    height: 5rem;
    width: 100%;
    background: ${({ theme, isActive }) =>
        isActive ? `${theme.primary}` : `${theme.primary}50`};
    color: ${({ theme }) => theme.white};
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    cursor: pointer;
`

const ProfileImgBox = styled.div`
    width: 15rem;
    height: 15rem;
    margin: 0 auto;
    position: relative;
`

const ProfileImg = styled.label`
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    cursor: pointer;
    font-size: 1.2rem;
    color: ${({ theme }) => theme.primary};
    gap: 1rem;
`

const Image = styled.img`
    width: 12rem;
    height: 12rem;
    border: 1px solid ${({ theme }) => theme.grey};
    border-radius: 100%;
`

const HiddenProfileImgInput = styled.input`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`
const FlexInputContainer = styled.div`
    display: flex;
    gap: 16px;
`

const PhoneInputContainer = styled.div`
    width: 75%;
`

const ErrorMessage = styled.p`
    color: red;
    position: absolute;
`

const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
`

const Flex = styled.div`
    display: flex;
    gap: 1.5rem;
`

const FormTextarea = styled.textarea`
    height: 10rem;
    width: 100%;
    border: 2px solid ${({ theme }) => theme.lineGrey};
    border-radius: 0.5rem;
    margin-top: 1rem;
    padding: 1rem;
    font-family: 'NeueMontreal';
`
const LanguagesSpoken = styled.div`
    border: 2px solid ${({ theme }) => theme.lineGrey};
    border-radius: 0.5rem;
    margin-top: 0.4rem;
    padding: 0 1rem;
    height: 4rem;
    width: 10rem;
    display: flex;
    gap: 0.1rem;
    justify-content: center;
    align-items: center;
`

const CountryFlag = styled.img`
    height: 1.7rem;
    width: 1.7rem;
    border-radius: 50%;
    margin-right: 0.5rem;
    border: 1px solid black;
`

interface IsActiveProp {
    isActive: boolean
}

interface ModalProps {
    isLineOfContact: boolean
    isAddSelfModal: boolean
}

interface AddUserModalProps {
    contactView: boolean
    closeModal: () => void
    isLineOfContact: boolean
    isAddSelfModal: boolean
    addUserCallback: (user: any) => any
    isLoading: boolean
    errors?: any
}

const AddUserModal: React.FC<AddUserModalProps> = ({
    contactView,
    closeModal,
    isLineOfContact,
    isAddSelfModal,
    isLoading,
    errors = null,
    addUserCallback,
}) => {
    const { clearCaseworkerValidationErrors, clearAddLineOfContactErrors } =
        useActions()

    const translation = useTranslation()

    const { addContactErrors } = useTypedSelector(
        (state) => state.lineOfContact
    )
    const { countries } = useTypedSelector((state) => state.country)

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [countryCode, setCountryCode] = useState('+45')
    const [countryId, setCountryId] = useState(208)
    const [position, setPosition] = useState('')
    const [note, setNote] = useState('')
    const [profileImg, setProfileImg] = useState<File>()
    const [profileImgSrc, setProfileImgSrc] = useState('')
    const [isImgSelected, setIsImgSelected] = useState(false)
    const [languages, setLanguages] = useState<{ country_id: number }[]>([])

    const [firstNameError, setFirstNameError] = useState('')
    const [lastNameError, setLastNameError] = useState('')
    const [emailError, setEmailError] = useState('')
    const [phoneError, setPhoneError] = useState('')
    const [positionError, setPositionError] = useState('')

    const [firstNameSuccess, setFirstNameSuccess] = useState(false)
    const [lastNameSuccess, setLastNameSuccess] = useState(false)
    const [emailSuccess, setEmailSuccess] = useState(false)
    const [phoneSuccess, setPhoneSuccess] = useState(false)
    const [positionSuccess, setPositionSuccess] = useState(false)

    useEffect(() => {
        if (errors) {
            if (typeof errors?.email !== 'undefined') {
                setEmailError(errors.email[0])
            }
            if (typeof errors.phone !== 'undefined') {
                setPhoneError(errors.phone[0])
            }
        }
    }, [errors])

    useEffect(() => {
        return () => {
            clearCaseworkerValidationErrors()
            clearAddLineOfContactErrors()
        }

        // eslint-disable-next-line
    }, [])

    const handleChangeCountry = (country: CountryInterface) => {
        setCountryCode(country.calling_code)
        setCountryId(country.id)
        phoneValidation()
    }

    const getFlag = (countryCode: number) => {
        if (!countries) {
            return
        }
        const country = countries.find(
            (country: CountryInterface) => country.id === countryCode
        )

        if (country) {
            return country.flag
        }
    }

    const handleUploadImage = (
        e: React.ChangeEvent<HTMLInputElement> | null
    ) => {
        if (e && e.target.files && e.target.files[0]) {
            setProfileImg(e.target.files[0])
            setProfileImgSrc(URL.createObjectURL(e.target.files[0]))
            setIsImgSelected(true)
        }
    }

    const handleAddLanguage = (countryId: number) => {
        const country = countries?.find(
            (country: CountryInterface) => country.id === countryId
        )
        if (country) {
            setLanguages((prevLanguages) => [
                ...prevLanguages,
                { country_id: countryId },
            ])
        }
    }

    const firstNameValidation = () => {
        setFirstNameError(checkFirstName(firstName, translation))

        setFirstNameSuccess(firstNameError.length === 0)
    }

    const firstNameOnFocus = () => {
        setFirstNameError('')
        setFirstNameSuccess(false)
    }

    const lastNameValidation = () => {
        setLastNameError(checkLastName(lastName, translation))

        setLastNameSuccess(lastNameError.length === 0)
    }

    const lastNameOnFocus = () => {
        setLastNameError('')
        setLastNameSuccess(false)
    }

    const positionValidation = () => {
        setPositionError(checkPosition(position, translation))

        if (positionError.length === 0) {
            setPositionSuccess(true)
        } else {
            setPositionSuccess(false)
        }
    }

    const positionOnFocus = () => {
        setPositionError('')
        setPositionSuccess(false)
    }

    const emailValidation = () => {
        setEmailError(checkEmail(email, translation))

        if (emailError.length === 0) {
            setEmailSuccess(true)
        } else {
            setEmailSuccess(false)
        }
    }

    const emailOnFocus = () => {
        setEmailError('')
        setEmailSuccess(false)
    }

    const phoneValidation = () => {
        setPhoneError(checkPhone(`${countryCode + phone}`, translation))

        if (phoneError.length === 0) {
            setPhoneSuccess(true)
        } else {
            setPhoneSuccess(false)
        }
    }

    const phoneOnFocus = () => {
        setPhoneError('')
        setPhoneSuccess(false)
    }

    const isFormValidated = () => {
        if (!isAddSelfModal) {
            if (
                isValidName(firstName) &&
                isValidName(lastName) &&
                isValidEmail(email) &&
                isValidPhone(`${countryCode + phone}`)
            ) {
                if (isLineOfContact) {
                    return isValidPosition(position)
                }
                return true
            } else {
                return false
            }
        } else {
            return isValidPosition(position)
        }
    }

    useEffect(() => {
        if (addContactErrors) {
            let errorMessage = ''
            if (addContactErrors['contact.email']) {
                errorMessage = addContactErrors['contact.email'][0]
            } else if (addContactErrors['contact.phone']) {
                errorMessage = addContactErrors['contact.phone'][0]
            } else {
                errorMessage = 'Something went wrong. Please try again later.'
            }
            alert(errorMessage)
        }
    }, [addContactErrors])

    const handleAddUser = async () => {
        if (!contactView && !isAddSelfModal) {
            if (!isFormValidated()) {
                return
            }

            const user: any = {
                first_name: firstName,
                last_name: lastName,
                email: email,
                phone: phone,
                country_id: countryId,
            }

            if (isLineOfContact) {
                user.position = position
                user.note = note
                user.languages = languages

                if (profileImg) {
                    user.profile_img = profileImg
                }
            }

            const success = await addUserCallback(user)

            if (success) {
                closeModal()
            }
        } else if (!isAddSelfModal) {
            if (!isFormValidated()) {
                return
            }

            const contactUser: Contact = {
                contact: {
                    first_name: firstName,
                    last_name: lastName,
                    email: email,
                    phone: phone,
                    country_id: countryId,
                },
                contact_information: {
                    position: position,
                    note: note,
                },
                languages: languages,
            }

            if (profileImg) {
                contactUser.contact_information.profile_img = profileImg
            }

            const success = await addUserCallback(contactUser)

            if (success) {
                closeModal()
            }
        } else {
            const contactInformation: {
                position: string
                note: string
                profile_img?: File | undefined
            } = {
                position: position,
                note: note,
            }

            if (profileImg) {
                contactInformation.profile_img = profileImg
            }

            const success = await addUserCallback(contactInformation)

            if (success) {
                closeModal()
            }
        }
    }

    return (
        <ModalContainer>
            <Modal
                isLineOfContact={isLineOfContact}
                isAddSelfModal={isAddSelfModal}
            >
                <Card id="card">
                    <CardHeader>
                        {translation.settingsPartner.popUpAddingANewUser}
                        <CancelButton onClick={closeModal}>
                            {translation.settingsPartner.popUpCancel}
                        </CancelButton>
                    </CardHeader>
                    <Form
                        isLineOfContact={isLineOfContact}
                        isAddSelfModal={isAddSelfModal}
                    >
                        <FormContent>
                            {!isAddSelfModal && (
                                <FlexColumn>
                                    <ProfileImgBox>
                                        <>
                                            <ProfileImg htmlFor="profileImgFile">
                                                {isImgSelected ? (
                                                    <>
                                                        <Image
                                                            src={profileImgSrc}
                                                            alt="img selected"
                                                        />
                                                        <p>Change photo</p>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Image
                                                            src={
                                                                profilePlaceholder
                                                            }
                                                            alt="img placeholder"
                                                        />
                                                        <p>Add photo</p>
                                                    </>
                                                )}
                                            </ProfileImg>
                                            <HiddenProfileImgInput
                                                type="file"
                                                id="profileImgFile"
                                                accept="image/jpeg, image/jpg, image/png, image/svg+xml"
                                                onChange={handleUploadImage}
                                            />
                                        </>
                                    </ProfileImgBox>
                                    <FormField>
                                        <PrimaryLabel
                                            isError={!!firstNameError}
                                            isSuccess={firstNameSuccess}
                                        >
                                            {
                                                translation.settingsPartner
                                                    .popUpFirstName
                                            }
                                            *
                                        </PrimaryLabel>
                                        <FormInput40
                                            type="text"
                                            placeholder={
                                                translation.settingsPartner
                                                    .popUpFirstName
                                            }
                                            value={firstName}
                                            onChange={(e) =>
                                                setFirstName(e.target.value)
                                            }
                                            className={
                                                firstNameError
                                                    ? 'border-error'
                                                    : firstNameSuccess
                                                    ? 'border-success'
                                                    : ''
                                            }
                                            onBlur={firstNameValidation}
                                            onFocus={firstNameOnFocus}
                                        />
                                        {firstNameError.length > 1 && (
                                            <ErrorMessage>
                                                {firstNameError}
                                            </ErrorMessage>
                                        )}
                                    </FormField>
                                    <FormField>
                                        <PrimaryLabel
                                            isError={!!lastNameError}
                                            isSuccess={lastNameSuccess}
                                        >
                                            {
                                                translation.settingsPartner
                                                    .popUpLastName
                                            }
                                            *
                                        </PrimaryLabel>
                                        <FormInput40
                                            type="text"
                                            placeholder={
                                                translation.settingsPartner
                                                    .popUpLastName
                                            }
                                            value={lastName}
                                            onChange={(e) =>
                                                setLastName(e.target.value)
                                            }
                                            className={
                                                lastNameError
                                                    ? 'border-error'
                                                    : lastNameSuccess
                                                    ? 'border-success'
                                                    : ''
                                            }
                                            onBlur={lastNameValidation}
                                            onFocus={lastNameOnFocus}
                                        />
                                        {lastNameError.length > 1 && (
                                            <ErrorMessage>
                                                {lastNameError}
                                            </ErrorMessage>
                                        )}
                                    </FormField>
                                    <FormField>
                                        <PrimaryLabel
                                            isError={!!emailError}
                                            isSuccess={emailSuccess}
                                        >
                                            {
                                                translation.settingsPartner
                                                    .popUpWorkEmail
                                            }
                                            *
                                        </PrimaryLabel>
                                        <FormInput40
                                            type="email"
                                            placeholder={
                                                translation.settingsPartner
                                                    .popUpWorkEmail
                                            }
                                            value={email}
                                            onChange={(e) =>
                                                setEmail(e.target.value)
                                            }
                                            className={
                                                emailError
                                                    ? 'border-error'
                                                    : emailSuccess
                                                    ? 'border-success'
                                                    : ''
                                            }
                                            onBlur={emailValidation}
                                            onFocus={emailOnFocus}
                                        />
                                        {emailError.length > 1 && (
                                            <ErrorMessage>
                                                {emailError}
                                            </ErrorMessage>
                                        )}
                                    </FormField>
                                    <FormField>
                                        <PrimaryLabel
                                            isError={!!phoneError}
                                            isSuccess={phoneSuccess}
                                        >
                                            {
                                                translation.settingsPartner
                                                    .popUpPhone
                                            }
                                            *
                                        </PrimaryLabel>
                                        <FlexInputContainer>
                                            <CountryCodeDropdown
                                                error={!!phoneError}
                                                success={phoneSuccess}
                                                selectDropdownCB={
                                                    handleChangeCountry
                                                }
                                            />
                                            <PhoneInputContainer>
                                                <FormInput40
                                                    type="phone"
                                                    placeholder={
                                                        translation
                                                            .settingsPartner
                                                            .popUpPhone
                                                    }
                                                    value={phone}
                                                    onChange={(e) =>
                                                        setPhone(e.target.value)
                                                    }
                                                    className={
                                                        phoneError
                                                            ? 'border-error'
                                                            : phoneSuccess
                                                            ? 'border-success'
                                                            : ''
                                                    }
                                                    onBlur={phoneValidation}
                                                    onFocus={phoneOnFocus}
                                                />
                                            </PhoneInputContainer>
                                        </FlexInputContainer>
                                        {phoneError.length > 1 && (
                                            <ErrorMessage>
                                                {phoneError}
                                            </ErrorMessage>
                                        )}
                                    </FormField>
                                    {!isLineOfContact && (
                                        <VerificationInfo>
                                            {
                                                translation.settingsPartner
                                                    .popUpText
                                            }
                                        </VerificationInfo>
                                    )}
                                </FlexColumn>
                            )}
                            {isLineOfContact && (
                                <FlexColumn>
                                    <FormField>
                                        <PrimaryLabel
                                            isError={!!positionError}
                                            isSuccess={positionSuccess}
                                        >
                                            {
                                                translation.settingsPartner
                                                    .popUpPosition
                                            }
                                            *
                                        </PrimaryLabel>
                                        <FormInput40
                                            type="text"
                                            placeholder="Position"
                                            value={position}
                                            onChange={(e) =>
                                                setPosition(e.target.value)
                                            }
                                            className={
                                                positionError
                                                    ? 'border-error'
                                                    : positionSuccess
                                                    ? 'border-success'
                                                    : ''
                                            }
                                            onBlur={positionValidation}
                                            onFocus={positionOnFocus}
                                        />
                                        {positionError.length > 1 && (
                                            <ErrorMessage>
                                                {positionError}
                                            </ErrorMessage>
                                        )}
                                    </FormField>
                                </FlexColumn>
                            )}
                            {isAddSelfModal && (
                                <FormField>
                                    <PrimaryLabel
                                        isError={!!positionError}
                                        isSuccess={positionSuccess}
                                    >
                                        {
                                            translation.settingsPartner
                                                .popUpPosition
                                        }
                                        *
                                    </PrimaryLabel>
                                    <FormInput40
                                        type="text"
                                        placeholder="Position"
                                        value={position}
                                        onChange={(e) =>
                                            setPosition(e.target.value)
                                        }
                                        className={
                                            positionError
                                                ? 'border-error'
                                                : positionSuccess
                                                ? 'border-success'
                                                : ''
                                        }
                                        onBlur={positionValidation}
                                        onFocus={positionOnFocus}
                                    />
                                    {positionError.length > 1 && (
                                        <ErrorMessage>
                                            {positionError}
                                        </ErrorMessage>
                                    )}
                                </FormField>
                            )}
                            {isLineOfContact && !isAddSelfModal && (
                                <>
                                    <FormField>
                                        <PrimaryLabel>Languages</PrimaryLabel>
                                        <Flex>
                                            <LanguagesSpoken>
                                                {countries &&
                                                    languages?.map(
                                                        (language: {
                                                            country_id: number
                                                        }) => {
                                                            const flag =
                                                                getFlag(
                                                                    language.country_id
                                                                )

                                                            return (
                                                                <CountryFlag
                                                                    key={
                                                                        language.country_id
                                                                    }
                                                                    src={flag}
                                                                    alt="country flag"
                                                                />
                                                            )
                                                        }
                                                    )}
                                            </LanguagesSpoken>
                                            {languages.length < 4 && (
                                                <CountryCodeDropdown
                                                    error={false}
                                                    success={false}
                                                    isLanguagesDropdown={true}
                                                    isInAddUser={true}
                                                    handleAddLanguage={
                                                        handleAddLanguage
                                                    }
                                                />
                                            )}
                                        </Flex>
                                    </FormField>
                                    <FormField>
                                        <PrimaryLabel htmlFor="note">
                                            {translation.clientView.notes}
                                        </PrimaryLabel>
                                        <FormTextarea
                                            id="note"
                                            name="note"
                                            maxLength={400}
                                            rows={8}
                                            placeholder="Type a note here ..."
                                            value={note}
                                            onChange={(e) =>
                                                setNote(e.target.value)
                                            }
                                        />
                                    </FormField>
                                </>
                            )}
                        </FormContent>
                        <div
                            style={{
                                padding: '0 1.4rem',
                                marginTop: '2rem',
                            }}
                        >
                            <Button
                                isActive={isFormValidated()}
                                onClick={handleAddUser}
                            >
                                {isLoading ? (
                                    <ButtonSpinnerWhite />
                                ) : (
                                    <>
                                        {
                                            translation.settingsPartner
                                                .popUpButtonAddUser
                                        }
                                    </>
                                )}
                            </Button>
                        </div>
                    </Form>
                </Card>
            </Modal>
        </ModalContainer>
    )
}

export default AddUserModal
