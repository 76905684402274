import axios from 'axios'
import config from '../../config'
import { NotificationActionType } from '../action-types'
import { Dispatch } from 'redux'

const { apiUrl } = config

export const getNotifications =
    () => async (dispatch: Dispatch, getState: () => any) => {
        const state: any = getState()

        const { notificationPage, notifications } = state.notification

        // If true another page of notifs is being fetched
        if (notifications) {
            dispatch({
                type: NotificationActionType.SET_GET_NOTIFICATIONS_NEXT_PAGE_LOADING,
            })
        }

        try {
            const res = await axios.get(
                `${apiUrl}/v1/me/notifications?page=${notificationPage}`
            )

            if (typeof res === 'undefined') {
                return false
            }

            const data = {
                notifications: res.data.data,
                notificationCount: res.data.meta.unread_count,
            }

            dispatch({
                type: NotificationActionType.GET_NOTIFICATIONS,
                payload: data,
            })
        } catch (error) {}
    }

export const showLatestNotification = (notification: any) => ({
    type: NotificationActionType.SHOW_LATEST_NOTIFICATION,
    payload: notification,
})

export const readAllNotifications = () => async (dispatch: Dispatch) => {
    try {
        await axios.post(`${apiUrl}/v1/me/notifications/read-all`)

        dispatch({
            type: NotificationActionType.READ_ALL_NOTIFICATIONS,
        })
    } catch (error) {}
}

export const readSingleNotification =
    (notificationId: string) => async (dispatch: Dispatch) => {
        try {
            const res: any = await axios.post(
                `${apiUrl}/v1/me/notifications/`,
                {
                    id: notificationId,
                }
            )

            if (res.status !== 200) {
                // TODO handle error
                return false
            }

            dispatch({
                type: NotificationActionType.READ_SINGLE_NOTIFICATION,
                payload: res.data.data,
            })

            return true
        } catch (error: any) {
            return false
        }
    }

export const filterNotifications = (filter: 'all' | 'unread') => ({
    type: NotificationActionType.FILTER_NOTIFICATIONS,
    payload: filter,
})

export const resetNotificationCount = () => ({
    type: NotificationActionType.RESET_NOTIFICATION_COUNT,
})
