import styled from 'styled-components'

export const Input = styled.input<PrimaryLabelProps>`
    width: 100%;
    border: 2px solid
        ${({ theme, isError, isSuccess }) =>
            isError ? theme.red : isSuccess ? 'green' : theme.lineGrey};
    border-radius: 0.5rem;
    margin-top: 0.4rem;
    padding: 0 1rem;
`
export const FormTextarea = styled.textarea<PrimaryLabelProps>`
    width: 100%;
    border: 2px solid
        ${({ theme, isError, isSuccess }) =>
            isError ? theme.red : isSuccess ? 'green' : theme.lineGrey};
    border-radius: 0.5rem;
    margin-top: 0.4rem;
    padding: 1rem;
    font-family: 'NeueMontreal';
`

export const FormInput40 = styled(Input)`
    height: 4rem;
`

export const FormInput50 = styled(Input)`
    height: 5rem;
`

// labels
interface PrimaryLabelProps {
    isError?: boolean
    isSuccess?: boolean
}
export const PrimaryLabel = styled.label<PrimaryLabelProps>`
    font-size: 1.2rem;
    font-weight: 600;
    margin-left: 0.2rem;
    color: ${({ theme, isError, isSuccess }) =>
        isError ? 'red' : isSuccess ? 'green' : theme.secondaryDark};
`

export const ErrorMessage = styled.div`
    color: ${({ theme }) => theme.red};
    position: absolute;
    font-size: 1rem;
`

// form fields

export const FormField = styled.div`
    width: 100%;
    padding-bottom: 2.8rem;
    position: relative;
`

export const SmallFormField = styled(FormField)`
    padding-bottom: 1rem;
    position: relative;
`

export const PhoneRow = styled.div`
    display: flex;
    width: 100%;
    gap: 1.6rem;
    flex: 5;
    position: relative;
`

export const FormFieldSmallBetween = styled.div`
    width: 100%;
    padding-bottom: 1.6rem;
    position: relative;
`
// Password field extras
export const ShowPasswordText = styled.text`
    font-size: 1.2rem;
    font-weight: 600;
    margin-left: 0.2rem;
`

export const InputFieldIcon = styled.i`
    position: absolute;
    top: 37%;
    right: 5%;
    font-size: 15px;

    &:hover {
        color: ${({ theme }) => theme.blue};
        cursor: pointer;
    }
`
