import Translation from '../../state/state-types/TranslationStateType'

export const isValidPasswordConfirm = (
    password1: string,
    password2: string
) => {
    return password1 === password2
}

export const checkPasswordConfirm = (
    password1: string,
    password2: string,
    translation: Translation
) => {
    if (password2.length < 8) {
        return translation.validation.passwordMustBeAtLeast
    } else if (!password2.match(/[A-Z]/)) {
        return translation.validation
            .yourPasswordMustContainerAtLeastOneUppercase
    } else if (!password2.match(/[a-z]/)) {
        return translation.validation
            .yourPasswordMustContainerAtLeastOneLowercase
    } else if (
        //eslint-disable-next-line
        password2.search(/[-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]/) < 0
    ) {
        return translation.validation.yourPasswordMustContainerAtLeastOneSymbol
    } else if (password2.search(/[0-9]/) < 0) {
        return translation.validation.yourPasswordMustContainerAtLeastOneNumber
    } else if (password1 !== password2) {
        return translation.validation.passwordsMustMatch
    } else {
        return ''
    }
}

export const passwordsMatchCheck = (password1: string, password2: string) => {
    return password1 === password2
}
