import produce from 'immer'
import { CountriesActionType } from '../action-types'
import { CountryAction } from '../actions/countryActions'

export interface CountryInterface {
    calling_code: string
    flag: string
    id: number
    iso_3166_3: string
    name: string
}

interface CountryState {
    countriesLoading: boolean
    countriesError: null | string
    countries: CountryInterface[] | null
}

const initialState: CountryState = {
    countriesLoading: false,
    countriesError: null,
    countries: null,
}

const reducer = produce(
    (state: CountryState = initialState, action: CountryAction) => {
        switch (action.type) {
            case CountriesActionType.ADD_COUNTRIES:
                state.countriesLoading = false
                state.countriesError = null
                state.countries = action.payload.countries

                return state
            default:
                return state
        }
    }
)

export default reducer
