import Translation from '../../state/state-types/TranslationStateType'

export const isValidZipCode = (code: string) => {
    const re = /[0-9a-zA-Z]+/

    return re.test(String(code).toLowerCase())
}

export const checkZipCode = (code: string, translation: Translation) => {
    if (!isValidZipCode(code)) {
        return translation.validation.notAValidZipCode
    }

    return ''
}
