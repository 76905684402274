import React, { useRef } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import {
    useActions,
    useTranslation,
    useTypedSelector,
} from '../../../../../hooks'
import notificationTransformer from '../../../../../state/transformers/NotificationTransformer'
import { Card, CardHeader } from '../../../../styled/cards'
import Spinner from '../../../../layout/spinner/Spinner'
import { NotificationLoadingContainer } from '../../../../layout/header/components/NotificationDropdown'
import { AiOutlineCheck } from 'react-icons/ai'

export const NotificationContainer = styled.div`
    width: 40%;
    height: 36rem;

    @media screen and (max-width: 600px) {
        width: 100%;
        height: 30.4rem;
    }
`

const NotificationInformationContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
`

const NotificationInformationCircle = styled.div`
    height: 0.6rem;
    width: 0.6rem;
    border-radius: 50%;
    background: ${({ theme }) => theme.grey};
`

const NotificationInformationCircleRed = styled(NotificationInformationCircle)`
    background: ${({ theme }) => theme.red};
    margin-left: 1rem;
`

const NotificationInformationText = styled.span`
    font-size: 1.2rem;
    color: ${({ theme }) => theme.primary};
    margin-left: 0.4rem;
`

const NotificationInformationTextGrey = styled(NotificationInformationText)`
    opacity: 0.5;
`

const Notifications = styled.div`
    height: 31.6rem;
    overflow-y: scroll;
`

const Notification = styled.div`
    height: 6.32rem;
    width: 100%;

    display: flex;
    padding: 1.4rem;
    border-bottom: 1px solid ${({ theme }) => theme.grey};
    cursor: pointer;

    :hover {
        background: ${({ theme }) => theme.lightBlue};
    }
`

interface IsNewProp {
    isNew: boolean
}

const NotificationCircle = styled(NotificationInformationCircle)<IsNewProp>`
    height: 0.8rem;
    width: 0.8rem;
    margin-right: 1rem;
    margin-top: 0.2rem;
    background: ${({ theme, isNew }) => (isNew ? theme.red : theme.grey)};
`

const NotificationTitle = styled.div`
    font-size: 1.4rem;
    color: ${({ theme }) => theme.primary};
`

const NotificationID = styled.div`
    font-size: 1rem;
    color: ${({ theme }) => theme.primary};
    margin-top: 0.5rem;
`

const NotificationDate = styled.div`
    display: flex;
    margin-left: auto;
`

const NotificationDateText = styled.span`
    font-size: 1rem;
    color: ${({ theme }) => theme.primary};
    opacity: 0.5;
    margin-left: 1rem;
`

const NoNotificationContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: ${({ theme }) => theme.greyText};
    font-size: 2rem;
    gap: 1rem;

    @media screen and (max-width: 600px) {
        height: 50%;
        font-size: 1.5rem;
    }
`

// const NotificationText = styled.p`
//   font-size: 1.2rem;
//   color: ${({ theme }) => `${theme.darkColor}80`};
//   margin-top: 1rem;
// `

const NotificationBox: React.FC = () => {
    const { filteredNotifications, notificationsNextPageLoading } =
        useTypedSelector((state) => state.notification)
    const { isTrialExpired } = useTypedSelector((state) => state.payment)

    const { filterNotifications, readSingleNotification, getNotifications } =
        useActions()

    const translation = useTranslation()

    const history = useHistory()

    const handleReadNotification = async (notification: any) => {
        if (!notification.read_at) {
            const success: any = await readSingleNotification(notification.id)

            success &&
                !isTrialExpired &&
                history.push(`/report/${notification.case_id}`)
        } else {
            !isTrialExpired && history.push(`/report/${notification.case_id}`)
        }
    }

    const scrollRef: any = useRef()

    const handleScroll = () => {
        if (scrollRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollRef.current

            if (scrollTop + clientHeight === scrollHeight) {
                getNotifications()
            }
        }
    }

    return (
        <>
            <NotificationContainer>
                <Card>
                    <CardHeader>
                        {translation.dashboardPartner.notifications}
                        <NotificationInformationContainer>
                            <NotificationInformationCircle />
                            <NotificationInformationText
                                style={{
                                    cursor: 'pointer',
                                }}
                                onClick={() => filterNotifications('all')}
                            >
                                {translation.dashboardPartner.all}
                            </NotificationInformationText>
                            <NotificationInformationCircleRed />
                            <NotificationInformationTextGrey
                                style={{
                                    cursor: 'pointer',
                                }}
                                onClick={() => filterNotifications('unread')}
                            >
                                {translation.dashboardPartner.unread}
                            </NotificationInformationTextGrey>
                        </NotificationInformationContainer>
                    </CardHeader>
                    <Notifications ref={scrollRef} onScroll={handleScroll}>
                        {filteredNotifications.length > 0 ? (
                            notificationTransformer(
                                filteredNotifications,
                                translation
                            ).map((notification: any) => (
                                <Notification
                                    key={notification.id}
                                    onClick={() =>
                                        handleReadNotification(notification)
                                    }
                                >
                                    <NotificationCircle
                                        isNew={!notification.read_at}
                                    />
                                    <div>
                                        <NotificationTitle>
                                            {notification.title}
                                        </NotificationTitle>
                                        {/*<NotificationText>*/}
                                        {/*    Nikulás Óskarsson has updated the status*/}
                                        {/*</NotificationText>*/}
                                        <NotificationID>
                                            ID: {notification.case_readable_id}
                                        </NotificationID>
                                    </div>
                                    <NotificationDate>
                                        <NotificationDateText>
                                            {notification.date
                                                ? notification.date
                                                : 'Just now'}
                                        </NotificationDateText>
                                    </NotificationDate>
                                </Notification>
                            ))
                        ) : (
                            <NoNotificationContainer>
                                <AiOutlineCheck fontSize={'3rem'} />
                                {translation.dashboardPartner.noNotifications}
                            </NoNotificationContainer>
                        )}

                        {notificationsNextPageLoading && (
                            <NotificationLoadingContainer>
                                <Spinner height="20" width="20" />
                            </NotificationLoadingContainer>
                        )}
                    </Notifications>
                </Card>
            </NotificationContainer>
        </>
    )
}

export default NotificationBox
