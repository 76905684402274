import { useState } from 'react'
import styled from 'styled-components'
import { AiOutlineCamera } from 'react-icons/ai'
import previewScreen from '../../../../assets/images/preview-screen.png'
import { useActions, useTypedSelector } from '../../../../hooks'
import ButtonSpinnerWhite from '../../../layout/spinner/ButtonSpinnerWhite'
import { checkColor, isValidColor } from '../../../../helpers/validation/color'
import { ErrorMessage } from '../../../styled/formElements'
import logo from '../../../../assets/images/logo-white-blue-bg.svg'

interface CompanyColorProps {
    companyColor: string
}

interface BtnsActiveProps {
    btnsActive: boolean
}

const Container = styled.div`
    display: flex;
`

const LeftSide = styled.div`
    height: 50rem;
    width: 65rem;
`
const ImageUploadAndPreview = styled.div`
    height: 20rem;
    width: 100%;
    margin-bottom: 3rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr 5fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    margin-bottom: 5rem;
`

const CompanyLogo = styled.div`
    width: 100%;
    height: 100%;
    font-size: 1.8rem;
    background: white;
    padding-left: 2rem;
    padding: 1rem;
    border-top: 0.1rem solid ${({ theme }) => theme.grey};
    border-left: 0.1rem solid ${({ theme }) => theme.grey};
`
const Preview = styled.div`
    width: 100%;
    height: 100%;
    font-size: 1.8rem;
    background: white;
    padding-left: 2rem;
    padding: 1rem;
    border-top: 0.1rem solid ${({ theme }) => theme.grey};
    border-right: 0.1rem solid ${({ theme }) => theme.grey};
    border-left: 0.1rem solid ${({ theme }) => theme.grey};
`
const UploadBox = styled.label`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: white;
    cursor: pointer;
`
const TypeOfFile = styled.p`
    font-size: 1 rem;
    color: ${({ theme }) => theme.primary};
    opacity: 0.5;
`
const CallToUpload = styled.p`
    font-size: 1.8rem;
    color: ${({ theme }) => theme.blue};
`

const CompanyLogoInputBox = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    border-bottom: 0.1rem solid ${({ theme }) => theme.grey};
    border-top: 0.1rem solid ${({ theme }) => theme.grey};
    border-left: 0.1rem solid ${({ theme }) => theme.grey};
`
const BlueCamera = styled(AiOutlineCamera)`
    font-size: 4rem;
    font-weight: 300px;
    color: ${({ theme }) => theme.blue};
`
const CompanyLogoHiddenInput = styled.input`
    width: 100%;
    height: 100%;
`
const PreviewImg = styled.div`
    width: 100%;
    height: 100%;
    background: white;
    border: 0.1rem solid ${({ theme }) => theme.grey};
    display: flex;
    align-items: center;
    justify-content: center;
`

const CompanyImage = styled.img`
    max-width: 85%;
    max-height: 85%;
`

const RightSide = styled.div`
    height: 30rem;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
const PreviewSite = styled.div`
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 0.2rem solid ${({ theme }) => theme.grey};
`

const PreviewBar = styled.div<CompanyColorProps>`
    width: 100%;
    height: 4rem;
    background-color: ${({ companyColor, theme }) =>
        companyColor ? companyColor : theme.primary};
    display: flex;
    align-items: center;
    padding-left: 2rem;
`
const PreviewLogo = styled.img`
    max-height: 1.5rem;
`

const PreviewScreen = styled.img``

const ColorChange = styled.div`
    height: 10rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 3rem;
`
const PrimaryColor = styled.p`
    font-size: 1.8rem;
    margin-bottom: 1rem;
    margin-top: 5rem;
`

const ColorInput = styled.input`
    font-size: 1.2rem;
    padding: 1rem;
    border: 0.2rem solid ${({ theme }) => theme.grey};
    border-radius: 0.5rem;
`

const SaveButton = styled.button<BtnsActiveProps>`
    height: 3.4rem;
    width: 100%;
    color: ${({ theme, btnsActive }) =>
        btnsActive ? theme.white : theme.primary};
    background: ${({ theme, btnsActive }) =>
        btnsActive ? theme.primary : `${theme.primary}50`};
    border: 0.1rem solid
        ${({ theme, btnsActive }) =>
            btnsActive ? theme.primary : `${theme.primary}50`};
    border-radius: 0.8rem;
    text-align: center;
    cursor: pointer;
    transition: background 0.2s;
    margin-bottom: 1rem;
`

const RestoreDefaultsButton = styled.button<BtnsActiveProps>`
    height: 3.4rem;
    width: 100%;
    color: ${({ theme, btnsActive }) =>
        btnsActive ? theme.white : theme.primary};
    background: ${({ theme, btnsActive }) =>
        btnsActive ? theme.red : `${theme.red}50`};
    border: 0.1rem solid
        ${({ theme, btnsActive }) =>
            btnsActive ? theme.red : `${theme.red}50`};
    border-radius: 0.8rem;
    text-align: center;
    cursor: pointer;
    transition: background 0.2s;
    :hover {
        color: white;
        background: ${({ theme }) => theme.red};
        border: 0.1rem solid ${({ theme }) => theme.red};
    }
`

const DesignFrame = () => {
    const { client } = useTypedSelector((state) => state.client)
    const { customDesignLoading } = useTypedSelector(
        (state) => state.customDesign
    )
    const { postCustomDesign, restoreCustomDesign } = useActions()
    const [companyLogo, setcompanyLogo] = useState(logo)
    const [companyColor, setCompanyColor] = useState(' #222330')
    const [companyCustomDesign, setCompanyCustomDesign] = useState({})
    const [colorError, setColorError] = useState('')
    const [colorSuccess, setColorSuccess] = useState(false)
    const [btnsActive, setBtnsActive] = useState(false)
    const [logoError, setLogoError] = useState('')

    const isFormValidated = () => {
        if (isValidColor(companyColor)) {
            return true
        } else {
            return false
        }
    }

    const colorValidation = () => {
        setColorError(checkColor(companyColor))
        setColorSuccess(colorError.length === 0)
    }

    const updateCompanyCustomDesign = (key: string, value: any) => {
        setCompanyCustomDesign({ ...companyCustomDesign, [key]: value })
    }

    const handlePostCustomDesign = async () => {
        const validated = isFormValidated()
        if (validated && companyLogo.length) {
            const success = await postCustomDesign(
                client.organization.data.id,
                companyCustomDesign
            )

            return success
        } else {
            setLogoError('You must upload a logo file')
        }
    }

    const handleRestoreDesign = () => {
        restoreCustomDesign()
        setCompanyColor('#222330')
        setcompanyLogo(logo)
        setColorError('')
        setColorSuccess(false)
    }

    const activateBtns = () => {
        if (companyColor.length || companyLogo.length) {
            setBtnsActive(true)
        }
    }

    return (
        <>
            <Container>
                <LeftSide>
                    <ImageUploadAndPreview>
                        <CompanyLogo>Company Logo</CompanyLogo>
                        <Preview>Preview</Preview>
                        <CompanyLogoInputBox>
                            <UploadBox htmlFor="logoFile">
                                <BlueCamera />
                                <TypeOfFile>Use a .png of .svg file</TypeOfFile>
                                <CallToUpload>Upload new logo</CallToUpload>
                                {logoError.length > 1 && (
                                    <ErrorMessage>{logoError}</ErrorMessage>
                                )}
                            </UploadBox>
                            <CompanyLogoHiddenInput
                                type="file"
                                accept="image/png, image/svg"
                                id="logoFile"
                                onChange={(e: any) => {
                                    updateCompanyCustomDesign(
                                        'logo',
                                        e.target.files[0]
                                    )
                                    activateBtns()
                                    setcompanyLogo(
                                        URL.createObjectURL(e.target.files[0])
                                    )
                                }}
                            ></CompanyLogoHiddenInput>
                        </CompanyLogoInputBox>

                        <PreviewImg>
                            <CompanyImage
                                src={companyLogo}
                                alt="company logo"
                            />
                        </PreviewImg>
                    </ImageUploadAndPreview>
                    <ColorChange>
                        <form>
                            <div>
                                <PrimaryColor>Primary Color:</PrimaryColor>
                                <ColorInput
                                    type="text"
                                    value={companyColor}
                                    onBlur={colorValidation}
                                    onFocus={() => setColorError('')}
                                    onChange={(e) => {
                                        setCompanyColor(e.target.value)
                                        activateBtns()
                                        updateCompanyCustomDesign(
                                            'color_hex_code',
                                            e.target.value
                                        )
                                    }}
                                    className={
                                        colorError
                                            ? 'border-error'
                                            : colorSuccess
                                            ? 'border-success'
                                            : ''
                                    }
                                />
                                {colorError.length > 1 && (
                                    <ErrorMessage
                                        style={{
                                            position: 'static',
                                            paddingTop: '0.5rem',
                                        }}
                                    >
                                        {colorError}
                                    </ErrorMessage>
                                )}
                            </div>
                        </form>
                    </ColorChange>
                    <SaveButton
                        onClick={handlePostCustomDesign}
                        btnsActive={btnsActive}
                    >
                        {customDesignLoading ? <ButtonSpinnerWhite /> : 'Save'}
                    </SaveButton>
                    <RestoreDefaultsButton
                        onClick={handleRestoreDesign}
                        btnsActive={btnsActive}
                    >
                        Restore Defaults
                    </RestoreDefaultsButton>
                </LeftSide>
                <RightSide>
                    <PreviewSite>
                        <PreviewBar companyColor={companyColor}>
                            <PreviewLogo src={companyLogo} />
                        </PreviewBar>
                        <PreviewScreen src={previewScreen} />
                    </PreviewSite>
                </RightSide>
            </Container>
        </>
    )
}

export default DesignFrame
