import produce from 'immer'
import { ResetPasswordAction } from '../actions'
import { ResetPasswordActionType } from '../action-types'

interface ResetPasswordState {
    resetPasswordLoading: boolean
    resetPasswordError: null | string
    resetPasswordSuccessMessage: null | string
}

const initialState: ResetPasswordState = {
    resetPasswordLoading: false,
    resetPasswordError: null,
    resetPasswordSuccessMessage: null,
}

const reducer = produce((state: ResetPasswordState = initialState, action: ResetPasswordAction) => {
    switch (action.type) {
        case ResetPasswordActionType.SEND_PASSWORD_RESET_LINK:
            state.resetPasswordLoading = false
            state.resetPasswordSuccessMessage = action.payload

            return state

        case ResetPasswordActionType.RESET_PASSWORD:
            state.resetPasswordLoading = false
            state.resetPasswordSuccessMessage = action.payload

            return state

        case ResetPasswordActionType.SET_RESET_PASSWORD_LOADING:
            state.resetPasswordLoading = true

            return state

        case ResetPasswordActionType.SET_RESET_PASSWORD_ERROR:
            state.resetPasswordError = action.payload
            state.resetPasswordLoading = false


            return state

        case ResetPasswordActionType.CLEAR_RESET_PASSWORD_SUCCESS_MESSAGE:
            state.resetPasswordSuccessMessage = null

            return state

        case ResetPasswordActionType.CLEAR_RESET_PASSWORD_ERROR:
            state.resetPasswordError = null

            return state

        default:
            return state
    }
})

export default reducer