import React, { FormEvent, useEffect, useState } from 'react'
import styled from 'styled-components'
import {
    ErrorMessage,
    FormField,
    FormInput50,
    InputFieldIcon,
    PrimaryLabel,
} from '../../../styled/formElements'
import { useParams } from 'react-router'
import { HeadingPrimary } from '../../../styled/typography'
import { NextButton } from '../../../reusable/buttons'
import { useActions, useTranslation, useTypedSelector } from '../../../../hooks'
import {
    checkPassword,
    checkPasswordConfirm,
} from '../../../../helpers/validation'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import EmailVerifiedModal from '../verify-email/modals/EmailVerifiedModal'
import PasswordValidationChecklist from '../../../PasswordValidationChecklist'

const eye = <FontAwesomeIcon icon={faEye} />
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />

const Container = styled.div`
    width: 35rem;
    margin: 0 auto;
    text-align: left;
    padding-top: 22rem;
`

const Form = styled.form`
    padding-top: 2rem;
    width: 100%;
    position: relative;
`

const PasswordText = styled.p`
    font-size: 1.2rem;
    color: ${({ theme }) => `${theme.primary}50`};
    margin-top: 0.5rem;
`

const NextButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 4rem;
`

const ServerError = styled.div`
    font-size: 1.2rem;
    color: ${({ theme }) => theme.red};
    position: absolute;
    top: 20rem;
`

const ResetPasswordPage: React.FC = () => {
    const { token } = useParams<{ token: string }>()

    const translation = useTranslation()

    const {
        resetPassword,
        clearResetPasswordError,
        clearResetPasswordSuccessMessage,
    } = useActions()

    const {
        resetPasswordLoading,
        resetPasswordError,
        resetPasswordSuccessMessage,
    } = useTypedSelector((state) => state.resetPassword)

    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')

    const [passwordError, setPasswordError] = useState('')
    const [passwordConfirmError, setPasswordConfirmError] = useState('')

    const checkValidation = (field: string) => {
        if (field === 'password') {
            setPasswordError(checkPassword(password, translation))
        } else if (field === 'confirm') {
            setPasswordConfirmError(
                checkPasswordConfirm(password, passwordConfirm, translation)
            )
        }
    }

    const [isFormValid, setIsFormValid] = useState(false)

    const handleFormValidation = (isvalid: boolean) => {
        setIsFormValid(isvalid)
    }

    const [showPassword, setShowPassword] = useState(true)
    const [showConfirmPassword, setShowConfirmPassword] = useState(true)

    const [isPasswordResetModalVisible, setIsPasswordResetModalVisible] =
        useState(false)

    const handleResetPassword = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        const payload = {
            password,
            password_confirmation: passwordConfirm,
            token,
        }

        await resetPassword(payload)
    }

    useEffect(() => {
        if (resetPasswordSuccessMessage) {
            setIsPasswordResetModalVisible(true)
        }
        // eslint-disable-next-line
    }, [resetPasswordSuccessMessage])

    // Cleanup
    useEffect(() => {
        return () => {
            clearResetPasswordError()
            clearResetPasswordSuccessMessage()
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (resetPasswordError) {
            setTimeout(clearResetPasswordError, 5000)
        }
        // eslint-disable-next-line
    }, [resetPasswordError])

    return (
        <>
            {isPasswordResetModalVisible && (
                <EmailVerifiedModal
                    headerText="Password has been reset"
                    taglineText="Your password has been reset, you can now use it to login."
                />
            )}
            <Container>
                <HeadingPrimary>Reset your password</HeadingPrimary>
                <PasswordText>
                    Your password must contain at least one lowercase letter,
                    one capital letter and one number
                </PasswordText>

                <Form onSubmit={(e) => handleResetPassword(e)}>
                    <FormField>
                        <PrimaryLabel isError={passwordError.length > 0}>
                            Password
                        </PrimaryLabel>
                        <FormInput50
                            isError={passwordError.length > 0}
                            type={showPassword ? 'text' : 'password'}
                            onBlur={() => checkValidation('password')}
                            onFocus={() => setPasswordError('')}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <InputFieldIcon
                            style={{
                                marginTop: '0.2rem',
                                top: '32%',
                            }}
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            {!showPassword ? eye : eyeSlash}
                        </InputFieldIcon>
                        {passwordError.length > 0 && (
                            <ErrorMessage>{passwordError}</ErrorMessage>
                        )}
                    </FormField>
                    <FormField>
                        <PrimaryLabel isError={passwordConfirmError.length > 0}>
                            Confirm password
                        </PrimaryLabel>
                        <FormInput50
                            isError={passwordConfirmError.length > 0}
                            type={showPassword ? 'text' : 'password'}
                            onBlur={() => checkValidation('confirm')}
                            onFocus={() => setPasswordConfirmError('')}
                            value={passwordConfirm}
                            onChange={(e) => setPasswordConfirm(e.target.value)}
                        />
                        <InputFieldIcon
                            style={{
                                marginTop: '0.2rem',
                                top: '32%',
                            }}
                            onClick={() =>
                                setShowConfirmPassword(!showConfirmPassword)
                            }
                        >
                            {!showConfirmPassword ? eye : eyeSlash}
                        </InputFieldIcon>
                        {passwordConfirmError.length > 0 && (
                            <ErrorMessage>{passwordConfirmError}</ErrorMessage>
                        )}
                    </FormField>
                    {resetPasswordError && (
                        <ServerError>{resetPasswordError}</ServerError>
                    )}
                    <PasswordValidationChecklist
                        password={password}
                        passwordConfirm={passwordConfirm}
                        isFormValidated={handleFormValidation}
                    />
                    <NextButtonContainer>
                        <NextButton
                            isLoading={resetPasswordLoading}
                            text="Send"
                            isActive={isFormValid}
                            width="133px"
                            icon={false}
                        />
                    </NextButtonContainer>
                </Form>
            </Container>
        </>
    )
}

export default ResetPasswordPage
