import styled from 'styled-components'
import { FiChevronDown } from 'react-icons/fi'
import { useEffect } from 'react'
import { useTheme } from '../../../../../hooks'

interface IsActiveProp {
    isActive: boolean
    isOpen?: boolean
}

const StyledDropdown = styled.div`
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.grey};
`

const DropdownHeader = styled.div`
    height: 6.4rem;
    display: flex;
    align-items: center;
    padding: 0 2rem;
    cursor: pointer;
`

const OptionNumberContainer = styled.div<IsActiveProp>`
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    border: ${({ theme, isActive }) =>
        !isActive && `1px solid ${theme.greyText}`};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 2rem;
    background: ${({ theme, isActive }) => isActive && theme.primary};
    transition: all 0.3s;
`

const OptionNumber = styled.p<IsActiveProp>`
    color: ${({ theme, isActive }) =>
        isActive ? theme.white : theme.greyText};
    font-size: 1.6rem;
    transition: color 0.3s;
`

const Option = styled.p<IsActiveProp>`
    color: ${({ theme, isActive }) =>
        isActive ? theme.primary : theme.greyText};

    font-size: 1.8rem;
`

const ArrowWrapper = styled.div<IsActiveProp>`
    &:first-child {
        color: ${({ theme, isActive }) =>
            isActive ? theme.primary : theme.greyText};
        fill: red;
        color: green;
    }
    transform: ${({ isOpen }) => !isOpen && 'rotate(-90deg)'};
    margin-left: auto;
`

const ArrowRightIcon = styled(FiChevronDown)`
    font-size: 2.2rem;
    transition: color 0.3s;
`

const DropdownBody = styled.div<IsActiveProp>`
    height: ${({ isActive }) => (isActive ? '42rem' : 0)};
    overflow: hidden;
    transition: height 0.3s;
`

interface DropdownProps {
    selectedOption: null | string
    handleSelectOption: (option: string) => void
    isActive: boolean
    option: string
    optionName: string
    optionNumber: number
    selectingFinishedCallback: () => void
    optionShouldClose: boolean
}

const Dropdown: React.FC<DropdownProps> = ({
    children,
    selectedOption,
    handleSelectOption,
    isActive,
    option,
    optionName,
    optionNumber,
    selectingFinishedCallback,
    optionShouldClose,
}) => {
    const theme = useTheme()

    useEffect(() => {
        optionShouldClose && selectingFinishedCallback()
        // eslint-disable-next-line
    }, [optionShouldClose])

    return (
        <StyledDropdown>
            <DropdownHeader onClick={() => handleSelectOption(option)}>
                <OptionNumberContainer
                    isActive={selectedOption === option || isActive}
                >
                    <OptionNumber
                        isActive={selectedOption === option || isActive}
                    >
                        {optionNumber}
                    </OptionNumber>
                </OptionNumberContainer>
                <Option isActive={selectedOption === option || isActive}>
                    {optionName}
                </Option>
                <ArrowWrapper
                    isActive={selectedOption === option || isActive}
                    isOpen={selectedOption === option}
                >
                    <ArrowRightIcon
                        color={
                            selectedOption === option
                                ? theme.primary
                                : theme.greyText
                        }
                    />
                </ArrowWrapper>
            </DropdownHeader>
            <DropdownBody isActive={selectedOption === option}>
                {children}
            </DropdownBody>
        </StyledDropdown>
    )
}

export default Dropdown
