export const checkQuestion = (question: string) => {
    if (question.length < 4) {
        return 'The question cannot be shorter'
    } else {
        return ''
    }
}

export const isValidQuestion = (question: string) => {
    if(question.length < 4) {
        return false
    }
    
    return true
}