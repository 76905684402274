import { useTranslation } from '../../../hooks'
import { Card, CardBody, CardCaseHeadingRow, CardCaseHeadingText, CardHeader } from '../../styled/cards'
import { CaseGrid4Items } from '../../styled/grids'
import OrganizationCase from './OrganizationCase'

export interface CaseInterface {
    id: number
    title: string
    description: string
    uuid: string
    category: string
    assigned_to: {
        name: string
        id: number
    }
    organization_name: string
    case_type: string
    is_closed: boolean
    status: string
    created_at: string
    closed_at: null | string
}

interface OrganizationCasesProps {
    title: string
    isAllClosed: boolean
    cases: Array<CaseInterface>
    height?: string
}
const OrganizationCases: React.FC<OrganizationCasesProps> = ({
    title,
    isAllClosed,
    cases,
    height,
}) => {
    const translation = useTranslation()

    const newCases = cases.filter(
        (currentCase) =>
            currentCase.status !== 'Denied' && currentCase.status !== 'Solved'
    )
    return (
        <Card>
            <CardHeader>{title}</CardHeader>
            <CardCaseHeadingRow>
                <CaseGrid4Items>
                    <CardCaseHeadingText>
                        {translation.singleClientViewPartner.headline}
                    </CardCaseHeadingText>
                    <CardCaseHeadingText>
                        {translation.singleClientViewPartner.date}
                    </CardCaseHeadingText>
                    <CardCaseHeadingText>
                        {translation.singleClientViewPartner.category}
                    </CardCaseHeadingText>
                    <CardCaseHeadingText>
                        {isAllClosed ? 'Closed' : 'Status'}
                    </CardCaseHeadingText>
                </CaseGrid4Items>
            </CardCaseHeadingRow>
            <CardBody maxHeight={height}>
                {newCases.map((currentCase) => (
                    <OrganizationCase
                        key={currentCase.id}
                        isAllClosed={isAllClosed}
                        currentCase={currentCase}
                    />
                ))}
            </CardBody>
        </Card>
    )
}

export default OrganizationCases
