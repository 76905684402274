import { useEffect, useState } from 'react'
import AllCases from './frames/AllCases'
import ClosedCases from './frames/ClosedCases'

const CasesPage: React.FC = () => {
    const [currentFrameNumber, setCurrentFrameNumber] = useState(1)
    const [currentFrame, setCurrentFrame] = useState(
        <AllCases setCurrentFrameNumber={setCurrentFrameNumber} />
    )

    useEffect(() => {
        if (currentFrameNumber === 1) {
            setCurrentFrame(
                <AllCases setCurrentFrameNumber={setCurrentFrameNumber} />
            )
        } else if (currentFrameNumber === 2) {
            setCurrentFrame(
                <ClosedCases setCurrentFrameNumber={setCurrentFrameNumber} />
            )
        }
    }, [currentFrameNumber])
    return <>{currentFrame}</>
}

export default CasesPage
