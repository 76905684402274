import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Card } from '../../../../../styled/cards'
import DescriptionFrame from './frames/DescriptionFrame'
import FilesFrame from './frames/FilesFrame'
import HistoryLogFrame from './frames/HistoryLogFrame'
import SeverityScoreFrame from './frames/SeverityScoreFrame'
import CommentFrame from './frames/CommentFrame'
import {
    FiAlertCircle,
    FiClock,
    FiFileText,
    FiMessageSquare,
    FiPaperclip,
    FiUser,
} from 'react-icons/fi'
import { useTranslation, useTypedSelector } from '../../../../../../hooks'
import PreferredContactFrame from './frames/PreferredContactFrame'

const BoxContainer = styled.div`
    height: 100%;
`

const FrameContainer = styled.div`
    min-height: 50rem;
    height: 50vh;
    overflow-y: auto;
`

interface IsActiveProp {
    isActive: boolean
}

const Header = styled.div`
    height: 5rem;
    border-bottom: 1px solid ${({ theme }) => theme.grey};
    display: flex;
`

const HeaderLink = styled.div<IsActiveProp>`
    font-size: 1.6rem;
    color: ${({ theme, isActive }) =>
        isActive ? theme.primary : theme.greyText};
    border-top: ${({ isActive }) => isActive && '2px solid transparent'};
    border-bottom: ${({ theme, isActive }) =>
        isActive && `2px solid ${theme.blue}`};
    height: 100%;
    display: flex;
    align-items: center;
    cursor: ${({ isActive }) => (isActive ? 'not-allowed' : 'pointer')};
    margin-left: 2rem;
    font-weight: 500;
    gap: 1rem;

    :not(:first-child) {
        margin-left: 4rem;
    }
`

const DescriptionIcon = styled(FiFileText)<IsActiveProp>`
    font-size: 1.6rem;
    color: ${({ theme, isActive }) =>
        isActive ? theme.primary : theme.greyText};

    @media screen and (max-width: 480px) {
        display: none;
    }
`

const FilesIcon = styled(FiPaperclip)<IsActiveProp>`
    font-size: 1.6rem;
    color: ${({ theme, isActive }) =>
        isActive ? theme.primary : theme.greyText};
    @media screen and (max-width: 480px) {
        display: none;
    }
`

const HistoryLogIcon = styled(FiClock)<IsActiveProp>`
    font-size: 1.6rem;
    color: ${({ theme, isActive }) =>
        isActive ? theme.primary : theme.greyText};
    @media screen and (max-width: 480px) {
        display: none;
    }
`

const ScoreIcon = styled(FiAlertCircle)<IsActiveProp>`
    font-size: 1.6rem;
    color: ${({ theme, isActive }) =>
        isActive ? theme.primary : theme.greyText};
`

const CommentIcon = styled(FiMessageSquare)<IsActiveProp>`
    font-size: 1.6rem;
    color: ${({ theme, isActive }) =>
        isActive ? theme.primary : theme.greyText};
`

const ContactIcon = styled(FiUser)<IsActiveProp>`
    font-size: 1.6rem;
    color: ${({ theme, isActive }) =>
        isActive ? theme.primary : theme.greyText};
`

interface DescriptionFilesHistoryBoxProps {
    description: string
    files: any[]
}

const DescriptionFilesHistoryBox: React.FC<DescriptionFilesHistoryBoxProps> = ({
    description,
    files,
}) => {
    const translation = useTranslation()

    const { openReport } = useTypedSelector(
        (state) => state.caseworkerOpenReport
    )

    const [frame, setFrame] = useState<
        'description' | 'files' | 'log' | 'score' | 'comment' | 'contact'
    >('description')

    const [currentFrameComponent, setCurrentFrameComponent] = useState<any>(
        <DescriptionFrame description={description} />
    )

    useEffect(() => {
        if (frame === 'description') {
            setCurrentFrameComponent(
                <DescriptionFrame description={description} />
            )
        } else if (frame === 'files') {
            setCurrentFrameComponent(<FilesFrame files={files} />)
        } else if (frame === 'log') {
            setCurrentFrameComponent(<HistoryLogFrame />)
        } else if (frame === 'score') {
            setCurrentFrameComponent(<SeverityScoreFrame />)
        } else if (frame === 'comment') {
            setCurrentFrameComponent(<CommentFrame />)
        } else if (frame === 'contact') {
            setCurrentFrameComponent(
                <PreferredContactFrame
                    name={`${openReport?.preferred_contact.first_name} ${openReport?.preferred_contact.last_name}`}
                />
            )
        }

        // eslint-disable-next-line
    }, [frame, files, description])

    const handleSetFrame = (
        newFrame:
            | 'description'
            | 'files'
            | 'log'
            | 'score'
            | 'comment'
            | 'contact'
    ) => {
        if (frame !== newFrame) {
            setFrame(newFrame)
        }
    }

    return (
        <BoxContainer>
            <Card>
                <Header>
                    <HeaderLink
                        onClick={() => handleSetFrame('description')}
                        isActive={frame === 'description'}
                    >
                        <DescriptionIcon isActive={frame === 'description'} />

                        {translation.caseworkerOpenReport.caseDescription}
                    </HeaderLink>
                    <HeaderLink
                        onClick={() => handleSetFrame('files')}
                        isActive={frame === 'files'}
                    >
                        <FilesIcon isActive={frame === 'files'} />

                        {translation.caseworkerOpenReport.files}
                    </HeaderLink>
                    <HeaderLink
                        onClick={() => handleSetFrame('log')}
                        isActive={frame === 'log'}
                    >
                        <HistoryLogIcon isActive={frame === 'log'} />

                        {translation.caseworkerOpenReport.historyLog}
                    </HeaderLink>
                    {openReport?.preferred_contact && (
                        <HeaderLink
                            onClick={() => handleSetFrame('contact')}
                            isActive={frame === 'contact'}
                        >
                            <ContactIcon isActive={frame === 'contact'} />
                            {translation.caseworkerOpenReport.preferredContact}
                        </HeaderLink>
                    )}
                    {openReport?.forwarded_at && (
                        <>
                            <HeaderLink
                                onClick={() => handleSetFrame('score')}
                                isActive={frame === 'score'}
                            >
                                <ScoreIcon isActive={frame === 'score'} />

                                {translation.caseworkerOpenReport.severityScore}
                            </HeaderLink>
                            <HeaderLink
                                onClick={() => handleSetFrame('comment')}
                                isActive={frame === 'comment'}
                            >
                                <CommentIcon isActive={frame === 'comment'} />

                                {translation.caseworkerOpenReport.comment}
                            </HeaderLink>
                        </>
                    )}
                </Header>
                <FrameContainer>{currentFrameComponent}</FrameContainer>
            </Card>
        </BoxContainer>
    )
}

export default DescriptionFilesHistoryBox
