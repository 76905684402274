import styled from 'styled-components'

export const CaseGrid5Items = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr 4fr 2fr 2fr;
    align-items: center;
    height: 100%;
`

export const ClientGrid5Items = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    align-items: center;
    height: 100%;
`

export const CaseGrid3Items = styled.div`
    display: grid;
    align-items: center;
    height: 100%;
    grid-template-columns: 3fr 2fr 1.4fr;

    @media screen and (max-width: 600px) {
        grid-template-columns: 3fr 3fr 1.4fr;
    }
`

export const CaseGrid6Items = styled.div`
    display: grid;
    grid-template-columns: 3fr 3fr 2fr 2fr 3fr 3fr;
    align-items: center;
    height: 100%;
`

export const CaseGrid4Items = styled.div`
    display: grid;
    grid-template-columns: 3fr 3fr 2fr 2fr;
    align-items: center;
    height: 100%;
`
