import { useState, useEffect } from 'react'
import { AiFillCheckCircle } from 'react-icons/ai'
import { useTranslation } from '../hooks'
import {
    isValidPassword,
    isValidPasswordConfirm,
    passwordLengthCheck,
    passwordLowerCaseCheck,
    passwordNumberCheck,
    passwordsMatchCheck,
    passwordSpecialCharCheck,
    passwordUppercaseCheck,
} from '../helpers/validation'
import styled from 'styled-components'
import { PasswordValidationCheckInterface } from '../ts/interfaces/PasswordValidationCheckInterface'

interface CheckedStyledProps {
    isValidated: boolean
}

const CheckStyled = styled(AiFillCheckCircle)<CheckedStyledProps>`
    font-size: 2rem;
    margin-right: 1rem;
    color: ${({ isValidated }) => (isValidated ? 'green' : 'gray')};
`
const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    p {
        color: gray;
        font-size: 1.2rem;
    }
`

const PasswordValidationChecklist: React.FC<
    PasswordValidationCheckInterface
> = ({ password, passwordConfirm, isFormValidated }) => {
    const translation = useTranslation()
    const [isLongEnough, setIsLongEnough] = useState<boolean>(false)
    const [hasUppercaseLetter, setHasUppercaseLetter] = useState<boolean>(false)
    const [hasLowercaseLetter, setHasLowercaseLetter] = useState<boolean>(false)
    const [hasNumber, setHasNumber] = useState<boolean>(false)
    const [hasSpecialChar, setHasSpecialChar] = useState<boolean>(false)
    const [passwordsMatch, setPaswordsMatch] = useState<boolean>(false)

    useEffect(() => {
        setIsLongEnough(passwordLengthCheck(password))
        setHasUppercaseLetter(passwordUppercaseCheck(password))
        setHasLowercaseLetter(passwordLowerCaseCheck(password))
        setHasNumber(passwordNumberCheck(password))
        setHasSpecialChar(passwordSpecialCharCheck(password))
        if (passwordConfirm.length >= 8) {
            setPaswordsMatch(passwordsMatchCheck(password, passwordConfirm))
        } else {
            setPaswordsMatch(false)
        }
        if (
            isValidPassword(password) &&
            isValidPasswordConfirm(password, passwordConfirm)
        ) {
            isFormValidated(true)
        } else {
            isFormValidated(false)
        }
    }, [password, passwordConfirm, isFormValidated])

    return (
        <div>
            <Row>
                <CheckStyled isValidated={isLongEnough} />
                <p>{translation.validation.isLongEnough}</p>
            </Row>
            <Row>
                <CheckStyled isValidated={hasUppercaseLetter} />
                <p>{translation.validation.hasUpperCaseLetter}</p>
            </Row>
            <Row>
                <CheckStyled isValidated={hasLowercaseLetter} />
                <p>{translation.validation.hasLowerCaseLetter}</p>
            </Row>
            <Row>
                <CheckStyled isValidated={hasNumber} />
                <p>{translation.validation.hasNumber}</p>
            </Row>
            <Row>
                <CheckStyled isValidated={hasSpecialChar} />
                <p>{translation.validation.hasSpecialCharacter}</p>
            </Row>
            <Row>
                <CheckStyled isValidated={passwordsMatch} />
                <p>{translation.validation.passwordsMatch}</p>
            </Row>
        </div>
    )
}

export default PasswordValidationChecklist
