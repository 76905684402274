import Translation from '../../state/state-types/TranslationStateType'

export const isValidPassword = (password: string) => {
    if (password.length < 8) {
        return false
    } else if (!password.match(/[A-Z]/)) {
        return false
    } else if (password.search(/[a-z]/i) < 0) {
        return false
    } else if (
        // eslint-disable-next-line
        password.search(/[-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]/) < 0
    ) {
        return false
    } else if (password.search(/[0-9]/) < 0) {
        return false
    }

    return true
}

export const checkPassword = (password: string, translation: Translation) => {
    if (password.length < 8) {
        return translation.validation.passwordMustBeAtLeast
    } else if (!password.match(/[A-Z]/)) {
        return translation.validation
            .yourPasswordMustContainerAtLeastOneUppercase
    } else if (!password.match(/[a-z]/)) {
        return translation.validation
            .yourPasswordMustContainerAtLeastOneLowercase
    } else if (
        //eslint-disable-next-line
        password.search(/[-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]/) < 0
    ) {
        return translation.validation.yourPasswordMustContainerAtLeastOneSymbol
    } else if (password.search(/[0-9]/) < 0) {
        return translation.validation.yourPasswordMustContainerAtLeastOneNumber
    }

    return ''
}

export const passwordLengthCheck = (password: string) => password.length > 8

export const passwordUppercaseCheck = (password: string) =>
    password.match(/[A-Z]/) !== null

export const passwordLowerCaseCheck = (password: string) =>
    password.match(/[a-z]/) !== null

export const passwordNumberCheck = (password: string) =>
    password.match(/[0-9]/) !== null

export const passwordSpecialCharCheck = (password: string) =>
    //eslint-disable-next-line
    password.match(/[-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]/) !== null
