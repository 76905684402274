import axios from 'axios'
import { ResetPasswordActionType } from '../action-types'
import config from '../../config'
import { Dispatch } from 'redux'


const { apiUrl } = config

export const sendPasswordResetLink = (email: string) => async (dispatch: Dispatch) => {
    dispatch(setResetPasswordLoading())

    try {
        const res: any = await axios.post(`${apiUrl}/v1/send-reset-password-email`, { email })

        if (res.status !== 200) {
            return dispatch(setResetPasswordError(res.data.data.error))
        }

        dispatch({
            type: ResetPasswordActionType.SEND_PASSWORD_RESET_LINK,
            payload: res.data.data.message,
        })
    } catch (error) {
        return dispatch(setResetPasswordError('Something went wrong. Please try sending again'))
    }
}

export const resetPassword = (payload: any) => async (dispatch: Dispatch) => {
    dispatch(setResetPasswordLoading())

    try {
        const res: any = await axios.post(`${apiUrl}/v1/reset-password`, payload)

        if (res.status !== 200) {
            return dispatch(setResetPasswordError(res.data.data.error))
        }

        dispatch({
            type: ResetPasswordActionType.SEND_PASSWORD_RESET_LINK,
            payload: res.data.data.message,
        })
    } catch (error: any) {
        return dispatch(setResetPasswordError('Something went wrong. Please try sending again'))
    }
}

export const setResetPasswordLoading = () => ({
    type: ResetPasswordActionType.SET_RESET_PASSWORD_LOADING,
})

export const setResetPasswordError = (error: string) => ({
    type: ResetPasswordActionType.SET_RESET_PASSWORD_ERROR,
    payload: error,
})

export const clearResetPasswordSuccessMessage = () => ({
    type: ResetPasswordActionType.CLEAR_RESET_PASSWORD_SUCCESS_MESSAGE,
})

export const clearResetPasswordError = () => ({
    type: ResetPasswordActionType.CLEAR_RESET_PASSWORD_ERROR,
})