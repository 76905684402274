import styled from 'styled-components'
import _ from 'lodash'
import { useTypedSelector } from '../../../hooks'

const StatisticsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(12, 1fr);
`

interface CaseBoxProps {
    position: number
    type: string
    isLastOne?: boolean
}

const StatisticColumn = styled.div``

const CaseBox = styled.div<CaseBoxProps>`
    width: 60%;
    margin-left: 20%;
    position: relative;

    ::after {
        content: '';
        height: 3.2rem;
        position: absolute;
        bottom: ${({ position }) => position * 3.2 + 'rem'};
        width: 100%;
        ${({ type }) =>
            type === 'new'
                ? `
    background: #849BB5;
  `
                : type === 'active'
                ? `background: #B0C1C7;`
                : `background: #3D4B60;`}

        ${({ isLastOne }) =>
            isLastOne &&
            `
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
    `}
    }
`

const ReportStatisticsGraph: React.FC = () => {
    const { dashboardStatistics } = useTypedSelector(
        (state) => state.caseStatistic
    )

    return (
        <>
            {dashboardStatistics && (
                <StatisticsContainer>
                    {_.map(
                        dashboardStatistics.months,
                        (statistic: any, index: number) => (
                            <StatisticColumn key={index}>
                                {_.range(0, statistic.new_cases, 1).map(
                                    (currentCase: number, index) => (
                                        <CaseBox
                                            key={index}
                                            position={currentCase}
                                            type="new"
                                        />
                                    )
                                )}
                                {_.range(
                                    statistic.new_cases,
                                    statistic.new_cases +
                                        statistic.active_cases,
                                    1
                                ).map((currentCase: number, index) => (
                                    <CaseBox
                                        key={index}
                                        position={currentCase}
                                        type="active"
                                    />
                                ))}
                                {_.range(
                                    statistic.new_cases +
                                        statistic.active_cases,
                                    statistic.new_cases +
                                        statistic.active_cases +
                                        statistic.forwarded_cases,
                                    1
                                ).map((currentCase: number, index) => (
                                    <CaseBox
                                        key={index}
                                        position={currentCase}
                                        type="closed"
                                        isLastOne={
                                            currentCase ===
                                            statistic.new_cases +
                                                statistic.active_cases +
                                                statistic.forwarded_cases -
                                                1
                                        }
                                    />
                                ))}
                                {_.range(
                                    statistic.new_cases +
                                        statistic.active_cases,
                                    statistic.new_cases +
                                        statistic.active_cases +
                                        statistic.forwarded_cases +
                                        statistic.closed_cases,
                                    1
                                ).map((currentCase: number, index) => (
                                    <CaseBox
                                        key={index}
                                        position={currentCase}
                                        type="closed"
                                        isLastOne={
                                            currentCase ===
                                            statistic.new_cases +
                                                statistic.active_cases +
                                                statistic.forwarded_cases +
                                                statistic.closed_cases -
                                                1
                                        }
                                    />
                                ))}
                            </StatisticColumn>
                        )
                    )}
                </StatisticsContainer>
            )}
        </>
    )
}

export default ReportStatisticsGraph
