import styled from 'styled-components'
import { HeadingSecondary } from '../../../../styled/typography'
import Cases from '../../../../reusable/cases/Cases'
import CasesFilterBox from '../../../../reusable/filters/CasesFilterBox'
import {
    useActions,
    useTranslation,
    useTypedSelector,
} from '../../../../../hooks'
import { useEffect } from 'react'

const Container = styled.div`
    width: 100%;
    padding: 4rem;
`

const FlexContainer = styled.div`
    display: flex;
    gap: 2.2rem;
    padding-top: 2rem;

    @media screen and (max-width: 480px) {
        flex-direction: column;
    }
`

const CasesBox = styled.div`
    width: 85%;

    @media (max-width: 1500px) {
        width: 75%;
    }

    @media screen and (max-width: 480px) {
        width: 100%;
    }
`

const FilterBox = styled.div`
    height: 36.6rem;
    width: 20%;

    @media (max-width: 1500px) {
        width: 25%;
    }

    @media screen and (max-width: 480px) {
        width: 100%;
    }
`

interface AllcasesProps {
    setCurrentFrameNumber: (frameNumber: number) => void
}

const AllCases: React.FC<AllcasesProps> = ({ setCurrentFrameNumber }) => {
    const {
        allReportsFiltered,
        allReportsFilteredCaseType,
        allReportsFilteredStatus,
        allReportsSearchString,
        organization,
    } = useTypedSelector((state) => state.caseworker)

    const {
        getAllReports,
        searchAllReports,
        addStatusFilter,
        addCaseTypeFilter,
        removeAllFilters,
    } = useActions()

    useEffect(() => {
        organization && getAllReports(organization.id)
        // eslint-disable-next-line
    }, [organization])

    const translation = useTranslation()

    const handleSearch = (search: string) => {
        searchAllReports(search)
    }

    const handleClear = () => {
        searchAllReports('')
    }

    const isActiveFilters = () => {
        return !!(
            allReportsFilteredCaseType !== 'all' ||
            allReportsFilteredStatus ||
            allReportsSearchString?.length > 0
        )
    }

    return (
        <Container>
            <HeadingSecondary
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                {translation.casesPartner.headlineCases}
            </HeadingSecondary>
            <FlexContainer>
                {allReportsFiltered && (
                    <>
                        <CasesBox>
                            <Cases
                                title={translation.casesPartner.allCases}
                                cases={allReportsFiltered}
                                isAllClosed={false}
                                height="27.2rem"
                            />
                        </CasesBox>

                        <FilterBox>
                            <CasesFilterBox
                                onBottomLinkClick={() =>
                                    setCurrentFrameNumber(2)
                                }
                                handleSearchChange={handleSearch}
                                handleStatusFilterClick={addStatusFilter}
                                handleCaseTypeFilterClick={addCaseTypeFilter}
                                handleClearSearch={handleClear}
                                showRemoveFilterBtn={isActiveFilters()}
                                handleRemoveFiltersClick={removeAllFilters}
                                searchText={allReportsSearchString}
                            />
                        </FilterBox>
                    </>
                )}
            </FlexContainer>
        </Container>
    )
}

export default AllCases
