import React, { useState } from 'react'
import styled from 'styled-components'
import { IoMdInformationCircleOutline } from 'react-icons/io'

const InformationContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    position: relative;
    height: 1.8rem;
`

const NoteContainer = styled.div<{ isOpen: boolean }>`
    position: absolute;
    padding: 1rem;
    gap: 0.675rem;
    width: 18rem;
    height: 12rem;
    text-align: left;
    border-radius: 1rem;
    background: ${({ theme }) => theme.white};
    top: 1.8rem;
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    overflow: auto;
    border: 1px solid ${({ theme }) => theme.grey};
`

const InformationIcon = styled(IoMdInformationCircleOutline)`
    color: ${({ theme }) => theme.lineGrey};
    font-size: 1.8rem;
`

const GreyFont = styled.h4`
    font-size: 1.125rem;
    color: ${({ theme }) => theme.lineGrey};
`

const Description = styled.h4`
    font-size: 1.35rem;
`

interface TooltipProps {
    note: string | null
}

const NewCaseContactTooltip: React.FC<TooltipProps> = ({ note }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)

    return (
        <InformationContainer onMouseLeave={() => setIsOpen(false)}>
            {note && <InformationIcon
                onMouseOver={() => {
                    setIsOpen(true)
                }}
            />}

            <NoteContainer isOpen={isOpen}>
                <GreyFont>Note</GreyFont>
                {note && <Description>{note}</Description>}
            </NoteContainer>
        </InformationContainer>
    )
}

export default NewCaseContactTooltip
