import _ from 'lodash'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
    checkEmail,
    checkFirstName,
    checkLastName,
    checkPhone,
    isValidEmail,
    isValidName,
    isValidPhone,
} from '../../../../helpers/validation'
import { useTranslation, useTypedSelector } from '../../../../hooks'
import { PersonalInformationInterface } from '../../../../ts/interfaces'
import Spinner from '../../../layout/spinner/Spinner'
import {
    ErrorMessage,
    FormFieldSmallBetween,
    FormInput40,
    PrimaryLabel,
} from '../../../styled/formElements'
import { HeadingPrimary } from '../../../styled/typography'
import { NextButton } from '../../buttons'
import CountryCodeDropdown from '../../form/CountryCodeDropdown'

const Container = styled.div`
    width: 100%;
    margin: 0 auto;
    padding-top: 12rem;
    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: center;
`

const Form = styled.form`
    padding-top: 4rem;
    max-width: 45rem;
    width: 30%;
    padding-bottom: 6rem;
    text-align: left;
`

const FlexInputContainer = styled.div`
    display: flex;
    gap: 16px;
`

const PhoneInputContainer = styled.div`
    width: 75%;
`

// const PasswordInformation = styled.p`
//     font-size: 1rem;
//     color: ${({ theme }) => theme.primary};
//     opacity: 0.5;
//     margin-left: 0.2rem;
//     padding-top: 0.4rem;
// `

const NextButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 4rem;
`

const SpinnerContainer = styled.div`
    padding-top: 6rem;
`

const ErrorMessageSmall = styled(ErrorMessage)`
    bottom: 0.2rem;
`

interface PersonalInformationFormProps {
    personalInformation: PersonalInformationInterface
    updatePersonalInformation: (key: string, value: string) => void
    setCurrentPageNumber: (pageNumber: number) => void
    handleRegister: any // TODO replace with propper type
    updatePersonalInformationCountry: (country: any) => void
}

const PersonalInformationForm: React.FC<PersonalInformationFormProps> = ({
    personalInformation,
    updatePersonalInformation,
    setCurrentPageNumber,
    handleRegister,
    updatePersonalInformationCountry,
}) => {
    const translation = useTranslation()
    const { authLoading, validationErrors, authError } = useTypedSelector(
        (state) => state.auth
    )

    const [formValidated, setFormValidated] = useState(false)

    useEffect(() => {
        if (
            !isValidName(registererFirstName) ||
            !isValidName(registererLastName) ||
            !isValidPhone(`${registererCountryCode + registererPhone}`) ||
            !isValidEmail(registererEmail)
            // !isValidPassword(password) ||
            // !isValidPasswordConfirm(password, password2)
        ) {
            return setFormValidated(false)
        }

        setFormValidated(true)
        // eslint-disable-next-line
    }, [personalInformation])

    const {
        registererFirstName,
        registererLastName,
        registererCountryCode,
        registererPhone,
        registererEmail,
        // password,
        // password2,
    } = personalInformation

    const [formFields, setFormFields] = useState({
        registererFirstName: {
            label: translation.onboardingFlow.firstName,
            placeholder: translation.onboardingFlow.enterFirstName,
            type: 'text',
            key: 'registererFirstName',
            errorMessage: '',
            success: false,
        },
        registererLastName: {
            label: translation.onboardingFlow.lastName,
            placeholder: translation.onboardingFlow.enterLastName,
            type: 'text',
            key: 'registererLastName',
            errorMessage: '',
            success: false,
        },
        registererPhone: {
            label: translation.onboardingFlow.phone,
            placeholder: translation.onboardingFlow.enterPhone,
            type: 'text',
            key: 'registererPhone',
            errorMessage: '',
            success: false,
        },
        registererEmail: {
            label: translation.onboardingFlow.workEmail,
            placeholder: translation.onboardingFlow.enterWorkEmail,
            type: 'email',
            key: 'registererEmail',
            errorMessage: '',
            success: false,
        },
        // password: {
        //     label: translation.onboardingFlow.password,
        //     placeholder: translation.onboardingFlow.enterPassword,
        //     type: 'password',
        //     key: 'password',
        //     errorMessage: '',
        //     success: false,
        // },
        // password2: {
        //     label: translation.onboardingFlow.confirmPassword,
        //     placeholder: translation.onboardingFlow.confirmYourPassword,
        //     type: 'password',
        //     key: 'password2',
        //     errorMessage: '',
        //     success: false,
        // },
    })

    useEffect(() => {
        if (validationErrors) {
            if (validationErrors['registerer.email']) {
                setFormFields((prevState) => ({
                    ...prevState,
                    registererEmail: {
                        ...formFields.registererEmail,
                        errorMessage: validationErrors['registerer.email'][0],
                    },
                }))
            }

            if (validationErrors['registerer.phone']) {
                setFormFields((prevState) => ({
                    ...prevState,
                    registererPhone: {
                        ...formFields.registererPhone,
                        errorMessage: validationErrors['registerer.phone'][0],
                    },
                }))
            }
        }
        // eslint-disable-next-line
    }, [validationErrors])

    const setError = (
        key: string,
        errorMsg: string,
        onFocus: boolean = false
    ) => {
        const success = errorMsg.length || onFocus ? false : true

        _.forOwn(formFields, (field) => {
            if (field.key === key) {
                setFormFields({
                    ...formFields,
                    [key]: {
                        // @ts-ignore
                        ...formFields[`${key}`],
                        errorMessage: errorMsg,
                        success: success,
                    },
                })
            }
        })
    }

    const validationBlurCallback = (key: string) => {
        if (key === 'registererFirstName') {
            setError(
                'registererFirstName',
                checkFirstName(registererFirstName, translation)
            )
        } else if (key === 'registererLastName') {
            setError(
                'registererLastName',
                checkLastName(registererLastName, translation)
            )
        } else if (key === 'registererPhone') {
            setError(
                'registererPhone',
                checkPhone(
                    `${registererCountryCode + registererPhone}`,
                    translation
                )
            )
        } else if (key === 'registererEmail') {
            setError(
                'registererEmail',
                checkEmail(registererEmail, translation)
            )
        }
        // else if (key === 'password') {
        //     setError('password', checkPassword(password))
        // } else if (key === 'password2') {
        //     setError('password2', checkPasswordConfirm(password, password2))
        // }
    }

    const validationOnFocusCallback = (key: string) => {
        setError(key, '', true) // Clear the error
    }

    const handleOnChange = (key: string, value: string) => {
        updatePersonalInformation(key, value)
    }

    useEffect(() => {
        setError(
            'phone',
            checkPhone(
                `${registererCountryCode + registererPhone}`,
                translation
            )
        )

        // eslint-disable-next-line
    }, [registererCountryCode])

    const localHandleRegister = async (e: any) => {
        e.preventDefault()
        if (formValidated) {
            const success = await handleRegister()

            if (success) {
                setCurrentPageNumber(5)
            }
        }
    }

    return (
        <Container>
            <HeadingPrimary>
                {translation.onboardingFlow.personalInformation}
            </HeadingPrimary>
            {authLoading ? (
                <SpinnerContainer>
                    <Spinner />
                </SpinnerContainer>
            ) : (
                <Form onSubmit={(e) => localHandleRegister(e)}>
                    {Object.values(formFields).map((field) =>
                        field.key !== 'registererPhone' ? (
                            <FormFieldSmallBetween key={field.key}>
                                <PrimaryLabel
                                    isError={
                                        field.errorMessage.length ? true : false
                                    }
                                    isSuccess={field.success}
                                >
                                    {field.label}
                                </PrimaryLabel>
                                <FormInput40
                                    onBlur={() =>
                                        validationBlurCallback(field.key)
                                    }
                                    onFocus={() =>
                                        validationOnFocusCallback(field.key)
                                    }
                                    className={
                                        field.errorMessage
                                            ? 'border-error'
                                            : field.success
                                            ? 'border-success'
                                            : ''
                                    }
                                    placeholder={field.placeholder}
                                    type={field.type}
                                    // @ts-ignore
                                    value={personalInformation[field.key]}
                                    onChange={(e) =>
                                        handleOnChange(
                                            field.key,
                                            e.target.value
                                        )
                                    }
                                />
                                {field.errorMessage && (
                                    <ErrorMessageSmall>
                                        {field.errorMessage}
                                    </ErrorMessageSmall>
                                )}
                                {/* {field.key === 'password' && (
                                <PasswordInformation>
                                    {
                                        translation.onboardingFlow
                                            .yourPasswordMustInclude
                                    }
                                </PasswordInformation>
                            )} */}
                            </FormFieldSmallBetween>
                        ) : (
                            <FormFieldSmallBetween key={field.key}>
                                <PrimaryLabel>{field.label}</PrimaryLabel>
                                <FlexInputContainer>
                                    <CountryCodeDropdown
                                        error={field.errorMessage.length > 1}
                                        success={field.success}
                                        selectDropdownCB={(country: any) =>
                                            updatePersonalInformationCountry(
                                                country
                                            )
                                        }
                                    />
                                    <PhoneInputContainer>
                                        <FormInput40
                                            onBlur={() =>
                                                validationBlurCallback(
                                                    field.key
                                                )
                                            }
                                            onFocus={() =>
                                                validationOnFocusCallback(
                                                    field.key
                                                )
                                            }
                                            className={
                                                field.errorMessage
                                                    ? 'border-error'
                                                    : field.success
                                                    ? 'border-success'
                                                    : ''
                                            }
                                            placeholder={field.placeholder}
                                            type={field.type}
                                            // @ts-ignore
                                            value={
                                                personalInformation[field.key]
                                            }
                                            onChange={(e) =>
                                                handleOnChange(
                                                    field.key,
                                                    e.target.value
                                                )
                                            }
                                        />
                                        {field.errorMessage && (
                                            <ErrorMessageSmall>
                                                {field.errorMessage}
                                            </ErrorMessageSmall>
                                        )}
                                    </PhoneInputContainer>
                                </FlexInputContainer>
                            </FormFieldSmallBetween>
                        )
                    )}
                    {authError && (
                        <ErrorMessageSmall>{authError}</ErrorMessageSmall>
                    )}
                    <NextButtonContainer>
                        <NextButton isActive={formValidated} />
                    </NextButtonContainer>
                </Form>
            )}
        </Container>
    )
}

export default PersonalInformationForm
