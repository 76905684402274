import axios from 'axios'
import config from '../../config'
import reportsTransformer from '../transformers/ReportsTransformer'
import {
    ContactUserActionType,
    CaseworkerActionType,
    OrganizationActionType,
} from '../action-types'
import { Dispatch } from 'redux'

const { apiUrl } = config

export const contactGetSelfReports = () => async (dispatch: any) => {
    dispatch({ type: ContactUserActionType.CONTACT_USER_LOADING })
    try {
        const res = await axios.get(
            `${apiUrl}/v1/me/incidents?with=organization,assigned`
        )

        dispatch({
            type: CaseworkerActionType.GET_ALL_REPORTS,
            payload: reportsTransformer(res.data.data),
        })

        return dispatch({
            type: ContactUserActionType.CONTACT_GET_SELF_REPORTS,
            payload: reportsTransformer(res.data.data),
        })
    } catch (error) {
        console.log('error', error)
    }
}

export const contactGetOrganizations =
    (contactId: number) => async (dispatch: Dispatch) => {
        const res = await axios.get(
            `${apiUrl}/v1/contacts/${contactId}/organizations`
        )

        if (res.status !== 200) {
            return
        }

        dispatch({
            type: OrganizationActionType.GET_ORGANIZATION_INFORMATION,
            payload: res.data.data[0],
        })
    }
