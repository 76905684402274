import produce from 'immer'
import { OrganizationAction } from '../actions'
import { OrganizationActionType } from '../action-types'

interface OrganizationState {
    organization: any
}

const initialState: OrganizationState = {
    organization: null,
}

const reducer = produce(
    (state: OrganizationState = initialState, action: OrganizationAction) => {
        switch (action.type) {
            case OrganizationActionType.GET_ORGANIZATION_INFORMATION:
                state.organization = action.payload

                return state
            default:
                return state
        }
    }
)

export default reducer
