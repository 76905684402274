import styled from 'styled-components'
import { ReporterInterface } from '../../../../../../ts/interfaces'
import { Card, CardHeader } from '../../../../../styled/cards'
import { useTranslation } from '../../../../../../hooks'
// import { CaseworkerActionType, CaseworkerOpenReportActionType } from '../../../../../../state/action-types'
// import Case from '../../../../../reusable/cases/Case'
// import SingleCase from '../../SingleCase'
// import ReporterInfoAnonymModal from './ReporterInfoAnonymModal'
// import {BsFillPersonFill} from 'react-icons/bs'

const ModalContainer = styled.div`
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);

    position: fixed;
    top: 0;
    left: 0;
    z-index: 500;
`

const Modal = styled.div`
    height: 40rem;
    width: 55.6rem;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 501;
    text-align: center;

    @media screen and (max-width: 480px) {
        width: 35rem;
    }
`

const CancelButton = styled.div`
    font-size: 1.4rem;
    color: ${({ theme }) => `${theme.primary}50`};
    cursor: pointer;
    padding: 1rem;
`

const Divider = styled.div`
    margin-top: 4rem;
    margin-bottom: 2.5rem;
`

const TextRegular = styled.p`
    font-size: 1.4rem;
    color: ${({ theme }) => `${theme.primary}80`};
`

const TextMedium = styled.p`
    color: ${({ theme }) => theme.primary};
    font-size: 1.4rem;
    font-weight: 600;
    margin-top: 1rem;
    margin-bottom: 2rem;
`

// const style = { fontSize: "1.6rem" };

interface ReporterInformationModalProps {
    closeModal: () => void
    reporter: ReporterInterface
}

const ReporterInformationModal: React.FC<ReporterInformationModalProps> = ({
    closeModal,
    reporter,
}) => {
    const translation = useTranslation()

    return (
        <ModalContainer>
            <Modal>
                <Card>
                    <CardHeader>
                        <div />
                        <CancelButton onClick={closeModal}>
                            {translation.caseworkerOpenReport.cancel}
                        </CancelButton>
                    </CardHeader>

                    <Divider>
                        <TextRegular>
                            {translation.caseworkerOpenReport.thisReportHasBeen}
                        </TextRegular>
                    </Divider>
                    <TextRegular>
                        {translation.caseworkerOpenReport.name}
                    </TextRegular>
                    <TextMedium>
                        {!Array.isArray(reporter) &&
                            `${reporter.first_name} ${reporter.last_name}`}
                    </TextMedium>
                    <TextRegular>
                        {translation.caseworkerOpenReport.position}
                    </TextRegular>
                    <TextMedium>
                        {!Array.isArray(reporter) && reporter.position}
                    </TextMedium>
                    <TextRegular>
                        {translation.caseworkerOpenReport.email}
                    </TextRegular>
                    <TextMedium>
                        {!Array.isArray(reporter) && reporter.email}
                    </TextMedium>
                    <TextRegular>
                        {translation.caseworkerOpenReport.phone}
                    </TextRegular>
                    <TextMedium>
                        {!Array.isArray(reporter) &&
                            '+45 ' + reporter.phone_number}
                    </TextMedium>
                </Card>
            </Modal>
        </ModalContainer>
    )
}

export default ReporterInformationModal
