import React, { useEffect, useState } from 'react'
import { useTranslation } from '../../../hooks'
import styled from 'styled-components'
import DesignFrame from './innerframes/DesignFrame'
import ReportQuestionsFrame from './innerframes/ReportQuestionsFrame'
import { Card } from '../../styled/cards'

interface IsActive {
    isActive: boolean
}

const FlexContainer = styled.div`
    display: flex;
    gap: 2.2rem;
    padding-top: 2rem;
`

const LeftContainer = styled.div`
    width: 25%;
`

const RightContainer = styled.div`
    width: 75%;
`

const NavigationContainer = styled.div`
    height: 46rem;
    position: relative;
`
const NavigationLink = styled.div<IsActive>`
    height: 6.2rem;
    width: 100%;
    border-bottom: ${({ theme, isActive }) =>
        isActive ? `2px solid ${theme.blue}` : `1px solid ${theme.primary}10`};

    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s;

    :hover {
        background: ${({ theme }) => `${theme.primary}05`};
    }
`
const NavigationLinkText = styled.span<IsActive>`
    font-size: 1.8rem;
    color: ${({ theme }) => theme.primary};
    opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
    margin-left: 1.4rem;
`

const CompanyCustomizationsFrame: React.FC = () => {
    const [currentFrameNumber, setCurrentFrameNumber] = useState(2)
    const [currentFrame, setCurrentFrame] = useState(<DesignFrame />)
    // const { client } = useTypedSelector((state) => state.client)
    const translation = useTranslation()

    useEffect(() => {
        if (currentFrameNumber === 1) {
            setCurrentFrame(<DesignFrame />)
        } else if (currentFrameNumber === 2) {
            setCurrentFrame(<ReportQuestionsFrame />)
        }
        // eslint-disable-next-line
    }, [currentFrameNumber])
    return (
        <>
            <FlexContainer>
                <LeftContainer>
                    <NavigationContainer>
                        <Card>
                            {/* <NavigationLink
                                isActive={currentFrameNumber === 1}
                                onClick={() => setCurrentFrameNumber(1)}
                            >
                                <NavigationLinkText
                                    isActive={currentFrameNumber === 1}
                                >
                                    Design
                                </NavigationLinkText>
                            </NavigationLink> */}
                            <NavigationLink
                                isActive={currentFrameNumber === 2}
                                onClick={() => setCurrentFrameNumber(2)}
                            >
                                <NavigationLinkText
                                    isActive={currentFrameNumber === 2}
                                >
                                    {translation.clientView.reportQuestions}
                                </NavigationLinkText>
                            </NavigationLink>
                        </Card>
                    </NavigationContainer>
                </LeftContainer>
                <RightContainer>{currentFrame}</RightContainer>
            </FlexContainer>
        </>
    )
}

export default CompanyCustomizationsFrame
