import {
    LineOfContactActionType,
    WhistleblowerActionType,
    TranslationActionType,
    ReportQuestionActionType,
} from '../action-types'
import axios from 'axios'
import config from '../../config'
import { Dispatch } from 'redux'

const { apiUrl } = config

export const whistleblowerLogin =
    (organizationalCode: string) => async (dispatch: Dispatch) => {
        try {
            const res = await axios.get(
                `${apiUrl}/v1/auth/organizations/${organizationalCode}?with=contact_lines`
            )

            if (
                res.status !== 201 &&
                res.status !== 200 &&
                res.status !== 204
            ) {
                return false
            }

            const companyName = res.data.data.name
            const customWbPolicyName = res.data.data.custom_wb_policy_name

            const accountType = res.data.data.account_type
            const isExcludeModeActive = !!res.data.data.is_exclude_mode_active

            if (res.data.data.default_language) {
                dispatch({
                    type: TranslationActionType.SWITCH_LANGUAGE,
                    payload: res.data.data.default_language,
                })
            }

            dispatch({
                type: LineOfContactActionType.GET_LINE_OF_CONTACTS,
                payload: res.data.data.contact_lines.data,
            })

            dispatch({
                type: ReportQuestionActionType.GET_REPORT_QUESTIONS,
                payload: res.data.data.custom_questions.data,
            })

            dispatch({
                type: WhistleblowerActionType.WHISTLEBLOWER_LOGIN,
                payload: {
                    organizationalCode,
                    companyName,
                    accountType,
                    isExcludeModeActive,
                    customWbPolicyName,
                },
            })

            dispatch({
                type: WhistleblowerActionType.CHANGE_REPORTING_CHANNEL_LANGUAGE,
                payload: res.data.data.reporting_channel_language.code,
            })

            return true
        } catch (error) {
            return false
        }
    }

export const setWhistleblowerLoading = () => {
    return {
        type: WhistleblowerActionType.SET_WHISTLEBLOWER_LOADING,
    }
}

export const createReport =
    (organizationalCode: string, report: any) => async (dispatch: Dispatch) => {
        dispatch(setWhistleblowerLoading())

        const payload = new FormData()

        report.files.forEach((file: any) => {
            payload.append('case_files[]', file.file)
        })
        report.questions.forEach((question: any, index: number) => {
            payload.append('questions[]'[index], question)
        })
        payload.append('title', report.title)
        payload.append('description', report.description)
        payload.append('case_type', report.whistleblowerApperance.key)
        report.contacts.forEach((contact: any, index: number) => {
            payload.append(`caseworkers[${index}][caseworker_id]`, contact.id)
            if (typeof contact.is_excluded !== 'undefined') {
                payload.append(
                    `caseworkers[${index}][is_excluded]`,
                    contact.is_excluded.toString()
                )
            }
        })

        if (report.whistleblower) {
            payload.append('with_whistleblower', '1')
            payload.append('first_name', report.whistleblower.firstName)
            payload.append('last_name', report.whistleblower.lastName)
            payload.append('email', report.whistleblower.email)
            payload.append('phone_number', report.whistleblower.phone)
            payload.append('position', report.whistleblower.position)
            payload.append('country_id', report.whistleblower.countryId)
        } else {
            payload.append('with_whistleblower', '0')
        }

        try {
            const res = await axios.post(
                `${apiUrl}/v1/whistleblower/report-incident/${organizationalCode}`,
                payload
            )

            if (
                res.status !== 201 &&
                res.status !== 200 &&
                res.status !== 204
            ) {
                if (typeof res.data.errors !== 'undefined') {
                    dispatch({
                        type: WhistleblowerActionType.SHOW_REPORT_ERRORS,
                        payload: res.data.errors,
                    })
                }
                dispatch(
                    setWhistleblowerError(
                        'Something went wrong, please try again later'
                    )
                )
                return false
            }

            dispatch({
                type: WhistleblowerActionType.CREATE_REPORT,
                payload: {
                    code: res.data.data.whistleblower_code,
                    id: res.data.data.id,
                },
            })

            return true
        } catch (error: any) {
            if (typeof error.response.data.errors !== 'undefined') {
                dispatch({
                    type: WhistleblowerActionType.SHOW_REPORT_ERRORS,
                    payload: error.response.data.errors,
                })
            }
            dispatch(
                setWhistleblowerError(
                    'Something went wrong, please try again later'
                )
            )

            return false
        }
    }

export const getReport =
    (whistleblowerCode: string) => async (dispatch: any) => {
        dispatch(setWhistleblowerLoading())

        try {
            const res: any = await axios.get(
                `${apiUrl}/v1/whistleblower/incidents/${whistleblowerCode}`
            )

            if (
                res.status !== 201 &&
                res.status !== 200 &&
                res.status !== 204
            ) {
                dispatch(
                    setWhistleblowerError(
                        'Something went wrong, please try again and make sure the whistleblower code is correct.'
                    )
                )

                return
            }

            dispatch({
                type: WhistleblowerActionType.GET_REPORT,
                payload: res.data.payload,
            })

            dispatch(
                getMessages(
                    res.data.payload.id,
                    res.data.payload.whistleblower_code
                )
            )
        } catch (error: any) {
            dispatch(
                setWhistleblowerError(
                    'Something went wrong, please try again and make sure the whistleblower code is correct.'
                )
            )
        }
    }

export const getMessages =
    (chatId: number, code: string) => async (dispatch: Dispatch) => {
        dispatch(setWhistleblowerLoading())

        try {
            const res: any = await axios.get(
                `${apiUrl}/v1/whistleblower/incident/${chatId}/talk/${code}`
            )

            if (
                res.status !== 201 &&
                res.status !== 200 &&
                res.status !== 204
            ) {
                // TODO dispatch chat error
                dispatch({
                    type: WhistleblowerActionType.GET_MESSAGES,
                    payload: [],
                })
                // dispatch(
                //     setWhistleblowerError(
                //         'Something went wrong, please try again and make sure the whistleblower code is correct.'
                //     )
                // )
                return
            }

            dispatch({
                type: WhistleblowerActionType.GET_MESSAGES,
                payload: res.data.data,
            })
        } catch (error) {
            // TODO dispatch chat error
            dispatch(
                setWhistleblowerError(
                    'Something went wrong, please try again and make sure the whistleblower code is correct.'
                )
            )
        }
    }

export const addMessage =
    (chatId: number, code: string, content: string, files: any) =>
    async (dispatch: Dispatch) => {
        dispatch(setWhistleblowerLoading())

        try {
            const payload = new FormData()

            payload.append('source', 'reporter')

            if (!files) {
                payload.append('type', 'text')
            } else {
                files.forEach((file: any) => {
                    payload.append('case_files[]', file)
                })
                payload.append('type', 'data')
            }
            content.length && payload.append('content', content)

            const res: any = await axios.post(
                `${apiUrl}/v1/whistleblower/incident/${chatId}/talk/${code}`,
                payload
            )

            if (
                res.status !== 201 &&
                res.status !== 200 &&
                res.status !== 204
            ) {
                // TODO dispatch chat error
                return
            }

            return res.data.data
        } catch (error) {
            // TODO dispatch chat error
            dispatch(
                setWhistleblowerError(
                    'Something went wrong, please try again and make sure the whistleblower code is correct.'
                )
            )
        }
    }

export const setWhistleblowerError = (errorMessage: string) => ({
    type: WhistleblowerActionType.SET_WHISTLEBLOWER_ERROR,
    payload: {
        message: errorMessage,
    },
})

export const resetWhistleblowerErrorMessageState = () => ({
    type: WhistleblowerActionType.RESET_WHISTLEBLOWER_ERROR_MESSAGE_STATE,
})

export const leaveReport = () => {
    return {
        type: WhistleblowerActionType.LEAVE_REPORT,
    }
}

export const whistleblowerLogout = () => ({
    type: WhistleblowerActionType.WHISTLEBLOWER_LOGOUT,
})

export const whistleblowerShowLatestMessage =
    (message: any) => (dispatch: any) => {
        const payload = {
            id: message.line.id,
            content: message.content.content,
            created_at: message.line.created_at,
            source: message.content.source,
            type: message.line.type,
            attributes: message.line.attributes,
            caseworker_name: message.content.caseworker_name,
            files: {
                data: message.files.map((file: any) => ({
                    name: file.original_name,
                    download_url: 'coming soon',
                    ...file,
                })),
            },
        }

        if (payload.files.data) {
            dispatch(wbShowLatestFiles(payload.files.data))
        }

        return dispatch({
            type: WhistleblowerActionType.SHOW_LATEST_MESSAGE,
            payload,
        })
    }

const wbShowLatestFiles = (payload: any) => ({
    type: WhistleblowerActionType.WHISTLEBLOWER_SHOW_LATEST_FILES,
    payload,
})

export const addReporterEmail =
    (id: number, email: string, code: string) => async (dispatch: Dispatch) => {
        dispatch(setWhistleblowerLoading())

        try {
            const payload = {
                email,
                whistleblower_code: code,
            }

            const res: any = await axios.post(
                `${apiUrl}/v1/whistleblower/incidents/${id}/add-reporter-email`,
                payload
            )

            if (
                res.status !== 201 &&
                res.status !== 200 &&
                res.status !== 204
            ) {
                return
            }

            dispatch({
                type: WhistleblowerActionType.ADD_REPORTER_EMAIL,
            })
            return
        } catch (error) {
            dispatch(
                setWhistleblowerError(
                    'Something went wrong, please try again and make sure the whistleblower code is correct.'
                )
            )
        }
    }

export const refreshCaseAfterForward = (report: any) => ({
    type: WhistleblowerActionType.REFRESH_CASE_AFTER_FORWARD,
    payload: report,
})
