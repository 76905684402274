import { add, differenceInDays, intervalToDuration } from 'date-fns'
import TranslationStateType from '../../state/state-types/TranslationStateType'

// Return the deadline for a report response
export const getResponseDeadline = (report: any, chatMessages: any, translation: TranslationStateType) => {
    if (!report) {
        return null
    }

    const initialDate = new Date(report.created_at)

    if (typeof chatMessages.find((message: any) => message.source === 'caseworker') === 'undefined') {
        return format(initialDate, { weeks: 1 }, translation)
    }

    return format(initialDate, { days: 92 }, translation)
}

const format = (date: any, time: any, translation: TranslationStateType) => {
    const addedDate = add(date, time)

    const duration = intervalToDuration({
        start: new Date(),
        end: addedDate,
    })

    const timeObj: any = {
        days: -differenceInDays(new Date(), addedDate),
        hours: duration.hours,
        minutes: duration.minutes,
        seconds: duration.seconds,
    }

    let dateString = timeObj.days ? `${timeObj.days} ${translation.caseworkerOpenReport.days}` : ''

    // Only show the hours if the days are less then 7
    if (timeObj.days < 7) {
        const hoursString = timeObj.hours ? `, ${timeObj.hours} ${translation.caseworkerOpenReport.hours}` : ''

        dateString += hoursString
    }
    // const minutesString = timeObj.minutes ? `${timeObj.minutes} minutes, ` : ''
    // const secondsString = timeObj.seconds ? `${timeObj.seconds} seconds, ` : ''

    return {
        dateString,
        daysLeft: timeObj.days,
    }
}

