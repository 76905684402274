import styled from 'styled-components'

export const NewCasePageFrame = styled.div`
    width: 100%;
    padding-top: 6.2rem;
    text-align: center;
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 480px) {
        padding-block-start: 3rem;
    }
`
