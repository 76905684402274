import { useEffect, useState } from 'react'
import { FiAlertCircle, FiPaperclip, FiSend, FiTrash2 } from 'react-icons/fi'
import styled from 'styled-components'
import { useTranslation } from '../../../hooks'
import ButtonSpinnerWhite from '../../layout/spinner/ButtonSpinnerWhite'

const NewMessageContainer = styled.div`
    border-top: 1px solid ${({ theme }) => theme.grey};
    padding: 1rem 0;
`

const NewMessageContent = styled.div`
    margin: 0 1.4rem;
`
const Textarea = styled.textarea`
    width: 100%;
    border: 1px solid ${({ theme }) => theme.grey};
    border-radius: 0.5rem;
    height: 12.4rem;
    padding: 1rem;
    font-family: 'NeueMontreal';
    resize: none;
`

const ButtonContainer = styled.div`
    padding: 1rem 0;
    display: flex;
    align-items: center;
`

const PaperClipIcon = styled(FiPaperclip)`
    color: ${({ theme }) => theme.primary};
    opacity: 0.5;
    cursor: pointer;
    font-size: 1.4rem;
`

interface SendButtonProps {
    isActive: boolean
}

const SendButton = styled.div<SendButtonProps>`
    height: 2.5rem;
    width: 12rem;
    border-radius: 20rem;
    background: ${({ theme, isActive }) => isActive && theme.blue};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    cursor: pointer;
    border: ${({ theme, isActive }) =>
        !isActive && `1px solid ${theme.greyText}`};
`

const SendIcon = styled(FiSend)<SendButtonProps>`
    color: ${({ theme, isActive }) =>
        isActive ? theme.white : theme.greyText};
    font-size: 1.4rem;
`

const SendText = styled.span<SendButtonProps>`
    color: ${({ theme, isActive }) =>
        isActive ? theme.white : theme.greyText};

    font-size: 1.4rem;
    margin-left: 0.5rem;
`

const HiddenFileInput = styled.input`
    display: none;
`

const FilePreview = styled.div`
    height: 2.5rem;
    padding-left: 1rem;
    background: ${({ theme }) => `${theme.primary}10`};

    display: flex;
    align-items: center;
    border-radius: 2rem;
    margin-left: 1rem;
`

const FilePreviewText = styled.p`
    font-size: 1.2rem;
    color: ${({ theme }) => theme.primary};
    margin-right: 1rem;
`

const FilePreviewIconContainer = styled.div`
    height: 2.5rem;
    width: 2.5rem;
    background: ${({ theme }) => theme.lineGrey};

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
`

const FilePreviewIcon = styled(FiTrash2)`
    color: ${({ theme }) => `${theme.primary}50`};
    font-size: 1.2rem;
`

const FileSizeError = styled.div`
    height: 2.5rem;
    padding: 0 1rem;
    background: ${({ theme }) => `${theme.red}10`};
    border-radius: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.red};
    font-size: 1.2rem;
    gap: 0.5rem;
    margin-left: 1rem;
`

const AlertIcon = styled(FiAlertCircle)`
    color: ${({ theme }) => theme.red};
    font-size: 1.4rem;
`

interface MessageFormProps {
    message: string
    setMessage: (e: any) => void
    files?: any
    setFiles?: (files: any) => void
    handleAddMessage: () => void
    placeholder?: string
    isLoading?: boolean
    buttonText?: null | string
    isReportClosed?: boolean
}

const MessageForm: React.FC<MessageFormProps> = ({
    message,
    setMessage,
    files = null,
    setFiles = () => {},
    handleAddMessage,
    placeholder = null,
    isLoading = false,
    buttonText = null,
    isReportClosed = false,
}) => {
    const translation = useTranslation()

    useEffect(() => {
        if (files?.length > 10) {
            setFileError('You cannot add more then 10 files at ones')
            setFiles([])
            return
        }

        files?.forEach((file: any) => {
            if (file.size > 2000000000) {
                setFileError(
                    'This file is too big. The file must be under 2 GB'
                )
                setFiles([])

                return
            }
        })
        // eslint-disable-next-line
    }, [files])

    const [fileError, setFileError] = useState('')

    const removeFile = (fileName: string) => {
        setFiles(files?.filter((file: any) => file.name !== fileName))
    }

    const handleSetFiles = (files: any) => {
        const filesArray = Array.from(files)
        setFiles(filesArray)
    }

    const addMessageClick = async () => {
        if (isLoading) {
            return
        }

        if (!message && !files.length) {
            return
        }

        await handleAddMessage()
        setMessage('')
        setFiles([])
    }

    return (
        <NewMessageContainer>
            <NewMessageContent>
                <Textarea
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder={
                        placeholder
                            ? placeholder
                            : translation.accessCaseFlow.enterMessage
                    }
                    value={message}
                    disabled={isReportClosed}
                />
                <ButtonContainer>
                    <label htmlFor="file-input">
                        <PaperClipIcon />
                    </label>
                    {fileError.length > 1 && (
                        <FileSizeError>
                            <AlertIcon />
                            {fileError}
                        </FileSizeError>
                    )}

                    {files?.map((file: any) => (
                        <FilePreview>
                            <FilePreviewText>{file.name}</FilePreviewText>
                            <FilePreviewIconContainer
                                onClick={() => removeFile(file.name)}
                            >
                                <FilePreviewIcon />
                            </FilePreviewIconContainer>
                        </FilePreview>
                    ))}

                    <HiddenFileInput
                        onChange={(e: any) => handleSetFiles(e.target.files)}
                        type="file"
                        id="file-input"
                        multiple
                    />
                    <SendButton
                        isActive={
                            message.length > 0 || files?.length > 0
                                ? true
                                : false
                        }
                        onClick={addMessageClick}
                    >
                        {!isLoading ? (
                            <>
                                <SendIcon
                                    isActive={
                                        message || files?.length > 0
                                            ? true
                                            : false
                                    }
                                />
                                <SendText
                                    isActive={
                                        message || files?.length > 0
                                            ? true
                                            : false
                                    }
                                >
                                    {buttonText
                                        ? buttonText
                                        : translation.accessCaseFlow.send}
                                </SendText>
                            </>
                        ) : (
                            <ButtonSpinnerWhite />
                        )}
                    </SendButton>
                </ButtonContainer>
            </NewMessageContent>
        </NewMessageContainer>
    )
}

export default MessageForm
