import styled from 'styled-components'
import { DynamicHeightBlueButton } from '../../../styled/buttons'
import { useActions, useTranslation, useTypedSelector } from '../../../../hooks'
// import { getUrlAfterAppSubdomain } from '../../../../helpers/routing'
import ButtonSpinnerWhite from '../../../layout/spinner/ButtonSpinnerWhite'
import { getUrlAfterAppSubdomain } from '../../../../helpers/routing'

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const Heading = styled.h3`
    font-size: 2rem;
    color: ${({ theme }) => theme.primary};
    font-weight: 600;
    margin-top: 2rem;
`

const ClickFinishToSendText = styled.p`
    font-size: 1.8rem;
    color: ${({ theme }) => theme.primary};
    margin-top: 3rem;
    margin-bottom: 6rem;
`

/* const LineOfContactsBtn = styled.span`
    color: ${({ theme }) => theme.blue};
    cursor: pointer;
    transition: opacity 0.3s;

    :hover {
        opacity: 0.8;
    }
`

const WillBeSentText = styled.p`
    font-size: 1.4rem;
    color: ${({ theme }) => theme.greyText};
    margin-top: 2rem;
    margin-bottom: 4rem;
`

const IfYouAreOneText = styled.p`
    font-size: 1.4rem;
    color: ${({ theme }) => theme.primary};
    margin-top: 5.5rem;
`

 */

interface ReadyToGoFrameProps {
    goBack: () => void
}

const OnboardingReadyToGoFrame: React.FC<ReadyToGoFrameProps> = ({
    goBack,
}) => {
    const { organizationFinishOnboarding } = useActions()

    const { user, authLoading } = useTypedSelector((state) => state.auth)

    const { client } = useTypedSelector((state) => state.client)

    const translation = useTranslation()

    const handleFinishOnboarding = async () => {
        if (!user) {
            return
        }

        const userRes: any = await organizationFinishOnboarding(
            user.userId,
            client.organization.data.id
        )

        if (!userRes) {
            // TODO Show error..
            alert(
                'Something went wrong with the redirect. Please refresh to be taken to the login'
            )
            return
        }

        let subdomain = 'client'

        if (userRes.roles.includes('contact')) {
            subdomain = 'contact'
        }

        const url = `http://app.${subdomain}.${getUrlAfterAppSubdomain()}/magic-login/${
            userRes.magicLoginCode
        }`

        return window.location.replace(url)
    }

    return (
        <>
            {/* <HeadingContainer>
                <div />
                <GoBackButton onClick={goBack}>
                    <LeftArrowIcon />
                    <GoBackText>Go back</GoBackText>
                </GoBackButton>
            </HeadingContainer> */}
            <Container>
                <Heading>{translation.onboardingPage.allSetEasy}</Heading>
                <ClickFinishToSendText>
                    {translation.onboardingPage.letsGetYouStarted}
                </ClickFinishToSendText>
                <DynamicHeightBlueButton
                    height="5.5rem"
                    width="30.7rem"
                    fontSize="1.6rem"
                    onClick={handleFinishOnboarding}
                >
                    {authLoading ? (
                        <ButtonSpinnerWhite />
                    ) : (
                        translation.onboardingPage.takeMeToTheDashboard
                    )}
                </DynamicHeightBlueButton>
            </Container>
        </>
    )
}

export default OnboardingReadyToGoFrame
