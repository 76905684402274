import axios from 'axios'
import config from '../../config'
import {
    ClientActionType,
    IdentityActionType,
    LineOfContactActionType,
    TranslationActionType,
    WhistleblowerActionType,
} from '../action-types'
import { Dispatch } from 'redux'

const { apiUrl } = config

export const getCompanyInformation = () => async (dispatch: Dispatch) => {
    dispatch(setClientLoading())

    try {
        const res = await axios.get(
            `${apiUrl}/v1/auth/identity?with=organization.contact_lines`
        )

        if (res.status !== 200) {
            return dispatch(
                setClientError('Something went wrong please reload')
            )
        }

        if (
            res.data.data.organization.data.default_language &&
            typeof res.data.data.organization.data.default_language !==
                'undefined'
        ) {
            dispatch({
                type: TranslationActionType.SWITCH_LANGUAGE,
                payload: res.data.data.organization.data.default_language,
            })
        }

        dispatch({
            type: LineOfContactActionType.GET_LINE_OF_CONTACTS,
            payload: res.data.data.organization.data.contact_lines.data,
        })

        dispatch({
            type: WhistleblowerActionType.CHANGE_REPORTING_CHANNEL_LANGUAGE,
            payload:
                res.data.data.organization.data.reporting_channel_language.data
                    .code,
        })

        dispatch({
            type: IdentityActionType.UPDATE_SELECTED_LANGUAGE,
            payload: res.data.data.selected_language.data.code,
        })

        return dispatch({
            type: ClientActionType.GET_COMPANY_INFORMATION,
            payload: res.data.data,
        })
    } catch (error) {
        return dispatch(setClientError('Something went wrong please reload'))
    }
}

export const setClientLoading = () => ({
    type: ClientActionType.SET_CLIENT_LOADING,
})

export const setClientError = (errorMessage: string) => ({
    type: ClientActionType.SET_CLIENT_ERROR,
    payload: {
        message: errorMessage,
    },
})
