import styled from 'styled-components'
import { Card, CardHeader } from '../../../../../styled/cards'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { useEffect, useState } from 'react'
import {
    useActions,
    useTranslation,
    useTypedSelector,
} from '../../../../../../hooks'
import {
    CheckIcon,
    EditButtonText,
    EditIcon,
    SaveButton,
    SaveButtonText,
} from '../../../../../styled/buttons'
import ButtonSpinnerWhite from '../../../../../layout/spinner/ButtonSpinnerWhite'
import {
    CancelButton,
    CancelIcon,
    CheckSavedMessageIcon,
    Edit,
    SuccessMessage,
    UpdateIdentityErrorMessage,
    UpdateStettings,
} from '../MyAccount'
import { AvailableLanguagesInterface } from '../../../../../../state/reducers/availableLanguagesReducer'

interface IsActiveProp {
    isActive: boolean
}

const UpdateLanguage = styled(SaveButton)<IsActiveProp>`
    background: ${({ theme, isActive }) =>
        isActive ? theme.blue : `${theme.primary}10`};
    && .save-button-text,
    .check-icon {
        color: ${({ theme, isActive }) =>
            isActive ? theme.white : `${theme.primary}40`};
    }

    &:hover {
        opacity: 0.9;
    }
`

const LanguageSelect = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 1rem;
    margin-block-start: 2rem;
    margin-inline: 10rem;
    border-radius: 0.5rem;
    border: 2px solid ${({ theme }) => theme.lineGrey};
`

const LanguageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.4rem;
    cursor: pointer;
`
const DropdownBox = styled.div`
    margin-inline: 10rem;
    border: 2px solid ${({ theme }) => theme.lineGrey};
    margin-block-start: 0.2rem;
    padding-block-end: 1rem;
    border-radius: 0.2rem;
`

const LanguageText = styled.p`
    padding-top: 1rem;
    width: 3rem;
    font-size: 1.5rem;
    cursor: pointer;
`
const DropdownIcon = styled.div`
    font-size: 1.4rem;
    color: black;
    right: 2rem;
`
const ArrowDownIcon = styled(FiChevronDown)`
    ${DropdownIcon}
`

const ArrowUpIcon = styled(FiChevronUp)`
    ${DropdownIcon}
`

const LanguageTextWrapper = styled.div`
    padding-inline: 0.5rem;

    &:hover {
        background-color: ${({ theme }) => theme.grey200};
    }
`

const DefaultLanguage: React.FC = () => {
    const { selectedTranslation } = useTypedSelector(
        (state) => state.translation
    )

    const { selectedLanguage } = useTypedSelector((state) => state.identity)

    const {
        availableLanguages,
        availableLanguagesError,
        availableLanguagesLoading,
        saveDefaultLanguageMessage,
    } = useTypedSelector((state) => state.availableLanguages)
    const { user } = useTypedSelector((state) => state.caseworker)
    const [isDropdownActive, setIsDropdownActive] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [hasLanguageChanged, setHasLanguageChanged] = useState(false)

    const [previousDefaultLanguage, setPreviousDefaultLanguage] =
        useState(selectedTranslation)
    const { changeLanguage, getAvailableLanguages, updateDefaultLanguage } =
        useActions()

    const translation = useTranslation()

    const [selectLanguage, setSelectLanguage] =
        useState<AvailableLanguagesInterface | null>(null)

    const toggleDropdown = () => {
        if (isEditing === true) {
            setIsDropdownActive(!isDropdownActive)
        }
    }

    const handleLanguageSelect = (language: AvailableLanguagesInterface) => {
        setPreviousDefaultLanguage(selectedTranslation)
        setSelectLanguage(language)
        toggleDropdown()
        setHasLanguageChanged(true)
        changeLanguage(language.code)
    }

    const handleCancel = () => {
        changeLanguage(previousDefaultLanguage)
        setIsEditing(false)
        setIsDropdownActive(false)
    }

    useEffect(() => {
        getAvailableLanguages()
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (selectedTranslation) {
            const languageSelected: any = availableLanguages?.find(
                (language) => language.code === selectedTranslation
            )
            setSelectLanguage(languageSelected)
        }
    }, [selectedTranslation, availableLanguages])

    useEffect(() => {
        if (selectedLanguage) {
            changeLanguage(selectedLanguage)
        }
        //eslint-disable-next-line
    }, [selectedLanguage])

    const handleSave = async () => {
        const payload = {
            selected_language_id: selectLanguage?.id,
        }
        const success = await updateDefaultLanguage(user.userId, payload)
        // changeLanguage(updateSelectedLanguage)

        setHasLanguageChanged(false)
        setIsEditing(false)

        return success
    }

    return (
        <>
            <Card>
                <CardHeader>
                    {translation.settingsPartner.defaultLanguage}
                    {isEditing ? (
                        <UpdateStettings>
                            <>
                                <CancelButton onClick={handleCancel}>
                                    <CancelIcon />
                                    {translation.settingsPartner.cancel}
                                </CancelButton>
                            </>
                            <UpdateLanguage isActive={hasLanguageChanged}>
                                {availableLanguagesLoading ? (
                                    <ButtonSpinnerWhite />
                                ) : (
                                    <>
                                        <CheckIcon className="check-icon" />
                                        <SaveButtonText
                                            className="save-button-text"
                                            onClick={handleSave}
                                        >
                                            {translation.settingsPartner.save}
                                        </SaveButtonText>
                                    </>
                                )}
                            </UpdateLanguage>
                        </UpdateStettings>
                    ) : (
                        <Edit onClick={() => setIsEditing(true)}>
                            <EditIcon />
                            <EditButtonText>
                                {translation.settingsPartner.edit}
                            </EditButtonText>
                        </Edit>
                    )}
                </CardHeader>
                <LanguageSelect>
                    <LanguageContainer onClick={toggleDropdown}>
                        <p>{selectLanguage?.name}</p>

                        {isDropdownActive ? <ArrowUpIcon /> : <ArrowDownIcon />}
                    </LanguageContainer>
                </LanguageSelect>
                {isDropdownActive && (
                    <DropdownBox>
                        {availableLanguages?.map((language) => {
                            if (language.code !== selectLanguage?.code) {
                                return (
                                    <div
                                        key={language.code}
                                        onClick={() =>
                                            handleLanguageSelect(language)
                                        }
                                    >
                                        <LanguageTextWrapper>
                                            <LanguageText>
                                                {language.name}
                                            </LanguageText>
                                        </LanguageTextWrapper>
                                    </div>
                                )
                            }
                            return <></>
                        })}
                    </DropdownBox>
                )}
                {availableLanguagesError && (
                    <UpdateIdentityErrorMessage
                        style={{
                            marginBlockStart: '14rem',
                            marginInlineStart: '2rem',
                        }}
                    >
                        <CancelIcon />
                        {availableLanguagesError}
                    </UpdateIdentityErrorMessage>
                )}
                {saveDefaultLanguageMessage && (
                    <SuccessMessage
                        style={{
                            marginBlockStart: '14rem',
                            marginInlineStart: '2rem',
                        }}
                    >
                        <CheckSavedMessageIcon />
                        {saveDefaultLanguageMessage}
                    </SuccessMessage>
                )}
            </Card>
        </>
    )
}

export default DefaultLanguage
