import styled from 'styled-components'
import { useTranslation } from '../../../hooks'
import {
    Card,
    CardBody,
    CardCaseHeadingRow,
    CardCaseHeadingText,
    CardHeader,
} from '../../styled/cards'
import { CaseGrid3Items } from '../../styled/grids'
import Case from './Case'
import { FiFolder } from 'react-icons/fi'

export interface CaseInterface {
    id: number
    title: string
    description: string
    uuid: string
    category: string
    assigned_to: {
        name: string
        id: number
    }
    organization_name: string
    case_type: string
    is_closed: boolean
    status: string
    created_at: string
    closed_at: null | string
}

const NoCaseContainer = styled.div`
    height: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    color: ${({ theme }) => theme.greyText};
    font-size: 2rem;

    @media screen and (max-width: 600px) {
        font-size: 1.5rem;
        padding-block: 8rem;
    }
`

const CompanyHeadingText = styled.div`
    @media screen and (max-width: 600px) {
        display: none;
    }
`

interface CasesProps {
    title: string
    isAllClosed: boolean
    cases: Array<CaseInterface>
    height?: string
}

const Cases: React.FC<CasesProps> = ({
    title,
    isAllClosed,
    cases,
    height = 'auto',
}) => {
    const translation = useTranslation()
    return (
        <Card maxHeight="55.4rem">
            <CardHeader>{title}</CardHeader>
            <CardCaseHeadingRow>
                <CaseGrid3Items>
                    <CardCaseHeadingText>
                        {translation.singleClientViewPartner.headline}
                    </CardCaseHeadingText>
                    <CompanyHeadingText>
                        <CardCaseHeadingText>
                            {translation.singleClientViewPartner.company}
                        </CardCaseHeadingText>
                    </CompanyHeadingText>
                    <CardCaseHeadingText>
                        {translation.singleClientViewPartner.status}
                    </CardCaseHeadingText>
                </CaseGrid3Items>
            </CardCaseHeadingRow>
            {cases.length > 0 ? (
                <CardBody maxHeight={height}>
                    {cases.map((currentCase) => (
                        <Case
                            key={currentCase.id}
                            isAllClosed={isAllClosed}
                            currentCase={currentCase}
                        />
                    ))}
                </CardBody>
            ) : (
                <NoCaseContainer>
                    <FiFolder fontSize={'3rem'} className="folder-icon" />
                    {translation.dashboardPartner.noCases}
                </NoCaseContainer>
            )}
        </Card>
    )
}

export default Cases
