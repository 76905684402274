import produce from 'immer'
import { CaseworkerActionType } from '../action-types'
import { CaseworkerAction } from '../actions'

interface PlanInterface {
    id: number
    name: string

    employee_count: string

    price_monthly: number
    price_yearly: number
}

interface CaseworkerState {
    caseworkerLoading: boolean
    caseworkerError: string | null
    clients: any
    ownReports: any
    allReports: any
    allReportsFiltered: any
    allReportsFilteredCaseType: 'all' | 'mine'
    allReportsFilteredStatus: any
    allReportsSearchString: string
    closedReports: any
    closedReportsFiltered: any
    closedReportsSearchString: string
    selectedOrganizationInformation: any
    user: any
    organization: any
    caseworkers: any
    fetchCaseworkersLoading: boolean
    selectedCaseworker: any
    newCaseworkerValidationErrors: any
    plan: null | PlanInterface
}

const initialState: CaseworkerState = {
    caseworkerLoading: false,
    caseworkerError: null,
    clients: null,
    ownReports: null,
    allReports: null,
    allReportsFiltered: null,
    allReportsFilteredCaseType: 'all',
    allReportsFilteredStatus: null,
    allReportsSearchString: '',
    closedReports: null,
    closedReportsFiltered: null,
    closedReportsSearchString: '',
    selectedOrganizationInformation: null,
    user: null,
    organization: null,
    caseworkers: [],
    fetchCaseworkersLoading: false,
    selectedCaseworker: null,
    newCaseworkerValidationErrors: [],
    plan: null,
}

const checkIfFieldIncludesString = (field: string, search: string) =>
    field.toLowerCase().includes(search.toLowerCase())

const reducer = produce(
    (state: CaseworkerState = initialState, action: CaseworkerAction) => {
        switch (action.type) {
            case CaseworkerActionType.SET_CASEWORKER_LOADING:
                state.caseworkerLoading = true

                return state
            case CaseworkerActionType.SET_CASEWORKER_ERROR:
                state.caseworkerLoading = false
                state.caseworkerError = action.payload.message

                return state

            case CaseworkerActionType.GET_ORGANIZATIONS:
                state.caseworkerLoading = false
                state.caseworkerError = null
                state.clients = action.payload

                return state

            case CaseworkerActionType.GET_OWN_REPORTS:
                state.caseworkerLoading = false
                state.caseworkerError = null
                state.ownReports = action.payload

                return state

            case CaseworkerActionType.GET_ALL_REPORTS:
                state.caseworkerLoading = false
                state.caseworkerError = null
                state.allReports = action.payload
                state.allReportsFiltered = action.payload

                return state

            case CaseworkerActionType.GET_CLOSED_REPORTS:
                state.caseworkerLoading = false
                state.caseworkerError = null
                state.closedReports = action.payload
                state.closedReportsFiltered = action.payload

                return state

            case CaseworkerActionType.GET_CLIENT_INFORMATION:
                state.caseworkerLoading = false
                state.caseworkerError = null
                state.selectedOrganizationInformation = action.payload

                return state

            case CaseworkerActionType.GET_SELF:
                const user = {
                    userId: action.payload.id,
                    firstName: action.payload.first_name,
                    lastName: action.payload.last_name,
                    email: action.payload.email,
                    activeCases: action.payload.active_cases,
                    phone: action.payload.phone,
                    selected_language: action.payload.selected_language,
                }

                if (action.payload.plan) {
                    state.plan = action.payload.plan.data
                }

                state.caseworkerLoading = false
                state.caseworkerError = null
                state.user = user
                state.organization = action.payload.organization.data

                return state

            case CaseworkerActionType.GET_SELF_ORGANIZATION:
                state.caseworkerLoading = false
                state.caseworkerError = null
                state.organization = action.payload

                return state

            case CaseworkerActionType.ADD_CASEWORKER:
                state.caseworkerLoading = false
                state.caseworkerError = null
                state.caseworkers.push(action.payload)

                return state

            case CaseworkerActionType.GET_ALL_CASEWORKERS:
                state.fetchCaseworkersLoading = false
                state.caseworkerError = null
                state.caseworkers = action.payload

                return state

            case CaseworkerActionType.SET_FETCH_CASEWORKERS_LOADING:
                state.fetchCaseworkersLoading = true

                return state

            case CaseworkerActionType.GET_SINGLE_CASEWORKER:
                state.caseworkerLoading = false
                state.caseworkerError = null
                state.selectedCaseworker = action.payload

                return state

            case CaseworkerActionType.SEARCH_ALL_CASES:
                state.allReportsSearchString = action.payload.searchValue

                return state

            case CaseworkerActionType.ADD_STATUS_FILTER:
                if (state.allReportsFilteredStatus === action.payload.status) {
                    state.allReportsFilteredStatus = null
                } else {
                    state.allReportsFilteredStatus = action.payload.status
                }

                return state

            case CaseworkerActionType.ADD_CASE_TYPE_FILTER:
                state.allReportsFilteredCaseType = action.payload.type

                return state

            case CaseworkerActionType.FILTER_ALL_CASES:
                const { allReportsSearchString } = state

                const filteredReports = state.allReports?.filter(
                    (report: any) => {
                        let returnReport
                        if (
                            !allReportsSearchString ||
                            checkIfFieldIncludesString(
                                report.uuid,
                                allReportsSearchString
                            ) ||
                            checkIfFieldIncludesString(
                                report.organizationName,
                                allReportsSearchString
                            ) ||
                            checkIfFieldIncludesString(
                                report.title,
                                allReportsSearchString
                            ) ||
                            checkIfFieldIncludesString(
                                report.assignedToName,
                                allReportsSearchString
                            ) ||
                            // TODO remove category filter
                            // when category is removed from preview
                            checkIfFieldIncludesString(
                                report.category,
                                allReportsSearchString
                            )
                        ) {
                            if (
                                state.allReportsFilteredStatus ||
                                state.allReportsFilteredCaseType !== 'all'
                            ) {
                                if (
                                    !state.allReportsFilteredStatus ||
                                    report.status ===
                                        state.allReportsFilteredStatus
                                ) {
                                    if (
                                        state.allReportsFilteredCaseType ===
                                        'all'
                                    ) {
                                        returnReport = true
                                    } else {
                                        if (
                                            report.assignedToId ===
                                            state.user.userId
                                        ) {
                                            returnReport = true
                                        } else {
                                            returnReport = false
                                        }
                                    }
                                }
                            } else {
                                returnReport = true
                            }
                        } else {
                            returnReport = false
                        }

                        return returnReport
                    }
                )
                state.allReportsFiltered = filteredReports

                return state

            case CaseworkerActionType.REMOVE_ALL_FILTERS:
                state.allReportsFilteredCaseType = 'all'
                state.allReportsSearchString = ''
                state.allReportsFilteredStatus = null
                state.allReportsFiltered = state.allReports

                return state

            case CaseworkerActionType.SEARCH_CLOSED_REPORTS:
                state.closedReportsSearchString = action.payload.searchValue
                const { closedReportsSearchString } = state

                const filteredClosedReports = state.closedReports.filter(
                    (report: any) => {
                        let returnReport
                        returnReport =
                            !closedReportsSearchString ||
                            checkIfFieldIncludesString(
                                report.uuid,
                                closedReportsSearchString
                            ) ||
                            checkIfFieldIncludesString(
                                report.organizationName,
                                closedReportsSearchString
                            ) ||
                            checkIfFieldIncludesString(
                                report.title,
                                closedReportsSearchString
                            ) ||
                            checkIfFieldIncludesString(
                                report.assignedToName,
                                closedReportsSearchString
                            ) ||
                            checkIfFieldIncludesString(
                                report.category,
                                closedReportsSearchString
                            )
                        return returnReport
                    }
                )
                state.closedReportsFiltered = filteredClosedReports

                return state

            case CaseworkerActionType.SHOW_CREATE_CASEWORKER_VALIDATION_ERRORS:
                state.caseworkerLoading = false
                state.newCaseworkerValidationErrors = action.payload

                return state

            case CaseworkerActionType.ADD_CASEWORKER_VALIDATION:
                state.caseworkerLoading = false
                // state.newCaseworkerValidationErrors = action.payload

                return state

            case CaseworkerActionType.CLEAR_CASEWORKER_VALIDATION_ERRORS:
                state.newCaseworkerValidationErrors = []

                return state

            default:
                return state
        }
    }
)

export default reducer
