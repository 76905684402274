import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FormInput40, PrimaryLabel } from '../../styled/formElements'
import CountryCodeDropdown from '../form/CountryCodeDropdown'
import { ContactInterface } from './frames/OnboardingCreateYourLineOfContactsFrame'
import { useTranslation, useTypedSelector } from '../../../hooks'
import {
    checkEmail,
    checkName,
    checkPhone,
    checkPosition,
} from '../../../helpers/validation'

const FormRow = styled.div`
    display: flex;
    width: 80%;
    gap: 1rem;
    margin-bottom: 3rem;
`

const FormField = styled.div`
    flex: 1;
    position: relative;
`
const PhoneRow = styled.div`
    display: flex;
    width: 100%;
    gap: 1.6rem;
    flex: 5;
    position: relative;
`

const ErrorMessage = styled.p`
    color: ${({ theme }) => theme.red};
    bottom: -3.5rem;
    position: absolute;
    height: 3rem;
`

interface ContactLineFormRowProps {
    handleSetContacts: any
    contact: ContactInterface
}

const ContactLineFormRow: React.FC<ContactLineFormRowProps> = ({
    handleSetContacts,
    contact,
}) => {
    const translation = useTranslation()

    const { validationErrors } = useTypedSelector((state) => state.auth)

    interface BaseErrorStateInterface {
        firstNameError: string
        lastNameError: string
        positionError: string
        emailError: string
        phoneError: string
    }
    const baseErrorState: BaseErrorStateInterface = {
        firstNameError: '',
        lastNameError: '',
        positionError: '',
        emailError: '',
        phoneError: '',
    }

    const [errorState, setErrorState] = useState(baseErrorState)

    /* const handleSetErrorState = (
        contactId: number,
        field: string,
        error: string
    ) => {
    } */

    const contactIncludesNonEmpty: () => boolean = () => {
        return Object.entries(contact)
            .filter(([key]: any) => key !== 'country' && key !== 'id')
            .some((contact) => contact[1] !== '') // contact = ['key', 'value']
    }

    const handleOnBlur = () => {
        // Nothing has been entered for this contact
        // So we don't validate, we remove existing errors
        // Since it's allowed to have an empty contact, he will simply not be created...
        if (!contactIncludesNonEmpty()) {
            return setErrorState({
                ...errorState,
                firstNameError: '',
                lastNameError: '',
                positionError: '',
                emailError: '',
            })
        }

        setErrorState({
            firstNameError: checkName(contact.first_name, translation),
            lastNameError: checkName(contact.last_name, translation),
            positionError: checkPosition(contact.position, translation),
            emailError: checkEmail(contact.email, translation),
            phoneError: checkPhone(
                `${contact.country.calling_code} ${contact.phone}`,
                translation
            ),
        })
    }

    const handleOnFocus = (field: string) => {
        if (field === 'firstName') {
            setErrorState({
                ...errorState,
                firstNameError: '',
            })
        } else if (field === 'lastName') {
            setErrorState({
                ...errorState,
                lastNameError: '',
            })
        } else if (field === 'position') {
            setErrorState({
                ...errorState,
                positionError: '',
            })
        } else if (field === 'email') {
            setErrorState({
                ...errorState,
                emailError: '',
            })
        } else if (field === 'phone') {
            setErrorState({
                ...errorState,
                phoneError: '',
            })
        }
    }

    useEffect(() => {
        if (!validationErrors || typeof contact.id === 'undefined') {
            return
        }

        const newErrorState: BaseErrorStateInterface = { ...errorState }

        if (`contacts.${contact.id - 1}.email` in validationErrors) {
            newErrorState.emailError =
                validationErrors[`contacts.${contact.id - 1}.email`][0]
        }

        if (`contacts.${contact.id - 1}.phone` in validationErrors) {
            newErrorState.phoneError =
                validationErrors[`contacts.${contact.id - 1}.phone`][0]
        }

        setErrorState(newErrorState)
        // eslint-disable-next-line
    }, [validationErrors])

    return (
        <FormRow>
            <FormField>
                <PrimaryLabel isError={errorState.firstNameError.length > 0}>
                    {translation.onboardingPage.firstName}
                </PrimaryLabel>
                <FormInput40
                    onBlur={() => handleOnBlur()}
                    onFocus={() => handleOnFocus('firstName')}
                    isError={errorState.firstNameError.length > 0}
                    onChange={(event) =>
                        handleSetContacts(
                            'first_name',
                            event.target.value,
                            contact.id
                        )
                    }
                />
                {errorState.firstNameError.length > 0 && (
                    <ErrorMessage>{errorState.firstNameError}</ErrorMessage>
                )}
            </FormField>

            <FormField>
                <PrimaryLabel isError={errorState.lastNameError.length > 0}>
                    {translation.onboardingPage.lastName}
                </PrimaryLabel>
                <FormInput40
                    onBlur={() => handleOnBlur()}
                    onFocus={() => handleOnFocus('lastName')}
                    isError={errorState.lastNameError.length > 0}
                    onChange={(event) =>
                        handleSetContacts(
                            'last_name',
                            event.target.value,
                            contact.id
                        )
                    }
                />
                {errorState.lastNameError.length > 0 && (
                    <ErrorMessage>{errorState.lastNameError}</ErrorMessage>
                )}
            </FormField>

            <FormField>
                <PrimaryLabel isError={errorState.positionError.length > 0}>
                    {translation.onboardingPage.position}
                </PrimaryLabel>
                <FormInput40
                    onBlur={() => handleOnBlur()}
                    onFocus={() => handleOnFocus('position')}
                    isError={errorState.positionError.length > 0}
                    onChange={(event) =>
                        handleSetContacts(
                            'position',
                            event.target.value,
                            contact.id
                        )
                    }
                />
                {errorState.positionError.length > 0 && (
                    <ErrorMessage>{errorState.positionError}</ErrorMessage>
                )}
            </FormField>

            <FormField>
                <PrimaryLabel isError={errorState.emailError.length > 0}>
                    {translation.onboardingPage.email}
                </PrimaryLabel>
                <FormInput40
                    onBlur={() => handleOnBlur()}
                    onFocus={() => handleOnFocus('email')}
                    isError={errorState.emailError.length > 0}
                    type="email"
                    onChange={(event) =>
                        handleSetContacts(
                            'email',
                            event.target.value,
                            contact.id
                        )
                    }
                />
                {errorState.emailError.length > 0 && (
                    <ErrorMessage>{errorState.emailError}</ErrorMessage>
                )}
            </FormField>
            <div>
                <PrimaryLabel isError={errorState.phoneError.length > 0}>
                    {translation.onboardingPage.phone}
                </PrimaryLabel>
                <PhoneRow>
                    <CountryCodeDropdown
                        error={errorState.phoneError.length > 0}
                        success={false}
                        selectDropdownCB={(country: any) => {
                            handleOnBlur()
                            handleSetContacts('country', country, contact.id)
                        }}
                    />
                    <FormInput40
                        onBlur={() => handleOnBlur()}
                        onFocus={() => handleOnFocus('phone')}
                        isError={errorState.phoneError.length > 0}
                        onChange={(event) =>
                            handleSetContacts(
                                'phone',
                                event.target.value,
                                contact.id
                            )
                        }
                    />
                    {errorState.phoneError.length > 0 && (
                        <ErrorMessage>{errorState.phoneError}</ErrorMessage>
                    )}
                </PhoneRow>
            </div>
        </FormRow>
    )
}

export default ContactLineFormRow
