import { useEffect, useState } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { useActions, useTranslation, useTypedSelector } from '../../../../hooks'
import { OrganizationInterface } from '../../../../ts/interfaces'
import { ErrorMessage, FormField, FormInput40, PrimaryLabel } from '../../../styled/formElements'
import { HeadingPrimary } from '../../../styled/typography'
import { NextButton } from '../../buttons'
import {
    checkAddress,
    checkCity,
    checkCompanyIdentificationNumber,
    checkCompanyName,
    checkEmail,
    checkPhone,
    checkZipCode,
    isValidAddress,
    isValidCompanyIdentificationNumber,
    isValidCompanyName,
    isValidEmail,
    isValidPhone,
    isValidZipCode,
} from '../../../../helpers/validation'
import CountryCodeDropdown from '../../form/CountryCodeDropdown'
import Spinner from '../../../layout/spinner/Spinner'

const Container = styled.div`
    width: 70rem;
    margin: 0 auto;
    padding-top: 12rem;
    text-align: center;
    padding-bottom: 6rem;
`

const Form = styled.form`
    padding-top: 6.6rem;
    margin: 0 auto;
`

const FormContainer = styled.div`
    width: 100%;
    display: flex;
    gap: 16px;
`

const FormHalf = styled.div`
    width: 50%;
    text-align: left;
`

const FlexInputContainer = styled.div`
    display: flex;
    gap: 16px;
`

const PhoneInputContainer = styled.div`
    width: 75%;
`

const CityInputContianer = styled.div`
    width: 60%;
`

const ZipInputContianer = styled.div`
    width: 40%;
`
const NextButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 4rem;
`

const SpinnerContainer = styled.div`
    padding-top: 6rem;
`

interface CompanyFormFrameProps {
    organization: OrganizationInterface
    updateOrganization: (key: string, value: null | string | number) => void
    setCurrentPageNumber: (pageNumber: number) => void
    updateOrganizationCountry: (country: any) => void
}

const CompanyFormFrame: React.FC<CompanyFormFrameProps> = ({
    organization,
    updateOrganization,
    setCurrentPageNumber,
    updateOrganizationCountry,
}) => {
    const translation = useTranslation()

    const { validationErrors, authLoading } = useTypedSelector(
        (state) => state.auth
    )
    const { verifyCompanyInformation } = useActions()

    useEffect(() => {
        if (validationErrors) {
            if (validationErrors['email']) {
                setFormFields((prevState) => ({
                    ...prevState,
                    email: {
                        ...formFields.email,
                        errorMessage: validationErrors['email'][0],
                    },
                }))
            }

            if (validationErrors['phone_number']) {
                setPhoneError(validationErrors['phone_number'][0])
            }

            if (validationErrors['vat']) {
                setFormFields((prevState) => ({
                    ...prevState,
                    cprNumber: {
                        ...formFields.cprNumber,
                        errorMessage: validationErrors['vat'][0],
                    },
                }))
            }
        }
        // eslint-disable-next-line
    }, [validationErrors])

    const {
        cprNumber,
        organizationName,
        email,
        countryCode,
        phone,
        address,
        city,
        zipCode,
    } = organization

    const validationOnFocusCallback = (key: string) => {
        setError(key, '', true) // Clear the error
    }

    const validationBlurCallback = (key: string) => {
        if (key === 'email') {
            setError('email', checkEmail(email, translation))
        } else if (key === 'cprNumber') {
            setError(
                'cprNumber',
                checkCompanyIdentificationNumber(cprNumber, translation)
            )
        } else if (key === 'address') {
            setError('address', checkAddress(address, translation))
        }
    }

    const formFieldObj = {
        cprNumber: {
            label: translation.onboardingFlow.companyNumber,
            placeholder: translation.onboardingFlow.enterCompanyNumber,
            type: 'number',
            key: 'cprNumber',
            value: cprNumber,
            errorMessage: '',
            success: false,
        },
        email: {
            label: translation.onboardingFlow.workEmail,
            placeholder: translation.onboardingFlow.enterWorkEmail,
            type: 'text',
            key: 'email',
            value: email,
            errorMessage: '',
            success: false,
        },
        address: {
            label: translation.onboardingFlow.address,
            placeholder: translation.onboardingFlow.enterAddress,
            type: 'text',
            key: 'address',
            value: address,
            errorMessage: '',
            success: false,
        },
    }

    const [formFields, setFormFields] = useState(formFieldObj)

    useEffect(() => {
        setFormFields(formFieldObj)

        // eslint-disable-next-line
    }, [translation])

    const [organizationError, setOrganizationError] = useState('')
    const [organizationSuccess, setOrganizationSuccess] = useState(false)
    const [phoneError, setPhoneError] = useState('')
    const [phoneSuccess, setPhoneSuccess] = useState(false)
    const [cityError, setCityError] = useState('')
    const [citySuccess, setCitySuccess] = useState(false)
    const [zipCodeError, setZipCodeError] = useState('')
    const [zipCodeSuccess, setZipCodeSuccess] = useState(false)

    // Comapny
    const handleCompanyValidation = () => {
        const validationError = checkCompanyName(organizationName, translation)

        !validationError && setOrganizationSuccess(true)
        setOrganizationError(validationError)
    }

    const handleCompanyOnFocus = () => {
        setOrganizationError('')
        setOrganizationSuccess(false)
    }

    // Phone
    const handlePhoneValidation = () => {
        const validationError = checkPhone(
            `${countryCode + phone}`,
            translation
        )

        !validationError && setPhoneSuccess(true)
        setPhoneError(validationError)
    }

    const handlePhoneOnFocus = () => {
        setPhoneError('')
        setPhoneSuccess(false)
    }

    //  City
    const handleCityValidation = () => {
        const validationError = checkCity(city, translation)

        !validationError && setCitySuccess(true)
        setCityError(validationError)
    }

    const handleCityOnFocus = () => {
        setCityError('')
        setCitySuccess(false)
    }

    // Zip code
    const handleZipCodeValidation = () => {
        const validationError = checkZipCode(zipCode, translation)

        !validationError && setZipCodeSuccess(true)
        setZipCodeError(validationError)
    }

    const handleZipCodeOnFocus = () => {
        setZipCodeError('')
        setZipCodeSuccess(false)
    }

    // const setSuccess = (key: string) => {
    //     _.forOwn(formFields, (field) => {
    //         setFormFields({
    //             ...formFields,
    //             [key]: {
    //                 success: true,
    //             },
    //         })
    //     })
    // }

    const setError = (
        key: string,
        errorMsg: string,
        onFocus: boolean = false
    ) => {
        const success = errorMsg.length || onFocus ? false : true
        _.forOwn(formFields, (field) => {
            if (field.key === key) {
                // @ts-ignore
                setFormFields({
                    ...formFields,
                    [key]: {
                        // @ts-ignore
                        ...formFields[key],
                        errorMessage: errorMsg,
                        success: success,
                    },
                })
            }
        })
    }

    const [isFormValidated, setIsFormValidated] = useState(false)

    useEffect(() => {
        if (
            !isValidCompanyIdentificationNumber(cprNumber) ||
            !isValidEmail(email) ||
            !isValidAddress(address) ||
            !isValidCompanyName(organizationName) ||
            !isValidPhone(`${countryCode + phone}`) ||
            !isValidZipCode(zipCode)
        ) {
            return setIsFormValidated(false)
        }

        setIsFormValidated(true)
        // eslint-disable-next-line
    }, [organization])

    // validate fields after selecting company from dropdown
    useEffect(() => {
        // organizationName.length && handleCompanyValidation()
        // phone.length && handlePhoneValidation()
        // city.length && handleCityValidation()
        // zipCode.length && handleZipCodeValidation()
        // cprNumber.length && setSuccess('cprNumber')
        // email.length && setSuccess('email')
        // address.length && validationBlurCallback('address')
    }, [])

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        if (isFormValidated) {
            const success = await verifyCompanyInformation(organization)

            // @ts-ignore
            if (success) {
                setCurrentPageNumber(3)
            }
        }
    }

    return (
        <Container>
            <HeadingPrimary>
                {translation.onboardingFlow.companyInformation}
            </HeadingPrimary>
            {authLoading ? (
                <SpinnerContainer>
                    <Spinner />
                </SpinnerContainer>
            ) : (
                <Form onSubmit={(e) => handleSubmit(e)}>
                    <FormContainer>
                        <FormHalf>
                            {Object.values(formFields).map((field) => (
                                <FormField key={field.key}>
                                    <PrimaryLabel
                                        isError={
                                            field.errorMessage ? true : false
                                        }
                                        isSuccess={field.success}
                                    >
                                        {field.label}
                                    </PrimaryLabel>
                                    <FormInput40
                                        onFocus={() =>
                                            validationOnFocusCallback(field.key)
                                        }
                                        onBlur={() =>
                                            validationBlurCallback(field.key)
                                        }
                                        className={
                                            field.errorMessage
                                                ? 'border-error'
                                                : field.success
                                                ? 'border-success'
                                                : ''
                                        }
                                        placeholder={field.placeholder}
                                        type={field.type}
                                        // @ts-ignore
                                        value={organization[field.key]}
                                        onChange={(e) =>
                                            updateOrganization(
                                                field.key,
                                                e.target.value
                                            )
                                        }
                                    />
                                    {field.errorMessage && (
                                        <ErrorMessage>
                                            {field.errorMessage}
                                        </ErrorMessage>
                                    )}
                                </FormField>
                            ))}
                        </FormHalf>
                        <FormHalf>
                            <FormField>
                                <PrimaryLabel
                                    isError={organizationError ? true : false}
                                    isSuccess={organizationSuccess}
                                >
                                    {translation.onboardingFlow.companyName}
                                </PrimaryLabel>
                                <FormInput40
                                    onFocus={handleCompanyOnFocus}
                                    onBlur={handleCompanyValidation}
                                    className={
                                        organizationError
                                            ? 'border-error'
                                            : organizationSuccess
                                            ? 'border-success'
                                            : ''
                                    }
                                    placeholder={
                                        translation.onboardingFlow
                                            .enterCompanyName
                                    }
                                    type="text"
                                    value={organizationName}
                                    onChange={(e) =>
                                        updateOrganization(
                                            'organizationName',
                                            e.target.value
                                        )
                                    }
                                />
                                {organizationError && (
                                    <ErrorMessage>
                                        {organizationError}
                                    </ErrorMessage>
                                )}
                            </FormField>
                            <FormField>
                                <PrimaryLabel
                                    isError={phoneError ? true : false}
                                    isSuccess={phoneSuccess}
                                >
                                    {translation.onboardingFlow.phone}
                                </PrimaryLabel>
                                <FlexInputContainer>
                                    <CountryCodeDropdown
                                        error={phoneError.length > 1}
                                        success={phoneSuccess}
                                        selectDropdownCB={
                                            updateOrganizationCountry
                                        }
                                    />
                                    <PhoneInputContainer>
                                        <FormInput40
                                            onFocus={handlePhoneOnFocus}
                                            onBlur={handlePhoneValidation}
                                            className={
                                                phoneError
                                                    ? 'border-error'
                                                    : phoneSuccess
                                                    ? 'border-success'
                                                    : ''
                                            }
                                            placeholder={
                                                translation.onboardingFlow
                                                    .enterPhone
                                            }
                                            type="text"
                                            value={phone}
                                            onChange={(e) =>
                                                updateOrganization(
                                                    'phone',
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </PhoneInputContainer>
                                </FlexInputContainer>
                                {phoneError && (
                                    <ErrorMessage>{phoneError}</ErrorMessage>
                                )}
                            </FormField>
                            <FormField>
                                <FlexInputContainer>
                                    <CityInputContianer>
                                        <PrimaryLabel
                                            isError={cityError ? true : false}
                                            isSuccess={citySuccess}
                                        >
                                            {translation.onboardingFlow.city}
                                        </PrimaryLabel>
                                        <FormInput40
                                            onFocus={handleCityOnFocus}
                                            onBlur={handleCityValidation}
                                            className={
                                                cityError
                                                    ? 'border-error'
                                                    : citySuccess
                                                    ? 'border-success'
                                                    : ''
                                            }
                                            placeholder={
                                                translation.onboardingFlow
                                                    .enterCity
                                            }
                                            type="text"
                                            value={city}
                                            onChange={(e) =>
                                                updateOrganization(
                                                    'city',
                                                    e.target.value
                                                )
                                            }
                                        />
                                        {cityError && (
                                            <ErrorMessage>
                                                {cityError}
                                            </ErrorMessage>
                                        )}
                                    </CityInputContianer>
                                    <ZipInputContianer>
                                        <PrimaryLabel
                                            isError={
                                                zipCodeError ? true : false
                                            }
                                            isSuccess={zipCodeSuccess}
                                        >
                                            {translation.onboardingFlow.zipCode}
                                        </PrimaryLabel>
                                        <FormInput40
                                            onFocus={handleZipCodeOnFocus}
                                            onBlur={handleZipCodeValidation}
                                            className={
                                                zipCodeError
                                                    ? 'border-error'
                                                    : zipCodeSuccess
                                                    ? 'border-success'
                                                    : ''
                                            }
                                            placeholder={
                                                translation.onboardingFlow
                                                    .enterZipCode
                                            }
                                            type="text"
                                            value={zipCode}
                                            onChange={(e) =>
                                                updateOrganization(
                                                    'zipCode',
                                                    e.target.value
                                                )
                                            }
                                        />
                                        {zipCodeError && (
                                            <ErrorMessage>
                                                {zipCodeError}
                                            </ErrorMessage>
                                        )}
                                    </ZipInputContianer>
                                </FlexInputContainer>
                            </FormField>
                        </FormHalf>
                    </FormContainer>
                    <NextButtonContainer>
                        <NextButton isActive={isFormValidated} />
                    </NextButtonContainer>
                </Form>
            )}
        </Container>
    )
}

export default CompanyFormFrame
