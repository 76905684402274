import styled from 'styled-components'
import { HeadingSecondary } from '../../../../styled/typography'
import SearchInput from '../../../../reusable/form/SearchInput'
import { useEffect } from 'react'
import { FlexJustSpaceBetween } from '../../../../styled/flex'
import BackButton from '../../../../reusable/buttons/BackButton'
import Cases from '../../../../reusable/cases/Cases'
import { useActions, useTranslation, useTypedSelector } from '../../../../../hooks'

const Container = styled.div`
    width: 100%;
    padding: 4rem;

    position: relative;
`

const CasesContainer = styled.div`
    margin-top: 2rem;
    height: 40rem;
`

interface ClosedCasesProps {
    setCurrentFrameNumber: (frameNumber: number) => void
}

const ClosedCases: React.FC<ClosedCasesProps> = ({ setCurrentFrameNumber }) => {
    const { organization, closedReportsFiltered, closedReportsSearchString } =
        useTypedSelector((state) => state.caseworker)
    const { getClosedReports, searchClosedReports } = useActions()

    const translation = useTranslation()

    useEffect(() => {
        organization && getClosedReports(organization.id)
        // eslint-disable-next-line
    }, [])

    const handleSearch = (text: string) => {
        searchClosedReports(text)
    }

    return (
        <Container>
            <BackButton onClick={() => setCurrentFrameNumber(1)} />
            <FlexJustSpaceBetween>
                <HeadingSecondary>
                    {translation.casesPartner.forwardedCases}
                </HeadingSecondary>
                <SearchInput
                    placeholder={translation.casesPartner.searchCases}
                    text={closedReportsSearchString}
                    onChangeCB={handleSearch}
                    onClearSearch={() => searchClosedReports('')}
                />
            </FlexJustSpaceBetween>
            <CasesContainer>
                {closedReportsFiltered && (
                    <Cases
                        cases={closedReportsFiltered}
                        title={translation.casesPartner.forwardedCases}
                        isAllClosed={true}
                        height='27.2rem'
                    />
                )}
            </CasesContainer>
        </Container>
    )
}

export default ClosedCases
