import styled from 'styled-components'
import {
    DeleteButton,
    DeleteButtonText,
    DeleteIcon,
    SaveButton,
    SaveButtonText,
    SaveIcon,
} from '../../../../../styled/buttons'
import ButtonSpinnerWhite from '../../../../../layout/spinner/ButtonSpinnerWhite'
import React from 'react'
import { useActions, useTheme, useTypedSelector } from '../../../../../../hooks'

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

interface PaymentMethodCrudButtonsProps {
    paymentMethod: any
}

const PaymentMethodCrudButtons: React.FC<PaymentMethodCrudButtonsProps> = ({
    paymentMethod,
}) => {
    const {
        defaultPaymentMethod,
        paymentMethodBeingMadeDefaultLoading,
        paymentMethodBeingDeletedLoading,
    } = useTypedSelector((state) => state.payment)

    const { user } = useTypedSelector((state) => state.auth)

    const { updateDefaultPaymentMethod, deletePaymentMethod } = useActions()

    const theme = useTheme()

    const handleDefaultUpdatePaymentMethod = async () => {
        if (!user || typeof user === 'undefined') {
            return false
        }

        await updateDefaultPaymentMethod(user.userId, paymentMethod.id)
    }

    const handleDeletePaymentMethod = async () => {
        if (!user || typeof user === 'undefined') {
            return false
        }

        await deletePaymentMethod(user.userId, paymentMethod.id)
    }

    return (
        <ButtonContainer>
            {defaultPaymentMethod?.id !== paymentMethod.id && (
                <SaveButton onClick={handleDefaultUpdatePaymentMethod}>
                    {paymentMethodBeingMadeDefaultLoading !==
                    paymentMethod.id ? (
                        <>
                            <SaveButtonText>Make default</SaveButtonText>
                            <SaveIcon />
                        </>
                    ) : (
                        <ButtonSpinnerWhite overrideColor={theme.blue} />
                    )}
                </SaveButton>
            )}
            {/* <EditButton>
                <EditButtonText>Edit</EditButtonText>
                <EditIcon />
            </EditButton> */}
            <DeleteButton onClick={handleDeletePaymentMethod}>
                {paymentMethodBeingDeletedLoading !== paymentMethod.id ? (
                    <>
                        <DeleteButtonText>Delete</DeleteButtonText>
                        <DeleteIcon />
                    </>
                ) : (
                    <ButtonSpinnerWhite overrideColor={theme.red} />
                )}
            </DeleteButton>
        </ButtonContainer>
    )
}

export default PaymentMethodCrudButtons
