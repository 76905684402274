import React from 'react'
import styled from 'styled-components'
import { useTypedSelector } from '../../../../../../../hooks'

interface CommentFrameProps {
}

const Comment = styled.p`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.primary};
  padding: 1.6rem 2.8rem;
  white-space: pre-wrap;
`

const CommentFrame: React.FC<CommentFrameProps> = () => {
    const { openReport } = useTypedSelector(state => state.caseworkerOpenReport)

    return <Comment>{openReport?.forward.data.comment}</Comment>
}

export default CommentFrame