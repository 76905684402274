import Translation from '../../state/state-types/TranslationStateType'

export const isValidCity = (city: string) => {
    if (city.length <= 1) {
        return false
    } else if (city.length > 85) {
        return false
    }

    return true
}

export const checkCity = (city: string, translation: Translation) => {
    if (city.length <= 1) {
        return translation.validation.nameOfTheCityMustBeAtLeast
    } else if (city.length > 85) {
        return translation.validation.nameOfTheCityCannot
    }

    return ''
}
