import produce from 'immer'
import { ReportHistoryLogActionType } from '../action-types'
import { ReportHistoryLogAction } from '../actions'

interface ReportHistoryLogState {
    logs: any
    loading: boolean
    error: null | string
}

const initialState: ReportHistoryLogState = {
    logs: [],
    loading: false,
    error: null,
}

const reducer = produce(
    (
        state: ReportHistoryLogState = initialState,
        action: ReportHistoryLogAction
    ) => {
        switch (action.type) {
            case ReportHistoryLogActionType.GET_HISTORY_LOG:
                state.logs = action.payload

                return state
            default:
                return state
        }
    }
)

export default reducer
