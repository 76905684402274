import { useEffect } from 'react'
import styled from 'styled-components'
import { useActions } from '../../../hooks'
import Spinner from '../../layout/spinner/Spinner'
import { useHistory } from 'react-router'

const Container = styled.div`
    height: calc(100vh - 8.5rem);
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
`

const Logout: React.FC = () => {
    const { logout } = useActions()

    const history = useHistory()

    const handleLogout = async () => {
        await logout()

        history.push('/')
    }

    useEffect(() => {
        handleLogout()

        // eslint-disable-next-line
    }, [])
    return (
        <Container>
            <Spinner />
        </Container>
    )
}

export default Logout
