import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Card } from '../../../../../styled/cards'
import { PrimaryLabel, FormInput40 } from '../../../../../styled/formElements'
import CountryCodeDropdown from '../../../../../reusable/form/CountryCodeDropdown'
import { NextButton } from '../../../../../reusable/buttons'
import {
    checkEmail,
    checkName,
    checkPhone,
    isValidEmail,
    isValidName,
    isValidPhone,
} from '../../../../../../helpers/validation'
import {
    useActions,
    useTranslation,
    useTypedSelector,
} from '../../../../../../hooks'
import {
    IntegrationPartnerInterface,
    PersonalInformation,
} from '../../NewestRegisterPage'

const Agreement = styled.div`
    font-size: 1.2rem;
    line-height: 2.8rem;
    font-weight: 400;
    color: ${({ theme }) => theme.black};
    text-align: center;
    display: flex;
    align-content: center;
    height: 2rem;
    justify-content: center;
    margin-top: 1rem;
`

const Label = styled.label`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

const Radio = styled.input`
    width: 1.6rem;
    height: 1.6rem;
    margin-right: 0.5rem;
    cursor: pointer;
    &:checked &::after {
        content: '\f00c';
        color: ${({ theme }) => theme.blue};
        font-family: 'FontAwesome';
    }
`

const Container = styled.div`
    padding-top: 7.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
`

const Heading = styled.h3`
    font-size: 3.2rem;
    font-weight: 600;
    padding-top: 4rem;
    color: ${({ theme }) => theme.primary};
`

const Tagline = styled.p`
    font-size: 1.6rem;
    padding-top: 3rem;
    color: ${({ theme }) => `${theme.primary}90`};
`

const TaglineSpan = styled.span`
    color: ${({ theme }) => theme.blue};
    font-weight: 600;
`

const Form = styled.form`
    width: 37rem;
    padding-top: 4rem;
`

const Field = styled.div`
    width: 100%;
    padding-bottom: 1.4rem;
    position: relative;
`

const PhoneRow = styled.div`
    display: flex;
    width: 100%;
    gap: 1.6rem;
`

const ErrorMessage = styled.div`
    color: ${({ theme }) => theme.red};
    position: absolute;
    font-size: 1rem;
`

const NextButtonContainer = styled.div`
    padding-top: 5rem;
    display: flex;
    justify-content: center;
`

interface PersonalInformationFrameProps {
    setCurrentStep: (value: number) => void
    personalInformation: PersonalInformation
    handleSetPersonalInformation: (event: ChangeEvent<HTMLFormElement>) => void
    setPersonalInformation: (val: any) => void
    integrationPartner: null | IntegrationPartnerInterface
}

const PersonalInformationFrame: React.FC<PersonalInformationFrameProps> = ({
    setCurrentStep,
    personalInformation,
    handleSetPersonalInformation,
    setPersonalInformation,
    integrationPartner,
}) => {
    const translation = useTranslation()

    const { verifyCompanyUserInformation } = useActions()

    const { authLoading, validationErrors } = useTypedSelector(
        (state) => state.auth
    )

    const handleSetCountryCode = (country: any) => {
        setPersonalInformation({
            ...personalInformation,
            country: country,
        })
    }

    useEffect(() => {
        if (!personalInformation.phoneNumber.length) {
            return
        }

        if (
            !isValidPhone(
                `${personalInformation.country.calling_code} ${personalInformation.phoneNumber}`
            )
        ) {
            return setPhoneNumberError(
                checkPhone(
                    `${personalInformation.country.calling_code} ${personalInformation.phoneNumber}`,
                    translation
                )
            )
        }

        setPhoneNumberError('')
        return setPhoneNumberSuccess(true)

        // eslint-disable-next-line
    }, [personalInformation.country, translation])

    // Handle showing form errors
    const [firstNameError, setFirstNameError] = useState('')
    const [lastNameError, setLastNameError] = useState('')
    const [phoneNumberError, setPhoneNumberError] = useState('')
    const [emailError, setEmailError] = useState('')

    const [firstNameSuccess, setFirstNameSuccess] = useState(false)
    const [lastNameSuccess, setLastNameSuccess] = useState(false)
    const [phoneNumberSuccess, setPhoneNumberSuccess] = useState(false)
    const [emailSuccess, setEmailSuccess] = useState(false)

    const [drcChecked, setDrcChecked] = useState(false)

    const handleFocus = (event: any) => {
        const name = event.target.name

        if (name === 'firstName') {
            setFirstNameError('')
        } else if (name === 'lastName') {
            setLastNameError('')
        } else if (name === 'phoneNumber') {
            setPhoneNumberError('')
        } else if (name === 'email') {
            setEmailError('')
        }
    }

    const handleBlur = (event: any) => {
        const name = event.target.name

        if (name === 'firstName') {
            if (!isValidName(personalInformation.firstName)) {
                return setFirstNameError(
                    checkName(personalInformation.firstName, translation)
                )
            }

            return setFirstNameSuccess(true)
        } else if (name === 'lastName') {
            if (!isValidName(personalInformation.lastName)) {
                return setLastNameError(
                    checkName(personalInformation.lastName, translation)
                )
            }

            return setLastNameSuccess(true)
        } else if (name === 'phoneNumber') {
            if (
                !isValidPhone(
                    `${personalInformation.country.calling_code} ${personalInformation.phoneNumber}`
                )
            ) {
                return setPhoneNumberError(
                    checkPhone(
                        `${personalInformation.country.calling_code} ${personalInformation.phoneNumber}`,
                        translation
                    )
                )
            }

            return setPhoneNumberSuccess(true)
        } else if (name === 'email') {
            if (!isValidEmail(personalInformation.email)) {
                return setEmailError(
                    checkEmail(personalInformation.email, translation)
                )
            }

            return setEmailSuccess(true)
        }
    }

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        const success: any = await verifyCompanyUserInformation(
            personalInformation
        )

        success && setCurrentStep(2)
    }

    const isValidForm = () =>
        isValidName(personalInformation.firstName) &&
        isValidName(personalInformation.lastName) &&
        isValidPhone(
            `${personalInformation.country.calling_code} ${personalInformation.phoneNumber}`
        ) &&
        isValidEmail(personalInformation.email) &&
        (drcChecked || !integrationPartner)

    useEffect(() => {
        if (validationErrors) {
            if ('email' in validationErrors) {
                setEmailError(validationErrors.email[0])
                setEmailSuccess(false)
            }

            if ('phone' in validationErrors) {
                setPhoneNumberError(validationErrors.phone[0])
                setPhoneNumberSuccess(false)
            }
        }
    }, [validationErrors])

    return (
        <Container>
            <Heading data-testid="personal-information-frame">
                {translation.registerPage.personalInformation}
            </Heading>
            <Tagline>
                {translation.registerPage.noCreditCardRequired}{' '}
                <TaglineSpan>{translation.registerPage.freeTrial}</TaglineSpan>{' '}
                {translation.registerPage.today}
            </Tagline>
            <Form
                onChange={handleSetPersonalInformation}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onSubmit={handleSubmit}
            >
                <Card
                    style={{
                        padding: '3rem 2rem',
                        overflow: 'visible',
                    }}
                >
                    <Field>
                        <PrimaryLabel
                            isSuccess={firstNameSuccess}
                            data-testid="first-name-label"
                        >
                            {translation.registerPage.firstname}
                        </PrimaryLabel>
                        <FormInput40
                            name="firstName"
                            defaultValue={personalInformation.firstName}
                            isError={firstNameError.length > 0}
                            isSuccess={firstNameSuccess}
                            placeholder="John"
                            data-testid="first-name-input"
                        />
                        {firstNameError && (
                            <ErrorMessage data-testid="register-first-name-error">
                                {firstNameError}
                            </ErrorMessage>
                        )}
                    </Field>
                    <Field>
                        <PrimaryLabel
                            isSuccess={lastNameSuccess}
                            data-testid="last-name-label"
                        >
                            {translation.registerPage.lastName}
                        </PrimaryLabel>
                        <FormInput40
                            name="lastName"
                            defaultValue={personalInformation.lastName}
                            isError={lastNameError.length > 0}
                            isSuccess={lastNameSuccess}
                            placeholder="Doe"
                            data-testid="last-name-input"
                        />
                        {lastNameError && (
                            <ErrorMessage data-testid="register-last-name-error">
                                {lastNameError}
                            </ErrorMessage>
                        )}
                    </Field>
                    <Field>
                        <PrimaryLabel
                            isSuccess={phoneNumberSuccess}
                            data-testid="phone-number-label"
                        >
                            {translation.registerPage.phoneNumber}
                        </PrimaryLabel>
                        <PhoneRow>
                            <CountryCodeDropdown
                                error={phoneNumberError.length > 0}
                                success={phoneNumberSuccess}
                                selectDropdownCB={(country: any) =>
                                    handleSetCountryCode(country)
                                }
                            />
                            <FormInput40
                                name="phoneNumber"
                                defaultValue={personalInformation.phoneNumber}
                                isError={phoneNumberError.length > 0}
                                isSuccess={phoneNumberSuccess}
                                placeholder="12345678"
                                data-testid="phone-number-input"
                            />
                        </PhoneRow>
                        {phoneNumberError && (
                            <ErrorMessage data-testid="register-phone-number-error">
                                {phoneNumberError}
                            </ErrorMessage>
                        )}
                    </Field>
                    <Field>
                        <PrimaryLabel
                            isSuccess={emailSuccess}
                            data-testid="email-label"
                        >
                            {translation.registerPage.email}
                        </PrimaryLabel>
                        <FormInput40
                            name="email"
                            defaultValue={personalInformation.email}
                            isError={emailError.length > 0}
                            isSuccess={emailSuccess}
                            placeholder="example@example.com"
                            data-testid="email-input"
                        />
                        {emailError && (
                            <ErrorMessage data-testid="register-email-error">
                                {emailError}
                            </ErrorMessage>
                        )}
                    </Field>
                </Card>
                {integrationPartner && (
                    <Agreement>
                        <Radio
                            onChange={() => setDrcChecked(!drcChecked)}
                            type="checkbox"
                            id="drc-radio"
                        />
                        <Label htmlFor="drc-radio">
                            {translation.registerPage.areYouAMemberOf}{' '}
                            {integrationPartner?.name}?
                        </Label>
                    </Agreement>
                )}
                <NextButtonContainer>
                    <NextButton
                        isLoading={authLoading}
                        isActive={isValidForm()}
                    />
                </NextButtonContainer>
            </Form>
        </Container>
    )
}

export default PersonalInformationFrame
