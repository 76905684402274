import styled from 'styled-components'
import { FiCheckCircle } from 'react-icons/fi'
import { useEffect, useState } from 'react'
import { NextButton } from '../../../../reusable/buttons'
import { SmallWhiteActiveButton } from '../../../../styled/buttons'
import { useTheme } from '../../../../../hooks/useTheme'
import { HeadingPrimary } from '../../../../styled/typography'
import { LoginInfo } from '../LoginPage'
import {
    useActions,
    useTranslation,
    useTypedSelector,
} from '../../../../../hooks'
import Spinner from '../../../../layout/spinner/Spinner'

const Container = styled.form`
    margin: 0 auto;
    padding-top: 22rem;
    text-align: center;
`

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 6rem;
`

// const ButtonSeperator = styled.p`
//     margin: 0 2.4rem;
//     font-size: 1.6rem;
//     color: ${({ theme }) => theme.black};
//     opacity: 0.8;
// `

const NextButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 4rem;
`

const SpinnerContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 8rem;
`

const ErrorMessage = styled.p`
    font-size: 1.2rem;
    color: ${({ theme }) => theme.red};
    margin-top: 1.2rem;
`

interface SelectAuthMethodProps {
    setCurrentPageNumber: (pageNumber: number) => void
    loginInfo: LoginInfo
    updateLoginInfo: (key: string, value: string) => void
}

const SelectAuthMethod: React.FC<SelectAuthMethodProps> = ({
    setCurrentPageNumber,
    loginInfo,
    updateLoginInfo,
}) => {
    const translation = useTranslation()
    const theme = useTheme()

    const { verification_method, email, phone } = loginInfo
    const { authLoading, authError } = useTypedSelector((state) => state.auth)
    const { send2FA } = useActions()

    const [activeMethod, setActiveMethod] = useState<null | string>(null)

    const handleChangeMethod = (method: 'sms' | 'email') => {
        setActiveMethod(method)
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        if (activeMethod) {
            const sendTo = verification_method === 'sms' ? phone : email
            await send2FA(verification_method, sendTo)
        }
    }

    useEffect(() => {
        if (activeMethod === 'sms') {
            updateLoginInfo('verification_method', 'sms')
        } else if (activeMethod === 'email') {
            updateLoginInfo('verification_method', 'email')
        }
        // eslint-disable-next-line
    }, [activeMethod])

    return (
        <Container onSubmit={(e) => handleSubmit(e)}>
            <HeadingPrimary>
                {translation.loginFlow.howDoYouWant}
            </HeadingPrimary>
            {!authLoading ? (
                <>
                    <ButtonContainer>
                        {/* <SmallWhiteActiveButton
                            active={activeMethod === 'sms'}
                            onClick={() => handleChangeMethod('sms')}
                        >
                            {activeMethod === 'sms' && (
                                <FiCheckCircle
                                    size={16}
                                    color={theme.white}
                                    style={{ marginRight: 12 }}
                                />
                            )}
                            {translation.loginFlow.buttonText}
                        </SmallWhiteActiveButton>
                        <ButtonSeperator>
                            {translation.loginFlow.or}
                        </ButtonSeperator> */}
                        <SmallWhiteActiveButton
                            active={activeMethod === 'email'}
                            onClick={() => handleChangeMethod('email')}
                        >
                            {activeMethod === 'email' && (
                                <FiCheckCircle
                                    size={16}
                                    color={theme.white}
                                    style={{ marginRight: 12 }}
                                />
                            )}
                            {translation.loginFlow.buttonEmail}
                        </SmallWhiteActiveButton>
                    </ButtonContainer>
                    {authError && <ErrorMessage>{authError}</ErrorMessage>}
                    <NextButtonContainer>
                        <NextButton
                            text={translation.loginFlow.login}
                            isActive={activeMethod ? true : false}
                            width="133px"
                            icon={false}
                            isLoading={authLoading}
                        />
                    </NextButtonContainer>
                </>
            ) : (
                <>
                    <SpinnerContainer>
                        <Spinner />
                    </SpinnerContainer>
                </>
            )}
        </Container>
    )
}

export default SelectAuthMethod
