const partiallyHideEmail = (email: string) => {
    let hide = email.split('@')[0].length - 2 //<-- number of characters to hide

    var r = new RegExp('.{' + hide + '}@', 'g')

    email = email.replace(r, '***@')

    return email
}

export default partiallyHideEmail
