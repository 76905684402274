import { ReportQuestionInterface } from './../../ts/interfaces/ReportQuestionInterface';
import axios from "axios"
import config from '../../config'
import { Dispatch } from 'redux'
import { ReportQuestionActionType } from '../action-types';

const setReportQuestionLoading = () => {
    return {
        type: ReportQuestionActionType.SET_REPORT_QUESTION_LOADING
    }

}

const { apiUrl } = config

export const addReportQuestion = (
    organizationId: number,
    payload: ReportQuestionInterface
) =>
    async (dispatch: Dispatch) => {
        dispatch(setReportQuestionLoading())

        const res = await axios.post(
            `${apiUrl}/v1/organizations/${organizationId}/questions`,
            payload,
        )

        if (res.status !== 200) {
            return false
        }

        dispatch({
            type: ReportQuestionActionType.ADD_REPORT_QUESTION,
            payload: res.data.data,
        })

        return true
    }

export const getReportQuestions = (
    organizationId: number
) =>
    async (dispatch: Dispatch) => {
        dispatch(setReportQuestionLoading())

        const res = await axios.get(
            `${apiUrl}/v1/organizations/${organizationId}/questions`
        )

        if (res.status !== 200) {
            return false
        }

        dispatch({
            type: ReportQuestionActionType.GET_REPORT_QUESTIONS,
            payload: res.data.data,
        })

        return true

    }

export const putReportQuestion = (
    organizationId: number,
    questionItem: ReportQuestionInterface,
    payload: any
) =>
    async (dispatch: Dispatch) => {
        dispatch(setReportQuestionLoading())

        const res = await axios.put(
            `${apiUrl}/v1/organizations/${organizationId}/questions/${questionItem.id}`,
            payload,
        )

        if (res.status !== 200) {
            return false
        }

        const questionPayload = {
            oldId: questionItem.id,
            newQuestion: res.data.data
        }

        dispatch({
            type: ReportQuestionActionType.PUT_REPORT_QUESTION,
            payload: questionPayload
        })

        return true

    }

export const deleteReportQuestion = (
    organizationId: number,
    questionItem: ReportQuestionInterface
) =>
    async (dispatch: Dispatch) => {
        dispatch(setReportQuestionLoading())

        const res = await axios.delete(
            `${apiUrl}/v1/organizations/${organizationId}/questions/${questionItem.id}`
        )

        if (res.status !== 200) {
            return false
        }

        dispatch({
            type: ReportQuestionActionType.DELETE_REPORT_QUESTION,
            payload: questionItem.id,
        })

        return true

    }