import produce from 'immer'
import { CaseworkerOpenReportAction } from '../actions/'
import { CaseworkerOpenReportActionType } from '../action-types'

interface CaseworkerOpenReportState {
    openReportLoading: boolean
    openReportError: null | string
    openReport: any
    openReportMessagesLoading: boolean
    openReportMessagesError: null | string
    openReportMessages: any[]
    addMessageLoading: boolean
    changeResponsibleLoading: boolean
    updateCategoryLoading: boolean
    forwardReportLoading: boolean
    forwardReportError: null | string
    updateStatusLoading: boolean
    closeReportLoading: boolean
}

const initialState: CaseworkerOpenReportState = {
    openReportLoading: false,
    openReportError: null,
    openReport: null,
    openReportMessagesLoading: false,
    openReportMessagesError: null,
    openReportMessages: [],
    addMessageLoading: false,
    changeResponsibleLoading: false,
    updateCategoryLoading: false,
    forwardReportLoading: false,
    forwardReportError: null,
    updateStatusLoading: false,
    closeReportLoading: false,
}

const reducer = produce(
    (
        state: CaseworkerOpenReportState = initialState,
        action: CaseworkerOpenReportAction
    ) => {
        switch (action.type) {
            case CaseworkerOpenReportActionType.SET_GET_SINGLE_REPORT_LOADING:
                state.openReportLoading = true

                return state

            case CaseworkerOpenReportActionType.SET_GET_SINGLE_REPORT_ERROR:
                state.openReportError = action.payload

                return state

            case CaseworkerOpenReportActionType.GET_SINGLE_REPORT:
                state.openReportLoading = false
                state.openReportError = null
                state.openReport = action.payload

                return state

            case CaseworkerOpenReportActionType.SET_GET_REPORT_MESSAGES_LOADING:
                state.openReportMessagesLoading = true

                return state

            case CaseworkerOpenReportActionType.SET_GET_REPORT_MESSAGES_ERROR:
                state.openReportMessagesError = action.payload

                return state

            case CaseworkerOpenReportActionType.GET_REPORT_MESSAGES:
                state.openReportMessagesLoading = false
                state.openReportMessagesError = null
                state.openReportMessages = [
                    ...state.openReportMessages,
                    ...action.payload,
                ].sort(function (a, b) {
                    // @ts-ignore
                    return (
                        new Date(a.created_at).getTime() -
                        new Date(b.created_at).getTime()
                    )
                })

                return state

            case CaseworkerOpenReportActionType.SET_ADD_MESSAGE_LOADING:
                state.addMessageLoading = true

                return state

            case CaseworkerOpenReportActionType.ADD_MESSAGE:
                state.addMessageLoading = false

                return state

            case CaseworkerOpenReportActionType.SHOW_LATEST_MESSAGE:
                // TODO handle loading
                state.openReportMessages.push(action.payload)

                return state

            case CaseworkerOpenReportActionType.SHOW_LATEST_FILES:
                state.openReport.files.data.push(...action.payload)

                return state

            case CaseworkerOpenReportActionType.SET_CHANGE_RESPONSIBLE_LOADING:
                state.changeResponsibleLoading = true

                return state

            case CaseworkerOpenReportActionType.CHANGE_RESPONSIBLE:
                return state

            case CaseworkerOpenReportActionType.SET_UPDATE_CATEGORY_LOADING:
                state.updateCategoryLoading = true

                return state

            case CaseworkerOpenReportActionType.UPDATE_CATEGORY:
                state.updateCategoryLoading = false
                state.openReport.category = action.payload.name
                state.openReport.category_id = action.payload.id

                return state

            case CaseworkerOpenReportActionType.SET_UPDATE_STATUS_LOADING:
                state.updateStatusLoading = true

                return state

            case CaseworkerOpenReportActionType.UPDATE_STATUS:
                state.updateStatusLoading = false
                state.openReport.status = action.payload

                return state

            case CaseworkerOpenReportActionType.SET_CLOSE_REPORT_LOADING:
                state.closeReportLoading = true

                return state

            case CaseworkerOpenReportActionType.SET_FORWARD_REPORT_LOADING:
                state.forwardReportLoading = true

                return state

            case CaseworkerOpenReportActionType.SET_FORWARD_REPORT_ERROR:
                state.forwardReportError = action.payload

                return state

            case CaseworkerOpenReportActionType.FORWARD_REPORT:
                state.forwardReportLoading = false
                state.forwardReportError = null

                return state

            case CaseworkerOpenReportActionType.CLOSE_REPORT:
                state.openReport.closed_at = action.payload.closed_at
                state.openReport.closed_comment = action.payload.closed_comment
                state.openReport.closed_reason = action.payload.closed_reason

                return state

            case CaseworkerOpenReportActionType.LEAVE_REPORT:
                state.openReportError = null
                state.openReportLoading = false
                state.openReport = null
                state.openReportMessages = []

                return state

            case CaseworkerOpenReportActionType.SHOW_SPECIAL_FORWARDED_MESSAGE:
                state.openReportMessages.push(action.payload)

                return state

            default:
                return state
        }
    }
)

export default reducer
