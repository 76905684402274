import produce from 'immer'
import { ClientActionType } from '../action-types'
import { ClientAction } from '../actions'

interface ClientState {
    clientLoading: boolean
    clientError: string | null
    client: any
}

const initialState: ClientState = {
    clientLoading: false,
    clientError: null,
    client: null,
}

const reducer = produce(
    (state: ClientState = initialState, action: ClientAction) => {
        switch (action.type) {
            case ClientActionType.GET_COMPANY_INFORMATION:
                state.clientLoading = false
                state.clientError = null
                state.client = action.payload

                return state

            case ClientActionType.SET_CLIENT_LOADING:
                state.clientLoading = true

                return state

            case ClientActionType.SET_CLIENT_ERROR:
                state.clientLoading = true
                state.clientError = action.payload.message

                return state
            default:
                return state
        }
    }
)

export default reducer
