import { Route } from 'react-router-dom'
import { useTypedSelector } from '../../hooks'
import { getUrlAfterAppSubdomain } from '../../helpers/routing/urls'

const ProtectedUnauthenticatedRoute = ({ component: Component, ...rest }) => {
    const { isLoggedIn } = useTypedSelector((state) => state.auth)

    return (
        <Route
            {...rest}
            render={(props) => {
                if (isLoggedIn) {
                    window.location.replace(
                        `http://app.admin.${getUrlAfterAppSubdomain()}`
                    )
                } else {
                    return <Component {...props} />
                }
            }}
        />
    )
}

export default ProtectedUnauthenticatedRoute
