import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { NewOrganizationInterface } from '../../../ts/interfaces'
import OnboardingHeader from '../../reusable/onboarding/header/OnboardingHeader'
import VideoBox from '../../reusable/onboarding/VideoBox'
import OnboardingHeaderStatus from '../../reusable/onboarding/header/OnboardingHeaderStatus'
import { useActions, useTranslation, useTypedSelector } from '../../../hooks'
import OnboardingCompanyInformationFrame from '../../reusable/onboarding/frames/OnboardingCompanyInformationFrame'
import OnboardingCreateYourLineOfContactsFrame from '../../reusable/onboarding/frames/OnboardingCreateYourLineOfContactsFrame'
import OnboardingGenerateWhistleblowerPolicyFrame from '../../reusable/onboarding/frames/OnboardingGenerateWhistleblowerPolicyFrame'
import OnboardingReadyToGoFrame from '../../reusable/onboarding/frames/OnboardingReadyToGoFrame'
import { useDefaultCountryDenmark } from '../../../hooks/useDefaultCountryDenmark'

interface SelfRegisterOrganizationInterface extends NewOrganizationInterface {
    country: any
}

const Container = styled.div`
    padding: 2rem 4rem;
`

const OnboardContainer = styled.div`
    height: 58rem;
    width: 100%;
    background: ${({ theme }) => theme.white};
    border: ${({ theme }) => `1px solid ${theme.grey}`};
    margin-top: 3rem;
    border-radius: 0.5rem;
`

const FrameBody = styled.div`
    padding: 3.2rem;
`

interface OnboardingPageProps {}

const OnboardingPage: React.FC<OnboardingPageProps> = () => {
    // Client will be null if logged in without any onboarding completed
    const { client } = useTypedSelector((state) => state.client)
    const { user } = useTypedSelector((state) => state.auth)

    const translation = useTranslation()

    const { getCompanyInformation, selfRegisterOrganization } = useActions()

    useEffect(() => {
        getCompanyInformation()

        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        client && setCurrentStep(client.organization.data.onboarding_step)
    }, [client])

    const denmark = useDefaultCountryDenmark()

    const [organizationInformation, setOrganizationInformation] =
        useState<SelfRegisterOrganizationInterface>({
            companyName: '',
            address: '',
            city: '',
            zipCode: '',
            employees: '',
            country: denmark,
        })

    useEffect(() => {
        if (denmark !== 'still default') {
            updateOrganizationInformation('country', denmark)
        }

        // eslint-disable-next-line
    }, [denmark])

    const updateOrganizationInformation = (
        key: string,
        value: null | string | any
    ) => {
        setOrganizationInformation({
            ...organizationInformation,
            [key]: value,
        })
    }

    const handleSubmit = async () => {
        if (user) {
            const success: any = await selfRegisterOrganization(
                organizationInformation,
                user.userId,
                user.temp
            )

            if (success) {
                await getCompanyInformation()
                setCurrentStep(2)
            }
        }
    }

    const [currentStep, setCurrentStep] = useState(1)

    const [currentFrame, setCurrentFrame] = useState(
        <OnboardingCompanyInformationFrame
            organizationInformation={organizationInformation}
            updateOrganizationInformation={updateOrganizationInformation}
            clickNextCB={() => setCurrentStep(2)}
        />
    )

    useEffect(() => {
        if (currentStep === 1) {
            setCurrentFrame(
                <OnboardingCompanyInformationFrame
                    organizationInformation={organizationInformation}
                    updateOrganizationInformation={
                        updateOrganizationInformation
                    }
                    clickNextCB={handleSubmit}
                />
            )
        } else if (currentStep === 2) {
            setCurrentFrame(
                <OnboardingCreateYourLineOfContactsFrame
                    clickNextCB={() => setCurrentStep(3)}
                />
            )
        } else if (currentStep === 3) {
            setCurrentFrame(
                <OnboardingGenerateWhistleblowerPolicyFrame
                    clickNextCB={() => setCurrentStep(4)}
                />
            )
        } else if (currentStep === 4) {
            setCurrentFrame(
                <OnboardingReadyToGoFrame goBack={() => setCurrentStep(3)} />
            )
        }
        // eslint-disable-next-line
    }, [currentStep, organizationInformation])
    return (
        <Container>
            <VideoBox currentStep={currentStep} />
            <OnboardContainer>
                <OnboardingHeader currentStep={currentStep}>
                    <OnboardingHeaderStatus
                        stepNumber={1}
                        text={
                            translation.onboardingPage.insertCompanyInformation
                        }
                        currentStep={currentStep}
                    />
                    <OnboardingHeaderStatus
                        stepNumber={2}
                        text={translation.onboardingPage.lineOfContact}
                        currentStep={currentStep}
                    />
                    <OnboardingHeaderStatus
                        stepNumber={3}
                        text={
                            translation.onboardingPage
                                .generateWhistleblowerPolicy
                        }
                        currentStep={currentStep}
                    />
                </OnboardingHeader>
                <FrameBody>{currentFrame}</FrameBody>
            </OnboardContainer>
        </Container>
    )
}

export default OnboardingPage
