import styled from 'styled-components'
import { useTypedSelector } from '../../../../../../hooks'
import { FiAlertCircle, FiPlusCircle } from 'react-icons/fi'

const BaseMessageComponent = styled.div`
    width: 40rem;
    height: 4rem;
    padding: 1.4rem;
    font-size: 1.2rem;
    border-radius: 0.2rem;
    margin: 1.4rem 0;
    align-items: center;
`

const AlertMessage = styled(BaseMessageComponent)`
    background-color: ${({ theme }) => `${theme.red}50`};
    color: ${({ theme }) => theme.red};
`

const SuccessMessage = styled(BaseMessageComponent)`
    background-color: ${({ theme }) => `${theme.blue}50`};
    color: ${({ theme }) => theme.blue};
`

const AlertIcon = styled(FiAlertCircle)`
    color: ${({ theme }) => theme.red};
    font-size: 1.2rem;
    margin-right: 0.5rem;
    margin-bottom: -0.2rem;
`

const SuccessIcon = styled(FiPlusCircle)`
    color: ${({ theme }) => theme.blue};
    font-size: 1.2rem;
    margin-right: 0.5rem;
    margin-bottom: -0.2rem;
`

const PaymentFlashMessages = () => {
    const {
        paymentMethodAddedMessage,
        paymentMethodDeletedMessage,
        paymentSuccessfulMessage,
        // defaultPaymentMethodUpdatedMessage,
    } = useTypedSelector((state) => state.payment)

    return (
        <>
            {paymentSuccessfulMessage && (
                <SuccessMessage>
                    <SuccessIcon />
                    {paymentSuccessfulMessage}
                </SuccessMessage>
            )}
            {paymentMethodAddedMessage && (
                <SuccessMessage>
                    <SuccessIcon />
                    {paymentMethodAddedMessage}
                </SuccessMessage>
            )}
            {paymentMethodDeletedMessage && (
                <AlertMessage>
                    <AlertIcon />
                    {paymentMethodDeletedMessage}
                </AlertMessage>
            )}
        </>
    )
}

export default PaymentFlashMessages
