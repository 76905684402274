import styled from 'styled-components'
import { FiArrowLeft } from 'react-icons/fi'
import { Card, CardHeader } from '../../../styled/cards'
import { useEffect, useState } from 'react'
import Dropdown from './components/Dropdown'
import SelectCategory from './components/frames/SelectCategory'
import AccusationAndEvidenceScore from './components/frames/AccusationAndEvidenceScore'
import ForwardReportPreview from './components/ForwardReportPreview'
import SelectLineOfContact from './components/frames/SelectLineOfContact'
import WriteAComment from './components/frames/WriteAComment'
import CaseHasBeenForwardedModal from './components/CaseHasBeenForwardedModal'
import AreYouSureYouWantToForwardModal from './components/AreYouSureYouWantToForwardModal'
import { useActions, useTranslation, useTypedSelector } from '../../../../hooks'
import { useParams } from 'react-router-dom'

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  min-height: 100vh;
  padding-top: 4rem;
  padding-bottom: 18rem;
  padding-left: 4rem;
`

const LeftContainer = styled.div`
  height: 100%;
`
const RightContainer = styled.div`
  height: 100%;
`

const ArrowIcon = styled(FiArrowLeft)`
  font-size: 2.2rem;
  color: ${({ theme }) => `${theme.primary}50`};
`

interface ForwardReportPageProps {
}

const ForwardReportPage: React.FC<ForwardReportPageProps> = () => {
    const { id } = useParams<{ id: string }>()

    const translation = useTranslation()

    const { getSingleReport, forwardReport } = useActions()

    const { organization } = useTypedSelector(state => state.caseworker)

    const { openReportLoading, openReport } = useTypedSelector((state) => state.caseworkerOpenReport)

    const { categories } = useTypedSelector(state => state.category)

    const [selectedOption, setSelectedOption] = useState<null | string>('select-category')

    // If the forward report screen was entered directly from the report the openReport should remain in the state
    // If not it needs to be fetched from the API
    useEffect(() => {
        if (organization) {
            getSingleReport(parseInt(id), translation, organization.id)
        }

        // eslint-disable-next-line
    }, [organization])


    const handleSelectOption = (option: string) => {
        if (option === selectedOption) {
            setSelectedOption(null)
        } else {
            setSelectedOption(option)
        }
    }

    const [category, setCategory] = useState<any>(null)
    const [score, setScore] = useState<null | number>(null)
    const [contact, setContact] = useState<any>(null)
    const [comment, setComment] = useState<null | string>(null)

    const [categoryClicked, setCategoryClicked] = useState(false)
    const [scoreSaveClicked, setScoreSaveClicked] = useState(false)
    const [contactClicked, setContactClicked] = useState(false)
    const [commentSaveClicked, setCommentSaveClicked] = useState(false)

    // Put the locally selected category as the selected category from the openReport
    useEffect(() => {
        if (openReport?.category) {
            // openReport only returns category name, so we need to use find on the category state to get the entire category object
            const category: any = categories.find((category: any) => category?.name === openReport.category)

            setCategory(category)
        }

        // eslint-disable-next-line
    }, [openReport])

    useEffect(() => {
        category && setCategoryClicked(true)
    },[category])

    useEffect(() => {
        score && setScoreSaveClicked(true)
    }, [score])

    useEffect(() => {
        contact && setContactClicked(true)
    }, [contact])

    useEffect(() => {
        comment && setCommentSaveClicked(true)
    }, [comment])

    useEffect(() => {
        if (selectedOption === 'select-category') {
            setCategoryClicked(false)
        }
        if (selectedOption === 'accusation-and-evidence') {
            setScoreSaveClicked(false)
        }
        if (selectedOption === 'line-of-contact') {
            setContactClicked(false)
        }
        if (selectedOption === 'write-a-comment') {
            setCommentSaveClicked(false)
        }
    }, [selectedOption])

    const [isAreYouSureModalOpen, setIsAreYouSureModalOpen] = useState(false)
    const [isCaseHasBeenForwardedModalOpen, setIsCaseHasBeenForwardedModalOpen] = useState(false)

    const handleForwardReport = async () => {
        const payload: any = {
            category_id: category?.id,
            // contact_line_id: contact?.id,
            contact_line_id: contact?.id, // user_id for nikulasoskarssontest@outlook.com
            severity_score: score,
            comment,
        }

        const success: any = await forwardReport(openReport.id, payload)

        if(success) {
            setIsAreYouSureModalOpen(false)
            setIsCaseHasBeenForwardedModalOpen(true)
        }
    }

    return (
        <>
            {!openReportLoading ? <>{isAreYouSureModalOpen && (
                <AreYouSureYouWantToForwardModal
                    closeModal={() => setIsAreYouSureModalOpen(false)}
                    handleForwardReport={handleForwardReport}
                    contact={contact}
                />
            )}
                {isCaseHasBeenForwardedModalOpen && <CaseHasBeenForwardedModal />}
                <Container>
                    <LeftContainer>
                        <Card>
                            <CardHeader>
                                <ArrowIcon />
                            </CardHeader>
                            <Dropdown
                                option='select-category'
                                optionName={
                                    translation.forwardReportPage.selectCatagory
                                }
                                optionNumber={1}
                                selectedOption={selectedOption}
                                handleSelectOption={handleSelectOption}
                                isActive={category !== null}
                                selectingFinishedCallback={() => {
                                    setSelectedOption('accusation-and-evidence')
                                    }
                                }
                                optionShouldClose={categoryClicked}
                            >
                                <SelectCategory
                                    selectedCategory={category}
                                    setSelectedCategory={setCategory}
                                />
                            </Dropdown>
                            <Dropdown
                                option='accusation-and-evidence'
                                optionName={
                                    translation.forwardReportPage
                                        .accusationAndEvidenceScore
                                }
                                optionNumber={2}
                                selectedOption={selectedOption}
                                handleSelectOption={handleSelectOption}
                                isActive={score !== null}
                                selectingFinishedCallback={() =>
                                    setSelectedOption('line-of-contact')
                                }
                                optionShouldClose={scoreSaveClicked}
                            >
                                <AccusationAndEvidenceScore setScore={setScore} />
                            </Dropdown>
                            <Dropdown
                                option='line-of-contact'
                                optionName={
                                    translation.forwardReportPage.lineOfContact
                                }
                                optionNumber={3}
                                selectedOption={selectedOption}
                                handleSelectOption={handleSelectOption}
                                isActive={contact !== null}
                                selectingFinishedCallback={() =>
                                    setSelectedOption('write-a-comment')
                                }
                                optionShouldClose={contactClicked}
                            >
                                <SelectLineOfContact
                                    selectedContact={contact}
                                    setContact={setContact}
                                />
                            </Dropdown>
                            <Dropdown
                                option='write-a-comment'
                                optionName={translation.forwardReportPage.comment}
                                optionNumber={4}
                                selectedOption={selectedOption}
                                handleSelectOption={handleSelectOption}
                                isActive={comment !== null}
                                selectingFinishedCallback={() =>
                                    setSelectedOption(null)
                                }
                                optionShouldClose={commentSaveClicked}
                            >
                                <WriteAComment setComment={setComment} />
                            </Dropdown>
                        </Card>
                    </LeftContainer>
                    <RightContainer>
                        <ForwardReportPreview
                            category={category}
                            score={score}
                            contact={contact}
                            comment={comment}
                            onForwardCase={() => setIsAreYouSureModalOpen(true)}
                        />
                    </RightContainer>
                </Container>
            </> : <>Loading...</>}
        </>
    )
}

export default ForwardReportPage
