const {
    REACT_APP_API_URL,
    REACT_APP_URL_EXTENSION,
    REACT_APP_ENV,
    REACT_APP_PUSHER_KEY,
    REACT_APP_STRIPE_PUBLIC_KEY,
} = process.env

const config = {
    apiUrl: REACT_APP_API_URL,
    appUrlExtension: REACT_APP_URL_EXTENSION,
    env: REACT_APP_ENV,
    pusherKey: REACT_APP_PUSHER_KEY,
    stripeKey: REACT_APP_STRIPE_PUBLIC_KEY,
}

export default config
