import styled from 'styled-components'

interface SelectButtonProps {
    active: boolean
}

export const SelectButton = styled.div<SelectButtonProps>`
    height: 6.5rem;
    min-width: 23rem;
    font-size: 1.6rem;
    padding: 0 1.5rem;
    background: ${({ active, theme }) =>
        active ? theme.black : theme.lightBlue};
    color: ${({ theme, active }) => (active ? theme.white : theme.primary)};
    border-radius: 3.4rem;
    font-weight: medium;
    cursor: ${({ active }) => !active && 'pointer'};
    margin: 1rem;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    transition: all 0.2s;

    :hover {
        background: ${({ theme }) => theme.black};
        color: ${({ theme }) => theme.white};
    }
`

export const WhiteSelectButton = styled(SelectButton)`
    background: ${({ active, theme }) => (active ? theme.black : theme.white)};
    border: 1px solid
        ${({ active, theme }) => (!active ? theme.black : theme.white)};
`

export const TransparentButtonSmall = styled.div`
    height: 3.5rem;
    width: 15rem;

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    border-radius: 3.4rem;
    color: ${({ theme }) => theme.primary};
    border: 1px solid ${({ theme }) => theme.primary};
    cursor: pointer;
`

export const TransparentButtonLarge = styled.div`
    height: 5rem;
    width: 30rem;

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.2rem;
    border-radius: 3.4rem;
    color: ${({ theme }) => theme.primary};
    border: 1px solid ${({ theme }) => theme.primary};
    cursor: pointer;
`
