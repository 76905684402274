import styled from 'styled-components'
import { IoRocketOutline } from 'react-icons/io5'
import { useTranslation } from '../../../../hooks'

const Header = styled.div`
    height: 10rem;
    width: 100%;
    display: flex;
    border-bottom: ${({ theme }) => `1px solid ${theme.grey}`};

    display: flex;
`

interface ReadyToGoProp {
    isActive: boolean
}

const ReadyToGo = styled.div<ReadyToGoProp>`
    background: ${({ theme, isActive }) =>
        isActive ? theme.blue : `${theme.blue}10`};
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    flex: 0.6;
`

const RocketIcon = styled(IoRocketOutline)<ReadyToGoProp>`
    color: ${({ theme, isActive }) =>
        isActive ? theme.white : theme.greyText};
    font-size: 1.6rem;
`

const ReadyToGoText = styled.h4<ReadyToGoProp>`
    font-size: 1.8rem;
    font-weight: 600;
    color: ${({ theme, isActive }) =>
        isActive ? theme.white : theme.greyText};
`

interface OnboardingHeaderProps {
    currentStep: number
}

const OnboardingHeader: React.FC<OnboardingHeaderProps> = ({
    currentStep,
    children,
}) => {
    const translation = useTranslation()

    return (
        <Header>
            {children}
            <ReadyToGo isActive={currentStep === 4}>
                <RocketIcon isActive={currentStep === 4} />
                <ReadyToGoText isActive={currentStep === 4}>
                    {translation.onboardingPage.readyToGo}
                </ReadyToGoText>
            </ReadyToGo>
        </Header>
    )
}

export default OnboardingHeader
