import Translation from '../../state/state-types/TranslationStateType'

export const checkLastName = (name: string, translation: Translation) => {
    if (name.length < 2) {
        return translation.validation.lastNameCannotBeShorter
    } else if (name.length > 26) {
        return translation.validation.lastNameCannotBeLonger
    } else {
        return ''
    }
}
