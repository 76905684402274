import { Route } from 'react-router-dom'
import { useTypedSelector } from '../../hooks'
import { getUrlAfterAppSubdomain } from '../../helpers/routing'

const ProtectedCaseworkerRoute = ({ component: Component, ...rest }) => {
    const { isLoggedIn, user } = useTypedSelector((state) => state.auth)

    return (
        <Route
            {...rest}
            render={(props) => {
                if (!isLoggedIn || !user) {
                    window.location.replace(
                        `http://app.${getUrlAfterAppSubdomain()}/logout`
                    )
                } else {
                    if (
                        !user.roles.some(role => role === 'partner' || role === 'caseworker')
                    ) {
                        window.location.replace(
                            `http://app.${getUrlAfterAppSubdomain()}/logout`
                        )
                    } else {
                        return <Component {...props} />
                    }
                }
            }}
        />
    )
}

export default ProtectedCaseworkerRoute
