import Translation from '../../state/state-types/TranslationStateType'

export const isValidName = (name: string) => {
    const result = name.length >= 2 && name.length <= 26
    return result
}

// TODO return translation for full name not first name
export const checkName = (name: string, translation: Translation) => {
    if (name.length < 2) {
        return translation.validation.firstNameCannotBeShorter
    } else if (name.length > 26) {
        return translation.validation.firstNameCannotBeLonger
    } else {
        return ''
    }
}
