import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { NextButton } from '../../buttons'
import {
    ButtonContainer,
    Heading,
    HeadingContainer,
    HeadingText,
    // HelpIcon,
} from '../styled'
import GeneratePolicyGif from '../../../../assets/gifs/generate-policy.gif'
import { FiFileText, FiThumbsUp } from 'react-icons/fi'
import { useActions, useTranslation, useTypedSelector } from '../../../../hooks'

const GeneratePolicyButton = styled.div`
    height: 16rem;
    width: 16rem;
    border: ${({ theme }) => `1px solid ${theme.blue}`};
    margin: 10.2rem auto;

    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s;
    cursor: pointer;

    :hover {
        opacity: 0.8;
    }

    :hover .hover-button-circle {
        height: 16rem;
        width: 16rem;
    }
`

const ButtonCircle = styled.div`
    height: 14.4rem;
    width: 14.4rem;
    background: ${({ theme }) => theme.blue};
    border-radius: 50%;
    transition: all 0.3s;

    display: flex;
    align-items: center;
    justify-content: center;

    color: ${({ theme }) => theme.white};
    font-size: 1.8rem;
`

const ImgContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    justify-content: center;
`
const Img = styled.img`
    height: 18rem;
    margin-top: 4rem;
`

const Pecentage = styled.span`
    font-size: 1.4rem;
    color: ${({ theme }) => theme.primary};
`

const CompletedContainer = styled.div`
    width: 100%;
    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: center;
`

const CompletedHeading = styled.h3`
    font-size: 2rem;
    color: ${({ theme }) => theme.primary};
`

const ThumbsUpIcon = styled(FiThumbsUp)`
    font-size: 1.8rem;
    color: ${({ theme }) => theme.green};
    margin-left: 0.6rem;
`

const CompletedTagline = styled.p`
    font-size: 1.6rem;
    color: ${({ theme }) => `${theme.primary}50`};
    margin-top: 1rem;
`

const FileIcon = styled(FiFileText)`
    font-size: 1.6rem;
    color: ${({ theme }) => `${theme.primary}50`};
    margin-left: 0.6rem;
    margin-top: 1rem;
`

const Flex = styled.div`
    display: flex;
    align-items: center;
`

const SubTitleText = styled.p`
    font-size: 1.4rem;
    margin-block: 0.6rem;
    color: #a0a0a6; ;
`

interface GenerateWhistleblowerPolicyFrameProps {
    clickNextCB: () => void
}

const OnboardingGenerateWhistleblowerPolicyFrame: React.FC<
    GenerateWhistleblowerPolicyFrameProps
> = ({ clickNextCB }) => {
    const { onboardingGenerateWhistleblowerPolicy } = useActions()

    const { user } = useTypedSelector((state) => state.auth)

    const { client } = useTypedSelector((state) => state.client)

    const translation = useTranslation()

    const handleClickNext = async () => {
        if (!user) {
            return
        }

        const success: any = await onboardingGenerateWhistleblowerPolicy(
            user.userId,
            client.organization.data.id
        )

        success && clickNextCB()
    }

    const [isGeneratingPolicy, setIsGeneratingPolicy] = useState(false)
    const [isGeneratingCompleted, setIsGeneratingCompleted] = useState(false)
    const [percentageCounter, setPercentageCounter] = useState(1)

    useEffect(() => {
        if (isGeneratingPolicy) {
            let i = 0
            const interval: any = setInterval(() => {
                i++

                setPercentageCounter(i)
                if (i >= 100) {
                    return clearInterval(interval)
                }
            }, 70)
            setTimeout(() => {
                setIsGeneratingPolicy(false)
                setIsGeneratingCompleted(true)
            }, 7000)
        }
    }, [isGeneratingPolicy])

    return (
        <>
            <HeadingContainer>
                <Heading>
                    <HeadingText>
                        {translation.onboardingPage.generateWhistleblowerPolicy}
                    </HeadingText>
                    {/*<HelpIcon />*/}
                </Heading>
                <ButtonContainer>
                    <NextButton
                        text={translation.onboardingPage.nextStep}
                        handleOnClick={handleClickNext}
                        isActive={isGeneratingCompleted}
                        height="3.5rem"
                        width="12.1rem"
                        fontSize="1.4rem"
                    />
                </ButtonContainer>
            </HeadingContainer>
            <SubTitleText>
                {translation.onboardingPage.subgenerateyourwbpolicy}
            </SubTitleText>

            {!isGeneratingCompleted && (
                <>
                    {!isGeneratingPolicy && (
                        <GeneratePolicyButton>
                            <ButtonCircle
                                className="hover-button-circle"
                                onClick={() => setIsGeneratingPolicy(true)}
                            >
                                {translation.onboardingPage.clickHere}
                            </ButtonCircle>
                        </GeneratePolicyButton>
                    )}
                </>
            )}
            <ImgContainer>
                {isGeneratingPolicy && (
                    <Pecentage
                        style={{
                            marginTop: '4rem',
                        }}
                    >
                        {percentageCounter}%{' '}
                        {translation.onboardingPage.complete}
                    </Pecentage>
                )}
                {(isGeneratingPolicy || isGeneratingCompleted) && (
                    <Img src={GeneratePolicyGif} />
                )}
                {isGeneratingPolicy && (
                    <Pecentage>
                        {
                            translation.onboardingPage
                                .yourWhistleblowerpolicyIsBeingGenerated
                        }
                    </Pecentage>
                )}
            </ImgContainer>
            {isGeneratingCompleted && (
                <CompletedContainer>
                    <Flex>
                        <CompletedHeading>
                            {' '}
                            {translation.onboardingPage.goodWork}
                        </CompletedHeading>
                        <ThumbsUpIcon />
                    </Flex>
                    <Flex>
                        <CompletedTagline>
                            {translation.onboardingPage.yourPolicyHasBeen}
                        </CompletedTagline>
                        <FileIcon />
                    </Flex>
                </CompletedContainer>
            )}
        </>
    )
}

export default OnboardingGenerateWhistleblowerPolicyFrame
