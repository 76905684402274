import styled from 'styled-components'
import { useTranslation, useTypedSelector } from '../../../../../../hooks'
import { capitalize } from 'lodash'
import categoryTransformer from '../../../../../../state/transformers/CategoryTransformer'

const Container = styled.div`
    margin: 0 6.2rem;
    margin-top: 1.4rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
`

interface IsActiveProp {
    isActive: boolean
}

const CategoryButton = styled.div<IsActiveProp>`
    padding: 0.8rem 1.2rem;
    border-radius: 10rem;
    border: ${({ theme, isActive }) =>
        !isActive && `1px solid ${theme.greyText}`};
    font-size: 1.4rem;
    color: ${({ theme, isActive }) =>
        isActive ? theme.white : theme.greyText};
    cursor: pointer;
    background: ${({ theme, isActive }) => isActive && theme.primary};

    :hover {
        background: ${({ theme }) => theme.primary};
        color: ${({ theme }) => theme.white};
    }
`

interface SelectCategoryProps {
    selectedCategory: any
    setSelectedCategory: (category: string) => void
}

const SelectCategory: React.FC<SelectCategoryProps> = ({
    selectedCategory,
    setSelectedCategory,
}) => {
    const { categories } = useTypedSelector((state) => state.category)

    const translation = useTranslation()

    return (
        <Container>
            {categories &&
                categoryTransformer(categories, translation).map(
                    (category: any) => (
                        <CategoryButton
                            onClick={() => setSelectedCategory(category)}
                            isActive={selectedCategory?.name === category.name}
                            key={category.id}
                        >
                            {capitalize(category.name)}
                        </CategoryButton>
                    )
                )}
        </Container>
    )
}

export default SelectCategory
