import React from 'react'
import styled from 'styled-components'
import SoftwareScreeningIcon from '../../assets/images/Software_and_Screening_Icon.png'
import SoftwareIcon from '../../assets/images/Software_Icon.png'
import { AiFillCheckCircle } from 'react-icons/ai'
import { useTranslation } from '../../hooks'

interface IsSmallInterface {
    isSmall?: boolean
}

interface OptionProps extends IsSmallInterface {
    isActive: boolean
    selectedAccountType: null | string
    accountType: string
}

const Option = styled.div<OptionProps>`
    text-align: center;
    width: ${({ isSmall }) => (isSmall ? '24.3rem' : '34.3rem')};
    border-radius: 1.66rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    background-color: ${({ theme, isActive }) =>
        isActive ? '#E1E9EE' : theme.white};
    border: ${({ isActive, theme }) =>
        isActive ? `1px solid black` : `1px solid #e9e9e9`};
    padding: ${({ isSmall }) => (isSmall ? '1rem' : '2rem')};
`

const OptionTitle = styled.h2<IsSmallInterface>`
    font-style: normal;
    font-weight: 700;
    font-size: ${({ isSmall }) => (isSmall ? '1.6rem' : '2.6rem')};
    line-height: 3rem;
    color: ${({ theme }) => theme.primary};
    margin-top: 2.45rem;
`

const OptionDescription = styled.div`
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.62rem;
    color: ${({ theme }) => theme.white};
    background-color: #246ea4;
    padding: 0.6rem 1.2rem;
    text-align: center;
    border-radius: 10rem;
    margin: 1.4rem auto 0 auto;
    width: fit-content;
`

const Line = styled.div`
    border-bottom: 0.08rem solid ${({ theme }) => theme.grey};
    margin: 2.1rem 0;
`

const SoftwareScreeningImg = styled.img`
    width: 11rem;
    margin: 0 auto;
`
const SoftwareImg = styled.img`
    width: 5.3rem;
    margin: 0 auto;
`

const List = styled.ul<IsSmallInterface>`
    margin: 0 auto;
    margin-left: ${({ isSmall }) => (isSmall ? '1rem' : '6.5rem')};

    .greenIcon {
        fill: ${({ theme }) => theme.green};
        font-size: ${({ isSmall }) => (isSmall ? '1.2rem' : '1.6rem')};
        margin-right: 0.5rem;
    }

    #redIcon {
        color: ${({ theme }) => theme.red};
        font-size: ${({ isSmall }) => (isSmall ? '1.2rem' : '1.6rem')};
        margin-right: 0.5rem;
    }

    #notIncluded {
        opacity: 0.3;
    }
`

const ListItem = styled.li`
    display: flex;
    text-align: left;
    align-items: center;
    font-size: 1.5rem;
    line-height: 2.6rem;
    font-weight: 400;
    color: ${({ theme }) => theme.secondaryDark};
    list-style: none;
`

interface AccountTypeProps {
    accountType: string
    isActive: boolean
    setSelectedAccountType?(): void
    selectedAccountType?: null | string
    employeeCount?: null | string
    price?: null | string
    small?: boolean
}

const AccountType: React.FC<AccountTypeProps> = ({
    accountType,
    isActive,
    setSelectedAccountType = () => {},
    selectedAccountType = null,
    employeeCount = null,
    price = null,
    small = false,
}) => {
    const translation = useTranslation()

    return (
        <Option
            accountType={accountType}
            selectedAccountType={selectedAccountType}
            isActive={isActive}
            onClick={setSelectedAccountType}
            isSmall={small}
        >
            {accountType !== 'software' ? (
                <SoftwareScreeningImg
                    src={SoftwareScreeningIcon}
                    alt="Software and Screening Icon"
                />
            ) : (
                <SoftwareImg src={SoftwareIcon} alt="Software Icon" />
            )}
            <OptionTitle isSmall={small}>
                {accountType === 'software'
                    ? translation.registerPage.software
                    : translation.registerPage.softwarePlusScreening}
                {employeeCount && `(${employeeCount})`}
            </OptionTitle>
            {!small && (
                <OptionDescription>
                    {translation.registerPage.easyAndSecure}
                </OptionDescription>
            )}
            {price && <OptionDescription>{price}</OptionDescription>}
            {price && (
                <OptionDescription>
                    {translation.registerPage.chargedYearly}
                </OptionDescription>
            )}
            <Line></Line>
            <List isSmall={small}>
                <ListItem>
                    <AiFillCheckCircle className="greenIcon" />
                    {translation.registerPage.secureWhistleblowerPlatform}
                </ListItem>

                <ListItem>
                    <AiFillCheckCircle className="greenIcon" />
                    {translation.registerPage.tailoredWhistleblowerPolicy}
                </ListItem>

                <ListItem>
                    <AiFillCheckCircle className="greenIcon" />
                    {translation.registerPage.reportManagement}
                </ListItem>

                <ListItem>
                    <AiFillCheckCircle className="greenIcon" />
                    {translation.registerPage.followUpCommunication}
                </ListItem>

                <ListItem>
                    <AiFillCheckCircle className="greenIcon" />
                    {translation.registerPage.emailNotifications}
                </ListItem>

                <ListItem>
                    <AiFillCheckCircle className="greenIcon" />
                    {translation.registerPage.employeeGuides}
                </ListItem>
            </List>
        </Option>
    )
}

export default AccountType
