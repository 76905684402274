import { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
    getSecondSubdomain,
    getUrlAfterAppSubdomain,
} from '../../helpers/routing'
import { useActions, useTranslation } from '../../hooks'
import { ButtonFullWidth } from '../styled/buttons'
import { Card, CardHeader } from '../styled/cards'

const ModalContainer = styled.div`
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);

    position: fixed;
    top: 0;
    left: 0;
    z-index: 500;
    overflow-y: hidden;
`

const Modal = styled.div`
    height: 35.2rem;
    width: 53.6rem;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 501;
    text-align: center;
`

const ModalHeading = styled.h3`
    font-size: 2rem;
    font-weight: 600;
    color: ${({ theme }) => theme.primary};
    padding-top: 4.5rem;
`

const ModalTagline = styled.p`
    font-size: 1.6rem;
    color: ${({ theme }) => theme.primary};
    padding-top: 2.5rem;
`

const Timer = styled.p`
    font-size: 1.6rem;
    padding-top: 2rem;
    color: ${({ theme }) => theme.green};
`

interface InactiveModalProps {
    closeModal: () => void
}

const InactiveModal: React.FC<InactiveModalProps> = ({ closeModal }) => {
    const [timer, setTimer] = useState(60)

    const translation = useTranslation()

    const { whistleblowerLogout } = useActions()

    useEffect(() => {
        const timeout = setTimeout(() => {
            setTimer(timer - 1)
        }, 1000)

        return () => clearTimeout(timeout)
    }, [timer])

    const userType = getSecondSubdomain()

    useEffect(() => {
        if (timer === 0) {
            if (userType === 'whistleblower') {
                localStorage.clear()

                whistleblowerLogout()
                window.location.replace(
                    `http://app.whistleblower.${getUrlAfterAppSubdomain()}/not-assigned-to-company`
                )
            } else {
                window.location.replace(
                    `http://app.${getUrlAfterAppSubdomain()}/logout`
                )

                closeModal()
            }
        }
        // eslint-disable-next-line
    }, [timer])
    return (
        <ModalContainer>
            <Modal>
                <Card>
                    <CardHeader>
                        {translation.inactiveModal.inactivityWarning}
                    </CardHeader>
                    <div
                        style={{
                            padding: '0 6.5rem',
                        }}
                    >
                        <ModalHeading>
                            {translation.inactiveModal.youWillBeLoggedOut}
                        </ModalHeading>
                        <ModalTagline>
                            {translation.inactiveModal.youHaveBeenInactive}
                        </ModalTagline>
                        <Timer>{timer}</Timer>
                    </div>
                    <div
                        style={{
                            marginTop: '6rem',
                            padding: '0 1.4rem',
                        }}
                    >
                        <ButtonFullWidth isActive={true} onClick={closeModal}>
                            {translation.inactiveModal.stayLoggedIn}
                        </ButtonFullWidth>
                    </div>
                </Card>
            </Modal>
        </ModalContainer>
    )
}

export default InactiveModal
