import styled from 'styled-components'
import { useTranslation, useTypedSelector } from '../../../../../../../hooks'
import historyLogTransformer from '../../../../../../../state/transformers/HistoryLogTransformer'

const HistoryLogContainer = styled.div``

const HistoryLogItem = styled.div`
    height: 10.2rem;
    width: 100%;
    border-bottom: ${({ theme }) => `1px solid ${theme.grey}`};
    display: flex;
`

const Date = styled.p`
    font-size: 1.2rem;
    color: ${({ theme }) => theme.greyText};
    margin-left: 2.8rem;
    margin-top: 3.4rem;
    margin-right: 5.6rem;
`

const Title = styled.p`
    margin-top: 3.4rem;
    font-size: 1.4rem;
    color: ${({ theme }) => theme.primary};
    font-weight: 600;
`

const Description = styled.p`
    margin-top: 1.4rem;
    font-size: 1.4rem;
    font-weight: 500;

    @media screen and (max-width: 480px) {
        margin-right: 2.8rem;
    }
`

interface HistoryLogFrameProps {}

const HistoryLogFrame: React.FC<HistoryLogFrameProps> = () => {
    const { logs } = useTypedSelector((state) => state.reportHistoryLog)
    const translation = useTranslation()

    return (
        <>
            {logs && (
                <HistoryLogContainer>
                    {historyLogTransformer(logs, translation).map(
                        (log: any) => (
                            <HistoryLogItem key={log.id}>
                                <Date>{log.createdAt}</Date>
                                <div>
                                    <Title>{log.title}</Title>
                                    <Description>{log.description}</Description>
                                </div>
                            </HistoryLogItem>
                        )
                    )}
                </HistoryLogContainer>
            )}
        </>
    )
}

export default HistoryLogFrame
