import styled from 'styled-components'
import { NextButton } from '../../../../reusable/buttons'
import { HeadingPrimary } from '../../../../styled/typography'
import { getUrlAfterAppSubdomain } from '../../../../../helpers/routing'
import { LoginInfo } from '../LoginPage'
import {
    useActions,
    useTranslation,
    useTypedSelector,
} from '../../../../../hooks'
import Spinner from '../../../../layout/spinner/Spinner'
import SecurityCodeInput from '../components/SecurityCodeInput'
import config from '../../../../../config'
import partiallyHidePhone from '../../../../../helpers/string/partiallyHidePhone'
import partiallyHideEmail from '../../../../../helpers/string/partiallyHideEmail'

interface EnterSecurityCodeProps {
    loginInfo: LoginInfo
    updateLoginInfo: (key: string, value: string) => void
}

const Container = styled.div`
    margin: 0 auto;
    padding-top: 22rem;
    text-align: center;
`

const Tagline = styled.h4`
    font-size: 1.6rem;
    color: ${({ theme }) => theme.black};
    opacity: 0.8;
    margin-top: 2.8rem;
    margin-bottom: 5rem;
`

const Form = styled.form`
    width: 50rem;
    margin: 0 auto;
`

const NextButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 4rem;
`

const SpinnerContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 8rem;
`

const ErrorMessage = styled.p`
    font-size: 1.2rem;
    color: ${({ theme }) => theme.red};
    margin-top: 1.2rem;
`

const SendAgainLink = styled.p`
    font-size: 1.2rem;
    color: ${({ theme }) => `${theme.primary}50`};
    cursor: pointer;
    padding-top: 2.4rem;
`

const EnterSecurityCode: React.FC<EnterSecurityCodeProps> = ({
    loginInfo,
    updateLoginInfo,
}) => {
    const translation = useTranslation()

    const { env } = config

    const { email, password, otp, verification_method, phone } = loginInfo

    const { authLoading, authError } = useTypedSelector((state) => state.auth)
    const { send2FA, verify2FA } = useActions()

    const validateCode = (code: string) => {
        return code.length === 6
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault()
        if (validateCode(otp)) {
            const user: any = await verify2FA(
                verification_method,
                email,
                password,
                otp
            )

            if (user) {
                let subdomain

                if (
                    user.roles.some(
                        (role: string) =>
                            role === 'partner' || role === 'caseworker'
                    )
                ) {
                    subdomain = 'partner'
                } else if (
                    user.roles.includes('contact') &&
                    user.temp === null
                ) {
                    // Contact is always the leading subdomain even if it is also a client user
                    subdomain = 'contact'
                } else if (user.roles.includes('client')) {
                    subdomain = 'client'
                } else {
                    subdomain = 'admin'
                }

                if (env === 'local') {
                    localStorage.setItem('isLoggedIn', 'true')
                    localStorage.setItem('roles', JSON.stringify(user.roles))
                }

                const url = `http://app.${subdomain}.${getUrlAfterAppSubdomain()}/magic-login/${
                    user.magicLoginCode
                }`

                window.location.replace(url)
            }
        }
    }

    const handleResendSubmit = async () => {
        const sendTo = verification_method === 'sms' ? phone : email
        await send2FA(verification_method, sendTo)
    }

    const hiddenEmailOrPhone = () => {
        if (verification_method === 'sms') {
            return partiallyHidePhone(phone)
        } else {
            return partiallyHideEmail(email)
        }
    }
    return (
        <Container>
            {!authLoading ? (
                <>
                    <HeadingPrimary>
                        {translation.loginFlow.enterThe6digit}
                    </HeadingPrimary>
                    <Tagline>
                        {translation.loginFlow.yourCodeWillArrive +
                            ' ' +
                            hiddenEmailOrPhone()}
                    </Tagline>
                    <Form onSubmit={(e) => handleSubmit(e)}>
                        <SecurityCodeInput
                            handleOnChange={(val: string) =>
                                updateLoginInfo('otp', val)
                            }
                        />
                        <SendAgainLink onClick={handleResendSubmit}>
                            {translation.loginFlow.didYouNotReceive}
                        </SendAgainLink>
                        <NextButtonContainer>
                            <NextButton
                                text={translation.loginFlow.login}
                                isActive={validateCode(otp)}
                                width="133px"
                                icon={false}
                            />
                        </NextButtonContainer>
                    </Form>

                    {authError && <ErrorMessage>{authError}</ErrorMessage>}
                </>
            ) : (
                <>
                    <SpinnerContainer>
                        <Spinner />
                    </SpinnerContainer>
                </>
            )}
        </Container>
    )
}

export default EnterSecurityCode
