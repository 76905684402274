import React from 'react'
import styled from 'styled-components'
import { Modal, ModalContainer } from '../../../../styled/modals'
import { Card, CardHeader } from '../../../../styled/cards'
import { ButtonFullWidth } from '../../../../styled/buttons'
import { Link } from 'react-router-dom'

const ModalParagraph = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.primary};
  padding-top: 2.6rem;
  text-align: center;
`

const ButtonContainer = styled.div`
  padding: 0 1.4rem;
  margin-top: 4rem;
`

interface CaseHasBeenForwardedModalProps {

}

const CaseHasBeenForwardedModal: React.FC<CaseHasBeenForwardedModalProps> = () => {
    return (<ModalContainer>
        <Modal height='20rem' width='40rem'>
            <Card>
                <CardHeader>Report has been forwarded</CardHeader>
                <ModalParagraph>
                    Your report has been forwarded
                </ModalParagraph>
                <Link to='/'>
                    <ButtonContainer>
                        <ButtonFullWidth isActive={true}>Go to dashboard</ButtonFullWidth>
                    </ButtonContainer>
                </Link>
            </Card>
        </Modal>
    </ModalContainer>)
}

export default CaseHasBeenForwardedModal