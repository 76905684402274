export const checkColor = (color: string) => {
    if (!color.includes('#')) {
        return 'The color HEX code must beggin with a #'
    }
    if (color.length < 7) {
        return 'The color HEX code cannot be shorter than 7 characters'
    } else if (color.length > 7){
        return 'The color HEX code cannot be longer than 7 characters'
    } else {
        return ''
    }
}

export const isValidColor = (color: string) => {
    if (/^#[0-9A-F]{6}$/i.test(color)) {
        return true
    } else {
        return false
    }
}