import { Route } from 'react-router-dom'
import { useTypedSelector } from '../../hooks'
import { getUrlAfterAppSubdomain } from '../../helpers/routing'

const ProtectedPartnerRoute = ({ component: Component, ...rest }) => {
    const { isLoggedIn, user } = useTypedSelector((state) => state.auth)

    return (
        <Route
            {...rest}
            render={(props) => {
                if (!isLoggedIn || !user?.roles.includes('partner')) {
                    return window.location.replace(
                        `http://app.${getUrlAfterAppSubdomain()}/logout`
                    )
                } else {
                    return <Component {...props} />
                }
            }}
        />
    )
}

export default ProtectedPartnerRoute
