import axios from 'axios'
import config from '../../config'
import { AvailableLanguagesActionType } from '../action-types'
import { Dispatch } from 'redux'

const { apiUrl } = config

export const getAvailableLanguages = () => async (dispatch: Dispatch) => {
    try {
        const res = await axios.get(`${apiUrl}/v1/available-languages`)

        if (res.status !== 200) {
            dispatch(
                setAvailableLanguagesError(
                    'something went wrong. Please, try again later.'
                )
            )
            return false
        }

        dispatch({
            type: AvailableLanguagesActionType.GET_AVAILABLE_LANGUAGES,
            payload: {
                availableLanguages: res.data.data,
            },
        })

        return true
    } catch (error: any) {
        dispatch(
            setAvailableLanguagesError(
                'something went wrong. Please, try again later.'
            )
        )
    }
}

export const updateDefaultLanguage =
    (userId: number, payload: any) => async (dispatch: Dispatch) => {
        dispatch(setAvailableLanguagesLoading())
        try {
            const res = await axios.put(`${apiUrl}/v1/users/${userId}`, payload)

            if (res.status !== 200) {
                return false
            }
            const selectedLanguageId = res.data.data.selected_language.data.id

            dispatch({
                type: AvailableLanguagesActionType.UPDATE_DEFAULT_LANGUAGE,
                payload: selectedLanguageId,
            })

            //@ts-ignore
            dispatch(clearLanguageFlashMessage('saveDefaultLanguageMessage'))
            return true
        } catch (error: any) {
            dispatch(
                setAvailableLanguagesError(
                    'Something went wrong. Please, try again later.'
                )
            )
        }
    }

export const updateReportingChannelLanguage =
    (clientId: number, organizationId: number, payload: any) =>
    async (dispatch: Dispatch) => {
        dispatch(setAvailableLanguagesLoading())
        try {
            const res = await axios.put(
                `${apiUrl}/v1/clients/${clientId}/organizations/${organizationId}`,
                payload
            )

            if (res.status !== 200) {
                return
            }

            const reportingChannelLanguageId =
                res.data.data.reporting_channel_language.data.id

            dispatch({
                type: AvailableLanguagesActionType.UPDATE_REPORTING_CHANNEL_LANGUAGE,
                payload: reportingChannelLanguageId,
            })
        } catch (error: any) {
            dispatch(
                setAvailableLanguagesError(
                    'Something went wrong. Please, Try again.'
                )
            )
        }
    }

export const setAvailableLanguagesLoading = () => ({
    type: AvailableLanguagesActionType.AVAILABLE_LANGUAGES_LOADING,
})

export const setAvailableLanguagesError = (message: string) => ({
    type: AvailableLanguagesActionType.AVAILABLE_LANGUAGES_ERROR,
    payload: {
        message,
    },
})

export const clearLanguageFlashMessage =
    (message: string) => (dispatch: any) => {
        return setTimeout(() => {
            dispatch({
                type: AvailableLanguagesActionType.CLEAR_DEFAULT_LANGUAGE_FLASH_MESSAGE,
                payload: {
                    message,
                },
            })
        }, 2000)
    }
