import styled from 'styled-components'
import { FiArrowRight } from 'react-icons/fi'

const ReportingChannelCardContainer = styled.div`
    height: 20vw;
    min-height: 25.3rem;
    width: 95%;
    background: ${({ theme }) => theme.white};
    border-radius: 0.5rem;
    padding: 0 4rem;
    cursor: pointer;
    color: ${({ theme }) => theme.primary};
    transition: all 0.3s;
    :hover {
        background: ${({ theme }) => theme.primary};
        color: ${({ theme }) => theme.white};
    }

    @media screen and (min-width: 1200px) and (max-width: 1600px) {
        height: 5vw;
    }

    @media (max-width: 1200px) {
        width: 100%;
    }

    @media screen and (max-width: 600px) {
        min-height: 16rem;
    }
`
const TitleContainer = styled.div`
    display: flex;
    padding-top: 12vw;
    align-items: center;

    @media screen and (min-width: 1200px) and (max-width: 1600px) {
        padding-top: 8vw;
    }

    @media (max-width: 600px) {
        padding-top: 5rem;
    }
    &:hover .arrow-icon {
        color: white;
    }
`
const Title = styled.h2`
    font-size: 3.2rem;
    margin-right: 1rem;

    @media screen and (max-width: 600px) {
        font-size: 2.3rem;
    }

    @media screen and (max-width: 400px) {
        font-size: 2rem;
    }
`
const Tagline = styled.h4`
    font-size: 1.6rem;
    opacity: 0.5;
    padding-top: 1.5rem;
`

interface ReportingChannelCardProp {
    onClick: React.MouseEventHandler<HTMLDivElement>
    title: string
    tagline: string
}

const ReportingChannelCard: React.FC<ReportingChannelCardProp> = ({
    onClick,
    title,
    tagline,
}) => {
    return (
        <ReportingChannelCardContainer onClick={onClick}>
            <TitleContainer>
                <Title>{title}</Title>
                <FiArrowRight color="arrow-icon" size={21} />
            </TitleContainer>
            <Tagline>{tagline}</Tagline>
        </ReportingChannelCardContainer>
    )
}

export default ReportingChannelCard
