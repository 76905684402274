import styled from 'styled-components'
import {
    FiArchive,
    FiClock,
    FiEdit2,
    FiMove,
    FiPlus,
    FiPlusCircle,
    FiTrash2,
    FiXCircle,
    FiCheck,
} from 'react-icons/fi'

interface SmallWhiteActiveButtonProps {
    active: boolean
}
export const SmallWhiteActiveButton = styled.div<SmallWhiteActiveButtonProps>`
    height: 5rem;
    width: 19rem;
    border-radius: 10rem;
    background: ${({ theme, active }) => (active ? theme.black : theme.white)};

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: ${({ active }) => !active && 'pointer'};

    color: ${({ theme, active }) => (active ? theme.white : theme.black)};
    font-size: 1.6rem;
    transition: all 0.2s;

    :hover {
        transform: ${({ active }) => !active && 'scale(1.03)'};
    }
`

const CrudButton = styled.div`
    height: 2.9rem;
    width: 12rem;
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    gap: 0.5rem;
    margin: 0.25rem 0;
`
const RoundButton = styled.div`
height: 3rem;
    width: 3rem;
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    gap: 0.5rem;
    margin: 0.25rem 0;`

export const SaveButton = styled(CrudButton)`
    background: ${({ theme }) => `${theme.blue}30`};
`

export const DeleteButton = styled(CrudButton)`
    background: ${({ theme }) => `${theme.red}30`};
`
export const RoundDeleteButton = styled(RoundButton)`
    background: ${({ theme }) => `${theme.red}25`};
`

export const EditButton = styled(CrudButton)`
    background: ${({ theme }) => `${theme.yellow}30`};
`

export const BlueEditButton = styled(RoundButton)`
background: ${({ theme }) => `${theme.blue}25`};
`

export const SaveIcon = styled(FiEdit2)`
    color: ${({ theme }) => theme.blue};
`

export const CheckIcon = styled(FiCheck)`
    color: ${({ theme }) => theme.blue};
`

export const ReorderIcon = styled(FiMove)`
    color: ${({ theme }) => theme.blue};
`

export const EditIcon = styled(FiEdit2)`
    color: ${({ theme }) => theme.yellow};
`
export const BlueEditIcon = styled(FiEdit2)`
    color: ${({ theme }) => theme.blue};
`

export const DeleteIcon = styled(FiTrash2)`
    color: ${({ theme }) => theme.red};
    font-size: 1.2rem;
`

export const SaveButtonText = styled.p`
    color: ${({ theme }) => theme.blue};
    font-size: 1.2rem;
`

export const DeleteButtonText = styled.p`
    color: ${({ theme }) => theme.red};
    font-size: 1.2rem;
`

export const EditButtonText = styled.p`
    color: ${({ theme }) => theme.yellow};
    font-size: 1.2rem;
`

interface ActiveProp {
    isActive: boolean
}

export const RadioButtonFlex = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`

export const RadioButtonCircle = styled.div<ActiveProp>`
    height: 1.1rem;
    width: 1.1rem;
    border-radius: 50%;
    opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${({ theme }) => theme.primary};
`

export const RadioButtonCircleInner = styled.div<ActiveProp>`
    height: 0.7rem;
    width: 0.7rem;
    background: ${({ theme }) => theme.blue};
    border-radius: 50%;
    opacity: ${({ isActive }) => (isActive ? 1 : 0)};
`

export const RadioButtonText = styled.p<ActiveProp>`
    font-size: 1.2rem;
    color: ${({ theme }) => theme.primary};
    opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
`

interface ButtonSmallOpacityProps {
    width?: string
}

export const ButtonSmallOpacity = styled.div<ButtonSmallOpacityProps>`
    height: 3.4rem;
    padding: 0 1rem;
    background: ${({ theme }) => `${theme.blue}35`};
    border-radius: 0.8rem;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background 0.2s;

    ${({ width }) => width && `width: ${width};`}

    :hover {
        background: ${({ theme }) => `${theme.blue}55`};
    }
`

export const ButtonSmallOpacityText = styled.span`
    color: ${({ theme }) => theme.blue};
    font-size: 1.2rem;
    margin-left: 0.8rem;
`

export const ButtonSmallOpacityRed = styled(ButtonSmallOpacity)`
    background: ${({ theme }) => `${theme.red}15`};

    :hover {
        background: ${({ theme }) => `${theme.red}25`};
    }
`

export const ButtonSmallOpacityRedText = styled(ButtonSmallOpacityText)`
    color: ${({ theme }) => theme.red};
`

export const ClockIcon = styled(FiClock)`
    color: ${({ theme }) => theme.blue};
    font-size: 1.4rem;
`

export const ArchiveIcon = styled(FiArchive)`
    color: ${({ theme }) => theme.blue};
    font-size: 1.4rem;
`

export const PlusIcon = styled(FiPlus)`
    color: ${({ theme }) => theme.blue};
    font-size: 1.4rem;
`
export const PlusIconCircle = styled(FiPlusCircle)`
    color: ${({ theme }) => theme.blue};
    font-size: 1.4rem;
`
export const ExitIcon = styled(FiXCircle)`
    color: ${({ theme }) => theme.red};
    font-size: 1.4rem;
`

export const ButtonFullWidth = styled.div<ActiveProp>`
    height: 5rem;
    width: 100%;
    background: ${({ theme, isActive }) =>
        isActive ? theme.blue : `${theme.primary}05`};
    color: ${({ theme, isActive }) =>
        isActive ? theme.white : `${theme.primary}50`};
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    cursor: pointer;
`

interface DynamicHeightProps {
    height: string
    width: string
    fontSize: string
    onClick?: any
}

export const DynamicHeightBlueButton = styled.div<DynamicHeightProps>`
    height: ${({ height }) => height};
    width: ${({ width }) => width};
    border-radius: 10rem;
    background: ${({ theme }) => theme.blue};
    color: ${({ theme }) => theme.white};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${({ fontSize }) => fontSize};
    cursor: pointer;
`

export const DynamicHeightPrimaryButton = styled.div<DynamicHeightProps>`
    height: ${({ height }) => height};
    width: ${({ width }) => width};
    border-radius: 0.5rem;
    background: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.white};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${({ fontSize }) => fontSize};
    cursor: pointer;
`

export const DynamicHeightTransButton = styled.div<DynamicHeightProps>`
    height: ${({ height }) => height};
    width: ${({ width }) => width};
    border-radius: 10rem;
    background: ${({ theme }) => theme.white};
    color: ${({ theme }) => theme.greyText};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${({ fontSize }) => fontSize};
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.greyText};
`

interface DynamicHeightSaveButtonProps extends ActiveProp, DynamicHeightProps {}

export const DynamicHeightSaveButton = styled.div<DynamicHeightSaveButtonProps>`
    height: ${({ height }) => height};
    width: ${({ width }) => width};
    border-radius: 10rem;
    background: ${({ theme, isActive }) =>
        isActive ? theme.blue : `${theme.primary}10`};
    color: ${({ theme, isActive }) =>
        isActive ? theme.white : `${theme.primary}50`};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${({ fontSize }) => fontSize};
    cursor: ${({ isActive }) => (isActive ? 'pointer' : 'not-allowed')};
`
