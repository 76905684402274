import styled from 'styled-components'
import { HeadingSecondary } from '../../../styled/typography'
import { useActions, useTranslation, useTypedSelector } from '../../../../hooks'
import { useEffect } from 'react'
import Cases from '../../../reusable/cases/Cases'
import NotificationBox from './components/NotificationBox'
import ReportStatistics from '../../../reusable/statistics/ReportStatistics'

const Container = styled.div`
    width: 100%;
    padding: 4rem;
`

const FlexContainer = styled.div`
    display: flex;
    gap: 2.2rem;
    padding-top: 2rem;

    @media screen and (max-width: 480px) {
        flex-direction: column;
    }
`

// TODO re-use card

const CasesContainer = styled.div`
    width: 100%;
    min-height: 36rem;
`

const DashboardPage: React.FC = () => {
    const { ownReports, organization } = useTypedSelector(
        (state) => state.caseworker
    )

    const translation = useTranslation()

    const { getOwnReports, getDashboardStatistics } = useActions()

    useEffect(() => {
        organization && getOwnReports(organization.id)
        // eslint-disable-next-line
    }, [organization])

    useEffect(() => {
        getDashboardStatistics()

        // eslint-disable-next-line
    }, [])

    return (
        <Container>
            <HeadingSecondary>
                {translation.dashboardPartner.headlineDashboard}
            </HeadingSecondary>
            <FlexContainer>
                {ownReports && (
                    <CasesContainer>
                        <Cases
                            cases={ownReports}
                            title={translation.dashboardPartner.yourcases}
                            isAllClosed={false}
                            height="27.2rem"
                        />
                    </CasesContainer>
                )}
                <NotificationBox />
            </FlexContainer>
            <ReportStatistics />
        </Container>
    )
}

export default DashboardPage
