import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useActions, useTranslation, useTypedSelector } from '../../../../hooks'
import {
    OrganizationInterface,
    PersonalInformationInterface,
} from '../../../../ts/interfaces'
import AddedNewClientFrame from '../../../reusable/frames/new-client/AddedNewClientFrame'
import CompanyFormFrame from '../../../reusable/frames/new-client/CompanyFormFrame'
import FindCompanyFrame from '../../../reusable/frames/new-client/FindCompanyFrame'
import NumberOfEmployeesFrame from '../../../reusable/frames/new-client/NumberOfEmployeesFrame'
import PersonalInformationForm from '../../../reusable/frames/new-client/PersonalInformationFormFrame'
import {
    ButtonSmallOpacityRed,
    ButtonSmallOpacityRedText,
    ExitIcon,
} from '../../../styled/buttons'
import { HeadingPrimary } from '../../../styled/typography'
import CancelModal from './modals/CancelModal'
import SendEmailModal from './modals/SendEmailModal'
import { FiArrowLeft } from 'react-icons/fi'
import { useDefaultCountryDenmark } from '../../../../hooks/useDefaultCountryDenmark'

const Container = styled.div`
    padding-top: 3rem;
    margin: 0 4rem;
    position: relative;
`

const HeadingContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const ArrowIcon = styled(FiArrowLeft)`
    font-size: 2.6rem;
    color: ${({ theme }) => `${theme.primary}50`};
    cursor: pointer;
    position: absolute;
    top: 12rem;
`

const AddNewClient: React.FC = () => {
    const { registerOrganization } = useActions()

    const defaultCountry = useDefaultCountryDenmark()

    const [isCancelModalActive, setIsCancelModalActive] = useState(false)
    const [isSendEmailModalActive, setIsSendEmailModalActive] = useState(false)

    const translation = useTranslation()

    const [currentPageNumber, setCurrentPageNumber] = useState(1)

    const selfOrganization = useTypedSelector(
        (state) => state.caseworker
    ).organization

    const [newOrganizationId, setNewOrganizationId] = useState<null | number>(
        null
    )

    useEffect(() => {
        if (defaultCountry) {
            updatePersonalInformationCountry(defaultCountry)
            updateOrganizationCountry(defaultCountry)
        }
        // eslint-disable-next-line
    }, [defaultCountry])

    const [organization, setOrganization] = useState<OrganizationInterface>({
        cprNumber: '',
        organizationName: '',
        email: '',
        countryCode: '+45',
        phone: '',
        address: '',
        city: '',
        zipCode: '',
        employeeCount: null,
        countryId: null,
    })

    const [personalInformation, setPersonalInformation] =
        useState<PersonalInformationInterface>({
            registererFirstName: '',
            registererLastName: '',
            registererCountryCode: '+45',
            registererPhone: '',
            registererCountryId: null,
            registererEmail: '',
        })

    const updateOrganization = (key: string, value: null | string | number) => {
        setOrganization({ ...organization, [key]: value })
    }

    const updatePersonalInformation = (key: string, value: string) => {
        setPersonalInformation({ ...personalInformation, [key]: value })
    }

    const updateOrganizationCountry = (country: any) => {
        setOrganization({
            ...organization,
            countryCode: country.calling_code,
            countryId: country.id,
        })
    }

    const updatePersonalInformationCountry = (country: any) => {
        setPersonalInformation({
            ...personalInformation,
            registererCountryCode: country.calling_code,
            registererCountryId: country.id,
        })
    }

    const handleRegister = async () => {
        if (selfOrganization) {
            const res: any = await registerOrganization(selfOrganization.id, {
                ...organization,
                ...personalInformation,
            })

            if (!res) {
                return false
            }

            setNewOrganizationId(res.id)

            return true
        }
    }

    const [currentPage, setCurrentPage] = useState(
        <FindCompanyFrame
            organization={organization}
            setCurrentPageNumber={setCurrentPageNumber}
            setOrganization={setOrganization}
        />
    )

    useEffect(() => {
        if (currentPageNumber === 1) {
            setCurrentPage(
                <FindCompanyFrame
                    organization={organization}
                    setCurrentPageNumber={setCurrentPageNumber}
                    setOrganization={setOrganization}
                />
            )
            // setCurrentPage(organizationFormComponent)
        } else if (currentPageNumber === 2) {
            setCurrentPage(
                <CompanyFormFrame
                    organization={organization}
                    updateOrganization={updateOrganization}
                    setCurrentPageNumber={setCurrentPageNumber}
                    updateOrganizationCountry={(country: any) =>
                        updateOrganizationCountry(country)
                    }
                />
            )
        } else if (currentPageNumber === 3) {
            setCurrentPage(
                <NumberOfEmployeesFrame
                    setCurrentPageNumber={setCurrentPageNumber}
                    updateOrganization={updateOrganization}
                />
            )
        } else if (currentPageNumber === 4) {
            setCurrentPage(
                <PersonalInformationForm
                    personalInformation={personalInformation}
                    updatePersonalInformation={updatePersonalInformation}
                    setCurrentPageNumber={setCurrentPageNumber}
                    handleRegister={handleRegister}
                    updatePersonalInformationCountry={(country: any) =>
                        updatePersonalInformationCountry(country)
                    }
                />
            )
        } else if (currentPageNumber === 5) {
            setCurrentPage(
                <AddedNewClientFrame
                    openSendEmailModal={() => setIsSendEmailModalActive(true)}
                />
            )
        }
        // eslint-disable-next-line
    }, [currentPageNumber, organization, personalInformation])

    return (
        <>
            {isCancelModalActive && (
                <CancelModal closeModal={() => setIsCancelModalActive(false)} />
            )}
            {isSendEmailModalActive && (
                <SendEmailModal
                    email={personalInformation.registererEmail}
                    closeModal={() => setIsSendEmailModalActive(false)}
                    organizationId={newOrganizationId}
                />
            )}
            <Container>
                <HeadingContainer>
                    <HeadingPrimary>
                        {translation.addNewClientPartner.headlineAddNewClient}
                    </HeadingPrimary>
                    {currentPageNumber !== 5 && (
                        <ButtonSmallOpacityRed
                            onClick={() => setIsCancelModalActive(true)}
                        >
                            <ExitIcon />
                            <ButtonSmallOpacityRedText>
                                {translation.addNewClientPartner.buttonCancel}
                            </ButtonSmallOpacityRedText>
                        </ButtonSmallOpacityRed>
                    )}
                </HeadingContainer>

                {currentPageNumber !== 1 && currentPageNumber !== 5 && (
                    <ArrowIcon
                        onClick={() =>
                            setCurrentPageNumber(currentPageNumber - 1)
                        }
                    />
                )}

                {currentPage}
            </Container>
        </>
    )
}

export default AddNewClient
