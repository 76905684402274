import { Route } from 'react-router-dom'
import { useTypedSelector } from '../../hooks'
import { getUrlAfterAppSubdomain } from '../../helpers/routing'

const ProtectedClientRoute = ({ component: Component, ...rest }) => {
    const { isLoggedIn, user } = useTypedSelector(state => state.auth)

    return (
        <Route
            {...rest}
            render={(props) => {
                if (!isLoggedIn || (!user?.roles.includes('contact'))) {
                    return window.location.replace(`http://app.${getUrlAfterAppSubdomain()}/logout`)

                } else {
                    return <Component {...props} />
                }
            }}
        />
    )
}

export default ProtectedClientRoute
