import styled from 'styled-components'

const Description = styled.p`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.primary};
  padding: 1.6rem 2.8rem;
  white-space: pre-wrap;
`

interface DescriptionFrameProps {
    description: string
}

const DescriptionFrame: React.FC<DescriptionFrameProps> = ({ description }) => {
    return <Description>{description}</Description>
}

export default DescriptionFrame
