import SkeletonCard from '../../reusable/skeletons/SkeletonCard'
import {
    FlexContainer,
    CasesContainer,
} from '../contact-users/dashboard/ContactDashboardPage'
import { StatisticsContainer } from '../../reusable/statistics/ReportStatistics'
import { NotificationContainer } from '../partners/dashboard/components/NotificationBox'

const LoadingSkeletonContactDashBoardPage: React.FC = () => {
    return (
        <>
            <FlexContainer>
                <CasesContainer>
                    <SkeletonCard border="0.3rem" />
                </CasesContainer>
                <NotificationContainer>
                    <SkeletonCard border="0.3rem" />
                </NotificationContainer>
            </FlexContainer>
            <StatisticsContainer>
                <SkeletonCard height="30rem" border="0.3rem" />
            </StatisticsContainer>
        </>
    )
}

export default LoadingSkeletonContactDashBoardPage
