import styled from 'styled-components'
import { useActions, useTranslation } from '../../../../../hooks'
import { NextButton } from '../../../../reusable/buttons'

const ModalContainer = styled.div`
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);

    position: fixed;
    top: 0;
    left: 0;
    z-index: 500;
`

const Modal = styled.div`
    height: 50rem;
    width: 58rem;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: ${({ theme }) => theme.white};
    z-index: 501;
    text-align: center;
    border-radius: 0.5rem;
    padding: 0 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 600px) {
        top: 55%;
        width: 35rem;
        padding: 0 2rem;
    }
`

const ModalHeading = styled.h4`
    font-size: 1.8rem;
    font-family: NeueMontreal-bold;
    padding-top: 6.6rem;
    color: ${({ theme }) => theme.darkColor};
    padding-bottom: 2.4rem;

    @media screen and (max-width: 600px) {
        padding-block-start: 2.5rem;
    }
`

const ModalSecondary = styled.h4`
    font-size: 1.8rem;
    color: ${({ theme }) => theme.darkColor};
`

const ModalText = styled.p`
    font-size: 1.6rem;
    color: ${({ theme }) => theme.black};
    padding: 2.4rem 0;
`

const ButtonContainer = styled.div`
    margin-top: 3rem;
`

interface ApperanceModalProps {}

const ApperanceModal: React.FC<ApperanceModalProps> = () => {
    const { closeModal } = useActions()

    const translation = useTranslation()

    return (
        <ModalContainer>
            <Modal>
                <ModalHeading>
                    {
                        translation.newCaseFlow
                            .popUpWhatIstheDifferenceBetweenAnAnonymous
                    }
                </ModalHeading>
                <ModalSecondary>
                    {translation.newCaseFlow.popUpAnonymous}
                </ModalSecondary>
                <ModalText>
                    {
                        translation.newCaseFlow
                            .popUpAnonymousYouProvideYourEmployer
                    }
                </ModalText>
                <ModalSecondary>
                    {translation.newCaseFlow.popUpConfidential}
                </ModalSecondary>
                <ModalText>
                    {
                        translation.newCaseFlow
                            .popUpConfidentialYouProvideYourEmployer
                    }
                </ModalText>
                <ButtonContainer>
                    <NextButton
                        text={translation.closeButton.close}
                        icon={false}
                        isActive={true}
                        handleOnClick={closeModal}
                    />
                </ButtonContainer>
            </Modal>
        </ModalContainer>
    )
}

export default ApperanceModal
