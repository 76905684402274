import { useEffect, useState } from 'react'
import { useTypedSelector } from '../../../../hooks'
import EnterWhistleblowerCode from './frames/EnterWhistleblowerCode'
import Report from './frames/Report'

const WhistleblowerAccessReportPage: React.FC = () => {
    const { openReport } = useTypedSelector((state) => state.whistleblower)

    const [currentPage, setCurrentPage] = useState(<EnterWhistleblowerCode />)

    useEffect(() => {
        if (!openReport) {
            return setCurrentPage(<EnterWhistleblowerCode />)
        } else {
            return setCurrentPage(<Report />)
        }
    }, [openReport])
    return <>{currentPage}</>
}

export default WhistleblowerAccessReportPage
