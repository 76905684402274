import { useActions, useTypedSelector } from '../../../hooks'
import { useEffect, useState } from 'react'
import OnboardingPage from './OnboardingPage'
import ClientInformationPage from './ClientInformationPage'
import LoadingClientInformationPage from '../loading/LoadingClientInformationPage'

const ClientHomepage: React.FC = () => {
    const { user } = useTypedSelector((state) => state.auth)
    const { client } = useTypedSelector((state) => state.client)
    const [isStillOnboarding, setIsStillOnboarding] = useState(true)
    const [isLoading, setIsLoading] = useState(true)
    const { getCompanyInformation } = useActions()

    const handleGetCompanyInformation = async () => {
        await getCompanyInformation()

        setIsLoading(false)
    }

    useEffect(() => {
        // user.temp !== null means no onboarding step has been completed
        if (user && !user.temp) {
            handleGetCompanyInformation()

            return
        }

        setIsLoading(false)

        // eslint-disable-next-line
    }, [user])

    useEffect(() => {
        if (client && client.organization.data.onboarded_at) {
            setIsStillOnboarding(false)
        }
    }, [client])

    return (
        <>
            {!isLoading ? (
                <>
                    {isStillOnboarding ? (
                        <OnboardingPage />
                    ) : (
                        <ClientInformationPage />
                    )}
                </>
            ) : (
                <LoadingClientInformationPage padding="4rem" />
            )}
        </>
    )
}

export default ClientHomepage
