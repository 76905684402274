import { useEffect, useState } from 'react'
import styled from 'styled-components'
import WhistleblowerApperance from './frames/WhistleblowerApperance'
import FillInReport from './frames/FillInReport'
import PersonalInformation from './frames/PersonalInformation'
import SumaryOfReporting from './frames/SumaryOfReporting'
import { FiArrowLeft } from 'react-icons/fi'
import ReportCompleted from './frames/ReportCompleted'
import {
    useActions,
    useTheme,
    useTranslation,
    useTypedSelector,
} from '../../../../hooks'
import { WhistleblowerInterface } from '../../../../ts/interfaces'
import ChooseContactFrame from './frames/ChooseContactFrame'
import ExcludeModeChooseContactFrame from './frames/ExcludeModeChooseContactFrame'

const GoBackContainer = styled.div`
    position: absolute;
    top: 24rem;
    left: 12rem;
    opacity: 0.5;
    cursor: pointer;
    z-index: 500;

    @media (max-width: 1000px) {
        left: 5vw;
    }

    @media screen and (max-width: 600px) {
        top: 10.3rem;
        opacity: 1;
        left: 2rem;

        .arrow-left-icon {
            width: 2.5rem;
        }
    }
`

const StatusBar = styled.div`
    height: 7.5rem;
    width: 100vw;
    background: ${({ theme }) => theme.grey};
    display: flex;
    justify-content: space-between;
    padding: 0 12vw;
    align-items: center;
    position: absolute;
    left: 0;

    @media (max-width: 600px) {
        padding: 0 6vw;
    }

    @media screen and (max-width: 600px) {
        height: 1rem;
    }
`

const StatusBarItem = styled.div`
    display: flex;
`

const StatusBarNumber = styled.div<GlowHighlightProp>`
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: ${({ shouldGlow: isActive, theme }) =>
        isActive ? theme.blue : theme.primary};
    opacity: ${({ shouldGlow: isActive }) => (isActive ? 1 : 0.3)};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    color: ${({ theme }) => theme.white};
    margin-right: 1.4rem;

    @media screen and (max-width: 600px) {
        display: none;
    }
`

const StatusBarText = styled.div<GlowHighlightProp>`
    font-size: 1.6rem;
    color: ${({ theme }) => theme.primary};
    opacity: ${({ shouldGlow: isActive }) => (isActive ? 1 : 0.3)};

    @media (max-width: 600px) {
        font-size: 1.4rem;
    }
    @media screen and (max-width: 600px) {
        display: none;
    }
`

interface StatusBarStatusProps {
    statusWidth: string
}

interface GlowHighlightProp {
    shouldGlow: boolean
}

const StatusBarStatus = styled.div<StatusBarStatusProps>`
    height: 4px;
    background: ${({ theme }) => theme.blue};
    width: ${({ statusWidth }) => statusWidth};
    transition: width 0.2s;
    position: absolute;
    bottom: 0;
    left: 0;

    @media screen and (max-width: 600px) {
        height: 1rem;
    }
`

const CurrentPage = styled.div`
    padding-block-start: 5rem;

    @media screen and (max-width: 600px) {
        padding-inline: 1.5rem;
        padding-block: 4rem;
    }
`

interface NewCasePageProps {}

export interface Apperance {
    key: string
    text: string
}

export interface Report {
    whistleblowerApperance: null | Apperance
    whistleblower: null | WhistleblowerInterface
    title: null | string
    description: null | string
    files: Array<FileWithKey>
    contacts: Array<any>
    questions: Array<{}>
}

interface FileWithKey extends File {
    key: number
}

export interface QuestionInterface {
    organization_custom_question_id: number
    answer: string
}
const NewCasePage: React.FC<NewCasePageProps> = () => {
    const translation = useTranslation()
    const selectedTranslation = useTypedSelector(
        (state) => state.translation.selectedTranslation
    )

    const { resetWhistleblowerErrorMessageState } = useActions()

    const { reportQuestions } = useTypedSelector(
        (state) => state.reportQuestions
    )
    const { reportErrors, isExcludeModeActive } = useTypedSelector(
        (state) => state.whistleblower
    )
    /* const { accountType } = useTypedSelector((state) => state.whistleblower) */

    const [currentPageNumber, setCurrentPageNumber] = useState(1)
    const [currentStep, setCurrentStep] = useState(1)
    const [statusWidth, setActiveWidth] = useState('0%')

    const [questions, setQuestions] = useState(
        reportQuestions.map((questionItem) => ({
            organization_custom_question_id: questionItem.id,
            answer: '',
        }))
    )

    const [report, setReport] = useState<Report>({
        whistleblowerApperance: null,
        whistleblower: null,
        title: null,
        description: null,
        files: [],
        contacts: [],
        questions: [],
    })

    const updateReport = (
        key: string,
        /*value: string | Array<File> | WhistleblowerInterface*/
        value: any
    ) => {
        if (key === 'files') {
            value.key = report.files.length
            const newFiles = value.map((file: File, index: number) => {
                return {
                    file,
                    key: report.files.length + index + 1,
                }
            })
            setReport({ ...report, [key]: [...report.files, ...newFiles] })
            return
        }

        setReport({ ...report, [key]: value })
    }

    const removeSingleFile = (fileIndex: number) => {
        setReport({
            ...report,
            files: report.files.filter((file, index) => index !== fileIndex),
        })

        removeErrorMessageState()
    }

    const removeErrorMessageState = () => {
        if (reportErrors && typeof reportErrors.length === 'undefined') {
            return resetWhistleblowerErrorMessageState()
        }
    }

    const updatePage = (pageIncrement: number = 1) => {
        setCurrentStep(currentStep + 1)
        setCurrentPageNumber(currentPageNumber + pageIncrement)
    }

    const goBack = () => {
        if (currentPageNumber === 1.5) {
            setCurrentPageNumber(currentPageNumber - 0.5)
        } else {
            setCurrentPageNumber(currentPageNumber - 1)
        }
    }

    const apperanceComponent = (
        <WhistleblowerApperance
            updateReport={updateReport}
            updatePage={updatePage}
            apperance={report.whistleblowerApperance}
        />
    )
    const theme = useTheme()
    const [currentPage, setCurrentPage] = useState(apperanceComponent)

    useEffect(() => {
        if (report.whistleblowerApperance?.key === 'anonymous') {
            updateReport('whistleblowerApperance', {
                key: 'anonymous',
                text: translation.newCaseFlow.anonymous,
            })
        } else if (report.whistleblowerApperance?.key === 'confidential') {
            updateReport('whistleblowerApperance', {
                key: 'confidential',
                text: translation.newCaseFlow.confidential,
            })
        }

        // eslint-disable-next-line
    }, [selectedTranslation])

    useEffect(() => {
        if (currentPageNumber === 1) {
            setCurrentPage(apperanceComponent)

            report.whistleblowerApperance
                ? setCurrentStep(2)
                : setCurrentStep(1)
        } else if (currentPageNumber === 1.5) {
            setCurrentPage(
                <PersonalInformation
                    updateReport={updateReport}
                    updatePage={updatePage}
                />
            )

            setCurrentStep(3)
        } else if (currentPageNumber === 2) {
            setCurrentPage(
                <FillInReport
                    updateReport={updateReport}
                    updatePage={updatePage}
                    removeSingleFile={removeSingleFile}
                    title={report.title}
                    description={report.description}
                    files={report.files}
                    questions={questions}
                    setQuestions={setQuestions}
                />
            )

            if (!report.title && !report.description) {
                setCurrentStep(7)
            } else if (report.title && report.description) {
                setCurrentStep(9)
            } else {
                setCurrentStep(8)
            }
        } else if (currentPageNumber === 3) {
            return setCurrentPage(
                isExcludeModeActive ? (
                    <ExcludeModeChooseContactFrame
                        updateReport={updateReport}
                        updatePage={updatePage}
                        contacts={report.contacts}
                    />
                ) : (
                    <ChooseContactFrame
                        updateReport={updateReport}
                        updatePage={updatePage}
                        selectedContact={report.contacts}
                    />
                )
            )
        } else if (currentPageNumber === 4) {
            return setCurrentPage(
                <SumaryOfReporting
                    report={report}
                    removeSingleFile={removeSingleFile}
                    updatePage={updatePage}
                    questions={questions}
                    contacts={report.contacts}
                />
            )
        } else if (currentPageNumber === 5) {
            setCurrentPage(<ReportCompleted />)

            setCurrentStep(11)
        }
        // eslint-disable-next-line
    }, [currentPageNumber, report])

    useEffect(() => {
        setActiveWidth(`${25 * (currentPageNumber - 1)}%`)
    }, [currentPageNumber])

    return (
        <div>
            {currentPageNumber !== 1 && currentPageNumber < 5 && (
                <GoBackContainer onClick={goBack}>
                    <FiArrowLeft
                        color={theme.darkColor}
                        size={36}
                        className="arrow-left-icon"
                    />
                </GoBackContainer>
            )}
            <StatusBar>
                <StatusBarItem>
                    <StatusBarNumber shouldGlow={currentPageNumber === 1}>
                        1
                    </StatusBarNumber>
                    <StatusBarText shouldGlow={currentPageNumber === 1}>
                        {translation.newCaseFlow.headerInitiateReport}
                    </StatusBarText>
                </StatusBarItem>
                <StatusBarItem>
                    <StatusBarNumber shouldGlow={currentPageNumber === 2}>
                        2
                    </StatusBarNumber>
                    <StatusBarText shouldGlow={currentPageNumber === 2}>
                        {translation.newCaseFlow.headerDescription}
                    </StatusBarText>
                </StatusBarItem>
                <StatusBarItem>
                    <StatusBarNumber shouldGlow={currentPageNumber === 3}>
                        3
                    </StatusBarNumber>
                    <StatusBarText shouldGlow={currentPageNumber === 3}>
                        {translation.newCaseFlow.headerChooseCaseworker}
                    </StatusBarText>
                </StatusBarItem>
                <StatusBarItem>
                    <StatusBarNumber shouldGlow={currentPageNumber === 4}>
                        4
                    </StatusBarNumber>
                    <StatusBarText shouldGlow={currentPageNumber === 4}>
                        {translation.newCaseFlow.headerSummary}
                    </StatusBarText>
                </StatusBarItem>
                <StatusBarStatus statusWidth={statusWidth} />
            </StatusBar>
            <CurrentPage>{currentPage}</CurrentPage>
            {/* <button onClick={() => setCurrentPageNumber(currentPageNumber + 1)}>
                +1
            </button> */}
        </div>
    )
}

export default NewCasePage
