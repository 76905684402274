import axios from 'axios'
import config from '../../config'
import { CategoryActionType } from '../action-types'
import { Dispatch } from 'redux'

const { apiUrl } = config

export const getCategories = () => async (dispatch: Dispatch) => {
    const res = await axios.get(`${apiUrl}/v1/categories`)

    dispatch({
        type: CategoryActionType.GET_CATEGORIES,
        payload: res.data.data,
    })
}
