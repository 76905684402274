const en = {
    registerPage: {
        personalInformation: 'Personal Information',
        accountType: 'Account type',
        noCreditCardRequired: 'No credit card required, start your',
        freeTrial: 'free trial',
        today: 'today',
        firstname: 'First name',
        lastName: 'Last name',
        phoneNumber: 'Phone number',
        email: 'Work email',
        choosebetweentwo:
            'Choose between two solutions depending on your needs',
        software: 'Software',
        softwarePlusScreening: 'Software + Screening',
        idealForBig: 'Ideal for large enterprises',
        idealForSmall: 'Ideal for small & medium-sized enterprises',
        easyAndSecure: 'Easy and secure',
        chargedYearly: 'Charged yearly',
        secureWhistleblowerPlatform: 'Secure whistleblower platform',
        tailoredWhistleblowerPolicy: 'Tailored whistleblower policy',
        reportManagement: 'Report management',
        followUpCommunication: 'Follow-up communication',
        emailNotifications: 'E-mail notifications',
        employeeGuides: 'Employee guides',
        areYouLookingFo: 'Are you looking for our partner program?',
        partnerprogrammodal: 'Partner Program',
        iHaveAgreedToWalor: 'I have read and agree to Walor’s',
        termsAndConditions: 'Terms & conditions',
        and: 'and',
        privacyAndCookiePolicy: 'Privacy and cookie policy',
        dataProcessingAgreement: 'Data Processing Agreement',
        ifYouAreLookingFor:
            'If you are looking for a partner program contact us at',
        thanksCheckYour: 'Done! Check your email',
        rememberToCheckYourSpam:
            "Didn't receive the email? Please check your spam folder or contact us at",
        here: 'here.',
        cancel: 'Cancel',
        areYouAMemberOf: 'Are you a member of',
    },

    // Onboarding
    onboardingPage: {
        hi: 'Hi',
        welcomeToWalor: ', welcome to Walor!',
        youAreAlmostDone: 'You are almost done setting your new solution up',
        insertCompanyInformation: 'Company Information',
        subcreateYourCompanyInformation:
            'Add the company address and the number of employees.',
        lineOfContact: 'Line of Contact',
        generateWhistleblowerPolicy: 'Whistleblower Policy',
        readyToGo: "Let's Begin",
        Back: 'Back',
        pleaseFillinThisInformation: 'Enter your company information',
        nextStep: 'Next step',
        name: 'Company name',
        address: 'Address',
        city: 'City',
        zipCode: 'Zip code',
        country: 'Country',
        numberOfEmployees: 'Number of employees',
        createYourLineOfContact: 'Create your Line of Contact',
        skipThisStep: 'Skip this step',
        subcreateYourLineOfContact:
            'These users will be responsible for handling whistleblowing reports for your organization.',
        firstName: 'First name',
        lastName: 'Last name',
        position: 'Position',
        email: 'Work email',
        phone: 'Phone number',
        generateyourwbpolicy: 'Generate your Whistleblower Policy',
        subgenerateyourwbpolicy:
            'This describes the legal framework of your whistleblowing solution.',
        clickHere: 'Click here',
        complete: 'Complete',
        yourWhistleblowerpolicyIsBeingGenerated:
            'Your Whistleblower Policy is being generated as we speak',
        goodWork: 'Congratulations',
        yourPolicyHasBeen: 'Your Whistleblower Policy has been created!',
        allSetEasy: 'All set! Easy, right?',
        letsGetYouStarted: "Let's get you started right away",
        takeMeToTheDashboard: 'Take me to the Dashboard',
    },

    onboardingFlow: {
        // Onboarding (1)
        companyInformation: 'Company information',
        searchForCompanyNameOrCompanyNumber:
            'Search for company name or company number',
        search: 'Search',
        companyNumber: 'Company number',
        enterCompanyNumber: 'Company number',
        companyName: 'Company name',
        enterCompanyName: 'Company name',
        workEmail: 'Work email',
        enterWorkEmail: 'Work email',
        phone: 'Phone',
        enterPhone: '12345678',
        address: 'Address',
        enterAddress: 'Address',
        city: 'City',
        enterCity: 'City',
        zipCode: 'Zip code',
        enterZipCode: '1234',
        numberOfEmployees: 'Number of employees',
        makeSureToChooseTheRightInterval:
            'Make sure to choose the right interval',

        // Onboarding (2)
        personalInformation: 'Personal information',
        firstName: 'First name',
        enterFirstName: 'First name',
        lastName: 'Last name',
        enterLastName: 'Last name',
        password: 'Password',
        enterPassword: 'Password',
        yourPasswordMustInclude:
            'Your password must contain at least one lowercase letter, one capital letter and one number',
        confirmPassword: 'Confirm password',
        confirmYourPassword: 'Confirm password',

        // Onboarding (3)
        selectYourSubscriptionPlan: 'Select your subscription plan',
        monthlyBilling: 'Monthly billing',
        yearlyBilling: 'Yearly billing',
        save20percent: 'Save 20%',
        aCompleteWhistleblowerSolution: 'A complete whistleblower solution',
        vat: 'DKK ex. VAT',
        freeOnboarding: 'Free onboarding & manuals',
        customizedWhistleblowerPolicy: 'Customized whistleblower policy',
        digitalWhistleblowerReportingChannel:
            'Digital whistleblower reporting channel',
        fullScreening: 'Competent screening of all incoming cases',
        supportInvestigation: 'Support internal investigation efforts',
        compliantAnonymousAndSecure: 'Compliant, anonymous and secure',
        youCanReadMoreHere: 'You can read more here',
        additionalText:
            'Walor will act as a third party to receive initial reports sent through the whistleblowing system. Walor carries out initial checks to review if a report is relevant to handle in the whistleblowing system, or  if it is more of a HR matter.  Using such a service ensures independency, non-bias handling, reduces workloads, guarantees anonymity and helps a company stay in line with EU Whistleblowing Directive requirements.',

        // Onboarding (4)
        // MISSING!!!!!!

        // Onboarding (5)
        thanksForYourSubscription: 'Thanks for your subscription',
        informationOnHowToGetStarted: 'Information on how to get started',
        linkText:
            'The link will provide you with the access to the digital whistleblower reporting channel where the reports can be created and submitted. Share this with your organization.',
        CopyToClipboard: 'Copy to clipboard',
        whistleblowerPolicyText:
            'Download your companys whistleblower policy below and login to the platform for additional material.',
        download: 'Download',
        buttonGoToLogin: 'Go to login',
    },

    loginFlow: {
        // Login (1)
        login: 'Login',
        email: 'Email',
        enterEmail: 'Enter email',
        password: 'Password',
        enterPassword: 'Password',
        forgotPassword: 'Forgot password?',

        // Login - Forgot password?
        underPageBackToLogin: 'Back to login',
        underPageForgotPassword: 'Forgot password?',
        underPageWriteYourEmail:
            'Write your email below to create a new password',
        ButtonEmailMe: 'Email me',

        // Login (2)
        howDoYouWant: 'How do you want to receive your security code?',
        buttonText: 'SMS',
        or: 'or',
        buttonEmail: 'Email',
        buttonSendCode: 'Send code',

        // Login (3)
        backToLogin: 'Back to login',
        enterThe6digit: 'Enter the 6-digit code',
        yourCodeWillArrive: 'Your code will arrive in a few seconds to',
        didYouNotReceive: 'Did you not receive the code? Click here to re-send',
        resendCode: 'Resend code',
    },

    whistleblowerCreateCase: {
        // Create case (1)
        companyName: 'Company name',
        whistleblowerScheme: 'Whistleblower Reporting Channel',
        createReport: 'Create report',
        startReport: 'Initiate an anonymous and safe report',
        accessExistingReport: 'Access existing report',
        checkStatus: 'Check the status of an already existing report',
        understandWhistleblowing: 'Understand whistleblowing',
        readMore: 'Employee guides',
        playvideo: 'Play',
        howThisWorks: 'How this works',
        createAReport: 'Create a report',
        createAReportSimply:
            'Create a report anonymously or confidentially. You can provide all the information and files you have gathered. You will get a unique whistleblowercode, once you have sent the report, that allows you to access the report again.',
        investigatingthereport: 'Internal investigation',
        caseworkerinthecompany:
            'The caseworkers that you choose to handle your report will receive a notification on their email to start conducting the internal investigation.',
        answeradditionalquestions: 'Answer additional questions',
        additionalQuestions:
            'The caseworkers that you choose might have additional questions to your report. Make sure to re-enter your report once in a while to aid in the internal investigation.',
        companiesWhistleblowerPolicy: 'Whistleblower Policy',
    },

    whistleblowerHowItWorksPage: {
        backToHomescreen: 'Back to homescreen',
        // whistleblowing in general
        whistleblowing: 'Whistleblowing in general',
        whatIsWhistleblowing: 'What is whistleblowing? ',
        whistleblowingDefinition:
            'Whistleblowing is the reporting by an individual of illegal or immoral actions within an organisation. The person reporting this, called a whistleblower, can either be part of this organisation or a third-party related to the organisation.',
        whereItsUseful:
            'In the vast majority of the cases, whistleblowing will take place in a work-related context. An employee might for example acquire information about illegal activities of his or her employer during the performance of his or her work, which the employee decides to report.',
        whatAreTheTypes: 'What types of whistleblowing exist?',
        typesOfWhistleblowing:
            'A distinction can be made between internal and external whistleblowing. Internal whistleblowing entails that a whistleblower makes a report within the organisation he or she is part of or related to. This means that the whistleblower communicates about the misconduct only within this organisation. On the other hand, external whistleblowing means that the whistleblower alerts others than the organisation itself of activities by that organisation. In that case, information is communicated to for example public authorities or the media.',
        whatIsTheImportance: 'What is the importance of whistleblowing?',
        preventionOrReparation:
            'First of all, whistleblowing plays an essential role in the prevention or reparation of harms to the public interest, as persons who are part of or related to an organisation are often the first or only to have knowledge or access about information of such harms.',
        ethicCulture:
            'Next to this, from the perspective of the organisation, whistleblowing contributes to a  culture of ethics, legality, transparency and participation.',
        whistleblowerSafety:
            'Lastly, whistleblowing allows the whistleblower to overcome a feeling of guilt or sense of responsibility after acquiring information about illegal or immoral actions in the company the whistleblower is part of or related to.',
        whistleblowerImportance:
            'Because of this importance, protection for whistleblowers exists by law.',
        // whistleblower scheme
        whistleblowerScheme: 'Whistleblower scheme',
        whatIsWhistleblowerScheme: 'What is a Whistleblower scheme?',
        whistleblowerIsATool:
            'A whistleblower scheme is a tool which allows people to make reports about a certain organisation. This system thereby guides whistleblowers through the process of reporting an illegal or unethical activity. This enables organisations to learn about, investigate and remedy illegal or unethical conduct.',
        whyHaveAWhistleBlowerScheme: 'Why have a whistleblower scheme?',
        whistleblowerLaw:
            'Following an increased focus of the European Union on the value of whistleblowing, the EU adopted Directive 2019/1937 on the Protection of Persons Who Report Breaches of Union Law on October 23, 2019. This Whistleblower Directive, which has been implemented into Danish law, provides a legal framework for whistleblowing schemes and protects whistleblowers that report information through a whistleblower scheme.',
        whistleblowingSchemeTransparency:
            'This whistleblowing scheme enables anyone to have a voice and exercise their right to be heard, which is essential to create a culture of transparency and a sense of belonging. Next to this, this scheme supports and encourages to raise concerns internally, close to the source of the problem. This makes it possible to remedy these concerns early and effectively.',
        whoCanUseThisWhistleblowerScheme:
            'Who can use this whistleblower scheme?',
        anyoneCanUse:
            'The whistleblower scheme can be used by anyone who has information about a public or private organisation which they have acquired in the course of his or her work-related activities.',
        categoriesOfPeople:
            'The categories of people who can use this whistleblowing scheme are broad. Reports can thus be filed by:',
        people1:
            'Employees. This entails both full and part-time employees as well as employees working under an unlimited or fixed-term contract.',
        people2: 'Job applicants',
        people3: 'Paid or unpaid trainees',
        people4: 'Volunteers',
        people5: 'Self-employed persons',
        people6: 'Shareholders',
        people7:
            'Members of the management, supervisory board or other governing board',
        people8:
            'Persons working under the supervision and direction of contractors, subcontractors and suppliers',
        noMatterTheRelationship:
            'Persons falling under these categories can make use of the whistleblowing scheme regardless of whether their employment relationship with the organisation has ceased, is still ongoing or has not yet begun.',
        whatCanBeReported:
            'What can be reported through this whistleblower scheme?',
        seriousViolations:
            'The whistleblower scheme can be used to address any information about serious violations of the law, attempts to conceal such violations or other serious matters that have occurred or are likely to occur in an organisation. Also in the case of reasonable suspicion of the occurrence of these violations or matters, this information can be reported.',
        violationsOfLaw:
            'The violations which can be reported about through the whistleblower scheme relate to specific breaches of EU law or other serious offenses and matters according to Danish law. Activities that can be reported through this scheme relate for example to corruption, protection of the environment, public health, privacy and personal data or sexual harassment and sexual abuse. These are only examples. Also other matters or violations of the law of a serious nature can be reported.',
        whatCantBeReported:
            'What can not be reported through this whistleblower scheme?',
        lessSeriousMatters:
            'This whistleblower scheme is not meant to report less serious matters or ordinary employment issues, such as dissatisfaction with salary or minor collegial issues. Next to this, reports that are not submitted in good faith and which are manifestly unfounded are not meant to be submitted.',
        inCaseOfDoubt:
            'If you are in doubt about whether an observation or suspicion is of a serious nature and should be reported or not, you are still encouraged to do so. All inquiries will be answered and processed.',
        howAreYouProtected: 'How are you protected when using this scheme?',
        noLiability:
            'Whistleblowers that report matters of a serious nature in good faith through this scheme enjoy broad protection, without incurring any liability for breaching a duty of confidentiality with regards to the reported information. Also persons assisting the whistleblower or persons or companies related to the whistleblower fall under this protection.',
        noRetaliation:
            'This protection entails a protection against any form of retaliation. Retaliations entail any action or omission as a result from the report and which cause or may cause unjustified harm to the whistleblower. Such actions can take multiple forms, such as for example dismissal, change of location of work, reduction in wages or harming the personal or professional reputation. If a whistleblower or protected person suffers any retaliation he or she will be compensated for this. An organisation that retaliates may also be subject to other sanctions.',
        // how does the process of reporting work?
        howDoesTheProcessWork: 'How does the process of reporting work?',
        howDoIReport: 'How do I report?',
        reportingChannel:
            'You can create a report through the digital reporting channel of the organisation you intend to report about.',
        whatIfIncomplete: 'What if my report is incomplete?',
        accessAndExpand:
            'It is always possible to access your report and to expand it by providing additional details or evidence. The persons handling the case might also request additional questions or documentation, which may be relevant to handle and investigate the report. A subsequent submission of answers or documentation solely depends however on your willingness to do so. No obligation to do so exists.',
        whoIsProccessing: 'Who is processing my report?',
        thirdParty:
            "Your report will be handled by the selected person(s) in the company's whistleblowing unit. Only these persons are authorized to conduct an investigation. Under no circumstances will your case be entrusted to a person involved in the reported activities or with whom there may be a conflict of interest.",
        howIsProccessed: 'How is my report processed?',
        investigations:
            'You will receive an acknowledgement of receival of the report within seven days. After this, an initial examination of the report will be conducted. If the report turns out to be notably unfounded, it will be rejected. You will receive a notice of this as soon as possible. However, if the initial examination determines that your report cannot be characterized as notably unfounded, it will be further investigated. Your case will then be processed internally. This internal investigation may have consequences for the person or persons who are involved. If the case is of particular serious nature, it may also be passed on to the police for further investigation. After conducting all investigations and imposing potential sanctions, the case will be closed. It will then be deleted from the system.',
        whatIfLooseCode: 'What if I lose my whistleblower code?',
        looseAccess:
            'Unfortunately, in the event of losing the whistleblower code, you will not have access anymore to your report. This is the only possibility to guarantee a secure and anonymous report. Your report will still be reviewed and investigated however.',
        unableToUptade:
            'You will not be able to update your report or answer any received questions about the report. In order to do this,  a new report will have to be made and submitted through this whistleblowing scheme.',
        howLongDoesItTake: 'How long will it take to process my report?',
        whithin3Months:
            'Your report will be processed and investigated as soon as possible. During this process, you will receive feedback on any updates or outcomes. The total duration will depend on your case but you will receive at the latest within three months after reporting information on the action envisaged or taken.',
        willIRemainSecret: 'Will my identity remain secret?',
        anounOrConfidential:
            'You have the opportunity to report either anonymously or confidential through the whistleblower scheme. Whereas the former entails that no personal information is shared, the latter includes the sharing of personal information by the whistleblower. If personal information is shared in your report, only those who are authorized to investigate the case will have access thereto. The confidentiality of the submitted report will therefore in any case be respected.',
        willPersonsBeNotified:
            'Will any of the persons concerned in a report be notified?',
        concernedsNotified:
            'The person or persons concerned will be informed of his or her involvement in a report. In each individual case, an assessment will be made of when this notice can be passed on, so that the notice will have no consequences for the collection of evidence.',
        noPersonalInfo:
            'No information, either direct or indirect, will be specified about who made the report towards the person concerned, even if you have chosen to report confidentially and therefore share your identity.',
        isSchemeSecured: 'Is this whistleblower scheme secured?',
        independencyGuarantee:
            'The whistleblower reporting channel is operated by Walor ApS, which is an independent third party who guarantees the safety and security of the system. The system does not log IP addresses and machine-IDs and will encrypt all data processed. The personal data rights of all parties involved will be respected. Only the responsible caseworker has access to the report.',
        whatIfImDissatisfied:
            'What if I am dissatisfied with the assessment or outcome of my report?',
        informCompetentAuth:
            'It is always possible to take further action if you consider grounds thereto exist. In no case a negative outcome of a report prevents you from doing so. After reporting through the whistleblower scheme, it is possible for you to report the information to the competent authorities. Next to this, where relevant, reports can also be made to institutions, bodies, offices or agencies of the European Union.',
    },

    whistleblowerPolicyPage: {
        // Whistleblower Policy (1)
        whistleblowerPolicyHeading: 'Whistleblower policy',
        backToHomescreen: 'Back to homescreen',
        whistleblowerPolicy: "'s whistleblower policy",
        companyName: 'Company name',
        thePurposeOfThisWhistleblower:
            'The purpose of this whistleblower policy is to explain how',
        whistleblowerSchemeWorks:
            "'s whistleblower scheme works. This scheme allows you to make reports about",
        aGoodUnderstanding:
            'A good understanding of this scheme can therefore prevent potentially important matters from being left unreported.',
        introductionTo: 'Introduction to',
        whistleblowerScheme: "'s whistleblower scheme",
        isAResponsibleCompanyWith:
            'is a responsible company with an open culture, where everyone can feel safe and express themselves if they experience wrongdoing or potential breaches of the law. As a starting point, it is always possible and strongly supported to talk to an immediate superior or a member of the Executive Board in these situations, but it is also possible to use the whistleblower reporting channel.',
        whistleblowerSchemeEnsuresProtection:
            "'s whistleblower scheme ensures protection for individuals who report serious offenses and matters. This scheme can be used if you experience, are aware of or have reasons to suspect the occurrence of such breaches in",
        orRelatedTo: 'or related to',
        bothInThePast:
            'both in the past, present or near future. Reports can be filed either anonymously or confidential.',
        whoCanUseThisWhistleblowingScheme:
            'Who can use this Whistleblowing scheme?',
        whistleblowerSchemeCanBeUsedByAnyone:
            "'s whistleblower scheme can be used by anyone who has information about",
        whichTheyHaveAcquired:
            'which they have acquired in the course of his or her work-related activities with',
        theCategoriesOfPeopleWho:
            'The categories of people who can use this whistleblowing scheme are broad. Reports can thus be filed by:',
        employees:
            'Employees. This entails both full and part-time employees as well as employees working under an unlimited or fixed-term contract.',
        jobApplicants: 'Job applicants',
        paidOrUnpaidTrainees: 'Paid or unpaid trainees',
        volunteers: 'Volunteers',
        selfEmployedPersons: 'Self-employed persons',
        shareholders: 'Shareholders',
        membersOfTheManagement:
            'Members of the management, supervisory board or other governing board of',
        personsWorkingUnder:
            'Persons working under the supervision and direction of contractors, subcontractors and suppliers of',
        personsFallingUnderTheseCategories:
            'Persons falling under these categories can make use of the whistleblowing scheme regardless of whether their employment relationship with',
        hasCeased: 'has ceased, is still ongoing or has not yet begun.',
        whatCanYouReport: 'What can you report?',
        theWhistleblowerSchemeCanBeUsed:
            'The whistleblower scheme can be used to address any information about serious violations of the law, attempts to conceal such violations or other serious matters that have occurred or are likely to occur in',
        alsoInTheCaseOfRasonable:
            'Also in the case of reasonable suspicion of the occurrence of these violations or matters, this information can be reported. It is required however that reports are submitted in good faith, and that this reporting scheme is not used for unfounded accusations towards innocent people.',
        theViolationsWhich:
            'The violations which can be reported about through the whistleblower scheme relate to specific breaches of EU law or other serious offenses and matters according to Danish law. Less serious matters or ordinary employment issues, such as dissatisfaction with salary or minor collegial issues are not meant to be reported through this whistleblower scheme.',
        activitesThatCanBeReported:
            'Activities that can be reported through this scheme relate for example to corruption, protection of the environment, public health, privacy and personal data or sexual harassment and sexual abuse. These are only examples. Also other matters or violations of the law of a serious nature can be reported.',
        ifYouAreInDoubtAbout:
            'If you are in doubt about whether your observation or suspicion is of a serious nature and should be reported or not, you are encouraged to do so. All inquiries will be answered and processed.',
        protectionOfWhistleblowers: 'Protection of whistleblowers',
        anyoneWhoReportsMatters:
            'Anyone who reports matters as described above in good faith is protected from any form of retaliation. Actions of retaliation entail direct or indirect actions or omissions which occur in a work-related context as a result from the report and which cause or may cause unjustified harm to the whistleblower. Such actions can take multiple forms, such as for example dismissal, change of location of work, reduction in wages or harming the reputation of the whistleblower.',
        ifAWhistleblowerSuffers:
            'If a whistleblower suffers any form of retaliation by',
        heOrSheWillBeCompensated:
            'he or she will be compensated for this. In case of dismissal, the dismissal shall be annulled and the employment relationship shall be maintained or re-established if the whistleblowers so wishes.',
        mayAlsoBeSubjectToOtherSanctions:
            'may also be subject to other sanctions.',
        howToReport: 'How to report?',
        youCanCreateAReportThrough: 'You can create a report through',
        digitalReportingChannel:
            "'s digital reporting channel. Reports cannot be submitted in any other way.",
        whoIsProcessingTheReports: 'Who is processing the reports?',
        reportsThatLieWithin:
            'Reports that lie within the scope of this policy are processed by the chosen person or persons in the company’s agreed line of contact, which are:',
        onlyThesePersonsAreAuthorised:
            'Only these persons are authorised to conduct an investigation. However, in some cases, the investigation may be carried out with the help of external parties, such as lawyers or auditors.',
        howAreTheReportsProcessed: 'How are the reports processed?',
        youWilRecieveAnAcknowledgement:
            'You will receive an acknowledgement of receival of the report within seven days. After this, an initial examination of the report will be conducted. If the report turns out to be notably unfounded, it will be rejected. The person who reported the case will be notified of this as soon as possible. However, if the initial examination determines that the report cannot be characterized as notably unfounded, it will be further investigated. The case will then be processed internally. The whistleblower will receive feedback on any updates or outcomes as soon as possible. This internal investigation may have consequences for the person or persons who are involved. If the case is of particular serious nature, it may also be passed on to the police for further investigation. After conducting all investigations and imposing potential sanctions, the case will be closed. It will then be deleted from the system.',
        subsequentClarificationAndCollection:
            'Subsequent clarification and collection of additional documentation',
        whenYouCreateAReport:
            "When you create a report, you get the opportunity to log in to the system and see if the caseworker has asked additional questions about the case or requests additional documentation. Any subsequent dialogue depends solely on the whistleblower's willingness to log in to the system and answer the caseworker's questions.",
        confidentialityAndITSecurity: 'Confidentiality and IT security',
        aReportCanBeMadeEither:
            'A report can be made either anonymous or confidential. Whereas the former entails that no personal information is shared, the latter includes the sharing of personal information by the whistleblower. If personal information is shared in your report, only those who are authorised to investigate the case will have access thereto. The confidentiality of the submitted report will therefore in any case be respected.',
        theWhistleblowerReportingChannel:
            'The whistleblower reporting channel is operated by Walor ApS, which is an independent third party who guarantees the safety and security of the system. The system does not log IP addresses and machine-IDs and will encrypt all data processed. Only the responsible caseworker has access to the report.',
        noticeOfPersonConcerned: 'Notice of person concerned',
        theRecipientOfTheReport:
            'The recipient of the report is obliged to provide the person being reported with information about the reported event. In each individual case, an assessment will be made of when this notice can be passed on, so that the notice will have no consequences for the collection of evidence.',
        noInformationEitherDirect:
            'No information, either direct or indirect, will be specified about who made the report towards the person concerned, even if the reporter has chosen to report confidentially.',
        questions: 'Questions',
        allQuestions:
            'All questions about the whistleblower scheme can be directed to',
    },

    newCaseFlow: {
        // New Case (1)
        headerInitiateReport: 'Initiate report',
        headerDescription: 'Description',
        headerChooseCaseworker: 'Choose caseworker',
        headerSummary: 'Summary',
        initiateReport: 'Initiate report',
        howWouldYouLikeToAppear: 'How would you like to appear in the report?',
        anonymous: 'Anonymous',
        or: 'or',
        confidential: 'Confidential',
        whatIstheDifferenceBetweenAnAnonymous:
            'What is the difference between an anonymous and a confidential report?',

        // Pop-up Anonymous/Confidential
        popUpWhatIstheDifferenceBetweenAnAnonymous:
            'What is the difference between an anonymous and a confidential report?',
        popUpAnonymous: 'Anonymous',
        popUpAnonymousYouProvideYourEmployer:
            'You provide your employer with information about both the potential wrongdoing, observed incident or accident but you do NOT need to provide any of your personal information at all.',
        popUpConfidential: 'Confidential',
        popUpConfidentialYouProvideYourEmployer:
            'You provide your employer with information about both the potential wrongdoing, observed incident or accident as well as some information about yourself.',

        // New case (1) - Confidential
        confidentialInformation: 'Confidential Information',
        provideYourPersonalInformation:
            'Provide your personal information to the report',
        firstName: 'First name',
        enterFirstName: 'First name',
        lastName: 'Last name',
        enterLastName: 'Last name',
        position: 'Position',
        enterPosition: 'Position',
        workEmail: 'Work email',
        enterWorkEmail: 'Work email',
        phone: 'Phone',
        enterPhone: '12345678',

        // New case (2)
        chooseCategory: 'Choose Category',
        itCanBeDifficultToChooseTheRightCategory:
            'It can be difficult to choose the right category for your whistleblower report.',
        doNotWorry:
            'Do not worry, the category will only serve as an indicative category.',
        sexism: 'Sexism',
        moneyLaundering: 'Money laundering',
        auditorsAct: 'Auditors act',
        corruption: 'Corruption',
        conflictOfInterest: 'Conflict of interest',
        environmentalProtection: 'Environmental protection',
        gdpr: 'GDPR',
        bribery: 'Bribery',
        competitionLaw: 'Competition law',
        fraud: 'Fraud',
        foodSafetyAndQualityProblems: 'Food safety and quality problems',
        internationalTradeControl: 'International trade control',
        protectionOfPersonalData: 'Protection of personal data',
        rightsAndProtectionOfIndividuals:
            'Rights and protection of individuals',

        // New case (3)
        describeTheEvents: 'Describe the events',
        theMoreDetailedTheDescription:
            'The more detailed the description is, the easier it is to investigate the case',
        allFieldsWith: 'All fields with * are required',
        headline: 'Headline *',
        enterHeadline: 'Headline',
        description: 'Description *',
        enterDescription: 'Description',
        files: 'Files',
        uploadFiles: 'Upload files',
        maximumFileSize: 'Maximum file size: 100 MB',
        addFiles: 'Add files',
        youShouldBeAwareThatFilesCanContainMetadata:
            'You should be aware that files can contain metadata, which could potentially compromise your anonymity.',
        informationYouCould: 'Information you could include',
        whereAndWhen: 'Where and when did the event take place?',
        whatIsYourKnowledge: 'What is your knowledge of the case?',
        whatDepartmentIsInvolved: 'Which department is involved?',
        whatIsYourRelationship:
            'What is your relationship with the organization?',
        // New Case (4)
        reportSummary: 'Report summary',
        summaryCaseWorker: 'Who will recieve the report',
        summaryHowWouldYouLikeToAppear:
            'How would you like to appear in the report?',
        summaryCategory: 'Category',
        summaryHeadline: 'Headline',
        summaryDescription: 'Description',
        summaryFiles: 'Files',
        submitReport: 'Submit report',

        // New Case (5)
        theReportHasBeenReceived: 'The report has been received',
        inOrderToAccessYourCaseAgain:
            'In order to access your case again, you need to save and store the whistleblower code safely, as this is the only way to access your case again.',
        whistleblowerCode: 'Whistleblower Code',
        copyToClipboard: 'Copy to clipboard',
        stayUpdated: 'Stay updated',
        youHaveTheOppertunityToReceiveAMail:
            'You have the opportunity to receive a mail whenever you have a new message from the investigator.',
        itIsImportantToMention:
            'It is important to mention that the email will not be shared with anyone and will remain completely anonymous.',
        theWhistleblowerCodeWillNot:
            'The Whistleblower Code will NOT be sent by mail',
        email: 'Email',

        chooseContactFrame: {
            pickYourCaseworker: 'Pick your caseworker',
            pickExcludedCaseworker: 'Caseworkers that will receive the report',
            theContactWill: 'The chosen contact will receive the report',
            theContactWillNot: 'Caseworkers that will receive the report',
        },

        // Pop-Up Whistleblower Code
        popUpRememberYourWhistleblowerCode: 'Remember your Whistleblower Code',
        popUpItIsImportantThatYouSaveAndStoreTheWhistleblowerCode:
            'It is important that you save and store the whistleblower code, so you will be able to access the case in the future.',
        popUpCopyToClipboard: 'Copy to clipboard',
    },

    accessCaseFlow: {
        // Access Case (1)
        accessExistingReport: 'Access existing report',
        inOrderToAccessYourCase:
            'In order to access your case, you need the whistleblower code from when you completed the report initially. If you have lost the code, you need to create a new report.',
        enterYourWhistleblowerCode: 'Enter your Whistleblower Code',
        enterCode: 'Enter code',

        // Access Case (2)
        logout: 'Log out',
        info: 'Info',
        id: 'ID',
        date: 'Date',
        appearance: 'Appearance',
        category: 'Category',
        files: 'Files',
        description: 'Description',
        messages: 'Messages',
        caseworker: 'Caseworker',
        anonymous: 'Anonymous',
        enterMessage: 'Enter message',
        send: 'Send',
        logoutOfReport: 'Logout of report',
    },

    header: {
        dashboard: 'Dashboard',
        cases: 'Cases',
        organization: 'Organization',
        clients: 'Clients',
        statistics: 'Statistics',
        settings: 'Settings',
        notifications: 'Notifications',
        readAll: 'Read all',
    },

    dashboardPartner: {
        // Dashboard - Partner
        headlineDashboard: 'Dashboard',
        yourcases: 'Your Cases',
        id: 'ID',
        company: 'Company',
        headline: 'Headline',
        category: 'Category',
        status: 'Status',
        notifications: 'Notifications',
        all: 'All',
        unread: 'Unread',
        noCases: 'You have no cases',
        noNotifications: 'You have no notifications',

        yourStatistics: {
            yourStatistics: 'Your statistics',
            new: 'New',
            open: 'Open',
            closed: 'Closed',
            forwarded: 'Forwarded',
            totalCases: 'Total cases',
            activeCases: 'Active cases',
            closedCases: 'Closed cases',
            forwardedCases: 'Forwarded cases',
            jan: 'Jan',
            feb: 'Feb',
            mar: 'Mar',
            apr: 'Apr',
            may: 'May',
            jun: 'Jun',
            jul: 'Jul',
            aug: 'Aug',
            sep: 'Sep',
            oct: 'Oct',
            nov: 'Nov',
            dec: 'Dec',
        },
    },

    casesPartner: {
        // Cases - Partner
        headlineCases: 'Cases',
        allCases: 'All cases',
        id: 'ID',
        company: 'Company',
        headline: 'Headline',
        date: 'Date',
        category: 'Category',
        responsible: 'Responsible',
        status: 'Status',
        filter: 'Filters',
        removeFilter: 'Remove filters',
        search: 'Search',
        myCases: 'My cases',
        new: 'New',
        actionRequired: 'Action required',
        awaitingResponse: 'Awaiting response',
        seeClosedCases: 'View closed cases',
        viewForwardedCases: 'View forwarded cases',
        forwardedCases: 'Forwarded cases',
        searchCases: 'Search for cases',

        // Cases (closed) - Partner
        buttonCases: 'Cases',
        headlineClosedCases: 'Closed cases',
        closedCases: 'Closed cases',
        caseClosed: 'Case closed',
    },

    caseworkerOpenReport: {
        goBack: 'Go back',
        reportBy: 'Report by',
        anonymous: 'Anonymous',
        days: 'days',
        hours: 'hours',
        thisReportHasBeen: 'This report has been sent by',
        sevenDaysMessageOne:
            'You must provide an acknowledgment of the receipt of a report within 7 days of receiving it.',
        sevenDaysMessageTwo:
            'Send a message to the whistleblower below and comply with the law.',
        ninetyDaysMessageOne:
            'The whistleblower should receive feedback as soon as possible and no later than 3 months from the acknowledgement of the receipt of the report.',
        ninetyDaysMessageTwo:
            'For example, feedback may be the plan for or result of an internal investigation.',
        name: 'Name',
        position: 'Position',
        email: 'Email',
        phone: 'Phone',
        exportCase: 'Export',
        youAreAboutToExport: 'You are about to export the case',
        yesExportCase: 'Yes, export case',
        editInformation: 'Edit information',
        changeResponsibility: 'Change responsible',
        theNewResponsible: 'The new responsible will be notified by e-mail',
        thereAreNoOther: 'There are no other caseworkers available',
        forward: 'Forward',
        title: 'Title',
        category: 'Category',
        notCategorize: 'Uncategorize',
        uncategorized: 'Uncategorized',
        categorizeCase: 'Categorize case',
        selectCategory: 'Select a category for this case',
        selectedCategory: 'Selected category',
        thisCaseIsSet: 'This case is set to be categorized as',
        close: 'Close',
        cancel: 'Cancel',
        save: 'Save',
        saveThis: 'Save',
        frequentCategories: 'Frequent categories',
        allCategories: 'All categories',
        ViewMoreCategories: 'View more catepories',
        ViewLess: 'View less',
        status: 'Status',
        changeStatus: 'Change status',
        youAreInTheProcess: 'You are in the process of changing the status to',
        awaitingResponse: 'Awaiting response',
        actionRequired: 'Action required',
        confirm: 'Confirm',
        caseDescription: 'Description',
        files: 'Files',
        fromCaseworker: 'From caseworker',
        fromWhistleblower: 'From whistleblower',
        noFilesUploaded: 'No files uploaded',
        historyLog: 'History log',
        preferredContact: 'Preferred contact',
        messages: 'Messages',
        noMessages: 'No messages',
        takeContactAnd:
            'Reach out to the whistleblower and let them know, that you have received the report',
        caseworker: 'Caseworker',
        whistleblower: 'Whistleblower',
        enterMessage: 'Enter message',
        internalNotes: 'Internal notes',
        takeNotesHere: 'Add your notes here, so you do not forget',
        onlyTheCaseworkers: 'This is only visible to the caseworkers',
        enterNote: 'Write note',
        saveNote: 'Save',
        closeCase: 'Close case',
        solved: 'Solved',
        denied: 'Denied',
        spam: 'Spam',
        lackOfEvidence: 'Lack of evidence',
        caseWithdrawal: 'Case withdrawal',
        forwarded: 'Forwarded',
        sentToAuthorities: 'Sent to authorities',
        others: 'Others',
        addComment: 'Add comment',
        enterComment: 'Enter comment',
        forwardedAt: 'Forwarded at',
        severityScore: 'Severity score',
        comment: 'Comment',
        theCaseHasBeenForwardedTo: 'The case has been forwarded to',
        theCaseHasBeenForwardedBy: 'The case has been forwarded by',
    },

    caseClosedViewPartner: {
        // Case View (closed) - Partner
        buttonCases: 'Cases',
        closeDate: 'Close date',
        reason: 'Reason',
        comment: 'Comment',
        info: 'Info',
        id: 'ID',
        date: 'Date',
        appearance: 'Appearance',
        category: 'Category',
        responsible: 'Responsible',
        company: 'Company',
        description: 'Description',
        files: 'Files',
        messages: 'Messages',
        caseworker: 'Caseworker',
        whistleblower: 'Whistleblower',
        internalNotes: 'Internal notes',
        historyLog: 'History log',
    },

    clientsPartner: {
        // Clients - Partner
        headlineClients: 'Clients',
        buttonAddNewClient: 'Add new client',
        allClients: 'All clients',
        name: 'Name',
        country: 'Country',
        openCases: 'Open cases',
        closeCases: 'Closed cases',
        newCases: 'New cases',
    },

    singleClientViewPartner: {
        // Single Client View - Partner
        buttonClients: 'Clients',
        information: 'Information',
        lineOfContact: 'Line of contact',
        cases: 'Cases',
        invoices: 'Invoices',

        // Single Client View - Partner (1)
        companyLogo: 'Company logo',
        useAFile: 'Use a .png or .svg file',
        uploadNewLogo: 'Upload new logo',
        removeLogo: 'Remove logo',
        links: 'Links',
        whistleblowerLink: 'Whistleblower link',
        whistleblowerPolicy: 'Whistleblower Policy',
        companyInformation: 'Company information',
        buttonSave: 'Save',
        companyNumber: 'Company number',
        companyName: 'Company name',
        workEmail: 'Work email',
        phone: 'Phone',
        address: 'Address',
        city: 'City',
        zipCode: 'Zip code',
        numberOfEmployees: 'Number of employees',
        contactPerson: 'Contact person',
        fullName: 'Full name',
        position: 'Position',

        // Single Client View - Partner (2)
        name: 'Name',
        enterName: 'Name',
        enterPosition: 'Position',
        enterWorkEmail: 'Work email',
        enterPhone: '12345678',
        personOne: 'Person 1',
        personTwo: 'Person 2',
        personThree: 'Person 3',
        addLineOfContact: 'Add caseworker',
        saveOrderOfContacts: 'Save order of contacts',
        lineOfContactsHaveNot: 'Line of contacts have not been created',
        addSelfAsContact: 'Add yourself as contact',

        // Single Client View - Partner (3)
        allCases: 'All cases',
        id: 'ID',
        company: 'Company',
        headline: 'Headline',
        date: 'Date',
        category: 'Category',
        responsible: 'Responsible',
        status: 'Status',
        filter: 'Filters',
        removeFilter: 'Remove filters',
        search: 'Search',
        myCases: 'My cases',
        new: 'New',
        actionRequired: 'Action required',
        awaitingResponse: 'Awaiting response',
        seeClosedCases: 'View closed cases',

        // Single Client View - Partner (4)
        allInvoices: 'All invoices',
        description: 'Description',
        subscriptionPlan: 'Subscription plan',
        invoiceTotal: 'Invoice total',
        nextPaymentDate: 'Next payment date',
        nextInvoiceTotal: 'Next invoice total',
        invoicesAreSentTo: 'Invoice are sent to',
        buttonChangeMail: 'Change mail',

        // Pop-up Change billing contact
        popUpchangeBillingContact: 'Change billing contact',
        popUpCancel: 'Cancel',
        popUpEmail: 'Email',
    },

    addNewClientPartner: {
        // Add new client - Partner (1)
        headlineAddNewClient: 'Add new client',
        buttonCancel: 'Cancel',
        findTheCompany: 'Find the company',
        searchForCompanyNameOrCompanyNumber:
            'Search for company name or company number',
        search: 'Search',
        findCompany: 'Find company',
        enterInformationManually: 'Enter information manually',
        noLetMeChangeIt: 'Change',
        buttonContinue: 'Continue',
        companyInformation: 'Company information',
        companyNumber: 'Company number',
        enterCompanyNumber: 'Company number',
        companyName: 'Company name',
        enterCompanyName: 'Company name',
        workEmail: 'Work email',
        enterWorkEmail: 'Work email',
        phone: 'Phone',
        enterPhone: '12345678',
        address: 'Address',
        enterAddress: 'Address',
        city: 'City',
        enterCity: 'City',
        zipCode: 'Zip code',
        enterZipCode: '1234',
        numberOfEmployees: 'Number of employees',
        makeSureToChooseTheRightInterval:
            'Make sure to choose the right interval',

        // Add new client - Partner (2)
        personalInformation: 'Personal information',
        firstName: 'First name',
        enterFirstName: 'First name',
        lastName: 'Last name',
        enterLastName: 'Last name',
        password: 'Password',
        enterPassword: 'Password',
        yourPasswordMustInclude:
            'Your password must contain at least one lowercase letter, one capital letter and one number',

        // Add new client - Partner (3)
        thanksForYourSubscription: 'You have added a new client',
        informationOnHowToGetStarted:
            'Information on how the client can get started',
        linkText:
            'The link will provide you with the access to the digital whistleblower reporting channel where the reports can be created and submitted. Share this with the client.',
        employeeLink: 'Employee link',
        CopyToClipboard: 'Copy to clipboard',
        whistleblowerPolicyText:
            'Find the client’s whistleblower policy below and refer the client to login to the platform for additional material.',
        whistleblowerPolicy: 'Whistleblower policy',
        download: 'Download',
        sendLinkText: 'Send the information to your client',
        buttonSendEmail: 'Send email',

        // Pop-up Send Email
        popUpSendEmail: 'Send email',
        popUpCancel: 'Cancel',
        popUpText:
            'Your are about to send the Whistleblower reporting channel link & Whistleblower Policy to',
        popUpButtonSendInformation: 'Send information',

        // Pop-up Add new client (Cancel)
        popUpAreYouSureYouWantToCancelThisSession:
            'Are you sure, that you want to cancel this session?',
        popUpYouWillLoseAllYourProgress: 'You will lose all your progress',
        popUpButtonNo: 'No',
        popUpButtonYes: 'Yes',
    },

    settingsPartner: {
        headlineSettings: 'Settings',
        myAccount: 'My account',
        billing: 'Billing',
        organization: 'Organization',
        users: 'Users',
        invoices: 'Invoices',
        logOut: 'Log out',

        // Settings - Partner - My account
        personalInformation: 'Personal information',
        cancel: 'Cancel',
        save: 'Save',
        edit: 'Edit',
        firstName: 'First name',
        lastName: 'Last name',
        workEmail: 'Work email',
        phone: 'Phone',
        changePassword: 'Change password',
        oldPassword: 'Old password',
        newPassword: 'New password',
        confirmPassword: 'Confirm password',
        passwordUpdate: 'Done!',
        passwordUpdadetSuccessfully: 'Password updated successfully.',
        yourPasswordMustInclude:
            'Your password must contain at least one lowercase letter, one capital letter and one number',
        notifications: 'Notifications',
        defaultLanguage: 'Default language',
        newCase: 'New case',
        receiveANotificationNewCaseIsRecieved:
            'Receive a notification on your work email, whenever a new case is received',
        updateFromActiveCases: 'Update from active case',
        receiveANotificationNewMessageIsRecieved:
            'Receive a notification on your work email, whenever you receive a new message from a whistleblower',
        emailUpdated: 'Email Updated!',
        verificationLinkSent:
            'We have sent a verification link to the new email. You will be logged out in',
        seconds: 'seconds.',

        // Settings - Partner - Organization
        companyLogo: 'Company logo',
        useAFile: 'Use a .png or .svg file',
        uploadNewLogo: 'Upload new logo',
        removeLogo: 'Remove logo',
        companyInformation: 'Company information',
        companyNumber: 'Company number',
        companyName: 'Company name',
        address: 'Address',
        city: 'City',
        zipCode: 'Zip code',

        // Settings - Partner - Users
        allUsers: 'All users',
        name: 'Name',
        added: 'Added',
        openCases: 'Open cases',
        closedCases: 'Closed cases',
        buttonAddNewUser: 'Add new user',

        // Pop-up Add new user
        popUpAddingANewUser: 'Adding a new user',
        popUpCancel: 'Cancel',
        popUpFirstName: 'First name',
        popUpLastName: 'Last name',
        popUpWorkEmail: 'Work email',
        popUpPhone: 'Phone',
        popUpPosition: 'Position',
        popUpPriority: 'Priority',
        popUpText:
            'The user has to verify their account by an activation email',
        popUpButtonAddUser: 'Add user',

        addPaymentMethodFrame: {
            billing: 'Billing',
            paymentMethods: 'Billing',
            addPaymentMethod: 'Add payment method',
            defaultPaymentMethod: 'Default payment method',
            yourDefaultPaymentMethod:
                'The default payment method will be used for all future payments. If you have a subscription fee due we will attempt to charge it automatically.',
            defaultPaymentMethodMissing: 'Default payment is missing',
            otherPaymentMethods: 'Other payment methods',
            otherPaymentMethodsYouHave:
                'Other payment methods you have added. Mark the one you wish to use as default.',
            payNow: 'Pay now',
            currentPlan: 'Current plan',
            belowYouCanSee: 'Here you can see the selected subscription plan.',
            cardNumber: 'Card number',
            expireDate: 'Expire date',

            addPaymentMethodModal: {
                addPaymentMethod: 'Add payment method',
                cancel: 'Cancel',
                card: 'Card',
                invoice: 'Invoice',
                errorLoadingStripe: 'Error, loading Stripe',
                fullName: 'Full name',
                email: 'Email',
                address: 'Address',
                city: 'City',
                zipCode: 'Zip code',
                bankInformation: 'Bank Information',
                cardInformation: 'Card information',
                loginMessage:
                    'This might take a few minutes. Please, be patient.',
            },

            flashMessages: {
                youHaveSuccessfullyAdded:
                    'You have successfully added a payment method',
                youHaveDeleted: 'You have deleted a payment method',
                paymentMethodUpdated: 'You have updated your payment method',
            },
        },

        // Settings - Partner - User (Single view) AND Settings - Partner - User (Single)
        userInformation: 'User information',
        confirmationEmailSent: 'Confirmation email sent',
        resendActivationLink: 'Resend activation link',
        ButtonRemoveUser: 'Remove user',

        // Pop-up Remove user
        popUpRemoveUser: 'Remove user',
        popUpYouAreAboutToRemove: 'You are about to remove',
        popUpTransferTheCases: 'Transfer the active cases to',
        popUpButtonRemoveUser: 'Remove user',

        // Settings - Partner - Invoices
        allInvoices: 'All invoices',
        date: 'Date',
        description: 'Description',
        subscriptionPlan: 'Subscription plan',
        invoiceTotal: 'Invoice total',
    },

    clientView: {
        information: 'Information',
        lineOfContact: 'Caseworkers',
        customization: 'Customization',
        invoices: 'Invoices',

        // Client_admin View - Information
        companyLogo: 'Company logo',
        useAFile: 'Use a .png or .svg file',
        uploadNewLogo: 'Upload new logo',
        removeLogo: 'Remove logo',
        links: 'Links',
        whistleblowerLink: 'Whistleblower Link',
        whistleblowerPolicy: 'Whistleblower Policy',
        companyInformation: 'Company information',
        buttonSave: 'Save',
        companyNumber: 'Company number',
        companyName: 'Company name',
        reportingChannel: 'Reporting channel',
        copyLinkReportingChannel: 'Copy link to your reporting channel',
        downloadQRCodeImage: 'Download image here',
        visitReportLink: 'Click here to visit your company reporting link',
        downloadQRCode: 'Download reporting channel QR-code',
        copyLinkWhistleblowerPolicy: 'Copy link to your whistleblower policy',
        reportingChannelLanguage: 'Reporting channel language:',
        reportingChannelLanguageError: 'Reporting channel language error:',

        workEmail: 'Work email',
        phone: 'Phone',
        address: 'Address',
        city: 'City',
        zipCode: 'Zip code',
        numberOfEmployees: 'Number of employees',
        contactPerson: 'Contact person',
        fullName: 'Full name',
        firstName: 'First name',
        lastName: 'Last name',
        position: 'Position',

        // Client_admin View - Line of Contact
        name: 'Name',
        enterName: 'Name',
        enterPosition: 'Position',
        enterWorkEmail: 'Work email',
        enterPhone: '12345678',
        personOne: 'Person 1',
        personTwo: 'Person 2',
        personThree: 'Person 3',
        willBeDisplayedReportLink:
            'The information below will be displayed to the whistleblower in the reporting link',
        languages: 'Languages',
        notes: 'Notes',
        preview: 'Preview',
        pickCaseworker: 'Pick your caseworker',
        chosenContactWillReceive: 'The chosen contact will receive the report',

        //Client_admin View - Customization
        addQuestion: 'Add Question',
        reportQuestions: 'Report Questions',

        // Client_admin View - Invoices
        allInvoices: 'All invoices',
        date: 'Date',
        description: 'Description',
        subscriptionPlan: 'Subscription plan',
        invoiceTotal: 'Invoice total',
        nextPaymentDate: 'Next payment date',
        nextInvoiceTotal: 'Next invoice total',
        invoicesAreSentTo: 'Invoice are sent to',
        buttonChangeMail: 'Change mail',

        // Pop-up Change billing contact
        // Use already existing pop-up material!!!
    },
    verifyEmail: {
        verifyEmail: 'Verify email',
        password: 'Password',
        confirmPassword: 'Confirm password',
        yourPasswordMust:
            'Your password must be at least 8 characters. It must also include 1 lowercase letter, 1 uppercase letter, 1 number and 1 symbol',
        emailVerified: 'Email verified',
        yourEmailHasBeen:
            'Your email has been verified, you can now go to the login page and login with your email and newly created password',
        goToLogin: 'Go to login',
        verifyNewEmail: 'Verified!',
        loginWithNewEmail: 'You can now login with the new email.',
    },
    // reusable components
    nextButton: {
        nextStep: 'Next',
    },
    finishButton: {
        finish: 'Finish',
    },
    closeButton: {
        close: 'Close',
    },
    submitButton: {
        submit: 'Submit',
    },
    loginButton: {
        login: 'Login',
    },
    sendButton: {
        send: 'Send',
    },
    saveButton: {
        save: 'Save',
    },
    dropdown: {
        countryNotFound: 'Country not found',
        search: 'Search',
    },
    copiedText: {
        copied: 'Copied',
    },
    you: {
        you: 'You',
    },
    inactiveModal: {
        inactivityWarning: 'Inactivity warning',
        youWillBeLoggedOut: 'You will be logged out',
        youHaveBeenInactive:
            'You have been inactive for 14 minutes. When the timer runs out you will be logged out',
        stayLoggedIn: 'Stay logged in',
    },
    notifications: {
        newMessage: 'New message',
        newFile: 'New file',
        deadlineAlert: 'Deadline Alert',
        newReport: 'New report',
        reportAssigned: 'Report assigned',
        reportUnassigned: 'Report unassigned',
        statusUpdated: 'Status updated',
        categoryChanged: 'Category changed',
        reportForwarded: 'Report forwarded',
        reportClosed: 'Report closed',
        internalNoteAdded: 'Internal note added',
        sentAMessageInYourReport: 'sent a message in your report',
        updatedTheStatusOfYourReport: 'updated the status of your report',
        changedTheCategoryOfYourReport: 'changed the category of your report',
        closedYourReport: 'closed your report',
    },
    historyLog: {
        forwarded: 'Forwarded',
        exported: 'Exported',
        changeResponsible: 'Change responsible',
        internalNote: 'Internal note',
        caseViewed: 'Case viewed',
        categoryChange: 'Category changed',
        fileUploaded: 'File uploaded',
        newMessage: 'New message',
        statusUpdated: 'Status updated',
        caseDelegation: 'Case delegation',
        reportCreated: 'Report created',
        to: 'to',
        by: 'by',
        from: 'from',
        caseHasBeenForwarded: 'Case has been forwarded',
        caseHasBeenExported: 'Case has been exported',
        caseHasBeenReassigned: 'Case has been reassigned',
        internalNoteHasBeen: 'Internal note has been written',
        caseHasBeenViewed: 'Case has been viewed',
        categoryIsChanged: 'Category is changed',
        messageFrom: 'Message from',
        caseAssigned: 'Case assigned',
        newCase: 'New case',
    },

    // Validation
    validation: {
        nameOfTheCityMustBeAtLeast:
            'Name of the city must be at least 1 character',
        nameOfTheCityCannot:
            'Name of the city cannot be longer then 85 characters',
        companyIdentificationNumberMust:
            'Number must be exactly 8 characters long',
        addressIsToShort: 'Address is to short',
        addressIsToLong: 'Address is to long',
        organizationIsToShort: 'Organization is to short',
        organizationIsToLong: 'Organization is to long',
        mustBeAValidEmail: 'Must be a valid email address',
        firstNameCannotBeShorter:
            'First name cannot be short then 2 characters',
        firstNameCannotBeLonger:
            'First name cannot be longer then 26 characters',
        lastNameCannotBeShorter: 'Last name cannot be short then 2 characters',
        lastNameCannotBeLonger: 'Last name cannot be longer then 26 characters',
        passwordMustBeAtLeast: 'Password must be at least 8 characters',
        yourPasswordMustContainerAtLeastOneUppercase:
            'Your password must contain at least one uppercase letter',
        yourPasswordMustContainerAtLeastOneLowercase:
            'Your password must contain at least one lowercase letter',
        yourPasswordMustContainerAtLeastOneSymbol:
            'Your password must contain at least one symbol letter',
        yourPasswordMustContainerAtLeastOneNumber:
            'Your password must contain at least one number letter',
        passwordsMustMatch: 'Passwords must match',
        notAValidPhoneNumber: 'Not a valid phone number',
        titleOfPositionMustBe:
            'Title of position must be longer then 2 characters',
        titleOfPositionCannotBe:
            'Title of position cannot be longer then 26 characters',
        mustSetAPriority: 'You must set a priority',
        notAValidZipCode: 'Not a valid zip code',
        isLongEnough: 'At least 8 characters long',
        hasUpperCaseLetter: 'At least 1 upper case letter [A-Z]',
        hasLowerCaseLetter: 'At least 1 lower case letter [a-z]',
        hasNumber: 'At least 1 number [0-9]',
        hasSpecialCharacter: 'At least 1 special character[!@#$%^&*()\\-__+.]',
        passwordsMatch: 'Both passwords match',
    },

    // Forward Report
    forwardReportPage: {
        selectCatagory: 'Select category',
        category: 'Category',
        uncategorized: 'Uncategorized',
        accusationAndEvidenceScore: 'Accusation and evidence score',
        accusationScore: 'Accusation score',
        evidenceScore: 'Evidence score',
        severityScore: 'Total severity score',
        lineOfContact: 'Line of contact',
        lineOfContactReminder:
            'Please make sure that the person is by no means implicated in this case',
        comment: 'Comment',
        pleaseLeaveComment: 'Please leave a comment here',
        forwardCase: 'Forward case',
        popUpConfirmForwardPartOne: 'Are you sure that you want to forward',
        popUpConfirmForwardPartTwo: 'to',
        popUpNo: 'No',
        popUpYes: "Yes, i'm sure",
        minimumAbbreviated: 'Min',
        maximumAbbreviated: 'Max',
        saveThis: 'Save this',
    },

    // global
    trialWarning: {
        goToSettings: 'Go to settings',
        yourTrialHasEnded: 'Your trial has ended.',
        yourFreeTrialEndedOn: 'Your free trial ended on:',
        yourFreeTrialEndsOn: 'Trial expires in',
        day: 'day',
        days: 'days',
    },
    categories: {
        accounting_irregularities: 'Accounting irregularities',
        bribery: 'Bribery',
        consumer_protection: 'Consumer protection',
        duty_of_confidentiality: 'Duty of confidentiality',
        embezzlement: 'Embezzlement',
        extortion: 'Extortion',
        financial_services_products_and_markets:
            'Financial services, products and markets',
        food_safety_animal_health_and_welfare:
            'Food safety, animal health and welfare',
        forgery: 'Forgery',
        fraudulence: 'Fraudulence',
        money_laundering_and_terrorist_financing:
            'Money laundering and terrorist financing',
        occupational_health_and_safety: 'Occupational health and safety',
        products_safety_and_compliance: 'Products safety and compliance',
        protection_of_environment: 'Protection of environment',
        protection_of_privacy_and_personal_data_and_security_of_network_and_information_systems:
            'Protection of privacy and personal data and security of network and information systems',
        public_health: 'Public health',
        public_procurement: 'Public procurement',
        radiation_protection_and_nuclear_safety:
            'Radiation protection and nuclear safety',
        serious_or_recurring_conflicts_at_the_workplace:
            'Serious or recurring conflicts at the workplace',
        sexual_harassment_and_sexual_assault:
            'Sexual harassment and sexual assault',
        the_financial_interests_of_the_european_union:
            'The financial interests of the European Union',
        the_internal_market_of_the_european_union_including_state_aid_and_corporate_taxation:
            'The internal market of the European Union, including state aid and corporate taxation',
        theft: 'Theft',
        conflict_of_interest: 'Conflict of interest',
        transport_safety: 'Transport safety',
    },
}

export default en
