import { useEffect, useState } from 'react'
import { useTypedSelector } from '../../../../hooks'
import EnterSecurityCode from './frames/EnterSecurityCode'
import ForgotPassword from './frames/ForgotPassword'
import LoginForm from './frames/LoginForm'
import SelectAuthMethod from './frames/SelectAuthMethod'

export interface LoginInfo {
    email: string
    password: string
    verification_method: string
    phone: string
    otp: string
}

const LoginPage: React.FC = () => {
    const [currentPageNumber, setCurrentPageNumber] = useState(1)

    const { loginStage } = useTypedSelector((state) => state.auth)

    const [loginInfo, setLoginInfo] = useState<LoginInfo>({
        email: '',
        password: '',
        verification_method: '',
        phone: '',
        otp: '',
    })

    const updateLoginInfo = (key: string, value: string) => {
        setLoginInfo({ ...loginInfo, [key]: value })
    }

    useEffect(() => {
        if (loginStage === 'verify-credentials') {
            setCurrentPageNumber(1)
        } else if (loginStage === 'send-2fa-token') {
            setCurrentPageNumber(3)
        } else if (loginStage === 'verify-2fa-token') {
            setCurrentPageNumber(4)
        } else if (loginStage === 'done') {
        }
    }, [loginStage])

    const [currentPage, setCurrentPage] = useState(
        <LoginForm
            setCurrentPageNumber={setCurrentPageNumber}
            loginInfo={loginInfo}
            updateLoginInfo={updateLoginInfo}
        />
    )

    useEffect(() => {
        if (currentPageNumber === 1) {
            setCurrentPage(
                <LoginForm
                    setCurrentPageNumber={setCurrentPageNumber}
                    loginInfo={loginInfo}
                    updateLoginInfo={updateLoginInfo}
                />
            )
        } else if (currentPageNumber === 2) {
            setCurrentPage(
                <ForgotPassword setCurrentPageNumber={setCurrentPageNumber} />
            )
        } else if (currentPageNumber === 3) {
            setCurrentPage(
                <SelectAuthMethod
                    setCurrentPageNumber={setCurrentPageNumber}
                    loginInfo={loginInfo}
                    updateLoginInfo={updateLoginInfo}
                />
            )
        } else if (currentPageNumber === 4) {
            setCurrentPage(
                <EnterSecurityCode
                    loginInfo={loginInfo}
                    updateLoginInfo={updateLoginInfo}
                />
            )
        }
        // eslint-disable-next-line
    }, [currentPageNumber, loginInfo])

    return <>{currentPage}</>
}

export default LoginPage
