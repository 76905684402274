import { CustomDesignInterface } from '../../ts/interfaces/CustomizeDesignInterface'
import logo from '../../assets/images/logo-white-blue-bg.svg'
import { CustomDesignActionType } from '../action-types'
import { CustomDesignActions } from '../actions/customDesignActions'
import produce from 'immer'

interface CustomDesignState {
    customDesignLoading: boolean
    customDesign: CustomDesignInterface
}

export const InitialState: CustomDesignState = {
    customDesignLoading: false,
    customDesign: {
        color_hex_code: '#222330',
        logo: logo,
    },
}

const reducer = produce(
    (state: CustomDesignState = InitialState, action: CustomDesignActions) => {
        switch (action.type) {
            case CustomDesignActionType.POST_CUSTOM_DESIGN:
                state.customDesignLoading = false
                state.customDesign = action.payload

                return state
            case CustomDesignActionType.RESTORE_CUSTOM_DESIGN: 
                return { ...InitialState}
            case CustomDesignActionType.SET_CUSTOM_DESIGN_LOADING:
                state.customDesignLoading = true

                return state
            default:
                return state
        }
    }
)

export default reducer
