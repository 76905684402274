import styled from 'styled-components'

export const NewCasePageFrameHeading = styled.h3`
    font-size: 3.2rem;
    color: ${({ theme }) => theme.primary};
    margin-bottom: 3.4rem;

    @media screen and (max-width: 600px) {
        font-size: 2.5rem;
        margin-block-end: 2rem;
    }
`

export const NewCasePageFrameTagline = styled.h3`
    font-size: 1.6rem;
    color: ${({ theme }) => theme.black};
    margin-bottom: 5rem;
    opacity: 0.8;

    @media screen and (max-width: 600px) {
        max-width: 90%;
    }
`

export const TaglineLight = styled.p`
    font-size: 1.4rem;
    color: ${({ theme }) => theme.black};
    opacity: 0.5;
    line-height: 2rem;
`

export const BoldText = styled.p`
    font-size: 1.4rem;
    font-weight: 600;
`
