import { useState } from 'react'
import styled from 'styled-components'
import { DynamicHeightSaveButton } from '../../../../../styled/buttons'
import { useTranslation } from '../../../../../../hooks'

const Container = styled.div`
    padding: 0 6.2rem;
    margin-top: 1.4rem;
`

const Textarea = styled.textarea`
    height: 23.3rem;
    width: 100%;
    border: 1px solid ${({ theme }) => theme.greyText};
    border-radius: 0.5rem;
    padding: 1rem;
    resize: none;
`

const SaveButton = styled(DynamicHeightSaveButton)`
    margin-top: 1rem;
    margin-left: auto;
`

interface WriteACommentProps {
    setComment: (text: string) => void
}

const WriteAComment: React.FC<WriteACommentProps> = ({ setComment }) => {
    const [text, setText] = useState('')
    const translation = useTranslation()

    const handleSave = () => {
        if (text.length > 0) {
            setComment(text)
        }
    }

    return (
        <Container>
            <Textarea
                placeholder={translation.forwardReportPage.pleaseLeaveComment}
                onChange={(e) => setText(e.target.value)}
            ></Textarea>
            <SaveButton
                onClick={handleSave}
                height="3rem"
                width="7.7rem"
                fontSize="1.4rem"
                isActive={text.length !== 0}
            >
                {translation.forwardReportPage.saveThis}
            </SaveButton>
        </Container>
    )
}

export default WriteAComment
