import React from 'react'
import styled from 'styled-components'
import AccountType from '../../../../../reusable/AccountType'
import {
    AccountTypeType,
    IntegrationPartnerInterface,
} from '../../NewestRegisterPage'
import { NextButton } from '../../../../../reusable/buttons'
import { useTranslation, useTypedSelector } from '../../../../../../hooks'

const Container = styled.div`
    padding-top: 7.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const Heading = styled.h3`
    font-size: 3.2rem;
    font-weight: 600;
    padding-top: 3rem;
    color: ${({ theme }) => theme.primary};
`

const Tagline = styled.p`
    font-size: 1.6rem;
    padding-top: 1rem;
    color: ${({ theme }) => `${theme.primary}90`};
`

const TaglineSpan = styled.span`
    color: ${({ theme }) => theme.blue};
    font-weight: 600;
`

const AccountTypeFlex = styled.div`
    display: flex;
    gap: 2rem;
    padding-top: 3rem;
    padding-bottom: 2rem;
`
interface PartnerProps {
    isSelected: boolean
}

const PartnerAccountType = styled.p<PartnerProps>`
    cursor: pointer;
    font-size: 1.2rem;
    white-space: nowrap;
    font-weight: 400;
    line-height: 1.85rem;
    padding: 2rem 22rem;
    border: ${({ isSelected }) =>
        isSelected ? `1px solid black` : `1px solid #e9e9e9`};
    border-radius: 1.66rem;
    color: ${({ theme }) => theme.primary};
    background-color: ${({ theme, isSelected }) =>
        isSelected ? '#E1E9EE' : theme.white};
`

const Agreement = styled.div`
    font-size: 1.2rem;
    line-height: 2.8rem;
    font-weight: 400;
    color: ${({ theme }) => theme.black};
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 3.2rem;
`

const Label = styled.label`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

const Radio = styled.input`
    width: 1.6rem;
    height: 1.6rem;
    margin-right: 0.5rem;
    cursor: pointer;
    &:checked &::after {
        content: '\f00c';
        color: ${({ theme }) => theme.blue};
        font-family: 'FontAwesome';
    }
`

const StyledLink = styled.a`
    color: ${({ theme }) => theme.blue};
`

const NextButtonContainer = styled.div`
    padding-top: 5rem;
    padding-bottom: 2rem;
    display: flex;
    justify-content: center;
`

interface AccountTypeFrameProps {
    selectedAccountType: null | AccountTypeType
    setSelectedAccountType: (type: null | AccountTypeType) => void
    handleRegisterUser: () => void
    termsAndConditionsChecked: boolean
    setTermsAndConditionsChecked: () => void
    dataProcessingChecked: boolean
    setDataProcessingChecked: (checked: boolean) => void
    setIsPartnerModalOpen: (status: boolean) => void
    integrationPartner: null | IntegrationPartnerInterface
}

const AccountTypeFrame: React.FC<AccountTypeFrameProps> = ({
    selectedAccountType,
    setSelectedAccountType,
    handleRegisterUser,
    termsAndConditionsChecked,
    setTermsAndConditionsChecked,
    dataProcessingChecked,
    setDataProcessingChecked,
    setIsPartnerModalOpen,
    integrationPartner,
}) => {
    const translation = useTranslation()

    const handleSelectAccountType = (accountType: AccountTypeType) =>
        accountType === selectedAccountType
            ? setSelectedAccountType(null)
            : setSelectedAccountType(accountType)

    const { authLoading } = useTypedSelector((state) => state.auth)

    return (
        <Container>
            <Heading>{translation.registerPage.accountType}</Heading>
            <Tagline>
                {translation.registerPage.noCreditCardRequired}{' '}
                <TaglineSpan>{translation.registerPage.freeTrial}</TaglineSpan>{' '}
                {translation.registerPage.today}
            </Tagline>
            <AccountTypeFlex>
                <AccountType
                    accountType="software"
                    isActive={selectedAccountType === 'software'}
                    selectedAccountType={selectedAccountType}
                    setSelectedAccountType={() =>
                        handleSelectAccountType('software')
                    }
                />

                {/*<AccountType
                    accountType="software_plus_screening"
                    isActive={selectedAccountType === 'software_plus_screening'}
                    selectedAccountType={selectedAccountType}
                    setSelectedAccountType={() =>
                        handleSelectAccountType('software_plus_screening')
                    }
                /> */}
            </AccountTypeFlex>
            {!integrationPartner && (
                <div>
                    <PartnerAccountType
                        isSelected={selectedAccountType === 'partner'}
                        onClick={() => setIsPartnerModalOpen(true)}
                    >
                        {translation.registerPage.areYouLookingFo}
                    </PartnerAccountType>
                </div>
            )}

            <Agreement>
                <Label>
                    <Radio
                        type="checkbox"
                        name="terms-and-conditions"
                        value={'terms-and-conditions'}
                        onChange={setTermsAndConditionsChecked}
                        checked={termsAndConditionsChecked}
                    />
                    <p>
                        {translation.registerPage.iHaveAgreedToWalor}{' '}
                        <StyledLink
                            target="_blank"
                            href="https://www.walor.io/terms-conditions"
                        >
                            {translation.registerPage.termsAndConditions}
                        </StyledLink>{' '}
                        {translation.registerPage.and}{' '}
                        <StyledLink
                            target="_blank"
                            href="https://www.walor.io/privacy-and-cookie-policy"
                        >
                            {translation.registerPage.privacyAndCookiePolicy}
                        </StyledLink>
                    </p>
                </Label>
                <Label>
                    <Radio
                        type="checkbox"
                        name="data-processing-agreement"
                        value={'data-processing-agreement'}
                        onChange={() => setDataProcessingChecked(true)}
                        checked={dataProcessingChecked}
                    />
                    <p>
                        {translation.registerPage.iHaveAgreedToWalor}{' '}
                        <StyledLink
                            target="_blank"
                            href="https://www.walor.io/data-processing-agreement"
                        >
                            {translation.registerPage.dataProcessingAgreement}
                        </StyledLink>
                    </p>
                </Label>
            </Agreement>
            <NextButtonContainer>
                <NextButton
                    isLoading={authLoading}
                    isActive={
                        selectedAccountType !== null &&
                        termsAndConditionsChecked &&
                        dataProcessingChecked
                    }
                    handleOnClick={handleRegisterUser}
                />
            </NextButtonContainer>
        </Container>
    )
}

export default AccountTypeFrame
