import styled from 'styled-components'
import { useTranslation, useTypedSelector } from '../../../../../hooks'
import CountryCodeDropdown from '../../../../reusable/form/CountryCodeDropdown'
import { Card, CardHeader } from '../../../../styled/cards'
import { FormField, FormInput40, PrimaryLabel } from '../../../../styled/formElements'

const FormContainer = styled.div`
    display: flex;
    gap: 2rem;
    padding: 4rem;
    width: 69rem;
    margin-left: 1.4rem;
`

const LeftContainer = styled.div`
    width: 50%;
`

const RightContainer = styled.div`
    width: 50%;
`

const FlexInputContainer = styled.div`
    display: flex;
    gap: 16px;
`

const PhoneInputContainer = styled.div`
    width: 85%;
`

const CityInputContianer = styled.div`
    width: 60%;
`

const ZipInputContianer = styled.div`
    width: 40%;
`

const Organization: React.FC = () => {
    const { organization } = useTypedSelector((state) => state.caseworker)

    const translation = useTranslation()

    return (
        <>
            <Card>
                <CardHeader>
                    {translation.settingsPartner.companyInformation}
                </CardHeader>
                <FormContainer>
                    <LeftContainer>
                        <FormField>
                            <PrimaryLabel>
                                {translation.settingsPartner.companyNumber}
                            </PrimaryLabel>
                            <FormInput40 value={organization?.vat} disabled />
                        </FormField>
                        <FormField>
                            <PrimaryLabel>
                                {translation.settingsPartner.workEmail}
                            </PrimaryLabel>
                            <FormInput40 value={organization?.email} disabled />
                        </FormField>
                        <FormField>
                            <PrimaryLabel>
                                {translation.settingsPartner.phone}
                            </PrimaryLabel>
                            <FlexInputContainer>
                                <CountryCodeDropdown
                                    error={false}
                                    success={false}
                                    selectDropdownCB={() => {}}
                                    disabled
                                    /* TODO add country code when u have it*/
                                />
                                <PhoneInputContainer>
                                    <FormInput40
                                        value={organization?.phone_number}
                                        disabled
                                    />
                                </PhoneInputContainer>
                            </FlexInputContainer>
                        </FormField>
                    </LeftContainer>
                    <RightContainer>
                        <FormField>
                            <PrimaryLabel>
                                {translation.settingsPartner.address}
                            </PrimaryLabel>
                            <FormInput40
                                value={organization?.address}
                                disabled
                            />
                        </FormField>
                        <FormField>
                            <PrimaryLabel>
                                {translation.settingsPartner.companyName}
                            </PrimaryLabel>
                            <FormInput40 value={organization?.name} disabled />
                        </FormField>
                        <FlexInputContainer>
                            <CityInputContianer>
                                <PrimaryLabel>
                                    {translation.settingsPartner.city}
                                </PrimaryLabel>
                                <FormInput40
                                    disabled
                                    value={organization?.city}
                                />
                            </CityInputContianer>
                            <ZipInputContianer>
                                <PrimaryLabel>
                                    {translation.settingsPartner.zipCode}
                                </PrimaryLabel>
                                <FormInput40
                                    value={organization?.zip_code}
                                    disabled
                                />
                            </ZipInputContianer>
                        </FlexInputContainer>
                    </RightContainer>
                </FormContainer>
            </Card>
        </>
    )
}

export default Organization
