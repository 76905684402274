import styled from 'styled-components'
import {
    useActions,
    useTranslation,
    useTypedSelector,
} from '../../../../../hooks'
import CountryCodeDropdown from '../../../../reusable/form/CountryCodeDropdown'

import { Card, CardHeader } from '../../../../styled/cards'
import {
    ErrorMessage,
    FormField,
    FormInput40,
    PrimaryLabel,
} from '../../../../styled/formElements'
import { useEffect, useRef, useState } from 'react'
import {
    CheckIcon,
    EditButton,
    EditButtonText,
    EditIcon,
    SaveButton,
    SaveButtonText,
} from '../../../../styled/buttons'

import {
    checkFirstName,
    checkLastName,
    checkEmail,
    checkPhone,
    isValidName,
    isValidEmail,
    isValidPhone,
} from '../../../../../helpers/validation'
import { UpdateUserIdentityInterface } from '../../../../../ts/interfaces/UserIdentityInterface'
import ButtonSpinnerWhite from '../../../../layout/spinner/ButtonSpinnerWhite'
import ChangePassword from './ChangePassword'
import UpdatedSettingsModal from './components/UpdatedSettingsModal'
import { ReactComponent as CloseIcon } from '../../../../../assets/images/x-icon.svg'
import { ReactComponent as checkIcon } from '../../../../../assets/images/check-icon-circle.svg'
import { CountryInterface } from '../../../../../state/reducers/countriesReducer'
import DefaultLanguage from './components/DefaultLanguage'

interface IsActiveProp {
    isActive: boolean
}

const TempCardContainer = styled.div`
    margin-bottom: 2rem;
`

const FormContainer = styled.div`
    display: flex;
    gap: 2rem;
    padding: 4rem 4rem 2rem 4rem;
    flex-direction: column;
`

const LeftContainer = styled.div`
    width: 100%;
    gap: 2rem;
    display: flex;

    @media only screen and (max-width: 69rem) {
        display: inline-block;
    }
`

const RightContainer = styled.div`
    width: 100%;
    gap: 2rem;
    display: flex;

    @media only screen and (max-width: 69rem) {
        display: inline-block;
    }
`

const FlexInputContainer = styled.div`
    display: flex;
    gap: 16px;
`

const PhoneInputContainer = styled.div`
    width: 85%;
`

const ErrorBlock = styled.div`
    padding-top: 0.5rem;
`

// const ToggleLabel = styled.label`
//     cursor: pointer;
//     display: inline-block;
// `

// const ToggleInput = styled.input`
//     position: absolute;
//     visibility: hidden;

//     :checked + .switch {
//         background: ${({ theme }) => theme.blue};
//         :before {
//             left: 30px;
//         }
//     }
// `

// const ToggleSwitch = styled.div`
//     display: inline-block;
//     background: #ccc;
//     border-radius: 16px;
//     width: 58px;
//     height: 26px;
//     position: relative;
//     vertical-align: middle;
//     transition: background 0.25s;
//     :before,
//     :after {
//         content: "";
//     }
//     :before {
//         display: block;
//         background: linear-gradient(to bottom, #fff 0%,#eee 100%);
//         border-radius: 50%;
//         box-shadow: 0 0 0 1px rgba(0,0,0,0.25);
//         height: 22px;
//         width: 22px;
//         position: absolute;
//         top: 2px;
//         left: 2px;
//         transition: left 0.25s;
// `

// const ChangeSettingContainer = styled.div`
//     padding: 0 1.4rem;
//     display: flex;
//     gap: 1rem;
//     margin-bottom: 1rem;

//     align-items: flex-start;

//     margin-top: 2rem;
// `

// const ChangeSettingHeading = styled.h4`
//     font-size: 1.6rem;
//     font-weight: 600;
//     color: ${({ theme }) => theme.primary};
// `

// const ChangeSettingTagline = styled.p`
//     font-size: 1.4rem;
//     color: ${({ theme }) => `${theme.primary}50`};
//     margin-top: 0.5rem;
// `

// const SaveButton = styled.div<IsActiveProp>`
//     height: 3.5rem;
//     margin-left: 1.4rem;
//     margin-top: 4rem;
//     width: 8.8rem;
//     border-radius: 0.5rem;
//     background: ${({ theme, isActive }) =>
//         isActive ? theme.blue : `${theme.primary}05`};
//     color: ${({ theme, isActive }) =>
//         isActive ? theme.white : `${theme.primary}50`};

//     display: flex;
//     align-items: center;
//     justify-content: center;
//     font-size: 1.6rem;
// `

export const UpdateStettings = styled.div`
    display: flex;
    align-items: center;
    gap: 2rem;
`

const UpdateIdentity = styled(SaveButton)<IsActiveProp>`
    background: ${({ theme, isActive }) =>
        isActive ? theme.blue : `${theme.primary}10`};
    && .save-button-text,
    .check-icon {
        color: ${({ theme, isActive }) =>
            isActive ? theme.white : `${theme.primary}40`};
    }

    &:hover {
        opacity: 0.9;
    }
`

export const CancelIcon = styled(CloseIcon)`
    width: 0.8rem;
`

export const CancelButton = styled.button`
    color: ${({ theme }) => theme.red};
    background-color: ${({ theme }) => `${theme.red}20`};
    font-size: 1.2rem;
    cursor: pointer;
    border: none;
    height: 2.9rem;
    width: 12rem;
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    &:hover {
        background-color: ${({ theme }) => `${theme.red}30`};
    }
`

export const Edit = styled(EditButton)`
    &:hover {
        background-color: ${({ theme }) => `${theme.yellow}20`};
    }
`

const BaseMessageComponent = styled.div`
    width: 27.8rem;
    height: 4rem;
    padding: 1.4rem;
    font-size: 1.2rem;
    border-radius: 0.2rem;
    margin-inline: 1.4rem;
    margin-block-end: 1.4rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
`

export const SuccessMessage = styled(BaseMessageComponent)`
    background-color: ${({ theme }) => `${theme.blue}50`};
    color: ${({ theme }) => theme.blue};
`

export const UpdateIdentityErrorMessage = styled(BaseMessageComponent)`
    background-color: ${({ theme }) => `${theme.red}50`};
    color: ${({ theme }) => theme.red};
`

export const CheckSavedMessageIcon = styled(checkIcon)`
    width: 1.3rem;

    && .check-icon-circle {
        stroke: ${({ theme }) => theme.blue};
    }
`

export interface User {
    userId: number
    firstName: string
    lastName: string
    email: string
    activeCases: number
    phone: string
    country: CountryInterface
}

const MyAccount: React.FC = () => {
    const { user } = useTypedSelector((state) => state.caseworker)
    const { identityLoading, saveChangesMessage, identityErrors } =
        useTypedSelector((state) => state.identity)
    const { updateIdentity, logout, clearUpdateIdentityError, getIdentity } =
        useActions()
    const { countries } = useTypedSelector((state) => state.country)
    const translation = useTranslation()

    const [isEditing, setIsEditing] = useState(false)

    const [isFormDirty, setIsFormDirty] = useState(false)

    const [firstNameError, setFirstNameError] = useState('')
    const [lastNameError, setLastNameError] = useState('')
    const [emailError, setEmailError] = useState('')
    const [phoneError, setPhoneError] = useState('')

    const [firstNameSuccess, setFirstNameSuccess] = useState(false)
    const [lastNameSuccess, setLastNameSuccess] = useState(false)
    const [emailSuccess, setEmailSuccess] = useState(false)
    const [phoneSuccess, setPhoneSuccess] = useState(false)

    const [showModal, setShowModal] = useState(false)
    const [countdown, setCountDown] = useState(8)

    const [formUser, setFormUser] = useState<null | User>(null)
    const oldUser = useRef()

    useEffect(() => {
        getIdentity()
        //eslint-disable-next-line
    }, [])

    const firstNameValidation = () => {
        if (!formUser) {
            return
        }
        setFirstNameError(checkFirstName(formUser.firstName, translation))
        setFirstNameSuccess(firstNameError.length === 0)
    }

    const lastNameValidation = () => {
        if (!formUser) {
            return
        }
        setLastNameError(checkLastName(formUser.lastName, translation))
        setLastNameSuccess(lastNameError.length === 0)
    }

    const emailValidation = () => {
        if (!formUser) {
            return
        }
        setEmailError(checkEmail(formUser.email, translation))
        setEmailSuccess(emailError.length === 0)
    }

    const phoneValidation = () => {
        if (!formUser) {
            return
        }
        setPhoneError(
            checkPhone(
                `${formUser.country.calling_code + formUser.phone}`,
                translation
            )
        )
        setPhoneSuccess(phoneError.length === 0)
    }

    const firstNameOnFocus = () => {
        setFirstNameError('')
        setFirstNameSuccess(false)
    }

    const lastNameOnFocus = () => {
        setLastNameError('')
        setLastNameSuccess(false)
    }

    const emailOnFocus = () => {
        setEmailError('')
        setEmailSuccess(false)
    }

    const phoneOnFocus = () => {
        setPhoneError('')
        setPhoneSuccess(false)
    }

    const isFormValidated = () => {
        if (!formUser) {
            return false
        }
        const result =
            isValidName(formUser.firstName) &&
            isValidName(formUser.lastName) &&
            isValidEmail(formUser.email) &&
            isValidPhone(`${formUser.country.calling_code + formUser.phone}`)
        return result
    }

    useEffect(() => {
        if (!user || !countries) {
            return
        }

        const newUser = {
            ...user,
            phone: user.phone.phone,
            country: countries?.find(
                (country) => country.calling_code === user.phone.country_code
            ),
        }

        setFormUser(newUser)

        oldUser.current = newUser
    }, [user, countries])

    const handleInputChanges = (e: any) => {
        if (!formUser) {
            return
        }
        const { name, value } = e.target

        setFormUser({ ...formUser, [name]: value })

        setIsFormDirty(true)
    }

    const handleCountryCodeChange = (country: CountryInterface) => {
        if (!formUser) {
            return
        }

        setFormUser({ ...formUser, country })
    }

    const handleCancel = () => {
        if (typeof oldUser.current === 'undefined') {
            return
        }
        setIsEditing(false)

        setFormUser(oldUser.current)
        setFirstNameSuccess(false)
        setLastNameSuccess(false)
        setEmailSuccess(false)
        setPhoneSuccess(false)
        setFirstNameError('')
        setLastNameError('')
        setEmailError('')
        setPhoneError('')
        setIsFormDirty(false)
        clearUpdateIdentityError()
    }

    const handleSave = async () => {
        if (!formUser) {
            return
        }
        if (!isFormValidated() || !isFormDirty) {
            return
        } else {
            const userIdentity: UpdateUserIdentityInterface = {
                first_name: formUser.firstName,
                last_name: formUser.lastName,
                email: formUser.email,
                phone: formUser.phone,
                country_id: formUser.country.id,
            }

            const res = await updateIdentity(user.userId, userIdentity)

            //@ts-ignore
            if (res.success) {
                // @ts-ignore
                if (res.shouldLogout) {
                    setShowModal(true)
                    setCountDown(8)
                }
                setIsEditing(false)
                setFirstNameSuccess(false)
                setLastNameSuccess(false)
                setEmailSuccess(false)
                setPhoneSuccess(false)
            } else {
                return
            }
        }
        setIsFormDirty(false)
    }

    useEffect(() => {
        if (showModal) {
            let timer: NodeJS.Timeout | undefined = undefined
            if (countdown > 0) {
                timer = setInterval(() => {
                    setCountDown(countdown - 1)
                }, 1000)
            } else {
                clearInterval(timer)
                logout()
                setShowModal(false)
            }
            return () => clearInterval(timer)
        }
        // eslint-disable-next-line
    }, [showModal, countdown])

    return (
        <>
            {showModal && (
                <UpdatedSettingsModal
                    title={translation.settingsPartner.emailUpdated}
                    tagline={`${translation.settingsPartner.verificationLinkSent} ${countdown} ${translation.settingsPartner.seconds}`}
                />
            )}
            <div style={{ display: 'flex', gap: '2rem' }}>
                <TempCardContainer>
                    <Card overflow="visible">
                        <CardHeader>
                            {translation.settingsPartner.personalInformation}
                            {isEditing ? (
                                <UpdateStettings>
                                    <>
                                        <CancelButton onClick={handleCancel}>
                                            <CancelIcon />
                                            {translation.settingsPartner.cancel}
                                        </CancelButton>
                                    </>
                                    <UpdateIdentity
                                        isActive={
                                            isFormValidated() && isFormDirty
                                        }
                                        onClick={handleSave}
                                    >
                                        {identityLoading ? (
                                            <ButtonSpinnerWhite />
                                        ) : (
                                            <>
                                                <CheckIcon className="check-icon" />
                                                <SaveButtonText className="save-button-text">
                                                    {
                                                        translation
                                                            .settingsPartner
                                                            .save
                                                    }
                                                </SaveButtonText>
                                            </>
                                        )}
                                    </UpdateIdentity>
                                </UpdateStettings>
                            ) : (
                                <Edit onClick={() => setIsEditing(true)}>
                                    <EditIcon />
                                    <EditButtonText>
                                        {translation.settingsPartner.edit}
                                    </EditButtonText>
                                </Edit>
                            )}
                        </CardHeader>

                        <FormContainer>
                            <LeftContainer>
                                <FormField>
                                    <PrimaryLabel
                                        isError={!!firstNameError}
                                        isSuccess={firstNameSuccess}
                                    >
                                        {translation.settingsPartner.firstName}
                                    </PrimaryLabel>
                                    <FormInput40
                                        name="firstName"
                                        value={formUser?.firstName}
                                        onChange={handleInputChanges}
                                        disabled={!isEditing}
                                        onBlur={firstNameValidation}
                                        onFocus={firstNameOnFocus}
                                        className={
                                            firstNameError
                                                ? 'border-error'
                                                : firstNameSuccess
                                                ? 'border-success'
                                                : ''
                                        }
                                    />
                                    <ErrorBlock>
                                        {firstNameError.length !== 0 ? (
                                            <ErrorMessage>
                                                {firstNameError}
                                            </ErrorMessage>
                                        ) : (
                                            ''
                                        )}
                                    </ErrorBlock>
                                </FormField>
                                <FormField>
                                    <PrimaryLabel
                                        isError={!!lastNameError}
                                        isSuccess={lastNameSuccess}
                                    >
                                        {translation.settingsPartner.lastName}
                                    </PrimaryLabel>

                                    <FormInput40
                                        name="lastName"
                                        value={formUser?.lastName}
                                        onChange={handleInputChanges}
                                        disabled={!isEditing}
                                        onBlur={lastNameValidation}
                                        onFocus={lastNameOnFocus}
                                        className={
                                            lastNameError
                                                ? 'border-error'
                                                : lastNameSuccess
                                                ? 'border-success'
                                                : ''
                                        }
                                    />
                                    <ErrorBlock>
                                        {lastNameError.length !== 0 ? (
                                            <ErrorMessage>
                                                {lastNameError}
                                            </ErrorMessage>
                                        ) : (
                                            ''
                                        )}
                                    </ErrorBlock>
                                </FormField>
                            </LeftContainer>
                            <RightContainer>
                                <FormField>
                                    <PrimaryLabel
                                        isError={!!emailError}
                                        isSuccess={emailSuccess}
                                    >
                                        {translation.settingsPartner.workEmail}
                                    </PrimaryLabel>
                                    <FormInput40
                                        name="email"
                                        value={formUser?.email}
                                        onChange={handleInputChanges}
                                        disabled={!isEditing}
                                        onBlur={emailValidation}
                                        onFocus={emailOnFocus}
                                        className={
                                            emailError
                                                ? 'border-error'
                                                : emailSuccess
                                                ? 'border-success'
                                                : ''
                                        }
                                    />
                                    <ErrorBlock>
                                        {emailError.length !== 0 ? (
                                            <ErrorMessage>
                                                {emailError}
                                            </ErrorMessage>
                                        ) : (
                                            ''
                                        )}
                                    </ErrorBlock>
                                </FormField>
                                <FormField>
                                    <PrimaryLabel
                                        isError={!!phoneError}
                                        isSuccess={phoneSuccess}
                                    >
                                        {translation.settingsPartner.phone}
                                    </PrimaryLabel>
                                    <FlexInputContainer>
                                        <CountryCodeDropdown
                                            selectDropdownCB={(country) =>
                                                handleCountryCodeChange(country)
                                            }
                                            error={false}
                                            success={false}
                                            disabled={!isEditing}
                                            previouslySelectedCountryCode={
                                                formUser?.country
                                                    .calling_code ?? '+45'
                                            }
                                        />
                                        <PhoneInputContainer>
                                            <FormInput40
                                                name="phone"
                                                value={formUser?.phone}
                                                onChange={handleInputChanges}
                                                disabled={!isEditing}
                                                onBlur={phoneValidation}
                                                onFocus={phoneOnFocus}
                                                className={
                                                    phoneError
                                                        ? 'border-error'
                                                        : phoneSuccess
                                                        ? 'border-success'
                                                        : ''
                                                }
                                            />
                                        </PhoneInputContainer>
                                    </FlexInputContainer>
                                    <ErrorBlock>
                                        {phoneError.length !== 0 ? (
                                            <ErrorMessage>
                                                {phoneError}
                                            </ErrorMessage>
                                        ) : (
                                            ''
                                        )}
                                    </ErrorBlock>
                                </FormField>
                            </RightContainer>
                        </FormContainer>
                        {saveChangesMessage && (
                            <SuccessMessage>
                                <CheckSavedMessageIcon />
                                {saveChangesMessage}
                            </SuccessMessage>
                        )}
                        {identityErrors && (
                            <UpdateIdentityErrorMessage>
                                <CancelIcon />
                                {identityErrors}
                            </UpdateIdentityErrorMessage>
                        )}
                    </Card>
                </TempCardContainer>
                <TempCardContainer style={{ width: '50%' }}>
                    <DefaultLanguage />
                </TempCardContainer>
            </div>
            <TempCardContainer>
                <ChangePassword />
            </TempCardContainer>
            {/* <Card
                style={{
                    height: '28rem',
                }}
            >
                <CardHeader>Notifications</CardHeader>

                <ChangeSettingContainer>
                    <ToggleLabel>
                        <ToggleInput type="checkbox" />
                        <ToggleSwitch className="switch" />
                    </ToggleLabel>
                    <div>
                        <ChangeSettingHeading>New case</ChangeSettingHeading>
                        <ChangeSettingTagline>
                            Receive a notification on your mail, whenever a new
                            case is received
                        </ChangeSettingTagline>
                    </div>
                </ChangeSettingContainer>
                <ChangeSettingContainer>
                    <ToggleLabel>
                        <ToggleInput type="checkbox" />
                        <ToggleSwitch className="switch" />
                    </ToggleLabel>
                    <div>
                        <ChangeSettingHeading>
                            Update from active case
                        </ChangeSettingHeading>
                        <ChangeSettingTagline>
                            Receive a notification on your mail, whenever you
                            receive a new message from a whistleblower
                        </ChangeSettingTagline>
                    </div>
                </ChangeSettingContainer>
                <SaveButton isActive={true}>Save</SaveButton>
            </Card> */}
        </>
    )
}

export default MyAccount
