import styled from 'styled-components'

export const ModalContainer = styled.div`
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 500;
`

interface ModalProps {
    height: string
    width: string
}

export const Modal = styled.div<ModalProps>`
    height: ${({ height }) => height};
    width: ${({ width }) => width};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

export const ModalCancelButton = styled.p`
    font-size: 1.4rem;
    color: ${({ theme }) => `${theme.primary}50`};
    cursor: pointer;
`
