import SkeletonCard from '../../reusable/skeletons/SkeletonCard'
import {
    FilterBox,
    CasesBox,
    FlexContainer,
} from '../../reusable/templates/AllCasesPageTemplate'

const LoadingAllCasesTemplate: React.FC = () => {
    return (
        <div>
            <FlexContainer>
                <CasesBox>
                    <SkeletonCard />
                </CasesBox>
                <FilterBox>
                    <SkeletonCard />
                </FilterBox>
            </FlexContainer>
        </div>
    )
}

export default LoadingAllCasesTemplate
