export const getUrlAfterAppSubdomain = () => {
    const url = window.location.host
    const urlParts = window.location.host.split('.')

    if (
        urlParts[1] === 'whistleblower' ||
        urlParts[1] === 'admin' ||
        urlParts[1] === 'partner' ||
        urlParts[1] === 'client' ||
        urlParts[1] === 'contact'
    ) {
        return url.substring(url.indexOf(urlParts[1]) + urlParts[1].length + 1)
    }

    return url.substring(url.indexOf('.') + 1)
}

export const getFirstSubdomain = () => {
    return window.location.host.split('.')[0]
}

export const getSecondSubdomain = () => {
    return window.location.host.split('.')[1]
}
