import { useTypedSelector } from '../../../../hooks'
import WhistleblowerPolicyPage from './WhistleblowerPolicyPage'
import IOIPolicy from './custom/IOIPolicy'

interface WhistleblowerPolicyResolverProps {}

const WhistleblowerPolicyResolver: React.FC<
    WhistleblowerPolicyResolverProps
> = () => {
    const { customWbPolicyName } = useTypedSelector(
        (state) => state.whistleblower
    )

    const resolvePolicy = () => {
        switch (customWbPolicyName) {
            case 'ioi':
                return <IOIPolicy />
            default:
                return <WhistleblowerPolicyPage />
        }
    }

    return (
        <>
            {!customWbPolicyName ? (
                <WhistleblowerPolicyPage />
            ) : (
                resolvePolicy()
            )}
        </>
    )
}

export default WhistleblowerPolicyResolver
