import { useEffect, useState } from 'react'
import styled from 'styled-components'
import LineOfContactsFrame from '../../reusable/frames/LineOfContactsFrame'
import CompanyInformationFrame from '../../reusable/frames/CompanyInformationFrame'
import CompanyCustomizationsFrame from '../../reusable/frames/CompanyCustomizationsFrame'
// import CompanyInvoiceFrame from '../../reusable/frames/CompanyInvoiceFrame'
import { HeadingSecondary } from '../../styled/typography'
import { useActions, useTranslation, useTypedSelector } from '../../../hooks'
import LoadingClientInformationPage from '../loading/LoadingClientInformationPage'

const Container = styled.div`
    width: 100%;
    padding: 4rem;
    position: relative;
`

export const Navigation = styled.div`
    border-bottom: 1px solid ${({ theme }) => `${theme.primary}20`};
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: 2rem;
    margin-bottom: 2rem;

    @media screen and (max-width: 600px) {
        justify-content: center;
    }
`

interface IsActive {
    isActive: boolean
}

export const NavigationLink = styled.div<IsActive>`
    padding-bottom: 1rem;
    opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
    color: ${({ theme }) => theme.primary};
    font-size: 1.8rem;
    font-weight: 600;
    margin-right: 3.5rem;
    border-bottom: ${({ theme, isActive }) =>
        isActive ? `2px solid ${theme.blue} ` : 'none'};
    cursor: pointer;

    @media screen and (max-width: 600px) {
        font-size: 1.5rem;
        margin-right: 1.5rem;
    }
`

const NotEssentialClientInformationPage = styled(Navigation)`
    // display: none;
`

export const MobileAlert = styled.p`
    @media screen and (max-width: 480px) {
        display: flex;
        justify-content: center;
        padding: 1rem 0.5rem 1rem 0.5rem;
        margin: 0 0 2rem 0;
        font-size: 1.2rem;
        border: 1px solid rgba(50, 85, 119, 0.7);
        border-radius: 5rem;
        color: ${({ theme }) => theme.white};
        background-color: rgba(50, 85, 119, 0.7);
    }
    display: none;
`

const ClientInformationPage: React.FC = () => {
    const { clientLoading, client } = useTypedSelector((state) => state.client)
    const { user } = useTypedSelector((state) => state.auth)

    const { getCompanyInformation } = useActions()

    const translation = useTranslation()

    const [currentInnerFrameNumber, setCurrentInnerFrameNumber] = useState(1)
    const [currentInnerFrame, setCurrentInnerFrame] = useState(
        <CompanyInformationFrame
            isClientUser={true}
            client={client}
            clientOrganization={client?.organization.data}
        />
    )

    useEffect(() => {
        if (currentInnerFrameNumber === 1) {
            setCurrentInnerFrame(
                <CompanyInformationFrame
                    isClientUser={true}
                    client={client}
                    clientOrganization={client?.organization.data}
                />
            )
        } else if (currentInnerFrameNumber === 2) {
            setCurrentInnerFrame(<LineOfContactsFrame />)
        } else if (currentInnerFrameNumber === 3) {
            setCurrentInnerFrame(<CompanyCustomizationsFrame />)
        }
        // eslint-disable-next-line
    }, [currentInnerFrameNumber, client])

    useEffect(() => {
        !client && getCompanyInformation()
        // eslint-disable-next-line
    }, [])

    return (
        <>
            {user?.roles.includes('client') && (
                <Container>
                    {clientLoading ? (
                        <LoadingClientInformationPage />
                    ) : (
                        <>
                            <MobileAlert>
                                For full content and editing, access the desktop
                                version.
                            </MobileAlert>
                            <HeadingSecondary>
                                {client?.organization.data.name}
                            </HeadingSecondary>
                            <NotEssentialClientInformationPage>
                                <NavigationLink
                                    isActive={currentInnerFrameNumber === 1}
                                    onClick={() =>
                                        setCurrentInnerFrameNumber(1)
                                    }
                                >
                                    {translation.clientView.information}
                                </NavigationLink>
                                <NavigationLink
                                    isActive={currentInnerFrameNumber === 2}
                                    onClick={() =>
                                        setCurrentInnerFrameNumber(2)
                                    }
                                >
                                    {translation.clientView.lineOfContact}
                                </NavigationLink>
                                <NavigationLink
                                    isActive={currentInnerFrameNumber === 3}
                                    onClick={() =>
                                        setCurrentInnerFrameNumber(3)
                                    }
                                >
                                    {translation.clientView.customization}
                                </NavigationLink>
                            </NotEssentialClientInformationPage>
                            {currentInnerFrame}
                        </>
                    )}
                </Container>
            )}
        </>
    )
}

export default ClientInformationPage
