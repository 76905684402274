import { Card, CardHeader } from '../../styled/cards'
import styled from 'styled-components'
import {
    FormField,
    FormInput40,
    PrimaryLabel,
    FormTextarea,
} from '../../styled/formElements'
import {
    RoundDeleteButton,
    DeleteIcon,
    BlueEditIcon,
    BlueEditButton,
} from '../../styled/buttons'
import { useTranslation, useTypedSelector, useActions } from '../../../hooks'
import CountryCodeDropdown from './CountryCodeDropdown'
import { useRef } from 'react'
import ButtonSpinnerWhite from '../../layout/spinner/ButtonSpinnerWhite'
import profilePlaceholder from '../../../assets/images/profile.svg'
import { CountryInterface } from '../../../state/reducers/countriesReducer'
import { UpdateContactInterface } from '../../../state/action-creators'

const HeaderButtonFlex = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`
const ContactPhotoCircle = styled.div`
    height: 10rem;
    width: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${({ theme }) => theme.grey};
    border-radius: 100%;
`

const ProfileImg = styled.label`
    height: 15rem;
    width: 15rem;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    cursor: pointer;
    font-size: 1.2rem;
    color: ${({ theme }) => theme.primary};
    gap: 1rem;
`

const Image = styled.img`
    width: 12rem;
    height: 12rem;
    border: 1px solid ${({ theme }) => theme.grey};
    border-radius: 100%;
`

const HiddenProfileImgInput = styled.input`
    height: 15rem;
    width: 15rem;
    display: flex;
    align-items: center;
    justify-content: center;
`

const FormBody = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 2rem;
    max-width: 100%;
`

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 100%;
    padding: 0 3rem 0 0;
`

const FlexInputContainer = styled.div`
    display: flex;
    gap: 16px;
`

const CancelButton = styled.p`
    font-size: 1.4rem;
    color: ${({ theme }) => `${theme.primary}60`};
    cursor: pointer;
    padding: 1.4rem;
`

const BlackButton = styled.div<BlackButtonProps>`
    height: 2.7rem;
    padding: 0 1.6rem;

    display: flex;
    align-items: center;
    background: ${({ theme, isActive }) =>
        !isActive ? `${theme.black}50` : theme.black};
    color: ${({ theme }) => theme.white};
    font-size: 1.4rem;
    border-radius: 0.5rem;
    cursor: ${({ isActive }) => (isActive ? 'pointer' : 'not-allowed')};
`

const NameEmailPhoneRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1.6rem;
`

const InfoBelowDisplayedWhistleblowerLink = styled.p`
    border-top: 2px solid ${({ theme }) => theme.lineGrey};
    border-top-style: dotted;
    padding: 1rem 0 2rem 0;
    color: ${({ theme }) => theme.greyText};
`

const PositionNotesLanguagesRow = styled.div`
    display: flex;
    gap: 2rem;
`

const LeftSide = styled.div`
    flex: 33%;
    display: flex;
    flex-direction: column;
`
const RightSide = styled.div`
    flex: 66%;
    display: flex;
    flex-direction: column;
`

const NotesWrapper = styled.div`
    width: 100%;
    height: 14rem;
    display: flex;
    flex-direction: column;
`

const LanguagesWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 1rem;
`

const LanguagesSpoken = styled.div<IsEditing>`
    border: 2px solid ${({ theme }) => theme.lineGrey};
    border-radius: 0.5rem;
    background-color: ${({ isEditing }) =>
        isEditing ? '' : 'rgba(239, 239, 239, 0.3)'};
    margin-top: 0.4rem;
    padding: 0 1rem;
    height: 4rem;
    width: 10rem;
    display: flex;
    gap: 0.2rem;
    justify-content: center;
    align-items: center;
`

const CountryFlag = styled.img`
    height: 1.7rem;
    width: 1.7rem;
    border-radius: 50%;
    margin-right: 0.5rem;
    border: 1px solid black;
`

interface BlackButtonProps {
    isActive: boolean
}

interface IsEditing {
    isEditing: boolean
}

interface MiniPersonFormProps {
    contact: any
    contactNumber: Number
    setContactToDelete: (contact: any) => void
    openIsDeleteContactModal: () => void
    cancelEditingCallback: () => void
    handleChangeContact: (contactId: number, field: string, value: any) => void
    profileImgSrc?: string
    isEditing: boolean
    setIsEditModeActive: React.Dispatch<React.SetStateAction<boolean>>
    setContactToEdit: React.Dispatch<any>
}

const MiniPersonForm: React.FC<MiniPersonFormProps> = ({
    contact,
    contactNumber,
    setContactToDelete,
    openIsDeleteContactModal,
    cancelEditingCallback,
    handleChangeContact,
    profileImgSrc,
    isEditing,
    setIsEditModeActive,
    setContactToEdit,
}) => {
    const translation = useTranslation()

    const { client } = useTypedSelector((state) => state.client)

    const { lineOfContactLoading, lineOfContactUpdateLoading } =
        useTypedSelector((state) => state.lineOfContact)

    const { updateContact } = useActions()

    const originalContact = useRef(contact)
    const { countries } = useTypedSelector((state) => state.country)

    const handleClickDelete = () => {
        setContactToDelete(contact)
        openIsDeleteContactModal()
    }

    const enableEditMode = () => {
        setContactToEdit(contact)
        setIsEditModeActive(true)
    }

    const disableEditMode = () => {
        setIsEditModeActive(false)
    }

    const handleSaveContactChanges = async () => {
        const newContactInfo: UpdateContactInterface = {
            contact: {},
            contact_information: {},
            languages: [],
        }

        const contactKeys: (keyof UpdateContactInterface['contact'])[] = [
            'first_name',
            'last_name',
            'email',
        ]

        const contactInformationKeys: (keyof UpdateContactInterface['contact_information'])[] =
            ['position', 'priority', 'note']

        contactKeys.forEach((key) => {
            if (contact[key] !== originalContact.current[key]) {
                newContactInfo.contact[key] = contact[key]
            }
        })

        if (
            contact.phone.phone !== originalContact.current.phone.phone ||
            contact.country_id !== originalContact.current.country_id
        ) {
            newContactInfo.contact.phone = contact.phone.phone
            newContactInfo.contact.country_id = contact.country_id
        }

        contactInformationKeys.forEach((key) => {
            if (
                contact.contact_information[key] !==
                originalContact.current.contact_information[key]
            ) {
                newContactInfo.contact_information[key] =
                    contact.contact_information[key]
            }
        })

        if (contact.languages !== originalContact.current.languages) {
            newContactInfo.languages = contact.languages.map(
                (language: CountryInterface) => ({
                    country_id: language.id,
                })
            )
        }

        if (contact.contact_information.profile_img instanceof File) {
            newContactInfo.contact_information.profile_img =
                contact.contact_information.profile_img
        }

        await updateContact(client.id, contact.id, newContactInfo)

        disableEditMode()
    }

    const checkIfFormValidated = () => {
        return (
            contact.first_name !== originalContact.current.first_name ||
            contact.last_name !== originalContact.current.last_name ||
            contact.phone.phone !== originalContact.current.phone.phone ||
            contact.country_id !== originalContact.current.country_id ||
            contact.email !== originalContact.current.email ||
            contact.languages !== originalContact.current.languages ||
            contact.contact_information?.position !==
                originalContact.current.contact_information?.position ||
            contact.contact_information?.priority !==
                originalContact.current.contact_information?.priority ||
            contact.contact_information?.note !==
                originalContact.current.contact_information?.note ||
            contact.contact_information?.profile_img !==
                originalContact.current.contact_information?.profile_img
        )
    }

    return (
        <Card overflow="visible">
            {contact && (
                <>
                    <CardHeader>
                        Person {contactNumber}
                        {!isEditing ? (
                            <HeaderButtonFlex>
                                <BlueEditButton onClick={enableEditMode}>
                                    <BlueEditIcon size={14} />
                                </BlueEditButton>
                                {client.id !== contact.id && (
                                    <RoundDeleteButton
                                        onClick={handleClickDelete}
                                    >
                                        {lineOfContactLoading ? (
                                            <ButtonSpinnerWhite />
                                        ) : (
                                            <DeleteIcon size={14} />
                                        )}
                                    </RoundDeleteButton>
                                )}
                            </HeaderButtonFlex>
                        ) : (
                            <HeaderButtonFlex>
                                <CancelButton
                                    onClick={() => {
                                        cancelEditingCallback()
                                        disableEditMode()
                                    }}
                                >
                                    Cancel
                                </CancelButton>
                                <BlackButton
                                    onClick={handleSaveContactChanges}
                                    isActive={checkIfFormValidated()}
                                >
                                    {lineOfContactLoading ? (
                                        <ButtonSpinnerWhite />
                                    ) : (
                                        <>
                                            {lineOfContactUpdateLoading ? (
                                                <ButtonSpinnerWhite />
                                            ) : (
                                                translation
                                                    .singleClientViewPartner
                                                    .buttonSave
                                            )}
                                        </>
                                    )}
                                </BlackButton>
                            </HeaderButtonFlex>
                        )}
                    </CardHeader>
                    <FormBody>
                        <ContactPhotoCircle>
                            {!contact.contact_information.profile_img ? (
                                <ProfileImg
                                    htmlFor={`profileImgFile${contact.id}`}
                                >
                                    <Image
                                        src={profilePlaceholder}
                                        alt="img placeholder"
                                    />
                                    <p>Change photo</p>
                                </ProfileImg>
                            ) : (
                                <>
                                    <ProfileImg
                                        htmlFor={`profileImgFile${contact.id}`}
                                    >
                                        {contact.contact_information
                                            .profile_img instanceof File ? (
                                            <>
                                                <Image
                                                    src={URL.createObjectURL(
                                                        contact
                                                            .contact_information
                                                            .profile_img
                                                    )}
                                                    alt="img selected"
                                                />
                                                <p>Change photo</p>
                                            </>
                                        ) : contact.contact_information
                                              .profile_img ? (
                                            <>
                                                <Image
                                                    src={
                                                        contact
                                                            .contact_information
                                                            .profile_img
                                                    }
                                                    alt="profile img"
                                                />
                                                <p>Change photo</p>
                                            </>
                                        ) : (
                                            <>
                                                <Image
                                                    src={profilePlaceholder}
                                                    alt="img placeholder"
                                                />
                                                <p>Add photo</p>
                                            </>
                                        )}
                                    </ProfileImg>
                                </>
                            )}
                            <HiddenProfileImgInput
                                type="file"
                                id={`profileImgFile${contact.id}`}
                                accept="image/jpeg, image/jpg, image/png, image/svg+xml"
                                onChange={(e) => {
                                    if (
                                        e.target.files &&
                                        e.target.files.length > 0
                                    ) {
                                        handleChangeContact(
                                            contact.id,
                                            'profile_img',
                                            e.target.files[0]
                                        )
                                    }
                                }}
                            />
                        </ContactPhotoCircle>
                        <FormContainer>
                            <NameEmailPhoneRow>
                                <FormField>
                                    <PrimaryLabel htmlFor="first_name">
                                        {translation.clientView.firstName}
                                    </PrimaryLabel>
                                    <FormInput40
                                        type="text"
                                        id="first_name"
                                        name="first_name"
                                        value={contact.first_name}
                                        disabled={!isEditing}
                                        onChange={(e) =>
                                            handleChangeContact(
                                                contact.id,
                                                'first_name',
                                                e.target.value
                                            )
                                        }
                                    />
                                </FormField>
                                <FormField>
                                    <PrimaryLabel htmlFor="last_name">
                                        {translation.clientView.lastName}
                                    </PrimaryLabel>
                                    <FormInput40
                                        type="text"
                                        id="last_name"
                                        name="last_name"
                                        value={contact.last_name}
                                        disabled={!isEditing}
                                        onChange={(e) =>
                                            handleChangeContact(
                                                contact.id,
                                                'last_name',
                                                e.target.value
                                            )
                                        }
                                    />
                                </FormField>
                                <FormField>
                                    <PrimaryLabel htmlFor="email">
                                        {translation.clientView.workEmail}
                                    </PrimaryLabel>
                                    <FormInput40
                                        type="email"
                                        id="email"
                                        name="email"
                                        value={contact.email}
                                        disabled={!isEditing}
                                        onChange={(e) =>
                                            handleChangeContact(
                                                contact.id,
                                                'email',
                                                e.target.value
                                            )
                                        }
                                    />
                                </FormField>
                                <FormField>
                                    <PrimaryLabel>
                                        {translation.clientView.phone}
                                    </PrimaryLabel>
                                    <FlexInputContainer>
                                        <CountryCodeDropdown
                                            previouslySelectedCountryCode={
                                                contact?.phone.country_code
                                            }
                                            error={false}
                                            success={false}
                                            selectDropdownCB={(country) => {
                                                handleChangeContact(
                                                    contact.id,
                                                    'country_id',
                                                    country.id
                                                )
                                            }}
                                            disabled={!isEditing}
                                        />
                                        <FormInput40
                                            value={contact.phone.phone}
                                            disabled={!isEditing}
                                            onChange={(e) =>
                                                handleChangeContact(
                                                    contact.id,
                                                    'phone',
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </FlexInputContainer>
                                </FormField>
                            </NameEmailPhoneRow>
                            <InfoBelowDisplayedWhistleblowerLink>
                                {
                                    translation.clientView
                                        .willBeDisplayedReportLink
                                }
                            </InfoBelowDisplayedWhistleblowerLink>
                            <PositionNotesLanguagesRow>
                                <LeftSide>
                                    <FormField>
                                        <PrimaryLabel htmlFor="position">
                                            {translation.clientView.position}
                                        </PrimaryLabel>
                                        <FormInput40
                                            value={
                                                contact.contact_information
                                                    .position
                                            }
                                            id="position"
                                            name="position"
                                            disabled={!isEditing}
                                            onChange={(e) =>
                                                handleChangeContact(
                                                    contact.id,
                                                    'position',
                                                    e.target.value
                                                )
                                            }
                                            placeholder="Please add your position"
                                        />
                                    </FormField>
                                    <PrimaryLabel>
                                        {translation.clientView.languages}
                                    </PrimaryLabel>
                                    <LanguagesWrapper>
                                        <LanguagesSpoken isEditing={isEditing}>
                                            {countries &&
                                                contact.languages?.map(
                                                    (language: any) => {
                                                        return (
                                                            <CountryFlag
                                                                key={
                                                                    language.country_id
                                                                }
                                                                src={
                                                                    language.flag
                                                                }
                                                                alt="country flag"
                                                            />
                                                        )
                                                    }
                                                )}
                                        </LanguagesSpoken>

                                        {contact.languages.length < 4 && (
                                            <CountryCodeDropdown
                                                error={false}
                                                success={false}
                                                selectDropdownCB={(country) => {
                                                    handleChangeContact(
                                                        contact.id,
                                                        'languages',
                                                        country
                                                    )
                                                }}
                                                disabled={!isEditing}
                                                isLanguagesDropdown={true}
                                            />
                                        )}
                                    </LanguagesWrapper>
                                </LeftSide>

                                <RightSide>
                                    <NotesWrapper>
                                        <PrimaryLabel htmlFor="note">
                                            {translation.clientView.notes}
                                        </PrimaryLabel>
                                        <FormTextarea
                                            id="note"
                                            name="note"
                                            maxLength={400}
                                            placeholder="Type a note here ..."
                                            value={
                                                contact.contact_information.note
                                            }
                                            onChange={(e) =>
                                                handleChangeContact(
                                                    contact.id,
                                                    'note',
                                                    e.target.value
                                                )
                                            }
                                            rows={8}
                                            disabled={!isEditing}
                                        />
                                    </NotesWrapper>
                                </RightSide>
                            </PositionNotesLanguagesRow>
                        </FormContainer>
                    </FormBody>
                </>
            )}
        </Card>
    )
}

export default MiniPersonForm
