import styled from 'styled-components'
import { ReactComponent as EditIcon } from '../../../../../../assets/images/edit-icon.svg'
import { useEffect, useState } from 'react'
import {
    useActions,
    useTranslation,
    useTypedSelector,
} from '../../../../../../hooks'
import { ReactComponent as CheckIcon } from '../../../../../../assets/images/check-icon-circle.svg'
import { AvailableLanguagesInterface } from '../../../../../../state/reducers/availableLanguagesReducer'
import { ReactComponent as CancelIcon } from '../../../../../../assets/images/x-icon.svg'
import ButtonSpinnerWhite from '../../../../../layout/spinner/ButtonSpinnerWhite'
import { Modal, ModalContainer } from '../../../../../styled/modals'
import { Card } from '../../../../../styled/cards'

const LanguageContainerWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid ${({ theme }) => theme.grey};
    border-radius: 1rem;
    padding-inline: 1rem;
    padding-block: 0.5rem;
    margin-block-start: 2rem;
`

const EditButton = styled(EditIcon)`
    width: 1.5rem;
    cursor: pointer;
`
const SaveButton = styled(CheckIcon)`
    width: 1.5rem;
    cursor: pointer;
    color: ${({ theme }) => theme.primary};
`
const CloseIcon = styled(CancelIcon)`
    width: 1.5rem;
    cursor: pointer;
    && .cancel-icon {
        stroke: ${({ theme }) => theme.primary};
        stroke-width: 1.5;
    }
`

const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`

const LanguageContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`

const LanguageTitle = styled.p`
    font-size: 1.5rem;
`

const LanguageText = styled.p`
    font-size: 1.5rem;
    opacity: 0.5;
`

const LanguageImage = styled.img`
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 50%;

    @media screen and (max-width: 600px) {
        display: none;
    }
`

const LanguageSelectContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`

const DropdownBox = styled.div`
    margin-inline-start: 19rem;
    margin-inline-end: 0.5rem;
    border: 1px solid ${({ theme }) => theme.lineGrey};
    margin-block-start: 0.2rem;
    padding-block: 1rem;
    padding-inline-end: 12rem;
    border-radius: 0.5rem;
    position: absolute;
    background-color: ${({ theme }) => theme.white};

    @media screen and (max-width: 600px) {
        padding-inline-end: 5rem;
    }
`

const LanguageWrapper = styled.div`
    padding-inline-start: 1rem;
    margin-inline-start: 1rem;
    padding-block: 0.3rem;
    margin-inline-start: 0.3rem;
    cursor: pointer;
`
const ModalHeading = styled.div`
    padding-inline-start: 35rem;
    padding-block-start: 0.5rem;
    color: ${({ theme }) => theme.lineGrey};
    font-size: 1.6rem;
    cursor: pointer;
`

const ModalErrorHeadline = styled.h2`
    opacity: 0.5;
    font-size: 1.8rem;
`

const ModalErrorText = styled.p`
    color: ${({ theme }) => theme.red};
    font-size: 1.5rem;
`

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    padding-inline: 6rem;
    padding-block: 7rem;
`

const ReportingChannelLanguage: React.FC = () => {
    const translation = useTranslation()

    const { getAvailableLanguages, updateReportingChannelLanguage } =
        useActions()

    const {
        availableLanguages,
        availableLanguagesLoading,
        availableLanguagesError,
    } = useTypedSelector((state) => state.availableLanguages)

    const { reportingChannelLanguage } = useTypedSelector(
        (state) => state.whistleblower
    )

    const { client } = useTypedSelector((state) => state.client)

    const [isDropdownActive, setIsDropdownActive] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [selectedLanguage, setSelectedLanguage] =
        useState<AvailableLanguagesInterface | null>(null)

    const [previousLanguage, setPreviousLanguage] =
        useState<AvailableLanguagesInterface | null>(null)

    const [showModal, setShowModal] = useState(true)

    useEffect(() => {
        getAvailableLanguages()
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (reportingChannelLanguage) {
            const languageSelected: any = availableLanguages?.find(
                (language) => language.code === reportingChannelLanguage
            )
            setSelectedLanguage(languageSelected)
        }
        //eslint-disable-next-line
    }, [reportingChannelLanguage, availableLanguages])

    const toggleDropDown = () => {
        setIsDropdownActive(!isDropdownActive)
    }

    const selectDefaultLanguage = (language: AvailableLanguagesInterface) => {
        setSelectedLanguage(language)
        toggleDropDown()
        setPreviousLanguage(selectedLanguage)
    }

    const handleCancel = () => {
        setIsEditing(false)
        setIsDropdownActive(false)
        setShowModal(false)
        if (previousLanguage) {
            setSelectedLanguage(previousLanguage)
        }
    }

    const handleLanguageSave = async () => {
        const payload = {
            reporting_channel_language_id: selectedLanguage?.id,
        }

        const success = await updateReportingChannelLanguage(
            client.id,
            client.organization.data.id,
            payload
        )

        setIsEditing(false)

        return success
    }

    return (
        <div>
            {availableLanguagesError && showModal && (
                <ModalContainer>
                    <Modal height="20rem" width="40rem">
                        <Card>
                            <ModalHeading onClick={handleCancel}>
                                {translation.closeButton.close}
                            </ModalHeading>
                            <ModalContent>
                                <ModalErrorHeadline>
                                    {
                                        translation.clientView
                                            .reportingChannelLanguageError
                                    }
                                </ModalErrorHeadline>
                                <ModalErrorText>
                                    {availableLanguagesError}
                                </ModalErrorText>
                            </ModalContent>
                        </Card>
                    </Modal>
                </ModalContainer>
            )}
            <LanguageContainerWrapper>
                <LanguageContainer>
                    <LanguageTitle>
                        {translation.clientView.reportingChannelLanguage}
                    </LanguageTitle>
                    <LanguageSelectContainer>
                        <LanguageText>{selectedLanguage?.name}</LanguageText>
                        <LanguageImage
                            src={selectedLanguage?.country.flag}
                            alt=""
                        />
                    </LanguageSelectContainer>
                </LanguageContainer>
                {isEditing ? (
                    <ButtonWrapper>
                        <CloseIcon onClick={handleCancel} />

                        {availableLanguagesLoading ? (
                            <ButtonSpinnerWhite overrideColor="black" />
                        ) : (
                            <SaveButton onClick={handleLanguageSave} />
                        )}
                    </ButtonWrapper>
                ) : (
                    <div onClick={toggleDropDown}>
                        <EditButton
                            onClick={() => setIsEditing(true)}
                            className="edit-buttton"
                        />
                    </div>
                )}
            </LanguageContainerWrapper>
            {isDropdownActive && (
                <DropdownBox>
                    {availableLanguages?.map((language) => {
                        if (language.code !== selectedLanguage?.code) {
                            return (
                                <LanguageWrapper
                                    key={language.id}
                                    onClick={() =>
                                        selectDefaultLanguage(language)
                                    }
                                >
                                    <LanguageText>{language.name}</LanguageText>
                                </LanguageWrapper>
                            )
                        }
                        return <></>
                    })}
                </DropdownBox>
            )}
        </div>
    )
}

export default ReportingChannelLanguage
