import { ReportQuestionInterface } from './../../ts/interfaces/ReportQuestionInterface'
import produce from 'immer'
import { ReportQuestionActionType } from '../action-types'
import { ReportQuestionActions } from '../actions/ReportQuestionActions'
import CustomDesingState from './customDesignReducer'
interface ReportQuestionState {
    reportQuestionsLoading: boolean,
    reportQuestions: ReportQuestionInterface[],
    customDesignData:{},
}

const InitialState: ReportQuestionState = {
    reportQuestionsLoading: false,
    reportQuestions: [],
    customDesignData: CustomDesingState,
}

const reducer = produce(
    (
        state: ReportQuestionState = InitialState,
        action: ReportQuestionActions
    ) => {
        switch (action.type) {
            case ReportQuestionActionType.SET_REPORT_QUESTION_LOADING:
                state.reportQuestionsLoading = true

                return state

            case ReportQuestionActionType.GET_REPORT_QUESTIONS:
                state.reportQuestionsLoading = false
                state.reportQuestions = action.payload

                return state

            case ReportQuestionActionType.ADD_REPORT_QUESTION:
                state.reportQuestionsLoading = false
                state.reportQuestions = [
                    ...state.reportQuestions,
                    action.payload,
                ]

                return state

            case ReportQuestionActionType.PUT_REPORT_QUESTION:
                state.reportQuestionsLoading = false
                state.reportQuestions = state.reportQuestions.map(
                    (questionItem) => {
                        if (questionItem.id === action.payload.oldId) {
                            return action.payload.newQuestion
                        }
                        return questionItem
                    }
                )

                return state
            case ReportQuestionActionType.DELETE_REPORT_QUESTION:
                state.reportQuestionsLoading = false
                state.reportQuestions = state.reportQuestions.filter(
                    (questionItem) => {
                        return questionItem.id !== action.payload
                    }
                )

                return state

            default:
                return state
        }
    }
)

export default reducer
