import React, { useState } from 'react'
import styled from 'styled-components'
import { FiHelpCircle, FiX } from 'react-icons/fi'
import { useTranslation } from '../../../../../hooks'

const Container = styled.div`
    height: 16.7rem;
    width: 34.7rem;
    background: #deeaf5;
    position: fixed;
    z-index: 500;
    bottom: 2rem;
    right: 2rem;
    border-radius: 2rem;
    padding: 2rem;
    text-align: left;

    @media screen and (max-width: 600px) {
        width: 32rem;
    }
`

const Heading = styled.h3`
    font-size: 1.6rem;
    font-weight: 600;
    color: ${({ theme }) => theme.black};
    margin-bottom: 2rem;
`

const ExampleQuestion = styled.p`
    font-size: 1.4rem;
    color: ${({ theme }) => theme.black};
    margin-top: 1rem;
`

const ExitIcon = styled(FiX)`
    background: ${({ theme }) => theme.primary};
    color: white;
    position: absolute;
    cursor: pointer;
    top: 1.5rem;
    right: 1rem;
    font-size: 2.2rem;
    border-radius: 50%;
`

const HelpIconBox = styled.div`
    height: 4rem;
    width: 4rem;
    border-radius: 2rem;
    background: ${({ theme }) => theme.primary};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    position: fixed;
    bottom: 2.8rem;
    right: 2.8rem;
`

const HelpIcon = styled(FiHelpCircle)`
    color: #fff;
    font-size: 2.2rem;
`
const InformationYouCouldInclude: React.FC = () => {
    const translation = useTranslation()

    const [isClosed, setIsClosed] = useState(false)

    return (
        <>
            {!isClosed ? (
                <Container>
                    <Heading>
                        {translation.newCaseFlow.informationYouCould}
                    </Heading>
                    <ExitIcon onClick={() => setIsClosed(true)} />
                    <ExampleQuestion>
                        {translation.newCaseFlow.whereAndWhen}
                    </ExampleQuestion>
                    <ExampleQuestion>
                        {translation.newCaseFlow.whatIsYourKnowledge}
                    </ExampleQuestion>
                    <ExampleQuestion>
                        {translation.newCaseFlow.whatDepartmentIsInvolved}
                    </ExampleQuestion>
                    <ExampleQuestion>
                        {translation.newCaseFlow.whatIsYourRelationship}
                    </ExampleQuestion>
                </Container>
            ) : (
                <HelpIconBox onClick={() => setIsClosed(false)}>
                    <HelpIcon />
                </HelpIconBox>
            )}
        </>
    )
}

export default InformationYouCouldInclude
